import React from "react";

import { ImageAsset, Venue } from "@greeter/core";
import { classNames } from "@greeter/util";

import VerifiedImage from "../../assets/verified.svg";
import DefaultImage from "../../assets/default.png";

import css from "./VenueIcon.module.scss";
import { LazyCoverImage, LazyImage } from "@greeter/matter";

export type VenueIconProps = {
  className?: string;
  showVerification?: boolean;
  venue?: Venue;
}

export const VenueIcon: React.FC<VenueIconProps> = ({ className, venue, showVerification }) => {
  const verified = venue?.verified ?? false;

  function buildVerified() {
    if (verified && showVerification) {
      return (
        <LazyImage
          className={css.verified}
          alt="✔"
          src={VerifiedImage}
          defaultImage={DefaultImage}
        />
      );
    }
  }

  return (
    <div {...classNames(css.venueIcon, className)}>
      <LazyCoverImage
        className={css.picture}
        alt="Venue icon"
        src={venue?.logoAsset ? ImageAsset.findUriWithSizeOrDefault(venue.logoAsset, "1x1-w256") : "Placeholder"}
      />
      {buildVerified()}
    </div>
  );
};
