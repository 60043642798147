import React from "react";

import { Booking, Order, Venue } from "@greeter/core";
import { BookingVenueIcon } from "./BookingVenueIcon";

export type ImageColumnProps = {
  venue: Venue;
  booking: Booking;
}

export const ImageColumn: React.FC<ImageColumnProps> = ({ venue, booking }) => {
  return (
    <BookingVenueIcon venue={venue} booking={booking} />
  );
};
