import { IonContent } from "@ionic/react";
import React from "react";

const Content: React.FC<React.PropsWithChildren> = ({ children }) => (
  <IonContent style={{ backgroundColor: "transparent" }} fullscreen>
    {children}
  </IonContent>
);

export default Content;
