import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { VenuePage } from "./VenuePage";
import {
  useActivitiesQuery,
  useDaysliceConfigQuery,
  useGreeterEventsQuery,
  useProductsQuery,
  useThemesQuery,
  useVenueQuery,
  useVenuesQuery,
} from "@greeter/guest-api-hooks";
import { mapQuery } from "@greeter/util";
import { useDefaultGuestApi } from "@greeter-guest/api/api";
import { useIonViewWillLeave } from "@ionic/react";
import { usePersistentStore } from "@greeter/store";

export type VenuePageApiHandlerProps = {
  venueId: string;
}

export function VenuePageApiHandler({ venueId }: VenuePageApiHandlerProps) {
  const api = useDefaultGuestApi();

  const venueQuery = useVenueQuery(api, venueId);
  const greeterEventsQuery = useGreeterEventsQuery(api, venueId);
  const activitiesQuery = useActivitiesQuery(api, venueId);
  const themesQuery = useThemesQuery(api, venueId);
  const productsQuery = useProductsQuery(api, venueId);
  const venuesQuery = useVenuesQuery(
    api,
    (greeterEventsQuery.data ?? []).map((ge) => ge.venueId)
  );
  const daysliceConfigQuery = useDaysliceConfigQuery(api);

  const store = usePersistentStore<string>();

  useIonViewWillLeave(() => {
    if (!venueQuery.data) return;

    const venue = venueQuery.data;

    store.save("greeter.city", venue.address.city);
    store.save("greeter.country", venue.address.country);
  }, [venueQuery.data]);

  return (
    <VenuePage
      venue={mapQuery(venueQuery)}
      products={productsQuery.data}
      greeterEvents={greeterEventsQuery.data ?? []}
      activities={activitiesQuery.data ?? []}
      themes={themesQuery.data}
      venues={venuesQuery.data}
      daysliceConfig={daysliceConfigQuery.data!}
    />
  );
};
