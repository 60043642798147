import React from "react";

import { classNames } from "@greeter/util";
import { IonIcon } from "@ionic/react";
import css from "./AlertIcon.module.scss";
import { Props } from "@greeter/matter";

export type AlertIconProps = Props & { icon: string; }

export function AlertIcon({ className, ...props }: AlertIconProps) {
  return <IonIcon {...classNames(className, css.AlertIcon)} {...props} />;
}
