import React from "react";
import { IonContent, IonIcon, IonPage, isPlatform } from "@ionic/react";
import { classNames, doNothing, Query } from "@greeter/util";
import { logger, warner } from "@greeter/log";
import { TableId, TableServiceOrder, Venue } from "@greeter/core";

import css from "./ReceiptPage.module.scss";
import {
  Button,
  fromOrder,
  PulsingGreeter,
  Receipt,
  Skeleton,
  useHiddenTabBar,
} from "@greeter/matter";
import { Title } from "@greeter-guest/components/Title";
import { VenueIcon } from "@greeter-guest/components/VenueIcon";
import Routes from "@greeter-guest/utility/Routes";
import { downloadOutline } from "ionicons/icons";
import { Cart } from "@greeter/commerce";
import { useHistory } from "react-router-dom";
import { useGreeterGuestApi } from "@greeter/api";
import { useDefaultGuestApi } from "@greeter-guest/api/api";

const name = "[ReceiptPage]";
const warn = warner(name);
const log = logger(name);

export const LipContent: React.FC<React.HTMLProps<HTMLDivElement>> = ({
  className,
  ...props
}) => {
  return <div {...classNames(css.LipContent)} {...props} />;
};

export const LipTip: React.FC<React.HTMLProps<HTMLDivElement>> = ({
  className,
  ...props
}) => {
  return <div {...classNames(css.LipTip, className)} {...props} />;
};

export type LipProps = React.HTMLProps<HTMLDivElement> & {
  slots?: {
    tip?: React.ReactNode;
  };
};

export const Lip: React.FC<LipProps> = ({ className, ...props }) => {
  return <div {...classNames(css.Lip, className)} {...props} />;
};

export type ReceiptPageProps = {
  tableServiceOrder: Query<TableServiceOrder>;
  tableServiceOrderPdf: Query<URL>;
  venue: Query<Venue>;
  tableId: TableId;

  onDownloadPdf?: () => Promise<void>;
};

export const ReceiptPage: React.FC<ReceiptPageProps> = ({
  tableServiceOrder,
  tableServiceOrderPdf,
  onDownloadPdf = doNothing,
  venue,
  tableId,
}) => {
  const history = useHistory();
  const api = useDefaultGuestApi();

  useHiddenTabBar();

  return (
    <IonPage>
      <IonContent>
        <div className={css.ReceiptPage}>
          <Lip style={{ maxWidth: "80ch", margin: "auto" }}>
            <LipContent>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 2fr",
                  placeItems: "center",
                }}
              >
                <div className={css.Grid}>
                  {venue.type !== "done" ? (
                    <Skeleton
                      style={{
                        width: "5rem",
                        height: "5rem",
                        borderRadius: "100vw",
                      }}
                      pulse
                    />
                  ) : (
                    <VenueIcon className={css.VenueIcon} venue={venue.data} />
                  )}
                </div>
                <Title>
                  Dine drinks
                  <br />
                  er på vej 🥳
                </Title>
                <div className="hr" />
              </div>
              {tableServiceOrder.type === "done" && (
                <Receipt lines={fromOrder(tableServiceOrder.data.order)} />
              )}
              <div className={css.Hint}>
                <p>
                  Hold denne side åben indtil dine <br />
                  drinks ankommer eller
                </p>
                <div>
                  Status på ordre:{" "}
                  {tableServiceOrder.type === "done" ? (
                    tableServiceOrder.data.status === "pending" ? (
                      <b>Afventer at bartender godkender...</b>
                    ) : tableServiceOrder.data.status === "accepted" ? (
                      <b>
                        Bartender er gået i gang med at lave dine drinks!{" "}
                        <span role="img">🍸</span>
                      </b>
                    ) : tableServiceOrder.data.status === "completed" ? (
                      <b>
                        Dine drinks bliver serveret <span role="img">🥳</span>
                      </b>
                    ) : tableServiceOrder.data.status === "rejected" ? (
                      <span
                        style={{
                          backgroundColor: "var(--gm-color-danger)",
                          borderRadius: "2px",
                          padding: "2px",
                        }}
                      >
                        <b>
                          Din ordre blev afvist. Du kan gå op i baren og
                          bestille i stedet.
                        </b>
                      </span>
                    ) : (
                      "Henter status..."
                    )
                  ) : (
                    "Henter status..."
                  )}
                </div>
                <a
                  href={
                    tableServiceOrderPdf.type === "done"
                      ? tableServiceOrderPdf.data.toString()
                      : ""
                  }
                  target={isPlatform("ios") ? "_blank" : "_self"}
                  className={css.Download}
                >
                  {tableServiceOrderPdf.type === "loading" ? (
                    <PulsingGreeter
                      variant="black"
                      style={{ width: "2rem", height: "auto" }}
                    />
                  ) : (
                    <>
                      <IonIcon
                        icon={downloadOutline}
                        className={css.DownloadIcon}
                      />{" "}
                      <span className={css.DownloadSpan}>Gem kvittering</span>
                    </>
                  )}
                </a>
              </div>
            </LipContent>
            <LipTip>
              {venue.type === "done" && (
                <Button
                  className={css.OrderAgainBtn}
                  onClick={() => {
                    history.push(
                      Routes.cart.route({ venueId: venue.data.id, ...tableId })
                    );
                  }}
                >
                  Bestil det samme igen
                </Button>
              )}
              {venue.type === "done" && (
                <div
                  className={css.Finish}
                  onClick={() => {
                    log("Resetting the cart");
                    Cart.load().clear().save();
                    log("Routing to menu...");
                    history.push(
                      Routes.menu.route({
                        venueId: venue.data.id,
                        area: tableId.area,
                        tableNumber: tableId.tableNumber,
                      })
                    );
                  }}
                >
                  Tilbage til menu
                </div>
              )}
            </LipTip>
          </Lip>
        </div>
      </IonContent>
    </IonPage>
  );
};
