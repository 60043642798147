import React from "react";

import { classNames } from "@greeter/util";
import { Button, ButtonProps } from "../Button";

import css from "./GradientButton.module.scss";

export const GradientButton: React.FC<ButtonProps> = ({
  className,
  ...props
}) => {
  return <Button {...classNames(className, css.GradientButton)} {...props} />;
};
