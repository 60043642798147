import React from "react";
import { CenteredContent } from "../CenteredContent";

import { PulsingGreeter } from "../Loading/PulsingGreeter";
import { BasePropsWithChildren } from "../BaseProps";
import { FadeOutFadeIn } from "../Animation/FadeOutFadeIn";

export type LoadableProps = BasePropsWithChildren & {
  dependsOn?: React.DependencyList | any;
  loadingComponent?: React.ReactNode;
};

export const DefaultLoader: React.FC = () => (
  <CenteredContent>
    <PulsingGreeter />
  </CenteredContent>
);

export const Loadable: React.FC<LoadableProps> = ({
  children,
  dependsOn,
  loadingComponent = <DefaultLoader />,
}) => {
  const isLoading = Array.isArray(dependsOn)
    ? dependsOn.some((o) => !o)
    : !dependsOn;
  return (
    <FadeOutFadeIn transitionKey={isLoading}>
      {isLoading ? loadingComponent : children}
    </FadeOutFadeIn>
  );
};
