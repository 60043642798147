/**
 * Allows for typed easy access to the localStorage facility
 */

type LocalStorageItem<T> = {
  data: T;
}

class LocalStorage {
  static save<T>(key: string, t: T): void {
    window.localStorage.setItem(key, JSON.stringify({ data: t }));
  }

  static get<T>(key: string): T | undefined {
    const item = window.localStorage.getItem(key);

    if (!item) return undefined;

    const parsedItem = JSON.parse(item) as LocalStorageItem<T>;
    return parsedItem.data;
  }
}


/**
 * Interface for the abstract store concept
 */
export interface IStore<T> {
  save(key: string, t: T): void;
  get(key: string): T | undefined;
  getOrDefault(key: string, t: T): T;
  getOrThrow(key: string): T;
}


export class LocalStorageStore<T> implements IStore<T> {
  save(key: string, t: T): void {
    LocalStorage.save(key, t);
  }

  get(key: string): T | undefined {
    return LocalStorage.get(key) as T;
  }

  getOrDefault(key: string, t: T): T {
    return LocalStorage.get(key) as T ?? t;
  }

  getOrThrow(key: string): T {
    const item = LocalStorage.get(key);

    if (!item) {
      throw new Error(`Could not find the item with key: ${key}`);
    }

    return item as T;
  }
}
