import { sortBy } from "lodash";
import { ImageAssetSchema } from "./ImageAsset";
import { z } from "zod";
import { SpecialPricePeriod } from "./SpecialPricePeriod";

export const ProductSchema = z.object({
  id: z.string(),
  title: z.string(),
  subTitle: z.string(),
  cost: z.optional(z.number()),
  price: z.number(),
  discountPrice: z.optional(z.number()),
  priority: z.number().default(0),
  description: z.string(),
  active: z.optional(z.boolean().default(true)),
  coverAssetId: z.optional(z.string()),

  // Readonly
  coverAsset: z.optional(ImageAssetSchema),
  coverUrl: z.string(),

  categoryId: z.optional(z.string()),
});

export type Product = z.infer<typeof ProductSchema>;

export function emptyProduct() {
  return Product.createDefault();
}

export module Product {
  export function createDefault(): Product {
    return {
      id: "",
      title: "",
      subTitle: "",
      price: 0,
      priority: 0,
      description: "",
      coverUrl: "",
    };
  }

  export function sameAs(a: Product, b: Product) {
    return a.id === b.id;
  }

  export function sortByPriceAndPriorityProperties() {
    return [(p: Product) => p.price, (p: Product) => p.priority];
  }

  export function sortByPriceAndPriority(products: Product[]) {
    return sortBy(
      sortBy(products, (p) => p.price),
      (p) => p.priority
    );
  }

  /**
   * Adjusts price on product to match the special price period
   */
  export function getPrice(
    product: Pick<Product, "price" | "id">,
    specialPricePeriods: Array<SpecialPricePeriod> = [],
    now?: Date
  ) {
    now ??= new Date();

    for (const spp of specialPricePeriods) {
      if (spp.productId !== product.id) continue;

      if (now >= spp.period.from && now <= spp.period.to) {
        return spp.price;
      }
    }

    return product.price;
  }
}
