import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { IonContent, IonIcon, isPlatform } from "@ionic/react";
// import GreeterIcon from "./GreeterIcon/GreeterIcon";
import LoginButton from "./LoginButton/LoginButton";
import LoginButtonList from "./LoginButtonList/LoginButtonList";
import Subtitle from "./Subtitle/Subtitle";
import Title from "./Title/Title";
import { PartyPage } from "@greeter-guest/components/PartyPage";
import {
  logoApple,
  logoFacebook,
  logoGoogle,
  mailOutline,
  personOutline,
} from "ionicons/icons";
import { match } from "ts-pattern";
import {
  motion,
  AnimatePresence,
  MotionProps,
  LayoutGroup,
} from "framer-motion";
import {
  Container,
  EmailInput,
  FloatingBackButton,
  GreeterIcon,
  Hint,
  HorizontalLine,
  OutlinedButton,
  PasswordInput,
  Spinner,
  displayWhen,
  useAuth,
  useSupportAlert,
  useSupportToast,
} from "@greeter/matter";
import {
  EmailAlreadyUsedWithOtherProviderError,
  EmailAlreadyExistsError,
  TimeoutError,
  EmailInvalidError,
  TooManyAttemptsError,
  PasswordInvalidError,
  LoginCredentialsInvalidError,
  WeakPasswordError,
} from "@greeter/auth";
import {
  doNothing,
  isFacebookBrowser,
  isInAppBrowser,
  isInstagramBrowser,
  isMac,
} from "@greeter/util";
import { ContinueButton } from "@greeter-guest/components/Button";
import { useHiddenTabBar } from "@greeter-guest/utility/Hooks";
import { useHistory } from "react-router-dom";
import Routes from "@greeter-guest/utility/Routes";
import { useLoading } from "@greeter/loading";
import { useActor, useMachine } from "@xstate/react";
import { enableLogs, logger } from "@greeter/log";
import { emailFlowMachine, EmailFlowState } from "./EmailFlowMachine";
import { usePersistentStore } from "@greeter/store";

import css from "./LoginPage.module.scss";
import { first, partial, some } from "lodash";
import { idleMachine } from "./IdleMachine";
import { Capacitor } from "@capacitor/core";
import { useSearchParams } from "@greeter/url";
import { useDefaultGuestApi } from "@greeter-guest/api/api";
import { useCustomerQuery } from "@greeter/guest-api-hooks";

const log = logger("[LoginPage]");

const useAuthErrorHandler = () => {
  const supportToast = useSupportToast();
  const handleAuthError = useCallback(
    <T,>(fn: () => Promise<T>) =>
      async () => {
        try {
          return await fn();
        } catch (err) {
          if (err instanceof EmailInvalidError) {
            supportToast("E-mail adressen er ikke gyldig");
          } else if (err instanceof EmailAlreadyUsedWithOtherProviderError) {
            supportToast(
              "Du har allerede lavet en bruger med den email. Login med den rigtige udbyder (Facebook, Apple eller email)."
            );
          } else if (err instanceof EmailAlreadyExistsError) {
            supportToast("Denne email eksisterer allerede i systemet.");
          } else if (err instanceof PasswordInvalidError) {
            supportToast(
              "Den adganskode er ikke gyldig. Den skal min. være 6 karakterer lang."
            );
          } else if (err instanceof TimeoutError) {
            supportToast(
              "Login lavede et timeout. Tjek om du har net og prøv igen."
            );
          } else if (err instanceof TooManyAttemptsError) {
            supportToast(
              "Du har prøvet for mange gange. Vent lidt og prøv igen senere."
            );
          } else if (
            err instanceof LoginCredentialsInvalidError ||
            err instanceof EmailInvalidError ||
            err instanceof PasswordInvalidError
          ) {
            supportToast(
              "Dine login oplysninger er forkert. Er du sikker på at dine email eller dit password er korrekt?"
            );
          } else {
            supportToast("Kunne ikke logge ind, skriv til support...");
          }

          throw err;
        }
      },
    [supportToast]
  );

  return handleAuthError;
};

function filterState<State extends { value: string }>(
  allowedStates: State["value"][]
) {
  return;
}

const fadeIn: MotionProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
};
const fadeInFadeOut: MotionProps = {
  ...fadeIn,
  exit: { opacity: 0 },
};

const allowedStates: EmailFlowState["value"][] = [
  "initial",
  "newUser",
  "existingUser",
];

export type EmailFlowProps = {
  checkEmailExists: (email: string) => Promise<boolean>;
  onCancel?: () => void;
  onSignIn?: () => void;
};
export function EmailFlow({
  checkEmailExists,
  onCancel = doNothing,
  onSignIn = doNothing,
}: EmailFlowProps) {
  useHiddenTabBar();

  const { auth } = useAuth();
  const { waitFor } = useLoading();
  const supportToast = useSupportToast();

  const handleSignIn = useCallback(
    async ({ email, password }) => {
      if (!auth) return;

      try {
        log("Signing in!");
        await waitFor(
          auth.signInWithEmailAndPassword(email, password),
          "Logger ind..."
        );
        log("Signed in!");
      } catch (e) {
        supportToast(
          "Et eller andet gik galt. Har du indtastet de rigtige information?"
        );
        throw e;
      }
    },
    [auth, supportToast, waitFor]
  );

  const handleAuthError = useAuthErrorHandler();

  const handleCreateUser = useCallback(
    async ({ email, password, repeatedPassword }) => {
      return await handleAuthError(async () => {
        if (password === repeatedPassword && auth) {
          log("Creating user!");
          await waitFor(
            auth.createUserWithEmailAndPassword(email, password),
            "Opretter dig..."
          );
        } else {
          supportToast("Passwords matcher ikke hinanden!");
        }
      })();
    },
    [handleAuthError, supportToast, waitFor, auth]
  );

  const [state, send, machine] = useMachine(emailFlowMachine);

  const [idleState, sendIdle, idlem] = useActor(idleMachine);

  useEffect(() => {
    const sub = machine.on("signedIn", () => onSignIn());
    return sub.unsubscribe;
  }, [machine]);

  useEffect(() => {
    send({ type: "next" });
  }, []);

  useEffect(() => {
    const sub = idlem.on("idle", async function onIdle() {
      if (state.context.email) {
        const exists = await checkEmailExists(state.context.email);
        send({ type: "setEmailExists", data: exists });
      }
    });

    return sub.unsubscribe;
  }, [idlem, state.context.email, checkEmailExists, send]);

  useEffect(() => {
    const sub = machine.on(
      "creatingUser",
      async function onCreatingUser({ data }) {
        try {
          await waitFor(handleCreateUser(data), "Opretter din bruger...");
          send({ type: "success" });
        } catch (e) {
          log(`Failed to create a user with error: `, e);
          send({ type: "error" });
        }
      }
    );

    return sub.unsubscribe;
  }, [machine]);

  useEffect(() => {
    const sub = machine.on("signingIn", async function onSigningIn({ data }) {
      try {
        await waitFor(handleSignIn(data), "Logger dig ind...");
        send({ type: "success" });
      } catch {
        send({ type: "error" });
      }
    });

    return sub.unsubscribe;
  }, [machine]);

  useEffect(() => {
    const sub = machine.on("done", async function onDone() {});

    return sub.unsubscribe;
  }, [machine]);

  useEffect(() => {
    log("Listening to transitions...");
    const sub = machine.subscribe(partial(log, "[EmailFlowMachine]"));
    return sub.unsubscribe;
  }, [machine]);

  useEffect(() => {
    if (idleState.matches("idle")) send({ type: "idle" });
  }, [send, idleState]);

  const [filteredState, setFilteredState] =
    useState<EmailFlowState["value"]>("initial");

  useEffect(() => {
    if (some(allowedStates, (s) => s === state.value)) {
      setFilteredState(state.value as EmailFlowState["value"]);
    }
  }, [state.value]);

  const pwRef = useRef<HTMLDivElement>(null);

  const newUser = filteredState === "newUser";
  const existingUser = filteredState === "existingUser";

  const title = useMemo(() => {
    const style = {
      fontWeight: "bold",
    } as React.CSSProperties;
    return newUser ? (
      <motion.h3 style={style} layout key="new" {...fadeInFadeOut}>
        Opret konto
      </motion.h3>
    ) : existingUser ? (
      <motion.h3
        id="btn-login"
        style={style}
        layout
        key="exists"
        {...fadeInFadeOut}
      >
        Log ind
      </motion.h3>
    ) : filteredState === "initial" ? (
      <motion.h3 style={style} layout key="initial" {...fadeInFadeOut}>
        Indtast email
      </motion.h3>
    ) : (
      <motion.h3 style={style} layout key="otherwise" {...fadeInFadeOut}>
        Hej!
      </motion.h3>
    );
  }, [newUser, existingUser, filteredState]);

  const body = useMemo(() => {
    return newUser ? (
      <>
        <motion.div layout {...fadeIn} key="password1">
          <PasswordInput
            data-testid="password-input"
            password={state.context.password}
            onChange={(password) =>
              newUser
                ? (() => {
                    send({
                      type: "updateNewUser",
                      data: { password: password },
                    });
                    sendIdle({ type: "keyPress" });
                  })()
                : existingUser
                ? (() => {
                    send({
                      type: "updateExistingUser",
                      data: { password: password },
                    });
                    sendIdle({ type: "keyPress" });
                  })()
                : doNothing()
            }
          />
          <Hint>Skal være 6 karakterer langt.</Hint>
        </motion.div>
        <motion.div
          layout="position"
          key="password-repeat"
          style={{ transition: "200ms" }}
          {...fadeIn}
        >
          <PasswordInput
            data-testid="repeat-password-input"
            password={state.context.repeatedPassword}
            onChange={(password) => {
              send({
                type: "updateNewUser",
                data: { repeatedPassword: password },
              });
              sendIdle({ type: "keyPress" });
            }}
            error={state.context.password !== state.context.repeatedPassword}
            placeholder="Gentag adgangskode..."
          />
          {state.context.password !== state.context.repeatedPassword && (
            <Hint>Skal matche adgangskode.</Hint>
          )}
        </motion.div>
      </>
    ) : (
      existingUser && (
        <motion.div
          ref={pwRef}
          layout
          onAnimationComplete={() => {
            pwRef.current?.querySelector("input")?.focus();
          }}
          {...fadeIn}
        >
          <PasswordInput
            data-testid="password-input"
            id="input-password"
            key="password2"
            password={state.context.password}
            onChange={(password) => {
              send({
                type: "updateExistingUser",
                data: { password: password },
              });
              sendIdle({ type: "keyPress" });
            }}
          />
        </motion.div>
      )
    );
  }, [
    state.context.password,
    state.context.repeatedPassword,
    send,
    newUser,
    existingUser,
    sendIdle,
  ]);

  const handleContinue = useCallback(
    function handleContinue() {
      send({ type: "next" });
    },
    [send]
  );

  const button = useMemo(() => {
    return match(filteredState)
      .with("newUser", () => (
        <ContinueButton
          onClick={handleContinue}
          disabled={
            state.context.repeatedPassword !== state.context.password ||
            state.context.password.length < 6
          }
        >
          Opret
        </ContinueButton>
      ))
      .with("existingUser", () => (
        <ContinueButton id="btn-login" onClick={handleContinue}>
          Log ind
        </ContinueButton>
      ))
      .otherwise(() => (
        <ContinueButton id="btn-continue" onClick={handleContinue}>
          Fortsæt
        </ContinueButton>
      ));
  }, [
    state.context.password,
    state.context.repeatedPassword,
    handleContinue,
    filteredState,
  ]);

  const handleSetEmail = useCallback(
    (email: string) => {
      send({
        type: newUser
          ? "updateNewUser"
          : existingUser
          ? "updateExistingUser"
          : "updateInitial",
        data: { email },
      });
      sendIdle({ type: "keyPress" });
    },
    [send, sendIdle, newUser, existingUser]
  );

  const handleCancel = useCallback(() => {
    send({ type: "cancel" });
    sendIdle({ type: "keyPress" });
    onCancel();
  }, [send, sendIdle]);

  return (
    <LayoutGroup>
      <motion.div layout layoutRoot className={css.EmailFlow}>
        <AnimatePresence presenceAffectsLayout initial={false} mode="wait">
          {title}
        </AnimatePresence>
        <motion.div layout {...fadeIn}>
          <EmailInput
            id="email-input"
            data-testid="email-input"
            email={state.context.email}
            onChange={handleSetEmail}
          />
        </motion.div>
        {body}
        <motion.div
          layout
          layoutRoot
          {...fadeIn}
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: ".5rem",
          }}
        >
          <OutlinedButton
            style={{
              padding: ".75rem 0.5rem",
              backgroundColor: "rgba(0,0,0,0)",
            }}
            onClick={handleCancel}
          >
            Tilbage
          </OutlinedButton>
          {button}
        </motion.div>
      </motion.div>
    </LayoutGroup>
  );
}

type LoginViewState = "initial" | "email" | "facebook";

type Provider = "google" | "facebook" | "email" | "apple";

const iconStyles = {
  height: "1.5rem",
  width: "1.5rem",
  marginLeft: "0.5rem",
  color: "#A2AAAD",
};

function createAuthHandler(
  fn: () => Promise<void> | void,
  onError: (msg: string) => void
) {
  return () => {
    try {
      return fn();
    } catch (err) {
      if (err instanceof EmailInvalidError) {
        onError("E-mail adressen er ikke gyldig");
      } else if (err instanceof EmailAlreadyUsedWithOtherProviderError) {
        onError(
          "Du har allerede lavet en bruger med den email. Login med den rigtige udbyder (Facebook, Apple eller email)."
        );
      } else if (err instanceof EmailAlreadyExistsError) {
        onError("Denne email eksisterer allerede i systemet.");
      } else if (err instanceof PasswordInvalidError) {
        onError(
          "Den adganskode er ikke gyldig. Den skal min. være 6 karakterer lang."
        );
      } else if (err instanceof TimeoutError) {
        onError("Login lavede et timeout. Tjek om du har net og prøv igen.");
      } else if (err instanceof TooManyAttemptsError) {
        onError(
          "Du har prøvet for mange gange. Vent lidt og prøv igen senere."
        );
      } else if (
        err instanceof LoginCredentialsInvalidError ||
        err instanceof EmailInvalidError ||
        err instanceof PasswordInvalidError
      ) {
        onError(
          "Dine login oplysninger er forkerte. Er du sikker på at dine email eller dit password er korrekt?"
        );
      }
    }
  };
}

type LoginButtonsProps = {
  onChooseProvider: (provider: Provider) => void;
  onBack?: () => void;
  onWillSignIn?: () => void;
  onDidSignIn?: () => void;
  style?: React.CSSProperties;
};

function LoginButtons(props: LoginButtonsProps) {
  const supportAlert = useSupportAlert();
  const api = useDefaultGuestApi();
  const { auth, isLoggedIn } = useAuth();
  const history = useHistory();

  function goBack() {
    if (props.onBack) {
      props.onBack();
    } else {
      history.goBack();
    }
  }

  const handleApple = createAuthHandler(async () => {
    log("Clicked Apple button");
    if (props.onWillSignIn) props.onWillSignIn();
    await auth?.signInWithApple();
    log("Done with apple login");
    goBack();
  }, supportAlert);

  const isIosOrWeb =
    isPlatform("ios") ||
    isPlatform("iphone") ||
    isMac() ||
    !Capacitor.isNativePlatform();

  const isAndroid =
    (Capacitor.isNativePlatform() && isPlatform("android")) ||
    // In app browsers are seen as "insecure" by google, and does not work with Facebook embedded browser */
    (!Capacitor.isNativePlatform() && !isInAppBrowser());

  const handleGoogle = createAuthHandler(async () => {
    log("Clicked Google button");
    if (props.onWillSignIn) props.onWillSignIn();
    await auth?.signInWithGoogle();
    log("Done with Google login");
    goBack();
  }, supportAlert);

  const handleFacebook = createAuthHandler(async () => {
    if (props.onWillSignIn) props.onWillSignIn();
    await auth?.signInWithFacebook();
    goBack();
  }, supportAlert);

  const search = useSearchParams();
  const featureFlags = search.get("featureFlags")?.split(",");
  const showAnonButton = featureFlags?.find(
    (ff) => ff === "experimental-anon-login"
  );

  const customerQuery = useCustomerQuery(api, { enabled: isLoggedIn });

  return (
    <div style={{ width: "70%", margin: "1rem auto", ...props.style }}>
      <LoginButton
        providerName="gæst"
        content="Fortsæt som gæst"
        providerIcon={
          <IonIcon
            icon={personOutline}
            style={{ ...iconStyles, color: "grey" }}
          />
        }
        onClick={async () => {
          if(props.onWillSignIn) props?.onWillSignIn();
          await auth?.signInAsAnon();
          await customerQuery.refetch();
          if(props.onDidSignIn) props?.onDidSignIn();
        }}
      />
      <div style={{ padding: "1rem 0" }}>
        <HorizontalLine />
      </div>
      <LoginButtonList>
        {isIosOrWeb && (
          <LoginButton
            key="apple-login-btn"
            onClick={handleApple}
            providerName="Apple"
            providerIcon={<IonIcon style={iconStyles} icon={logoApple} />}
          />
        )}

        {isAndroid && (
          <LoginButton
            key="google-login-btn"
            onClick={handleGoogle}
            providerName="Google"
            providerIcon={<IonIcon style={iconStyles} icon={logoGoogle} />}
          />
        )}

        <LoginButton
          key="facebook-login-btn"
          onClick={handleFacebook}
          providerName="Facebook"
          providerIcon={
            <IonIcon
              style={{ ...iconStyles, color: "#4267B2" }}
              icon={logoFacebook}
            />
          }
        />
        <LoginButton
          key="email-login-btn"
          data-testid="email-login-btn"
          onClick={() => props.onChooseProvider("email")}
          providerName="email"
          providerIcon={
            <IonIcon
              style={{ ...iconStyles, color: "grey" }}
              icon={mailOutline}
            />
          }
        />
      </LoginButtonList>
    </div>
  );
}

export type LoginPageProps = {
  onRedirect?: (url: string) => void;
  onBack?: () => void;
  onDone?: () => void;
  onCancel?: () => void;

  onWillSignIn?: () => void;
};

export function LoginPage(props: LoginPageProps) {
  const history = useHistory();
  const api = useDefaultGuestApi();

  const [state, setState] = useState<LoginViewState>("initial");

  const { auth, isLoggedIn, isAwaitingAuthChange } = useAuth();

  const customerQuery = useCustomerQuery(api, { enabled: isLoggedIn });

  const redirectUrlKey = "greeter.redirect";
  const redirectStore = usePersistentStore<string>();

  const search = useSearchParams();

  useEffect(() => {
    if (!auth) return;

    log("Subscribing to redirect event");
    const unsub = auth.onRedirect(async () => {
      await customerQuery.refetch();
      const redirectUrl = search.get("redirect") ?? Routes.profile.route();
      if (props.onRedirect) {
        props.onRedirect(redirectUrl);
      } else if (props.onDone) {
        props.onDone();
      } else {
        history.push(redirectUrl);
      }
    });

    return () => unsub();
  }, [auth, customerQuery, history, search]);

  async function handleEmailSignIn() {
    if (props.onDone) {
      await customerQuery.refetch();
      props.onDone();
    } else {
      history.goBack();
    }
    setState("initial");
  }

  async function checkEmailExists(email: string) {
    return (
      (await auth?.fetchSignInMethods(email))?.some((m) => m === "email") ??
      false
    );
  }

  const [loading, setLoading] = useState<string | undefined>();

  return (
    <PartyPage key="login-page">
      <FloatingBackButton
        onClick={props.onBack}
        fallback={Routes.home.route()}
      />
      <IonContent>
        <div className={css.LoginPage}>
          <Container className={css.Container}>
            {state === "initial" ? (
              <>
                <GreeterIcon />
                <Title />
                <Subtitle />
              </>
            ) : (
              state === "email" && (
                <>
                  <GreeterIcon />
                  <Title />
                </>
              )
            )}
            <LoginButtons
              key="login-btns"
              style={{
                ...displayWhen(state === "initial" || state === "facebook"),
              }}
              onBack={props.onBack}
              onWillSignIn={() => {
                setLoading("Logger ind...")
                if (props.onWillSignIn) props.onWillSignIn();
              }}
              onDidSignIn={() => {
                setLoading(undefined);
              }}
              onChooseProvider={(p) => p === "email" && setState(p)}
            />
            <div
              style={{
                width: "70%",
                margin: "0 auto",
                ...displayWhen(state === "email"),
              }}
            >
              <EmailFlow
                onCancel={() => setState("initial")}
                onSignIn={handleEmailSignIn}
                checkEmailExists={checkEmailExists}
              />
            </div>
          </Container>
        </div>
      </IonContent>
      <Spinner reason={(isAwaitingAuthChange && "Afventer svar...") || loading} />
    </PartyPage>
  );
}
