import styles from "./ActivityCard.module.scss";
import defaultImage from "@greeter-guest/assets/darts.jpg";
import React from "react";
import { Activity } from "@greeter/core";
import Routes from "@greeter-guest/utility/Routes";

import { useHistory } from "react-router-dom";
import { LazyImage } from "@greeter/matter";

export type ActivityCardProps = {
  activity: Activity;
}

export const ActivityCard: React.FC<ActivityCardProps> = ({ activity }) => {
  const history = useHistory();
  return (
    <div className={styles.ActivityCard}>
      <div
        onClick={() =>
          history.push(
            Routes.venuesTab.venues.route({ queries: { activityId: activity.id } })
          )
        }
        className={styles.card}
      >
        <LazyImage
          className={styles.cover}
          alt={activity.name}
          src={activity.coverUrl}
          defaultImage={defaultImage}
        />
        <p className={styles.content}>{activity.name}</p>
      </div>
    </div>
  );
};
