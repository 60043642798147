import css from "./Swipable.module.scss";
import React, { MutableRefObject } from "react";
import { useSpring, animated } from "react-spring";
import { useDrag } from "@use-gesture/react";
import { classNames, doNothing } from "@greeter/util";
import { throttle } from "lodash";

export type PositionHandler = (
  down: boolean,
  x: number,
  y: number,
  velocity: number,
  distance: number
) => { x: number; y: number };

export type SwipableProps = React.PropsWithChildren &{
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  positionHandler?: PositionHandler;
  ref?: MutableRefObject<HTMLDivElement | null>;
  onRelease?: () => void;
}

export const Swipable = React.forwardRef<HTMLDivElement, SwipableProps>(
  (
    {
      className,
      children,
      positionHandler = (down, x) => ({ x: x > 0 && down ? x : 0, y: 0 }),
      onRelease = doNothing
    },
    ref
  ) => {
    const [{ xy }, setPosition] = useSpring(() => ({ xy: [0, 0] }));

    const bind = useDrag(({ down, movement: [mx, my], distance: [dx, dy], velocity: [vx, vy] }) => {
      const newPos = positionHandler(down, mx, my, vx, dx);
      setPosition({ xy: [newPos.x, newPos.y] });
      if (!down) throttle(onRelease, 200);
    });

    return (
      <animated.div
        ref={ref}
        {...classNames(css.Swipable, className)}
        {...bind()}
        style={{
          // TypeScript does not correctly identify the parameters. This causes a false positive error.
          // @ts-ignore
          transform: xy.interpolate(((x, y) => `translate3d(${x}px, ${y}px, 0)`)),
        }}
      >
        {children}
      </animated.div>
    );
  }
);
