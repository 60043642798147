import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
} from "react";

import { createAuth, IAuthentication, UserInfo } from "@greeter/auth";
import { partial } from "lodash";
import { doNothing } from "@greeter/util";
import { Config, Env } from "@greeter/config";
import { logger } from "@greeter/log";

const log = logger("[AuthProvider]");

export type ContextParams = {
  isAwaitingAuthChange: boolean;
  isLoggedIn: boolean;
  isAnon: boolean;
  userInfo?: UserInfo;
  refresh: () => Promise<void>;
  auth: IAuthentication | undefined;
};

const initialValue: ContextParams = {
  isAwaitingAuthChange: true,
  isLoggedIn: false,
  isAnon: false,
  userInfo: undefined,
  refresh: async () => doNothing(),
  auth: undefined,
};
const AuthContext = React.createContext(initialValue);

export type AuthProviderProps = React.PropsWithChildren & {
  env?: Env;
  config?: Config;
  firebaseConfig?: any;
  auth?: IAuthentication;
};

export function AuthProvider({
  children,
  env,
  firebaseConfig,
  auth: injectedAuth,
}: AuthProviderProps) {
  const auth: IAuthentication | undefined = useMemo(() => {
    if (injectedAuth) return injectedAuth;
    if (env) return createAuth({ env, firebaseConfig });
  }, [env, firebaseConfig, injectedAuth]);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAnon, setIsAnon] = useState(false);
  const [awaitingAuthChange, setAwaitingAuthChange] = useState(true);
  const [userInfo, setUserInfo] = useState<UserInfo | undefined>({
    id: "",
    name: "",
    email: "",
    isAnon: false,
  });

  const reset = useCallback(() => {
    setIsLoggedIn(false);
    setUserInfo(undefined);
  }, []);

  useEffect(() => {
    if (!auth) return;

    log("Subscribing to auth change");
    const unsub2 = auth?.onAuthReady(async () => {
      setAwaitingAuthChange(false);
    });
    const unsub = auth?.onAuthChange((userInfo: UserInfo | undefined) => {
      const l = partial(log, "[AuthStateChange]");
      if (userInfo) {
        l("User logged in. Restoring auth state...", JSON.stringify(userInfo));
        setAwaitingAuthChange(false);
        setUserInfo(userInfo);
        setIsLoggedIn(true);
        setIsAnon(userInfo.isAnon);
      } else {
        l("No user found, resetting state...", JSON.stringify(userInfo));
        reset();
      }
    });

    (async () => {
      if (!auth) return;

      const user = await auth.getUser();
      setUserInfo(user);
      setIsLoggedIn(!!user);
    })();

    return () => {
      unsub();
      unsub2();
    };
  }, [auth, reset]);

  const handleRefresh = useCallback(async () => {
    try {
      if (!auth) {
        return;
      }

      await auth?.refreshToken();
      const user = await auth?.getUser();
      if (user) {
        setUserInfo(user);
      } else {
        reset();
      }
    } catch {
      reset();
    }
  }, [auth, reset]);

  const values = useMemo(() => {
    return {
      auth,
      userInfo,
      isAwaitingAuthChange: awaitingAuthChange,
      isLoggedIn,
      isAnon,
      refresh: handleRefresh,
    };
  }, [auth, userInfo, isLoggedIn, awaitingAuthChange, handleRefresh]);

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
}

export function useAuth() { return useContext(AuthContext) };
