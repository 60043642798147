import React, { useMemo } from "react";
import { Loadable } from "@greeter/matter";
import { Month } from "@greeter/date";
import { MonthSection } from "./MonthSection";
import { compareAsc, compareDesc } from "date-fns";
import { BookingHistoryItem } from "./BookingHistoryItem";

import { Booking, Venue, Year } from "@greeter/core";
import { first, flatten } from "lodash";

import css from "./BookingHistory.module.scss";

export type BookingHistoryProps = {
  bookings: Booking[];
  venues: Venue[];
};

const MONTH_KEY = 0;
const YEAR_KEY = 0;

export function groupBookings(
  bookings: Booking[],
  sort: "asc" | "desc"
): Map<Year, Map<Month, Booking[]>> {
  [...bookings].sort((a, b) =>
    sort === "asc"
      ? compareAsc(a.period.from, b.period.from)
      : compareDesc(a.period.from, b.period.from)
  );
  return Booking.groupByYearAndMonth(Booking.groupByYear(bookings));
}

const toHistoryItems = (
  groupedBookings: Map<Year, Map<Month, Booking[]>>,
  venues: Venue[],
  activateFirst = false
) => {
  return [...groupedBookings.entries()].map(([year, bookingsByMonth]) =>
    [...bookingsByMonth.entries()].map(([month, bookings]) => (
      <MonthSection year={year} month={month}>
        {bookings.map((booking, i) => {
          const venue =
            venues.find((v) => v.id === booking.venueId) ??
            Venue.createDefault();
          return (
            <BookingHistoryItem
              active={activateFirst && i === 0}
              venue={venue}
              booking={booking}
            />
          );
        })}
      </MonthSection>
    ))
  );
};

export const BookingHistory: React.FC<BookingHistoryProps> = ({
  venues,
  bookings,
}) => {
  const futureBookings = useMemo(
    () =>
      bookings
        .filter((b, i) => b.period.to > new Date())
        .sort((a, b) => compareAsc(a.period.from, b.period.from)),
    [bookings]
  );
  const wrappedFirstBooking = useMemo(() => {
    return toHistoryItems(
      first(futureBookings)
        ? groupBookings([first(futureBookings)!], "asc")
        : new Map(),
      venues,
      true
    );
  }, [venues, bookings]);
  const wrappedFutureBookings = useMemo(() => {
    return toHistoryItems(
      groupBookings(
        futureBookings.filter((b, i) => i > 0),
        "asc"
      ),
      venues
    );
  }, [venues, bookings]);

  const wrappedGroupedBookings = useMemo(() => {
    return flatten(
      toHistoryItems(
        groupBookings(
          bookings.filter((b) => b.period.to < new Date()),
          "desc"
        ),
        venues
      )
    );
  }, [venues, bookings]);
  return (
    <Loadable dependsOn={[venues, bookings]}>
      <div className={css.BookingHistory}>
        {wrappedFirstBooking.length > 0 && (
          <div>
            <h2 className={css.Title}>Din næste booking</h2>
            {wrappedFirstBooking}
          </div>
        )}
        {wrappedFutureBookings.length > 0 && (
          <div>
            <h2 className={css.Title}>Fremtidige bookinger</h2>
            {wrappedFutureBookings}
          </div>
        )}
        {wrappedGroupedBookings.length > 0 && (
          <div>
            <h2 className={css.Title}>Tidligere bookinger</h2>
            {wrappedGroupedBookings}
          </div>
        )}
      </div>
    </Loadable>
  );
};
