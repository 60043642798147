export type Activity = {
    id: string;
    name: string;
    coverUrl: string;
};
export module Activity {
    export function createDefault(): Activity {
        return { id: "Placeholder", name: "Placeholder", coverUrl: "Placeholder" };
    }
}
