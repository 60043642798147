import React from "react";
import css from "./Subtitle.module.scss";

export function Subtitle() {
  return (
    <div className={css.Subtitle}>
      Vi hjælper dig med at finde og
      <br />
      sammensætte de bedste byture
    </div>
  );
}

export default Subtitle;
