import { getConfig, getEnv } from "@greeter-guest/utility/ConfigHooks";
import { useLoading } from "@greeter/loading";
import {
  GradientButton,
  LoadingOverlay,
  useSupportToast,
} from "@greeter/matter";
import { doNothing } from "@greeter/util";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { FormEvent, useCallback, useEffect, useState } from "react";

const config = getConfig();

const stripePromise = loadStripe(config.stripe.publishableKey, {
  locale: "da",
});

const declineError = [
  "authentication_required",
  "approve_with_id",
  "call_issuer",
  "card_not_supported",
  "card_velocity_exceeded",
  "currency_not_supported",
  "do_not_honor",
  "do_not_try_again",
  "duplicate_transaction",
  "expired_card",
  "fraudulent",
  "generic_decline",
  "incorrect_cvc",
  "incorrect_number",
  "incorrect_pin",
  "incorrect_zip",
  "insufficient_funds",
  "invalid_account",
  "invalid_amount",
  "invalid_cvc",
  "invalid_expiry_month",
  "invalid_expiry_year",
  "invalid_number",
  "processing_error",
  "reenter_transaction",
  "restricted_card",
  "revocation_of_all_authorizations",
  "revocation_of_authorization",
  "security_violation",
  "service_not_allowed",
  "stolen_card",
  "stop_payment_order",
  "testmode_decline",
  "transaction_not_allowed",
  "try_again_later",
  "withdrawal_count_limit_exceeded",
  "pickup_card",
  "online_or_offline_pin_required",
  "offline_pin_required",
  "not_permitted",
  "issuer_not_available",
  "lost_card",
  "merchant_blacklist",
  "new_account_information_available",
  "no_action_taken",
  "not_permitted",
  "pin_try_exceeded",
] as const;

// https://stripe.com/docs/declines/codes
export type DeclineError = (typeof declineError)[number];

export class MissingCardElementError extends Error {}
export class MissingStripeError extends Error {}

export function translateStripeErrorToDanish(err: DeclineError): string {
  switch (err) {
    case "authentication_required":
      return "Dit kort er blevet afvist og kræver godkendelse.";
    case "approve_with_id":
      return "Dit kort kunne ikke autoriseres.";
    case "card_not_supported":
      return "Kortet er ikke understøttet.";
    case "withdrawal_count_limit_exceeded":
    case "card_velocity_exceeded":
      return "Ikke nok penge på kortet. Dit kort kan ikke opfylde det ønskede beløb.";
    case "currency_not_supported":
      return "Danske kroner er ikke understøttet af kortet.";
    case "duplicate_transaction":
      return "En duplikat transaktion var været lavet indenfor kort tid.";
    case "expired_card":
      return "Dit kort er udløbet.";
    case "fraudulent":
      return "Dit kort blev afvist da der er opdaget mistænksom aktivitet.";
    case "stolen_card":
    case "restricted_card":
    case "pickup_card":
    case "lost_card":
    case "merchant_blacklist":
    case "generic_decline":
      return "Dit kort blev afvist.";
    case "incorrect_cvc":
    case "incorrect_number":
    case "incorrect_pin":
    case "incorrect_zip":
    case "invalid_account":
    case "invalid_amount":
    case "invalid_cvc":
    case "invalid_expiry_month":
    case "invalid_expiry_year":
    case "invalid_number":
      return "Forkerte kort-oplysninger indtastet.";
    case "insufficient_funds":
      return "Der er ikke nok danske dollars på kortet.";
    case "processing_error":
      return "En fejl forekom under transaktionen. Prøv igen om et øjeblik.";
    case "testmode_decline":
      return "Et Stripe test kort er blevet brugt. Prøv med et rigtigt kort istedet.";
    case "try_again_later":
      return "Kortet er blevet afvist af ukendte årsager. Prøv igen senere.";
    case "online_or_offline_pin_required":
    case "offline_pin_required":
      return "Kortet er blevet afvist da det mangler en pin-kode.";
    case "not_permitted":
      return "Kortet er ikke tilladt denne handling.";
    case "issuer_not_available":
      return "Kunne ikke få forbindelse til banken. Prøv igen senere.";
    case "new_account_information_available":
      return "Kontoen kortet er tilknyttet er ikke gyldig.";
    case "pin_try_exceeded":
      return "Du har prøvet at indtaste din pin-kode for mange gange. Kontakt din bank.";
    case "do_not_honor":
    case "do_not_try_again":
    case "reenter_transaction":
    case "revocation_of_all_authorizations":
    case "revocation_of_authorization":
    case "security_violation":
    case "service_not_allowed":
    case "stop_payment_order":
    case "transaction_not_allowed":
    case "no_action_taken":
    case "call_issuer":
    default:
      return "Dit kort blev afvist af en ukendt årsag.";
  }
}

type CardErrors =
  | "charge_expired_for_capture"
  | "expired_card"
  | "incorrect_cvc"
  | "incorrect_number"
  | "invalid_card_type"
  | "invalid_cvc"
  | "invalid_number";

export function toDeclineError(stripeErrCode?: string): DeclineError {
  return declineError.find((e) => e === stripeErrCode) ?? "processing_error";
}

export function useStripeErrorHandler() {
  const displaySupportToast = useSupportToast();
  return useCallback(
    (err?: string) =>
      displaySupportToast(translateStripeErrorToDanish(toDeclineError(err))),
    [displaySupportToast]
  );
}

/**
 * https://stripe.com/docs/declines/codes
 * https://stripe.com/docs/error-codes
 */
export class StripeDeclineError extends Error {
  error: DeclineError;

  constructor(error: DeclineError) {
    super();

    this.error = error;
  }
}

export type CheckoutFormProps = React.PropsWithChildren & {
  onPay?: () => void;
  onError?: (error: DeclineError) => void;
  onSuccess?: () => void;
  token: string;
  redirectUrl?: () => string;
};

export const CheckoutForm: React.FC<CheckoutFormProps> = ({
  onPay = doNothing,
  onError = doNothing,
  onSuccess = doNothing,
  token,
  redirectUrl,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const env = getEnv();
  const displayStripeToast = useStripeErrorHandler();
  const [confirming, setConfirming] = useState(false);

  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  async function pay(ev: FormEvent) {
    ev.preventDefault();
    onPay();

    if (!elements) {
      throw new MissingCardElementError();
    }

    if (!stripe) {
      throw new MissingStripeError();
    }

    setConfirming(true);
    const result = await stripe.confirmPayment({
      elements: elements,
      redirect: "if_required",
      confirmParams: {
        return_url: redirectUrl?.call({}),
      },
    });
    setConfirming(false);

    if (result.error) {
      onError
        ? onError(toDeclineError(result.error.decline_code))
        : displayStripeToast(result.error.decline_code);
      setErrorMessages([
        ...errorMessages,
        JSON.stringify(result.error, null, 4),
      ]);
    } else {
      onSuccess();
    }
  }

  return (
    <form
      onSubmit={pay}
      style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
    >
      <PaymentElement
        id="payment"
        options={{
          wallets: { applePay: "auto", googlePay: "auto" },
          business: { name: "Greeter" },
          paymentMethodOrder: ["mobilepay", "apple_pay", "google_pay", "card"],
        }}
      />
      <GradientButton disabled={!stripe}>Betal</GradientButton>
      {confirming && <LoadingOverlay message="Betaler..." />}
      {env === "dev" && (
        <pre
          style={{
            width: "100%",
            height: "100%",
            padding: "1rem",
            color: "green",
            backgroundColor: "black",
            textAlign: "left",
            boxSizing: "border-box",
            maxWidth: "100vw",
          }}
        >
          {errorMessages}
        </pre>
      )}
    </form>
  );
};

export type CheckoutProps = CheckoutFormProps & {
  token: string;
  redirectUrl?: () => string;
};

export const Checkout: React.FC<CheckoutProps> = ({ token, ...formProps }) => {
  return (
    <Elements
      key={token}
      stripe={stripePromise}
      options={{ clientSecret: token }}
    >
      <CheckoutForm token={token} {...formProps} />
    </Elements>
  );
};
