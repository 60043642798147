import React, { useMemo } from "react";
import { Order, OrderLine } from "@greeter/core";

import css from "./Receipt.module.scss";
import { z } from "zod";

const priceFormatter = Intl.NumberFormat("da-DK", { maximumFractionDigits: 2 });

export const ReceiptLineItem: React.FC<{
  left: string;
  right: string;
}> = ({ left, right }) => {
  return (
    <>
      <p className={css.Left}>{left}</p>
      <p className={css.Right}>{right}</p>
    </>
  );
};

export const ReceiptLineSchema = z.object({
  productId: z.optional(z.string()),
  quantity: z.number(),
  unitPrice: z.number(),
  title: z.string(),
});
export type ReceiptLine = z.infer<typeof ReceiptLineSchema>;

export type ReceiptProps = React.PropsWithChildren & {
  lines: Array<ReceiptLine>;
  customTotal?: number;
};

export function fromOrder(order: Order): ReceiptLine[] {
  return order.orderLines.map((ol: OrderLine) => {
    return {
      title: ol.title,
      unitPrice: ol.unitPrice,
      quantity: ol.quantity,
    };
  });
}

export function Receipt({ lines, customTotal }: ReceiptProps) {
  const total = useMemo(() => {
    let _total = 0;

    for (const line of lines) {
      _total += line.unitPrice * line.quantity;
    }

    return _total;
  }, [lines]);

  return (
    <div>
      <h3 className={css.Title}>Priser DKKR inkl. moms</h3>
      <div className={css.Receipt}>
        {lines.map((l) => {
          return (
            <ReceiptLineItem
              key={l.productId}
              left={`${l.quantity} x ${l.title}`}
              right={`${
                priceFormatter.format(l.unitPrice * l.quantity) ?? 0
              } kr`}
            />
          );
        })}
        <div className={css.ReceiptHR} />
        <ReceiptLineItem
          left="Total"
          right={`${priceFormatter.format(total ?? 0)} kr`}
        />
        {customTotal && (
          <>
            <ReceiptLineItem
              left="Tilbud"
              right={`${priceFormatter.format(customTotal)} kr`}
            />
            <ReceiptLineItem
              left="Difference"
              right={`${priceFormatter.format(total - customTotal)} kr`}
            />
          </>
        )}
      </div>
    </div>
  );
}
