import { Env } from "@greeter/config";
import { timestamp, logger } from "@greeter/log";
import { IAuthentication } from "./IAuthentication";
import { NativeFirebaseAuth } from "./NativeFirebaseAuth";
import { WebFirebaseAuth } from "./WebFirebaseAuth";
import { Capacitor } from "@capacitor/core";

const log = logger(timestamp(), "[FirebaseAuth][Factory]");

export type CreateAuthOptions = {
  env: Env;
  firebaseConfig: any;
  force?: "native" | "web";
};

export function createAuth({
  env,
  firebaseConfig,
  force,
}: CreateAuthOptions): IAuthentication {
  if (force && force === "native") {
    log("Forced NATIVE, initialized FirebaseAuth for native");
    return new NativeFirebaseAuth(firebaseConfig);
  } else if (force === "web") {
    log("Forced WEB, initialized FirebaseAuth for native");
    return WebFirebaseAuth.createOrGetSingleton(firebaseConfig, env);
  }

  if (Capacitor.isNativePlatform()) {
    log("Detected NATIVE, initialized FirebaseAuth for native");
    return new NativeFirebaseAuth(firebaseConfig);
  } else {
    log("Detected WEB, initialized FirebaseAuth for web");
    // NOTE: We use this singleton method to avoid listening on the wrong instance.
    return WebFirebaseAuth.createOrGetSingleton(firebaseConfig, env);
  }
}
