import { DatePeriod } from "./DatePeriod";
import { z } from "zod";

export const dateStringToDate = z
  .string()
  .datetime()
  .transform((s) => new Date(s));

export const DatePeriodSchema = z.object({
  from: dateStringToDate,
  to: dateStringToDate,
}).transform(s => new DatePeriod(s));

