import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { doNothing } from "@greeter/util";

import { atom, useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { last } from "lodash";
import { useSignal } from "@greeter/event";
import { logger } from "@greeter/log";
import { differenceInMilliseconds } from "date-fns";

const log = logger("[UseLoading]");

const useArray = <T>() => {
  const arrayRef = useRef([] as T[]);
  const [trigger, refresh] = useRefresh();
};

type Milliseconds = number;

export type QueryablePromiseOptions = {
  timeout?: Milliseconds;
}
export class QueryablePromise<T> {
  _isSuccess: boolean = false;
  _isError: boolean = false;
  _isSettled: boolean = false;
  _startedAt: Date = new Date();
  _timeout: Milliseconds = 10_000;

  constructor(public promise: Promise<T>, opts?: QueryablePromiseOptions) {
    this._startedAt = new Date();
    if (opts?.timeout) this._timeout = opts.timeout;

    promise
      .then(() => {
        this._isSuccess = true;
        this._isSettled = true;
        this._isError = false;
      })
      .catch(() => {
        this._isError = true;
        this._isSettled = true;
        this._isSuccess = false;
      });
  }

  get isSuccess(): boolean {
    return this._isSuccess;
  }

  get isSettled(): boolean {
    return this._isSettled;
  }

  get isError(): boolean {
    return this._isError;
  }

  get isTimedOut(): boolean {
    const diffInMs = differenceInMilliseconds(new Date(), this._startedAt);
    const timedout = (diffInMs > this._timeout)
    return timedout;
  }
}

/**
 * Used as an alternative to the redirectUrl stacking which didnt work too well
 */
export const redirectUrlsAtom = atomWithStorage("redirectUrls", []);
export const useRedirectUrlsAtom = () => useAtom(redirectUrlsAtom);

export const isLoadingAtom = atom(false);
export const reasonAtom = atom("");

export type WaitForPromise<T> = {
  promise: QueryablePromise<T>;
  reason: string;
};
export const waitingForTasksAtom = atom<WaitForPromise<any>[]>(
  [] as WaitForPromise<any>[]
);
export const checkTasksAtom = atom<NodeJS.Timer | undefined>(undefined);

const useRefresh = (): [number, () => void] => {
  const [val, setVal] = useState(0);
  const refreshRef = useRef(() => setVal(Math.random()));
  // const refresh = useCallback(() => {
  //   setVal(Math.random());
  // }, []);
  //
  const result = useMemo(() => {
    return [val, refreshRef.current] as [number, () => void];
  }, [val]);

  return result;
};

export const useLoading = () => {
  const [tasks, setTasks] = useAtom(waitingForTasksAtom);
  const [checkTasks, setCheckTasks] = useAtom(checkTasksAtom);

  const removeSettledTasks = useCallback(function _removeSettledTasks() {
    setTasks(tasks.filter((p) => !p.promise.isSettled && !p.promise.isTimedOut));
  }, [tasks]);
  const pushTask = useCallback(function _pushTask<T>(task: WaitForPromise<T>) {
    setTasks([...tasks, task]);
  }, [tasks]);

  const waitFor = useCallback(function _waitFor<T>(task: Promise<T>, reason: string = "", opts?: QueryablePromiseOptions) {
    const qp = new QueryablePromise(task, opts);

    const t: WaitForPromise<T> = {
      promise: qp,
      reason: reason,
    };
    pushTask(t);
    qp.promise.then(removeSettledTasks);
    return task;
  }, []);

  /**
   * This is a sort of hacky solution to ensure that the
   * the tasks never end i a state where the promise is settled
   */
  useEffect(() => {
    const id = setTimeout(() => { removeSettledTasks(); setCheckTasks(undefined); }, 1000);
    setCheckTasks(id);

    return () => {
      setCheckTasks(undefined);
      clearTimeout(id);
    }
  }, [removeSettledTasks, pushTask]);

  const numberOfTasks = tasks.length;
  const reason = last(tasks)?.reason ?? "";
  const result = useMemo(() => {
    return {
      waitFor,
      isLoading: numberOfTasks > 0,
      reason: reason,
      numberOfTasks: numberOfTasks,
    }
  }, [waitFor, numberOfTasks, reason])
  return result;
};

/**
 * @deprecated should use Spinner instead even though it's cool to use Signals
 */
export const useDeclarativeLoading = (loading: boolean, msg: string) => {
  const doneSignal = useSignal();
  const { waitFor } = useLoading();

  return useEffect(() => {
    if (loading) {
      waitFor(
        new Promise<void>((resolve) => {
          doneSignal.subscribe(() => resolve());
        }),
        msg
      );
    } else {
      doneSignal.emit();
    }

    return () => {
      doneSignal.emit();
    };
  }, [waitFor, doneSignal, loading, msg]);
};
