import { Tab } from "@greeter-guest/utility/Routes";
import { useEffect, useMemo, useState } from "react";

/**
 * useIonRouter is profoundly inconsistent. So I build my own tab detecter.
 * I mean, how hard can it be seriously.
 *
 * Specifically when redirecting back from mobilepay payment and landing on create-booking page
 * for some reason ionic is not picking up that we are in the greeter-events tab and defaults
 * to the venues tab. Hopefully this is obsolete in the future.
 *
 * Defaults to the venues tab.
 */
export function useDetectTab(): Tab {
  return useMemo(() => {
    const paths = window.location.pathname.split("/").slice(1);
    const first = paths[0];

    if (!first) {
      return "venues";
    } else if (first === "greeter-events") {
      return "greeter-events";
    } else if (first === "venues") {
      return "venues";
    } else if (first === "profile") {
      return "profile";
    }

    return "venues";
  }, []);
}
