import React from "react";
import { chevronDown } from "ionicons/icons";
import { IonIcon } from "@ionic/react";

import expandCss from "../ExpandButton.module.scss";

export type ReadMoreButtonProps = React.HTMLProps<HTMLDivElement> & {};

export const ReadMoreButton: React.FC<ReadMoreButtonProps> = ({
  children,
  ...props
}) => {
  return (
    <div {...props} className={expandCss.ExpandButton}>
      <div className={expandCss.Text}>{children}</div>
      <div className={expandCss.ChevronContainer}>
        <IonIcon icon={chevronDown} className={expandCss.Chevron} />
      </div>
    </div>
  );
};
