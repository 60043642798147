import { Env } from "@greeter/config";
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import {
  AuthenticationError,
  EmailInvalidError,
  IAuthentication,
  LoginCredentialsInvalidError,
  PasswordInvalidError,
  SignInMethod,
  UserInfo,
} from "./IAuthentication";
import { EventEmitter, Handle } from "@greeter/event";
import { Capacitor } from "@capacitor/core";
import { deleteApp, FirebaseApp, getApp, initializeApp } from "firebase/app";
import { logger, timestamp, warner } from "@greeter/log";
import {
  Auth,
  UserInfo as FirebaseUserInfo,
  getIdToken,
  fetchSignInMethodsForEmail,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  initializeAuth,
  indexedDBLocalPersistence,
  getAuth,
  SignInMethod as FirebaseSignInMethod,
  createUserWithEmailAndPassword,
  signInAnonymously,
  AuthErrorCodes,
  signOut,
  signInWithRedirect,
  getRedirectResult,
} from "firebase/auth";
import { getOrInitFirebaseApp, getOrInitFirebaseAuth } from "./FirebaseHooks";
import { partial } from "lodash";
import {
  EmailAlreadyExistsError,
  EmailAlreadyUsedWithOtherProviderError,
  TimeoutError,
  TooManyAttemptsError,
  WeakPasswordError,
} from "./IAuthentication";
import {
  isFacebookBrowser,
  isInAppBrowser,
  isInstagramBrowser,
} from "@greeter/util";

const name = "[FirebaseAuth]";
const log = logger(name);
const warn = warner(name);
