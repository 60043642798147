import React from "react";

import css from "./RejectionMessage.module.scss";

export type RejectionMessageProps = React.PropsWithChildren & {
  open?: boolean;
};

export const RejectionMessage: React.FC<RejectionMessageProps> = ({
  open = false,
  children,
}) => {
  return <div className={css.RejectionMessage}>"{children}"</div>;
};
