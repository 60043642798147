import { classNames } from "@greeter/util";
import React from "react";

import css from "./CardHeader.module.scss";

export type CardHeaderProps = React.PropsWithChildren & {
  className?: string;
  style?: React.CSSProperties;
};

export const CardHeader: React.FC<CardHeaderProps> = ({
  className,
  children,
  style,
}) => {
  return (
    <div {...classNames(css.CardHeader, className)} style={style}>
      {children}
    </div>
  );
};
