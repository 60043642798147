import React from "react";

import { classNames } from "@greeter/util";

import css from "./CardTitle.module.scss";

type Size = "sm" | "md" | "lg";

export type CardTitleProps = React.PropsWithChildren & {
  className?: string;
  size?: Size;
};

export const CardTitle: React.FC<CardTitleProps> = ({
  className = "",
  children,
  size = "md",
}) => {
  return (
    <div
      {...classNames(
        css.CardTitle,
        className,
        size === "md" ? "text-md" : size === "sm" ? "text-sm" : "text-lg"
      )}
    >
      {children}
    </div>
  );
};
