import {
  BookingSettings,
  GreeterEvent,
  ImageAsset,
  SpecialOpeningHours,
  Venue,
  WeeklyOpeningHours,
} from "@greeter/core";
import {
  DateFactory,
  DatePeriod,
  TimeOfDay,
  toDateOnlyString,
  withTimeOfDay,
} from "@greeter/date";
import { array } from "@greeter/util";
import {
  addDays,
  addSeconds,
  differenceInDays,
  isSameDay,
} from "date-fns";

const log = logger("[ChooseDateSelector][Utility]");

// import placeholderCover from "../assets/party.jpg";
import placeholderCover from "../assets/party.jpg";
import { logger } from "@greeter/log";

// TODO: Better naming for this?
export interface SelectDate {
  coverUrl: string;
  date: Date;
}

export function createDateRange(from: Date, daysForward: number): Date[] {
  return array(0, daysForward).map((i) => addDays(DateFactory.create(from), i));
}

const midnight = { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 };

const fodLog = logger("[ChooseDateSelector][fillOutDateGaps]");
export function mergeGreeterEvents(
  greeterEvents: GreeterEvent[],
  openingHours: Array<DatePeriod>,
  now = DateFactory.create()
): SelectDate[] {
  const dates = new Map<string, SelectDate>();

  for (let i = 0, l = openingHours.length; i < l; i++) {
    const openingHour = openingHours[i];
    const diffInDays = differenceInDays(openingHour.to, openingHour.from) + 1;

    for (let j = 0; j < diffInDays; j++) {
      const key = toDateOnlyString(addDays(openingHour.from, j));

      dates.set(key, {
        coverUrl: placeholderCover,
        date: addDays(openingHour.from, j),
      });
    }
  }

  for (let i = 0, l = greeterEvents.length; i < l; i++) {
    const greeterEvent = greeterEvents[i];
    const diffInDays =
      differenceInDays(greeterEvent.endsAt, greeterEvent.startsAt) + 1;

    for (let j = 0; j < diffInDays; j++) {
      const key = toDateOnlyString(addDays(greeterEvent.startsAt, j));
      const existing = dates.get(key);
      if (existing) { // We only replace the days which we are open
        dates.set(key, {
          coverUrl: ImageAsset.findUriWithSizeOrDefault(
            greeterEvent.coverAsset,
            "16x9-w512"
          ),
          date: addDays(greeterEvent.startsAt, j),
        });
      }
    }
  }

  const result = [...dates.values()];

  return result;
}


function afterNow(now: Date) {
  return (ge: GreeterEvent) => ge.endsAt >= (now ?? DateFactory.create());
}

const gbdLog = logger("[ChooseDateSelector][generateBookableDates]");
export type GenerateBookableDatesOptions = {
  now?: Date;
  range?: number;
  /**
   * Describes the offset to allow for
   * Say we generate dates for today and the end of today is 23:00
   * now is 20:00
   * if the buffer is 1 hour then we check if now + buffer > end-of-day
   * if it is, then skip that date
   */
  buffer?: TimeOfDay;
};
export type GenerateBookableDatesArgs = {
  openingHours: WeeklyOpeningHours;
  specialOpeningHours: SpecialOpeningHours[];
  events: GreeterEvent[];
};

function assert(condition: boolean, msg: string) {
  if (!condition)
    throw new Error(msg);
}

/**
 * Generates bookable dates based on all opening hours rules.
 *
 * It then returns that array so that you can easily match that array
 * up with for example bookings.
 */
export function generateBookableDates(
  args: GenerateBookableDatesArgs,
  { now, range, buffer }: GenerateBookableDatesOptions = {
    now: DateFactory.create(),
    range: 360,
    buffer: TimeOfDay.parse("01:00:00"),
  }
): SelectDate[] {
  now ??= DateFactory.create();
  range ??= 360;
  buffer ??= TimeOfDay.parse("01:00:00");

  const nowWithBuffer = addSeconds(now, buffer.getTotalSeconds());

  const openingHoursAsDatePeriods = Venue.createDatePeriodsFromOpeningHours({
    openingHours: args.openingHours,
    specialOpeningHours: args.specialOpeningHours,
    now: nowWithBuffer,
    range,
  });

  const dates = mergeGreeterEvents(
    args.events.filter(afterNow(now)),
    openingHoursAsDatePeriods,
    nowWithBuffer
  );

  return dates;
}
