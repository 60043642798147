import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Input } from "../Input";
import { doNothing } from "@greeter/util";
import { Hint } from "../Hint";
import { PhoneIcon } from "../Icons";

import { IonIcon } from "@ionic/react";
import { callOutline, phoneLandscape } from "ionicons/icons";

import css from "./PhoneNumberInput.module.scss";
import { PhoneNumber } from "@greeter/core";

export type PhoneNumberInputProps = {
  onChange?: (result: PhoneNumber) => void;
  onValidChange?: (valid: boolean) => void;
  hint?: React.ReactNode;
  phoneNumber?: PhoneNumber;
};

const danishPhoneNumberLimit = 8;
const spacesForReadability = 3;
const characterLimit = danishPhoneNumberLimit + spacesForReadability;

export type UsePhoneNumberProps = React.PropsWithChildren & {
  value: string;
  prefix: string;
};

type UsePhoneNumberResult = [
  PhoneNumber,
  (prefix: string, number: string) => void
];

export const usePhoneNumber = (
  initialPhoneNumber: PhoneNumber
): UsePhoneNumberResult => {
  const [_phoneNumber, _setPhoneNumber] =
    useState<PhoneNumber>(initialPhoneNumber);

  const result: UsePhoneNumberResult = useMemo(() => {
    return [
      _phoneNumber,
      (prefix: string, number: string) =>
        _setPhoneNumber(PhoneNumber.format(prefix, number)),
    ];
  }, [_phoneNumber]);

  return result;
};

const placeholder = "Dit tlf. nummer...";
export function PhoneNumberInput({
  onChange = doNothing,
  onValidChange = doNothing,
  phoneNumber = PhoneNumber.create(),
  hint = "Indtast dit nummer",
}: PhoneNumberInputProps) {
  const valid = useMemo(
    () => PhoneNumber.valid(phoneNumber.prefix, phoneNumber.number),
    [phoneNumber]
  );

  const [_phoneNumber, setPhoneNumber] = usePhoneNumber(phoneNumber);

  useEffect(() => {
    onValidChange(valid);
  }, [valid]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;
      setPhoneNumber(_phoneNumber.prefix, val);
    },
    [_phoneNumber, setPhoneNumber]
  );

  useEffect(() => {
    onChange(_phoneNumber);
  }, [_phoneNumber]);

  return (
    <div>
      <div className={css.PhoneNumberInput}>
        <div className={css.Prefix}>{_phoneNumber.prefix}</div>
        <Input
          type="tel"
          icon={<IonIcon icon={callOutline} />}
          placeholder={placeholder}
          inputClassName={css.InputField}
          onChange={handleChange}
          value={_phoneNumber.number}
          error={!PhoneNumber.valid(_phoneNumber.prefix, _phoneNumber.number)}
          pattern={"[0-9 ]+"}
        />
      </div>
      {hint && (typeof hint === "function" || typeof hint === "object") ? (
        hint
      ) : (
        <Hint text={`${hint}`} />
      )}
    </div>
  );
};
