import React from "react";
import { classNames } from "@greeter/util";
import { OutlinedButton, ButtonProps } from "@greeter/matter";

import css from "./NotNowButton.module.scss";

export type NotNowButtonNotNowButtonProps = ButtonProps;

export const NotNowButton: React.FC<NotNowButtonNotNowButtonProps> = ({
  className,
  ...props
}) => {
  return (
    <OutlinedButton {...props} {...classNames(css.NotNowButton, className)}>
      Ikke nu
    </OutlinedButton>
  );
};
