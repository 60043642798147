import React from "react";
import { useParams } from "react-router-dom";
import { GreeterEventPageApiHandler } from "./GreeterEventPageApiHandler";

type Params = {
  greeterEventId: string;
};

export function GreeterEventPageRouteHandler(props) {
  const greeterEventId = props.match.params.greeterEventId;
  return <GreeterEventPageApiHandler greeterEventId={greeterEventId ?? ""} />;
}
