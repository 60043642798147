import React from "react";
import { Venue } from "@greeter/core";
import { VenueIcon } from "@greeter-guest/components/VenueIcon";
import {
  Card,
  CardContent,
  CardTitle,
  CardDescription,
  CardHeader,
  LazyCoverImage,
} from "@greeter/matter";
import { classNames } from "@greeter/util";

import "./BigContentCard.scss";
import css from "./BigContentCard.module.scss";

export type BigContentCardProps = {
  venue?: Venue;
  label?: React.ReactNode;
  onClick?: () => void;
  title: string;
  style?: React.CSSProperties;
  className?: string;
  description?: string;
  cover: string;
};

export const BigContentCard: React.FC<BigContentCardProps> = ({
  className,
  style,
  title,
  description,
  cover,
  venue,
  label,
  onClick,
}) => {
  return (
    <div style={{ position: "relative" }}>
      <Card
        {...classNames(css.BigContentCard, css.BigGreeterEventCard, className)}
        style={style}
        onClick={onClick}
      >
        <CardHeader className={css.CardHeader}>
          <LazyCoverImage src={cover} />
        </CardHeader>
        <CardContent className={css.CardContent}>
          <CardTitle size="md">{title}</CardTitle>
          <CardDescription>{description}</CardDescription>
        </CardContent>
        <VenueIcon className={css.VenueIcon} venue={venue} />
      </Card>
      {label}
    </div>
  );
};
