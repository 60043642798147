import React, { useCallback, useEffect, useState } from "react";
import { classNames } from "@greeter/util";
import { ContextualBookingStatus } from "@greeter/core";

import css from "./BookingStatusText.module.scss";

type BookingStatusTextProps = {
  status: ContextualBookingStatus;
}

export const BookingStatusText: React.FC<BookingStatusTextProps> = ({
  status,
}) => {
  const classNamesWithDefault = useCallback(
    (...extra: string[]) => classNames(css.BookingStatusText, ...extra),
    []
  );

  switch (status) {
    case "accepted":
      return <div {...classNamesWithDefault(css.Accepted)}>Accepteret</div>;
    case "created":
      return <div {...classNamesWithDefault(css.Pending)}>Mangler betaling</div>;
    case "pending":
      return <div {...classNamesWithDefault(css.Pending)}>Afventer bekræftelse</div>;
    case "rejected":
      return <div {...classNamesWithDefault(css.Rejected)}>Afvist</div>;
    case "missed":
      return <div {...classNamesWithDefault(css.Rejected)}>Misset</div>;
    case "inProgress":
      return <div {...classNamesWithDefault(css.Done)}>Igangværende</div>;
    case "completed":
      return <div {...classNamesWithDefault(css.Done)}>Afsluttet</div>;
    default:
      return <div className={css.OrderStatusText}>Status: {status}</div>;
  }
};
