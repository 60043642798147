import React from "react";

import styles from "./CardDescription.module.scss";
import { classNames } from "@greeter/util";

export type CardDescriptionProps = React.PropsWithChildren & {
  className?: string;
  style?: React.CSSProperties;
};

export const CardDescription: React.FC<CardDescriptionProps> = ({
  children,
  className,
  style,
}) => {
  return (
    <div {...classNames(styles.CardDescription, className)} style={style}>
      {children}
    </div>
  );
};
