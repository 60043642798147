import React from "react";

import { classNames } from "@greeter/util";
import { Button, ButtonProps } from "../Button";

import css from "./OutlinedGradientButton.module.scss";

export const OutlinedGradientButton: React.FC<ButtonProps> = ({
  className,
  ...props
}) => {
  return (
    <Button {...classNames(className, css.OutlinedGradientButton)} {...props} />
  );
};
