import React from "react";
import { classNames } from "@greeter/util";

import css from "./HorizontalLine.module.scss";

export type HorizontalLineProps = React.PropsWithChildren &{
  className?: string;
  thickness?: number;
};

export const HorizontalLine: React.FC<HorizontalLineProps> = ({
  className = "",
  thickness = 1,
}) => {
  return (
    <div
      style={{ borderBottom: `${thickness}px dashed` }}
      {...classNames(className, css.HorizontalLine)}
    ></div>
  );
};
