export function isFacebookBrowser() {
  const ua = navigator.userAgent;
  return ua.indexOf("FBAN") > -1 || ua.indexOf("FBAV") > -1;
}

export function isInstagramBrowser() {
  const ua = navigator.userAgent;
  return ua.indexOf("Instagram") > -1;
}

export function isInAppBrowser() {
  return isInstagramBrowser() || isFacebookBrowser();
}

export function isMac() {
  return navigator.userAgent.indexOf("Mac") > -1;
}
