import { Swiper, SwiperSlide, SwiperClass } from "swiper/react";
import { Pagination, Controller, Navigation } from "swiper/modules";
import React, { useEffect, useMemo, useState } from "react";
import { classNames, doNothing } from "@greeter/util";

import css from "./SwiperMultiCarousel.module.scss";
import "./SwiperCarousel.scss";
import { SwiperOptions } from "swiper/types";

type Direction = "vertical" | "horizontal";

export type SwiperMultiCarouselProps = React.PropsWithChildren & {
  className?: string;
  isInfinite?: boolean;
  startInMiddle?: boolean;
  containerEndClass?: string;
  containerStartClass?: string;
  onSlideChange?: (index: number) => void;
  onTransitionEnd?: (index: number) => void;
  onTranslate?: (carouselController: CarouselController) => void;
  onReachBeginning?: (carouselController: CarouselController) => void;
  onReachEnd?: (carouselController: CarouselController) => void;
  slidesPerView?: number;
  spacing?: number;
  startAt?: number;
  direction?: Direction;
  onControllerInit?: (carouselController: CarouselController) => void;
};

export const SwiperMultiCarousel: React.FC<SwiperMultiCarouselProps> = ({
  children,
  className = "",
  isInfinite = false,
  startInMiddle = false,
  containerStartClass = "",
  containerEndClass = "",
  onSlideChange = doNothing,
  onTransitionEnd = doNothing,
  onTranslate = doNothing,
  onReachEnd = doNothing,
  onReachBeginning = doNothing,
  slidesPerView,
  spacing = 12,
  startAt = undefined,
  direction = "horizontal",
  onControllerInit = doNothing,
}) => {
  const [swiperController, setSwiperController] = useState<
    SwiperClass | undefined
  >(undefined);
  const [hasBeenInititalized, setHasBeenInitialized] = useState(false);
  const [carouselController, setCarouselController] =
    useState<CarouselController>();

  const wrappedChildren =
    React.Children.map(children, (child, index) => (
      <SwiperSlide style={{ width: "fit-content", marginRight: "12px" }} key={index}>
        {child}
      </SwiperSlide>
    )) || [];

  useEffect(() => {
    const middle = Math.floor(wrappedChildren.length / 2);
    if (middle !== 0 && !hasBeenInititalized && swiperController) {
      if (startAt !== undefined && startAt >= 0)
        swiperController?.slideTo(startAt);
      else if (startInMiddle) swiperController?.slideTo(middle);

      setHasBeenInitialized(true);
    }
  }, [
    hasBeenInititalized,
    swiperController,
    startAt,
    startInMiddle,
    wrappedChildren.length,
  ]);
  useEffect(() => {
    if (swiperController)
      setCarouselController(new CarouselController(swiperController));
  }, [swiperController]);

  useEffect(() => {
    if (carouselController) onControllerInit(carouselController);
  }, [carouselController, onControllerInit]);

  const settings: SwiperOptions = useMemo(() => {
    const options: SwiperOptions = {
      slidesPerView: slidesPerView ? slidesPerView : "auto",
      spaceBetween: spacing,
      pagination: { bulletClass: css.Hide },
      direction: direction,
      preventInteractionOnTransition: false,
    };
    if (isInfinite) {
      settings.loop = true;
      settings.loopAdditionalSlides = 10;
    }
    if (startInMiddle) {
      settings.centeredSlides = true;
    }

    return options;
  }, [direction, isInfinite, slidesPerView, spacing, startInMiddle]);

  const additionalComponents: JSX.Element[] = [];
  if (containerStartClass)
    additionalComponents.push(
      <span
        key={additionalComponents.length}
        className={containerStartClass}
        slot="container-start"
      />
    );
  if (containerEndClass)
    additionalComponents.push(
      <span
        key={additionalComponents.length}
        className={containerEndClass}
        slot="container-end"
      />
    );

  const swiper = (
    <Swiper
      {...classNames(css.MultiCarousel, "Carousel", className)}
      modules={[Pagination, Controller, Navigation]}
      onSwiper={setSwiperController}
      onSlideChange={(swiper) => {
        onSlideChange(swiper.activeIndex);
      }}
      onSlideChangeTransitionEnd={(swiper) => {
        onTransitionEnd(swiper.activeIndex);
      }}
      onSetTranslate={() => {
        if (carouselController) onTranslate(carouselController);
      }}
      onReachEnd={() => {
        if (carouselController) onReachEnd(carouselController);
      }}
      onReachBeginning={() => {
        if (carouselController) onReachBeginning(carouselController);
      }}
      {...settings}
    >
      {wrappedChildren}
      {additionalComponents}
    </Swiper>
  );

  return swiper;
};

export class CarouselController {
  constructor(public readonly swiper: SwiperClass) {}

  slideTo(index: number) {
    // this.swiper.slideTo(index);
  }
}
