export function humanReadableDate(
  date: Date,
  customOptions?: Intl.DateTimeFormatOptions
): string {
  const defaultOptions = {
    minute: "numeric",
    hour: "numeric",
    day: "numeric",
    month: "long",
    weekday: "long",
    hour12: false,
  } as Intl.DateTimeFormatOptions;

  const defaultFormat = new Intl.DateTimeFormat(
    "da-DK",
    customOptions ? customOptions : defaultOptions
  );

  // TODO: We might have to format directly on Date.
  return capitalize(defaultFormat.format(date));
}

export function capitalize(input: string): string {
  // Guard against empty and null/undefined strings
  if (!input) return input;

  return input.charAt(0).toUpperCase() + input.slice(1);
}
export function padNumberToString(input: number, length: number): string {
  return String(input).padStart(length, "0");
}

export function simpleCreateUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export type Action<T> = (v?: T) => void;
export type Func<T> = () => T;

export interface Predicate<T> {
  evaluate(element: T): boolean;
}

export function* range(from: number, to: number, step = 1): Iterable<number> {
  if (step === 0)
    throw new Error("Step cannot be 0, it would result in endless loop.");
  const directionLeft = from > to;
  let i = from;

  while ((i <= to && !directionLeft) || (i >= to && directionLeft)) {
    yield i;
    i += step;
  }
}

export function array(from: number, to: number, step = 1) {
  return Array.from(range(from, to, step));
}

export interface Range {
  min: number;
  max: number;
}

export function mapRanges(val: number, from: Range, to: Range) {
  if (val < from.min || val > from.max)
    throw new Error("Value needs to be within lower bounds range");
  return (
    ((val - from.min) * (to.max - to.min)) / (from.max - from.min) + from.min
  );
}

export function isEmpty(array?: any[]) {
  return !!array && array.length <= 0;
}

export function map(
  x: number,
  inMin: number,
  inMax: number,
  outMin: number,
  outMax: number
) {
  return ((x - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
}

export function doNothingAsync(): Promise<void> {
  return Promise.resolve();
}
export function doNothing(): void {
  return;
};

export type Dimension = {
  width: number;
  height: number;
};
export const placeKitten = ({ width, height }: Dimension) => {
  return `http://placekitten.com/g/${width}/${height}`;
};

export const square = (size: number) => ({ width: size, height: size });

export type Optional<T> = T | undefined | null | false;
export type ClassNameString = Optional<string> | Optional<string>[];
export function classNames(...classNames: ClassNameString[]) {
  return {
    className: classNames
      .filter((c) => !!c)
      .flat()
      .join(" "),
  };
}
