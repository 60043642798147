import {
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import { animate } from "motion";
import { useEffect, useState } from "react";

export function useHiddenTabBar() {
  const [rect, setRect] = useState<DOMRect>();

  useIonViewWillEnter(() => {
    const tabBar = document.body.querySelector("ion-tab-bar");

    const rect = tabBar?.getBoundingClientRect();
    setRect(rect);

    if (tabBar && rect && tabBar.style.display !== "none") {
      animate(
        tabBar as HTMLElement,
        {
          height: [`${rect.height}px`, 0],
          border: [tabBar.style.borderColor, "transparent"],
        },
        { easing: "ease-out", duration: 0.2 }
      );
    }
  }, []);
  useIonViewWillLeave(() => {
    const tabBar = document.body.querySelector("ion-tab-bar");
    if (tabBar && rect) {
      animate(
        tabBar as HTMLElement,
        {
          height: [0, `${rect.height}px`],
          border: ["transparent", tabBar.style.borderColor],
        },
        { easing: "ease-out", duration: 0.2 }
      );
    }
  }, [rect]);
}

type UseThrottledValueOptions<T> = Partial<{
  initial: T;
  delay: number;
}>;

export function useThrottledValue<T>(
  func: (prev: T | undefined) => T,
  opts?: UseThrottledValueOptions<T>
) {
  if (!opts) opts = { initial: undefined, delay: undefined };
  if (!opts.delay) opts.delay = 500;

  const [value, setValue] = useState<T | undefined>(opts.initial);
  useEffect(() => {
    const id = setTimeout(() => {
      setValue(func(value));
    }, opts?.delay);

    return () => clearTimeout(id);
  }, [opts, opts.delay, func, value]);

  return value;
}
