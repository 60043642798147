import css from "./FadeOutFadeIn.module.scss";

import React from "react";
import {
  CSSTransition,
  SwitchTransition,
} from "react-transition-group";

import "./FadeOutFadeIn.scss";
import { BasePropsWithChildren } from "../../BaseProps";
import { classNames } from "@greeter/util";

export type FadeOutFadeInProps = BasePropsWithChildren & {
  transitionKey?: any;
  transition?: string;
}

export const FadeOutFadeIn: React.FC<FadeOutFadeInProps> = ({
  transitionKey,
  className,
  ...props
}) => {
  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        mountOnEnter
        unmountOnExit
        key={transitionKey}
        timeout={50}
        classNames="fade"
      >
        <div {...classNames(css.FadeOutFadeIn, className)} {...props} />
      </CSSTransition>
    </SwitchTransition>
  );
};
