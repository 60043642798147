import React, { useMemo } from "react";
import { Activity } from "@greeter/core";
import { ActivityCard } from "@greeter-guest/components/ActivityCard";
import { Carousel } from "@greeter/matter";

export type ActivitiesCarouselProps = {
  activities: Activity[];
}

export const ActivitiesCarousel: React.FC<ActivitiesCarouselProps> = ({ activities }) => {
  const activityCards = useMemo(
    () =>
      activities.map((activity: Activity, index: number) => {
        return <ActivityCard key={index} activity={activity} />;
      }),
    [activities]
  );

  return (
    <div className={ActivitiesCarousel.name}>
      <Carousel spacing="md">{activityCards}</Carousel>
    </div>
  );
};
