import React, { useEffect, useMemo, useRef, useState } from "react";
import { doNothing, map } from "@greeter/util";
import { partial } from "lodash";
import { nanoid } from "nanoid";
import { SwipableProps, PositionHandler, Swipable } from "../Swipable";

const log = partial(console.log, "[FlyoutSwipeable]");

export type FlyoutSwipableProps = SwipableProps & {
  style?: React.CSSProperties;
  // Notify of progress defined between 0 and 1
  onProgress?: (progress: number) => void;
  onFlyout?: () => void;
  onFlyoutReachesEnd?: () => void;
  // Traveldistance within parent container, defaults to 0.5
  distanceToTravelBeforeFlyout?: number;
}

export const FlyoutSwipable: React.FC<FlyoutSwipableProps> = ({
  className,
  style,
  children,
  onFlyout = doNothing,
  onFlyoutReachesEnd = doNothing,
  onProgress = doNothing,
  distanceToTravelBeforeFlyout = 0.33,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [parentWidth, setParentWidth] = useState<number | undefined>(undefined);
  const [hasFlownOut, setHasFlownOut] = useState<boolean>(false);

  // TODO: It should be the parents width
  const flyoutDestination = (ref.current?.getBoundingClientRect().width ?? Number.MAX_VALUE) + (parentWidth ?? window.innerWidth);
  const [key, setKey] = useState(nanoid());

  useEffect(() => {
    setParentWidth(ref.current?.parentElement?.getBoundingClientRect().width);
  }, [ref.current, ref.current?.parentElement?.clientWidth]);

  useEffect(() => {
    if (hasFlownOut) {
      setTimeout(onFlyout, 100);

      // Reset
      setTimeout(() => {
        setHasFlownOut(false);
        setKey(nanoid());
      }, 1000);
    }
  }, [hasFlownOut]);

  const positionHandler: PositionHandler = (down, x, y, velocity, distance) => {
    if (parentWidth && x > 0) {
      const progress = map(distance, 0, parentWidth * distanceToTravelBeforeFlyout, 0, 1);
      onProgress(progress);
    }

    if (parentWidth && distance > parentWidth * distanceToTravelBeforeFlyout && x > 0) {
      setHasFlownOut(true);
    }
    const newx = hasFlownOut ? flyoutDestination : down ? x : 0;

    return { x: newx > 0 && down ? newx : hasFlownOut ? flyoutDestination : 0, y: 0 };
  };

  return (
    <Swipable key={key} ref={ref} style={style} positionHandler={positionHandler}>
      {children}
    </Swipable>
  );
};
