import { ActorRefFrom, raise, setup, cancel, emit } from "xstate";

type CommandEvent<T> = { type: T };

export type KeyPressEvent = CommandEvent<"keyPress">;
export type IdleEvent = CommandEvent<"idle">;
export type ActiveEvent = CommandEvent<"idle">;

const idleId = "idleTimer";

export type IdleEvents = KeyPressEvent | IdleEvent | ActiveEvent;

export const idleMachine = setup({
  types: {} as {
    events: IdleEvents;
    emitted: { type: "idle" }
  },
  actions: {
    startIdleTimer: raise({ type: "idle" }, { delay: 1000, id: idleId }),
    cancelIdleTimer: cancel(idleId),
  },
}).createMachine({
  initial: "idle",
  states: {
    active: {
      entry: ["startIdleTimer"],
      on: {
        idle: { target: "idle" },
        keyPress: { actions: ["cancelIdleTimer", "startIdleTimer"] },
      },
    },
    idle: {
      entry: emit({ type: "idle" }),
      on: {
        keyPress: { target: "active" },
      },
    },
  },
});

export type IdleMachineRef = ActorRefFrom<typeof idleMachine>;
