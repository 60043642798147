import React from "react";

import { Props } from "@greeter/matter";
import { classNames } from "@greeter/util";

import css from "./PageCover.module.scss";

export function PageCover({
  className,
  children,
  ...props
}: Props) {
  return (
    <div {...classNames(className, css.PageCover)} {...props}>
      {children}
      <div
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          background:
            `linear-gradient(
                to top,
                var(--gm-color-default-bg),
                rgba(var(--gm-color-default-bg-rgb-value), 0)
            )`,
          width: "100%",
          height: "20%",
        }}
      />
    </div>
  );
};
