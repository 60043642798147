import { Capacitor } from "@capacitor/core";
import { PushNotifications } from "@capacitor/push-notifications";
import { IGuestApi } from "@greeter/api";
import { partial } from "lodash";

const log = partial(console.log, "[PushNotifications]");

export type SubscribeToPushNotificationsArgs = {
  isLoggedIn: boolean;
  guestApi: IGuestApi;
};
export const subscribeToPushNotifications = async ({
  isLoggedIn,
  guestApi
}: SubscribeToPushNotificationsArgs) => {
  if (Capacitor.isNativePlatform() && isLoggedIn) {
    try {
      log("Initiating PushNotifications registration");
      PushNotifications.addListener("registrationError", (error: any) => {});
      PushNotifications.addListener("registration", async (token) => {
        log(`Subscribing to notifications with token ${token.value}`);
        await guestApi.subscribeToNotifications(token.value);
      });

      // TODO: Show popup of message, from the top?
      PushNotifications.addListener(
        "pushNotificationReceived",
        async (notification) => {}
      );

      const permissionState = (await PushNotifications.checkPermissions())
        .receive;
      log("Permission state", permissionState);

      if (permissionState !== "granted") {
        PushNotifications.requestPermissions().then((result) => {
          if (result.receive === "granted") {
            log("Granted, registering");
            PushNotifications.register();
          }
        });
      } else if (permissionState === "granted") {
        PushNotifications.register();
      }
    } catch (e) {
      log("Unable to register for push notification.", e);
    }
  }
};
