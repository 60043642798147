import React from "react";
import { Props } from "@greeter/matter";
import { classNames } from "@greeter/util";

import css from "./Section.module.scss";

export type SectionProps = Props & {
  padding?: boolean;
}

export const Section: React.FC<SectionProps> = ({ padding, className = "", ...props }) => (
  <div
    {...classNames(
      css.Section,
      css[`Section___padding___${padding}`],
      className
    )}
    {...props}
  />
);
