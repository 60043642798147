import { z } from "zod";

export const FileSchema = z.object({
  name: z.string(),
  fullPath: z.string(),
  created: z.string().transform((s) => new Date(s)),
});

export type File = {
  name: string;
  fullPath: string;
  created: Date;
};

export const ImageSizeStrings = [
  "1x1-w100",
  "1x1-w150",
  "1x1-w256",
  "4x3-w150",
  "4x3-w256",
  "4x3-w512",
  "4x3-w768",
  "4x3-w1024",
  "16x9-w512",
  "16x9-w1024",
  "16x9-w1600",
] as const;

const ImageSizeStringSchema = z
  .enum(ImageSizeStrings)
  .transform((parsed) => parsed as ImageSizeString);

type ImageSizeString = (typeof ImageSizeStrings)[number];

export const ImageSizeSchema = z.object({
  size: ImageSizeStringSchema,
  uri: z.string(),
});
type ImageSize = z.infer<typeof ImageSizeSchema>;

export const ImageAssetSchema = z.object({
  id: z.string(),
  name: z.string(),
  root: z.string(),
  path: z.string(),
  uri: z.string(),
  sizes: z.array(ImageSizeSchema),
});

export type ImageAsset = z.infer<typeof ImageAssetSchema>;

export module ImageAsset {
  export function findUriWithSizeOrDefault(
    asset: ImageAsset,
    size: ImageSizeString
  ): string {
    const _size = asset.sizes.find(withSize(size));

    if (_size) return _size.uri;

    return asset.uri;
  }

  export function withSize(size: ImageSizeString) {
    return (imageSize: ImageSize, ..._: any[]) => {
      return imageSize.size === size;
    };
  }
}
