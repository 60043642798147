import React from "react";

import { useLazyImage } from "../Hooks";
import { Skeleton } from "../Skeleton";
import { classNames as classNameProp, ClassNameString } from "@greeter/util";

import "animate.css";

import css from "./LazyImage.module.scss";

export type LazyImageProps = React.HTMLAttributes<HTMLImageElement> & {
  alt: string;
  src?: string | false;
  defaultImage?: string;
  classNames?: { container: ClassNameString };
  styles?: { container?: React.CSSProperties }
};

export const LazyImage: React.FC<LazyImageProps> = ({
  className,
  classNames,
  styles,
  src,
  alt,
  defaultImage,
  ...props
}) => {
  const [ref, isLoading] = useLazyImage(!src ? undefined : src);

  return (
    <div {...classNameProp(classNames?.container, css.LazyCoverContainer)} style={styles?.container}>
      {/* Acts like a trigger for loading the image.
          This allows us to do interesting tricks also,
          such as "load-when-center-is-visible" or other
          such loads.*/}
      <div className={css.LoadTrigger} ref={ref} />
      {isLoading ? (
        <Skeleton pulse {...classNameProp(css.Skeleton, className)} />
      ) : (
        <img
          {...classNameProp(
            css.LazyImage,
            className,
            "animate__animated",
            "animate__fadeIn"
          )}
          alt={alt}
          src={!src ? undefined : src}
          onError={(event) => {
            // if (defaultImage && defaultImage !== src)
            //     setTimeout(() => (event.target as HTMLImageElement).src = defaultImage, 1000);
          }}
          {...props}
        />
      )}
    </div>
  );
};
