import partyImage from "../../assets/FestFolk.jpg";

import React, { useEffect, useState } from "react";
import Page from "@greeter-guest/components/Page";

import { IonPage } from "@ionic/react";

import css from "./PartyPage.module.scss";

export type PartyPageProps = React.PropsWithChildren

export const PartyPage: React.FC<PartyPageProps> = (props) => {
  return <IonPage {...props} className={css.PartyPage} />;
};

