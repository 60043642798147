import { convertDayToString, Day, Month } from "@greeter/date";
import { useTranslation } from "react-i18next";
import { convertMonthToString } from "./DateUtil";

export function useDefaultTranslation() {
  return useTranslation("translation");
}

export function useTranslatedMonth(month: Month) {
  const { t, i18n } = useDefaultTranslation();
  const translatedMonth = t(`month.${convertMonthToString(month)}`);
  return translatedMonth;
}

export function useTranslatedDay(day: Day) {
  const { t, i18n } = useDefaultTranslation();
  return t(`week.${convertDayToString(day)}`);
}
