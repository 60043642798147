import React from "react";

import { Section  }from "./Section";
import { classNames } from "@greeter/util";
import { BasePropsWithChildren } from "@greeter/matter";

import styles from "./TitledSection.module.scss";

export type TitleSectionProps = BasePropsWithChildren & {
  title: string;
  padding?: boolean;
  hint?: string
}

export const TitledSection: React.FC<TitleSectionProps> = ({ children, title, className = "", padding, hint }) => (
  <Section padding={padding} {...classNames(styles.TitledSection, className)}>
    <p className={styles.Title}>
      <strong>{title}</strong>
      {hint && <div style={{fontSize: "0.8rem", opacity: 0.6}}>{hint}</div>}
    </p>
    {children}
  </Section>
);
