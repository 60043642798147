import { RefObject, useEffect, useRef, useState } from "react";
import { logger } from "@greeter/log";

const log = logger("[useIdle]")

const idleDetectionEvents = [
  "click",
  "touchmove",
] as (keyof HTMLElementEventMap)[];

export type UseIdleOptions = {
  timeout?: number;
}

/**
 * Mostly the following
 * https://github.com/mantinedev/mantine/blob/master/packages/%40mantine/hooks/src/use-idle/use-idle.ts
 */
export function useIdle(ref?: HTMLElement | null, opts: UseIdleOptions = { timeout: 10_000 }) {
  const timer = useRef<number>();
  const [idle, setIdle] = useState(true);

  useEffect(() => {
    function handleEvents() {
      setIdle(false);

      if (timer.current) {
        clearTimeout(timer.current);
      }

      timer.current = window.setTimeout(() => {
        setIdle(true);
      }, opts.timeout);
    };

    idleDetectionEvents.forEach(e => ref?.addEventListener(e, handleEvents, { passive: true }));

    return () => {
      idleDetectionEvents.forEach(e => ref?.removeEventListener(e, handleEvents));
    }
  }, [ref]);

  return idle;
}
