import React, { useEffect, useMemo, useState } from "react";

import ClockIcon from "@greeter-guest/assets/clock-dark.svg";
import DresscodeIcon from "@greeter-guest/assets/shirt-dark.svg";
import TicketIcon from "@greeter-guest/assets/ticketinverse.svg";

import { DaysliceConfig, GreeterEvent, ImageAsset } from "@greeter/core";
import { Venue } from "@greeter/core";
import { TitledSection } from "@greeter-guest/components/Section/TitledSection";
import { humanReadableDate } from "@greeter/util";
import { UpcomingGreeterEventsCarousel } from "@greeter-guest/components/UpcomingGreeterEventsCarousel";
import { VenueIcon } from "@greeter-guest/components/VenueIcon";
import { Optional } from "@greeter-guest/utility/Utility";
import {
  IonContent,
  IonPage,
  useIonRouter,
  useIonViewDidEnter,
  useIonViewWillEnter,
} from "@ionic/react";
import {
  Container,
  CoverImage,
  DescriptionCard,
  FloatingBackButton,
  LazyCoverImage,
  PageCover,
  RoundedButton,
  ScrollableContent,
  Loadable,
  LazyImage,
} from "@greeter/matter";

import { Helmet } from "react-helmet";

import { useHistory } from "react-router-dom";
import Routes, {
  CreateBookingV2Route,
  Tab,
  VenueRouteV2,
} from "@greeter-guest/utility/Routes";

import css from "./GreeterEventPage.module.scss";

export type GreeterEventPageProps = {
  venue: Optional<Venue>;
  greeterEvent: GreeterEvent;
  greeterEvents?: GreeterEvent[];
  venues?: Venue[];
  daysliceConfig: DaysliceConfig;
};

export function GreeterEventPage({
  greeterEvent,
  greeterEvents,
  venue,
  venues,
  daysliceConfig,
}: GreeterEventPageProps) {
  const _greeterEvents = useMemo(
    () => greeterEvents?.filter((ge) => ge.id !== greeterEvent.id) ?? [],
    [greeterEvent.id, greeterEvents]
  );

  const history = useHistory();
  const router = useIonRouter();

  return (
    <IonPage>
      <Helmet>
        <title>Event - {greeterEvent.name}</title>
        <meta name="id" content={greeterEvent.id} />
        <meta name="name" content={greeterEvent.name} />
        <meta name="og:name" content={greeterEvent.name} />
        <meta name="og:description" content={greeterEvent.description} />
        <meta name="og:image" content={greeterEvent.coverUrl} />
      </Helmet>
      <FloatingBackButton />
      <IonContent>
        <Container>
          <div className={css.GreeterEventPage}>
            {!!venue && (
              <>
                <LazyCoverImage
                  alt={greeterEvent.name}
                  style={{ aspectRatio: "16/9" }}
                  src={ImageAsset.findUriWithSizeOrDefault(
                    greeterEvent.coverAsset,
                    "16x9-w1024"
                  )}
                  styles={{ container: { opacity: 0.75 } }}
                />
                <DescriptionCard className={css.DescriptionCard}>
                  <Loadable dependsOn={venue}>
                    <CardHeader venue={venue!} greeterEvent={greeterEvent} />
                  </Loadable>
                  <ScrollableContent className={css.descriptionContainer}>
                    <pre className={css.descriptionContent}>
                      {greeterEvent.description}
                    </pre>
                  </ScrollableContent>
                </DescriptionCard>
              </>
            )}
            <RoundedButton
              active
              style={{
                marginTop: "-2rem",
                position: "relative",
                background:
                  "linear-gradient(40deg, var(--gm-color-secondary-highlight), var(--gm-color-primary-highlight))",
                marginLeft: "auto",
                marginRight: "auto",
                padding: "0.75rem 2rem",
              }}
              onClick={() => {
                if (venue) {
                  const route = new CreateBookingV2Route(
                    router.routeInfo.tab as Tab
                  ).route({
                    venueId: venue?.id,
                    arrivalDate: greeterEvent.startsAt,
                  });
                  console.log("ROUTE", route);
                  history.push(route);
                }
              }}
            >
              Book bord
            </RoundedButton>
            {!!_greeterEvents && _greeterEvents.length > 0 && (
              <TitledSection
                padding={false}
                title={`Andre events på ${venue?.name}`}
              >
                <UpcomingGreeterEventsCarousel
                  greeterEvents={_greeterEvents}
                  venues={venues}
                  daysliceConfig={daysliceConfig}
                />
              </TitledSection>
            )}
          </div>
        </Container>
      </IonContent>
    </IonPage>
  );
}

// -------------- Internal components
type CardHeaderProps = React.PropsWithChildren & {
  // TODO: properly handle undefined
  venue: Optional<Venue>;
  greeterEvent?: GreeterEvent;
};

/// More descriptive than a inlined method.
/// Since this is specific for GreeterEventPage is is being held in here for now.
function CardHeader(props: CardHeaderProps) {
  const venue = props.venue;
  const greeterEvent = props.greeterEvent;

  const tags = useMemo(() => {
    const tags: React.ReactNode[] = [];
    if (greeterEvent) {
      const { startsAt, entryPrice, dressCode } = greeterEvent;

      // Disabled until we have ticket pricing properly implemented
      // tags.push(
      //   <IconListItem
      //     key={tags.length}
      //     icon={TicketIcon}
      //     description={entryPrice === 0 ? "Gratis" : `${entryPrice}kr`}
      //   />
      // );
      tags.push(
        <IconListItem
          key={tags.length}
          icon={ClockIcon}
          description={`${humanReadableDate(startsAt, {
            hour: "numeric",
            minute: "numeric",
          })}`}
        />
      );
      tags.push(
        <IconListItem
          key={tags.length}
          icon={DresscodeIcon}
          description={dressCode && dressCode !== "" ? dressCode : "Ingen"}
        />
      );
    }
    return tags;
  }, [greeterEvent]);

  // TODO: Taken out for now. Currently in discussion
  // if (streetName) {
  // const streetName = venue?.address.toString();
  //     tags.push(
  //         <li key={tags.length}>
  //             <img src={locationIcon} alt="" className={styles.listIcon} /> {streetName}
  //         </li>
  //     );
  // }
  const history = useHistory();
  const router = useIonRouter();

  if (venue && greeterEvent) {
    const address = `${venue.address.street}, ${venue.address.city}`;
    return (
      <div className={css.header}>
        <div className={css.headerInfo}>
          <div className={css.eventInfo}>
            <strong className={css.eventTitle}>{greeterEvent.name}</strong>
            <br />
            <p className={css.eventLocation}>{venue.name}</p>
            <br />
            <p className={css.eventLocation}>{address}</p>
          </div>
          <p className={css.eventDate}>
            {humanReadableDate(greeterEvent.startsAt)}
          </p>
        </div>
        <div className={css.borderBottom}></div>
        <ul className={css.tags}>{tags}</ul>
        <div
          className={css.venueIcon}
          onMouseDown={() =>
            history.push(
              new VenueRouteV2(router.routeInfo.tab as Tab).route({
                venueId: venue.id,
              })
            )
          }
        >
          <VenueIcon venue={venue} />
        </div>
      </div>
    );
  } else {
    return <div className={css.header}>Loading greeter event...</div>;
  }
}

function IconListItem(props: any) {
  const { description, icon } = props;
  return (
    <li>
      <img src={icon} alt="" className={css.listIcon} />
      {description}
    </li>
  );
}
