import React, { useMemo } from "react";

import { classNames, doNothing } from "@greeter/util";
import { IonIcon } from "@ionic/react";
import { checkmark } from "ionicons/icons";
import { FlyoutSwipable } from "../FlyoutSwipeable";
import { MultiStageConfirmer } from "../MultiStageConfirmer";
import { PulsingGreeter } from "../../Loading/PulsingGreeter";
import { CenteredContent } from "../../CenteredContent";

import css from "./ConfirmSwiper.module.scss";

export type ConfirmStage = "unconfirmed" | "confirming" | "done";

export type ConfirmSwiperProps = React.PropsWithChildren &{
  style?: React.CSSProperties;
  className?: string;
  stage?: ConfirmStage;
  onConfirm?: () => Promise<void>;
  text?: string;
  float?: boolean;
};

export const ConfirmSwiper = React.forwardRef<
  HTMLDivElement,
  ConfirmSwiperProps
>(
  (
    {
      className,
      stage = "unconfirmed",
      onConfirm = doNothing,
      text = "> Swipe for at betale",
      float = false,
      ...props
    },
    ref
  ) => {
    const stages = useMemo(() => {
      return [
        {
          name: "unconfirmed" as ConfirmStage,
          component: (
            <FlyoutSwipable onFlyout={onConfirm}>
              <CenteredContent className={css.SwipeToConfirm}>
                {text}
              </CenteredContent>
            </FlyoutSwipable>
          ),
        },
        {
          name: "confirming" as ConfirmStage,
          component: (
            <CenteredContent>
              <PulsingGreeter className={css.Icon} />
            </CenteredContent>
          ),
        },
        {
          name: "done" as ConfirmStage,
          component: (
            <CenteredContent>
              <IonIcon className={css.Icon} icon={checkmark} />
            </CenteredContent>
          ),
        },
      ];
    }, [text, onConfirm]);

    return (
      <div ref={ref} {...props}>
        <MultiStageConfirmer
          {...classNames(css.ConfirmSwiper, float && css.Float, className)}
          stage={stage}
          stages={stages}
          defaultComponent={
            <CenteredContent>
              <PulsingGreeter />
            </CenteredContent>
          }
        />
      </div>
    );
  }
);
