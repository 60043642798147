import React, { useEffect, useState } from "react";
import { ConditionalRender } from "@greeter-guest/components/ConditionalRender/ConditionalRender";

import { Order } from "@greeter/core";
import { classNames } from "@greeter/util";
import { Optional } from "@greeter-guest/utility/Utility";

import css from "./PriceColumn.module.scss";

export type PriceColumnProps = {
  order: Order;
  decimalSeparator?: Optional<string>;
};

export const PriceColumn: React.FC<PriceColumnProps> = ({
  order,
  decimalSeparator,
}) => {
  const showPdfEmailLink = (order && order.status === "accepted") ?? false;

  const { total } = order;
  const hasDecimal = total / Math.floor(total) - 1 > 0;

  return (
    <div {...classNames(css.PriceColumn, !showPdfEmailLink ? css.Single : "")}>
      <ConditionalRender shouldRender={showPdfEmailLink}>
        <div
          className={css.SendLink}
          onClick={() => console.log("Sending pdf to email")}
        >
          Send PDF til email
        </div>
      </ConditionalRender>
      <div {...classNames(css.Price, css.End)}>
        {hasDecimal
          ? `${total}`.replace(".", decimalSeparator ?? ",")
          : `${total},-`}
      </div>
    </div>
  );
};
