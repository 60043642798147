import * as Firebase from "firebase/auth";
import { AuthenticationError, SignInMethod, UserInfo } from "./IAuthentication";
import { partial } from "lodash";
import { errorCodeMap } from "./errors";
import { logger } from "@greeter/log";

const log = logger("[Auth]", "[Utils]");

export function mapFirebaseUserInfo(
  info: Firebase.User | undefined | null
) {
  return info
    ? ({
        id: info?.uid ?? "",
        name: info?.displayName ?? "",
        email: info?.email ?? "",
        isAnon: info?.isAnonymous ?? false,
      } as UserInfo)
    : undefined;
}

export function mapSignInMethods(
  fbSignInMethod: string
): SignInMethod | undefined {
  return fbSignInMethod === Firebase.SignInMethod.EMAIL_PASSWORD
    ? ("email" as SignInMethod)
    : fbSignInMethod === Firebase.SignInMethod.FACEBOOK
    ? ("facebook" as SignInMethod)
    : fbSignInMethod === Firebase.SignInMethod.GOOGLE
    ? ("google" as SignInMethod)
    : undefined;
}

/**
 * Abstracts away the general handling of errors. However, be advised, argument order matters
 * and should be considered in the errorCodeMap, since passwords might leak.
 * This does however lead to nice developer ergonomics, but at the cost of understandability when things go wrong.
 *
 * NOTE: This works. But it doesnt play well with the NX and storybook setup. Therefore we settle for the functional approach instead.
 */
export function handleFirebaseError(...errorParams: any[]) {
  return function (
    target: any,
    propertyKey: string,
    descriptor: PropertyDescriptor
  ) {
    const ogMethod = descriptor.value;

    descriptor.value = async function (...args: any[]) {
      try {
        return await ogMethod(args);
      } catch (error: any) {
        const errorFactory = errorCodeMap[error.code] as any;

        if (errorFactory) {
          throw partial(errorFactory, args)();
        } else {
          throw new AuthenticationError({
            code: error.code,
            message: error.message,
          });
        }
      }
    };
  };
}

export async function handleFirebase<T>(
  action: () => Promise<T>,
  ...errorArgs: any[]
) {
  try {
    return await action();
  } catch (error: any) {
    log(error);

    const errorFactory = errorCodeMap[error.code] as any;

    if (errorFactory) {
      throw partial(errorFactory, errorArgs)();
    } else {
      throw new AuthenticationError({
        code: error.code,
        message: error.message,
      });
    }
  }
}
