import {
  Day,
  TimeOfDay,
  TimeOfDayPeriod,
  convertDayToString,
} from "@greeter/date";
import { Table } from "./Table";
import { DailyOpeningHours } from "./DailyOpeningHours";

export type AreaDailyOpeningHours = DailyOpeningHours & {
  isInherited: boolean;
  isOpen: boolean;
};

function defaultOpeningHours(): AreaDailyOpeningHours {
  return {
    isOpen: true,
    isInherited: false,
    from: TimeOfDay.parse("00:00"),
    to: TimeOfDay.parse("23:59"),
  };
}

// TODO: Perhaps, turn this into a simple object instead of it being a map.
export class AreaWeeklyOpeningHours extends Map<
  Day,
  AreaDailyOpeningHours | undefined
> {
  static alwaysOpen() {
    const oh = new AreaWeeklyOpeningHours();

    oh.set(Day.Monday, defaultOpeningHours());
    oh.set(Day.Tuesday, defaultOpeningHours());
    oh.set(Day.Wednesday, defaultOpeningHours());
    oh.set(Day.Thursday, defaultOpeningHours());
    oh.set(Day.Friday, defaultOpeningHours());
    oh.set(Day.Saturday, defaultOpeningHours());
    oh.set(Day.Sunday, defaultOpeningHours());

    return oh;
  }

  close(day: Day) {
    const existing = this.get(day) ?? defaultOpeningHours();

    existing.isOpen = false;
    this.set(day, existing);

    return this;
  }

  open(day: Day, period?: TimeOfDayPeriod) {
    const existing = this.get(day) ?? defaultOpeningHours();

    existing.isOpen = true;
    existing.from = period?.from ?? existing.from;
    existing.to = period?.to ?? existing.to;

    this.set(day, existing);

    return this;
  }

  setOpeningHours(day: Day, period: TimeOfDayPeriod) {
    const existing = this.get(day) ?? defaultOpeningHours();

    existing.from = period.from;
    existing.to = period.to;

    this.set(day, existing);

    return this;
  }

  clone() {
    return new AreaWeeklyOpeningHours(this);
  }

  toJSON() {
    return [...this.entries()].map(([k, v]) => [convertDayToString(k), v]);
  }
}

export type Area = {
  name: string;
  floorPlanUrl: string;
  tables: Table[];
  openingHours: AreaWeeklyOpeningHours;
};
