import DefaultImage from "@greeter-guest/assets/default.png";

import React from "react";

import { Booking, Venue } from "@greeter/core";
import { BookingStatusIndicator } from "../BookingStatusIndicator";

import css from "./BookingVenueIcon.module.scss";

export type BookingVenueIconProps = {
  venue: Venue;
  booking: Booking;
};

export const BookingVenueIcon: React.FC<BookingVenueIconProps> = ({
  venue,
  booking,
}) => {
  return (
    <div className={css.IconContainer} style={{ aspectRatio: "1/1" }}>
      <img
        className={css.Icon}
        src={venue.logoUrl ?? DefaultImage}
        alt="Venue"
      />
      <BookingStatusIndicator
        status={booking.status}
        className={css.BookingStatusIndicator}
      />
    </div>
  );
};
