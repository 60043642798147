import React from "react";
import { BasePropsWithChildren } from "@greeter/matter";

export type GridAreaProps = BasePropsWithChildren & {
  area: string;
};

export const GridArea: React.FC<GridAreaProps> = ({ area, ...props }) => (
  <div {...props} style={{ gridArea: area }} />
);
