import "./Header";

import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";
import Routes, { Tab } from "@greeter-guest/utility/Routes";
import { FrontPageApiHandler } from "@greeter-guest/pages/FrontPage";

import { GreeterEventPageRouteHandler } from "@greeter-guest/pages/GreeterEventPage";
import {
  IonApp,
  IonTabs,
  IonTabBar,
  useIonViewWillEnter,
  useIonViewWillLeave,
  IonTabButton,
  IonIcon,
  IonRouterOutlet,
  IonLabel,
  isPlatform,
  useIonRouter,
  RouteManagerContext,
} from "@ionic/react";
import NotificationProvider from "@greeter-guest/contexts/NotificationProvider";
import { CreateBookingPageApiHandler } from "@greeter-guest/pages/CreateBookingPage";
import { LoginPage } from "@greeter-guest/pages/LoginPage";
import { BookingSummaryRouteHandler } from "@greeter-guest/pages/BookingSummaryPage";
import PrivacyPage from "@greeter-guest/pages/PrivacyPage";
import { VenueOverviewPageRouteHandler } from "@greeter-guest/pages/VenueOverviewPage";
import VenuePageRouteHandler from "@greeter-guest/pages/VenuePage/VenuePageRouteHandler";
import { UserPageApiHandler } from "@greeter-guest/pages/UserPage/UserPageApiHandler";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { calendar, home, person, storefront } from "ionicons/icons";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route, useLocation } from "react-router-dom";
import { AuthProvider, LoadingListener, useAuth } from "@greeter/matter";
import { LoginProvider } from "./contexts/LoginProvider";
import { CapacitorStripeProvider } from "@capacitor-community/stripe/dist/esm/react/provider";
import { PushNotifications } from "@capacitor/push-notifications";
import { subscribeToPushNotifications } from "./utility/PushNotifications";
import { GreeterEventOverviewPageApiHandler } from "./pages/GreeterEventOverviewPage/GreeterEventOverviewPageApiHandler";
// import { FirebaseAnalytics } from "@capacitor-firebase/analytics";
import { UpdateUserRouteHandler } from "./pages/UpdateUserPage/UpdateUserRouteHandler";
import { DeleteUserPage } from "./pages/DeleteUserPage/DeleteUserPage";
import { logger, warner } from "@greeter/log";
import { getConfig, getEnv, getFirebaseConfig } from "./utility/ConfigHooks";

import { useDefaultGuestApi } from "./api/api";
import { partial } from "lodash";
import { MenuPageApiHandler } from "./pages/MenuPage/MenuPageApiHandler";
import { CartPageApiHandler } from "./pages/CartPage/CartPageApiHandler";

import { PaymentPage } from "./pages/PaymentPage/PaymentPage";

import { ReceiptPageApiHandler } from "./pages/ReceiptPage/ReceiptPageApiHandler";
import { Capacitor } from "@capacitor/core";
// import { hotjar } from "react-hotjar";
import { PostHogProvider, usePostHog } from "posthog-js/react";

import css from "./App.module.scss";
import posthog from "posthog-js";
import { useSearchParams } from "@greeter/url";

const queryClient = new QueryClient();
const NAME = "[App]";
const log = logger(NAME);
const warn = warner(NAME);

function PageTracker() {
  const location = useLocation();
  const l = useMemo(() => partial(log, "[PageTracker]"), []);

  const [init, setInit] = useState(false);
  const posthog = usePostHog();

  useEffect(() => {
    try {
      if (!init) {
        //FirebaseAnalytics.setEnabled({ enabled: true });
        //hotjar.initialize(3303516, 6);
        setInit(true);
      }
    } catch {
      l("Failed to enable FirebaseAnalytics... Skipping...");
    }
  }, [init, l]);

  useEffect(() => {
    if (!init) return;

    const path = location.pathname + location.search;

    l("Analytics: Location changed", location);

    try {
      // FirebaseAnalytics.setCurrentScreen({
      //   screenName: location.pathname + location.search,
      // });
      // FirebaseAnalytics.logEvent({
      //   name: "screen_view",
      //   params: {
      //     screen_name: path,
      //     screen_class: document.title,
      //   },
      // });
      // hotjar.stateChange(path);
    } catch {
      l("Failed to trace page activity... Bailing...");
    }
  }, [init, location, l]);

  return null;
}

function LocationDebugger() {
  const location = useLocation();
  console.log("[LocationDebugger][location]", location);

  const router = useIonRouter();
  console.log("[LocationDebugger][router]", router.routeInfo);

  return null;
}

const HideTabBar: React.FC<React.PropsWithChildren> = ({ children }) => {
  useIonViewWillEnter(() => {
    const tabBar = document.body.querySelector("ion-tab-bar");
    if (tabBar) {
      tabBar.style.display = "none";
    }
  });
  useIonViewWillLeave(() => {
    const tabBar = document.body.querySelector("ion-tab-bar");
    if (tabBar) {
      tabBar.style.removeProperty("display");
    }
  });

  return <>{children}</>;
};

const usePushNotifications = () => {
  const { isLoggedIn } = useAuth();
  const guestApi = useDefaultGuestApi();
  useEffect(() => {
    if (!Capacitor.isNativePlatform()) {
      warn("Not native platform. Skipping push notifications...");

      return;
    }

    if (isLoggedIn && guestApi) {
      (async () => {
        // We only subscribe if user has already "granted" push notifications,
        // so as to avoid a premature cancellation of the push notifications because the user
        // does not see why they should agree.
        // Granting permission should be after creating a booking.
        if (
          (await PushNotifications.checkPermissions()).receive === "granted"
        ) {
          await subscribeToPushNotifications({ isLoggedIn, guestApi });
        }
      })();
    }
  }, [guestApi, isLoggedIn]);
};

posthog.init("phc_6OUeu1GfKjIxHDdpALF280hTbD5ixWEAB75rtPqk28B", {
  api_host: "https://eu.i.posthog.com",
  respect_dnt: true,
  session_recording: {
    maskAllInputs: true,
    maskTextSelector: "*",
  },
});

/**
 * Note for the future: CONDITIONAL ROUTES CAUSE ISSUES WITH THE HISTORY API!
 */
const Tabs: React.FC = () => {
  const config = getConfig();
  usePushNotifications();

  const tabButtonLayout = isPlatform("desktop") ? "icon-start" : "icon-top";

  return (
    <IonTabs>
      <IonRouterOutlet>
        <Route exact path="/">
          <Redirect
            to={Routes.venuesTab.venues.route({}) + window.location.search}
          />
        </Route>
        <Route exact path={Routes.login.template()}>
          <LoginPage />
        </Route>
        <Route exact path={Routes.privacy.template()}>
          <PrivacyPage />
        </Route>
        {/* TODO: The original home page. */}
        {/*<Route exact path={Routes.home.template()}>
          <FrontPageApiHandler />
        </Route>*/}

        {/* NOTE: venues tab routes */}
        <Route
          exact
          path={Routes.home.template()}
          component={VenueOverviewPageRouteHandler}
        />
        <Route
          exact
          path={Routes.venue.template()}
          render={({ match }) => {
            return (
              <Redirect
                to={
                  Routes.venuesTab.venue.route({
                    venueId: match.params.venueId ?? "",
                  }) + window.location.search
                }
              />
            );
          }}
        />
        <Route
          exact
          path={Routes.venuesTab.venue.template()}
          component={VenuePageRouteHandler}
        />

        <Route
          exact
          path={Routes.bookingSummary.template()}
          render={({ match }) => {
            if (!match.params.bookingId) {
              return (
                <Redirect
                  to={
                    Routes.venuesTab.venues.route({}) + window.location.search
                  }
                />
              );
            }

            return (
              <Redirect
                to={Routes.venuesTab.bookingSummary.route({
                  bookingId: match.params.bookingId,
                })}
              />
            );
          }}
        />
        <Route
          exact
          path={Routes.venuesTab.bookingSummary.template()}
          component={BookingSummaryRouteHandler}
        />

        {/* NOTE: greeter-events tab routes */}
        <Route
          exact
          path={Routes.greeterEvent.template()}
          render={({ match }) => {
            const greeterEventId = match.params.greeterEventId;

            if (!greeterEventId) {
              return <Redirect to={Routes.venuesTab.venues.route({})} />;
            }

            return (
              <Redirect
                to={Routes.greeterEventsTab.greeterEvent.route({
                  greeterEventId,
                })}
              />
            );
          }}
        />
        <Route
          exact
          path={Routes.greeterEventsTab.greeterEvents.template()}
          component={GreeterEventOverviewPageApiHandler}
        />
        <Route
          exact
          path={Routes.greeterEventsTab.createBooking.template()}
          component={CreateBookingPageApiHandler}
        />
        <Route
          exact
          path={Routes.greeterEventsTab.bookingSummary.template()}
          component={BookingSummaryRouteHandler}
        />

        <Route
          exact
          path={Routes.greeterEventsTab.greeterEvent.template()}
          component={GreeterEventPageRouteHandler}
        />

        {/* NOTE: profile tab routes */}
        <Route
          exact
          path={Routes.profileTab.profile.template()}
          component={UserPageApiHandler}
        />

        <Route
          exact
          path={Routes.updateUser.template()}
          render={() => <Redirect to={Routes.profileTab.updateUser.route()} />}
        />
        <Route
          exact
          path={Routes.profileTab.updateUser.template()}
          component={UpdateUserRouteHandler}
        />

        <Route
          exact
          path={Routes.deleteUser.template()}
          render={() => (
            <Redirect
              to={
                Routes.profileTab.deleteUser.route({}) + window.location.search
              }
            />
          )}
        />
        <Route
          exact
          path={Routes.profileTab.deleteUser.template()}
          component={DeleteUserPage}
        />

        <Route exact path={Routes.menu.template()}>
          <MenuPageApiHandler />
        </Route>
        <Route exact path={Routes.cart.template()}>
          <CartPageApiHandler />
        </Route>
        <Route exact path={Routes.payment.template()}>
          <PaymentPage />
        </Route>

        {/* this supports the legacy /venue links. */}
        <Route
          exact
          path={Routes.createBooking.template()}
          render={({ match }) => {
            if (!match.params.venueId) {
              return <Redirect to={Routes.venues.route({})} />;
            }

            return (
              <Redirect
                to={
                  Routes.venuesTab.createBooking.route({
                    venueId: match.params.venueId!,
                  }) + window.location.search
                } // Pass along the query params
              />
            );
          }}
        />
        <Route
          path={Routes.venuesTab.createBooking.template()}
          component={CreateBookingPageApiHandler}
        />

        <Route exact path={Routes.receipt.template()}>
          <ReceiptPageApiHandler />
        </Route>
      </IonRouterOutlet>
      <IonTabBar
        className={css.Navbar}
        slot={isPlatform("desktop") ? "top" : "bottom"}
      >
        {/* Hidden for now, until we have more volume for the front()page */}
        {/*<IonTabButton
          layout={tabButtonLayout}
          tab={Routes.home.route()}
          href={Routes.home.route()}
          className={css.NavbarItem}
          selected={location.pathname === Routes.home.route()}
        >
          <IonIcon icon={home} className={css.icon} />
          <IonLabel>Hjem</IonLabel>
        </IonTabButton>*/}
        <IonTabButton
          layout={tabButtonLayout}
          tab={"venues" as Tab}
          href={Routes.venuesTab.venues.route({})}
          className={css.NavbarItem}
        >
          <IonIcon icon={storefront} className={css.icon} />
          <IonLabel>Venues</IonLabel>
        </IonTabButton>
        <IonTabButton
          layout={tabButtonLayout}
          tab={"greeter-events" as Tab}
          href={Routes.greeterEventsTab.greeterEvents.route()}
          className={css.NavbarItem}
        >
          <IonIcon icon={calendar} className={css.icon} />
          <IonLabel>Events</IonLabel>
        </IonTabButton>
        <IonTabButton
          layout={tabButtonLayout}
          tab={"profile" as Tab}
          href={Routes.profileTab.profile.route()}
          className={css.NavbarItem}
        >
          <IonIcon icon={person} className={css.icon} />
          <IonLabel>Profil</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export function PosthogIdentifier(props: PropsWithChildren) {
  // const posthog = usePostHog();
  // const login = useAuth();

  // useEffect(() => {
  //   posthog.identify(login.userInfo?.id, { email: login.userInfo?.email });
  // }, [login, posthog]);

  return props.children;
}

/**
 * This only loads capacitor stripe config when native platform.
 */
const CapacitorStripeProviderNative = React.memo((props: PropsWithChildren) => {
  const config = useMemo(() => getConfig(), []);

  if (Capacitor.isNativePlatform()) {
    return (
      <CapacitorStripeProvider publishableKey={config.stripe.publishableKey}>
        {props.children}
      </CapacitorStripeProvider>
    );
  } else {
    return props.children;
  }
});

export function App() {
  const config = useMemo(() => getConfig(), []);
  const env = useMemo(() => getEnv(), []);
  const firebaseConfig = useMemo(() => getFirebaseConfig(), []);

  return (
    <IonApp className={css.App}>
      <PostHogProvider client={posthog}>
        <CapacitorStripeProviderNative>
          <QueryClientProvider client={queryClient}>
            <NotificationProvider>
              <AuthProvider
                env={env}
                config={config}
                firebaseConfig={firebaseConfig}
              >
                <PosthogIdentifier />
                <LoginProvider>
                  <IonReactRouter>
                    <Tabs />
                    <PageTracker />
                    <LoadingListener />
                  </IonReactRouter>
                </LoginProvider>
              </AuthProvider>
            </NotificationProvider>
          </QueryClientProvider>
        </CapacitorStripeProviderNative>
      </PostHogProvider>
    </IonApp>
  );
}
