import React, { ChangeEvent, useState } from "react";
import { classNames } from "@greeter/util";

import css from "./Input.module.scss";

export type OnChange = (event: ChangeEvent<HTMLInputElement>) => void;

export type InputProps = {
  inputClassName?: string;
  icon?: React.ReactNode;
  inputIconClassName?: string;
  error?: boolean;
} & React.HTMLProps<HTMLInputElement>;

export const Input: React.FC<InputProps> = ({
  icon,
  className,
  inputClassName,
  inputIconClassName,
  error = false,
  ...props
}) => {
  return (
    <div className={css.InputContainer}>
      {icon && <div className={css.IconWrapper}>{icon}</div>}
      <input
        {...classNames(
          css.Input,
          className,
          inputClassName,
          error && css.Danger
        )}
        {...props}
      />
    </div>
  );
};
