import placeholderCover from "@greeter-guest/assets/profile_cover.jpg";
import receiptBackground from "@greeter-guest/assets/FestFolk.jpg";

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { doNothing, classNames, doNothingAsync } from "@greeter/util";
import { Query } from "@greeter/query";
import { Res } from "@greeter/result";
import { logger, warner } from "@greeter/log";

import { useInitEffect } from "@greeter/hooks";
import { DatePeriod, TimeOfDay, withTimeOfDay } from "@greeter/date";
import {
  CreateBooking,
  CreateBookingRequest,
  CreateOrderLineRequest,
} from "@greeter/api";
import {
  Venue,
  Product,
  GreeterEvent,
  FloorPlan,
  Booking,
  Table,
  BookingSettings,
  OrderLine,
  ImageAsset,
  SpecialOpeningHours,
  Customer,
} from "@greeter/core";

import {
  IonContent,
  IonIcon,
  IonModal,
  IonPage,
  isPlatform,
  useIonAlert,
  useIonToast,
  useIonViewDidEnter,
  useIonViewWillEnter,
} from "@ionic/react";

import { TableId } from "@greeter/core";

import { first, range, size, throttle } from "lodash";
import { useHistory } from "react-router-dom";
import {
  BookTableBox,
  DescriptionCard,
  TextField,
  SelectValue,
  ProductsCarousel,
  Area,
  Loadable,
  ConfirmSwiper,
  Container,
  SelectHighlighter,
  ChooseDateSelector,
  Summary,
  TitledSection,
  Toolbar,
  BackButton,
  ChatButton,
  ChatAttributes,
  QuantityControls,
  Skeleton,
  PageCover,
  LazyCoverImage,
  Spinner,
  GroupSizePicker,
  displayWhen,
  Receipt,
} from "@greeter/matter";
import { useHiddenTabBar } from "@greeter-guest/utility/Hooks";
import { ClickableBar } from "./ClickableBar";
import Routes, {
  BookingSummaryRoute,
  CreateBookingV2Route,
  Intent,
  Tab,
} from "@greeter-guest/utility/Routes";
import { LoginStatus, useResizeOnChange } from "./Hooks";
import { useInView } from "react-intersection-observer";

import {
  alertCircleOutline,
  calendarOutline,
  constructOutline,
  peopleOutline,
  squareOutline,
  timeOutline,
  warningOutline,
} from "ionicons/icons";

import { ArgumentNullOrUndefinedError, ArrayEmptyError } from "@greeter/error";

import { useActor } from "@xstate/react";

import { Helmet } from "react-helmet";

import css from "./CreateBookingPage.module.scss";
import {
  createBookingMachine,
  CreateBookingEvents,
} from "./CreateBookingMachine";
import { getEnv } from "@greeter-guest/utility/ConfigHooks";
import { Env } from "@greeter/config";
import { createBookingPeriod2 } from "./Utility";
import { toDateString } from "@greeter/date";
import { CartItem, ProductId } from "@greeter/commerce";
import { minutesToMilliseconds, set } from "date-fns";
import { ZodTypeAny } from "zod";
import { Snapshot, SnapshotFrom } from "xstate";
import { PersistedCache, PersistedCachePolicy } from "./persisted-cache";
import { CreateBookingContext, CreateBookingSnapshotSchema } from "./schema";
import { Capacitor } from "@capacitor/core";
import { Checkout, DeclineError } from "@greeter-guest/components/Payments";
import { LoginPage } from "../LoginPage";
import { UpdateUserPage } from "../UpdateUserPage";
import { UpdateUserPageApiHandler } from "../UpdateUserPage/UpdateUserPageApiHandler";
import { useDetectTab } from "@greeter-guest/hooks";
import { InvalidArrivalTimeError } from "./errors";
import { SpecialPricePeriod } from "libs/core/src/lib/SpecialPricePeriod";

export const log = logger("[CreateBookingPage]");
export const warn = warner("[CreateBookingPage]");

type UsePersistedCacheProps = {
  id: string;
  schema: ZodTypeAny;
  policy: PersistedCachePolicy;
};
function usePersistedCache<T>(props: UsePersistedCacheProps) {
  const cache = useMemo(() => {
    return new PersistedCache<T>(props.id, props.schema, props.policy);
  }, [props.id]); // We ignore policy in deps. Should only be set once.

  return cache;
}

/**
 * Saves the restorable data to the url
 * and returns a functioning combination of
 * location.pathname and location.search.
 *
 * This is then saved to the url first and then returned.
 */
function saveSnapshotToUrl(
  context: CreateBookingContext,
  el?: HTMLIonContentElement
) {
  const pairs: [string, string][] = [];

  if (context.arrivalDate) {
    pairs.push(["arrivalDate", toDateString(context.arrivalDate)]);
  }

  if (context.arrivalTime) {
    pairs.push([
      "arrivalTime",
      TimeOfDay.fromDate(context.arrivalTime).toString(),
    ]);
  }

  if (context.comment) {
    pairs.push(["comment", context.comment]);
  }

  if (context.groupSize) {
    pairs.push(["groupSize", `${context.groupSize}`]);
  }

  if (context.selectedTables && context.selectedTables.length > 0) {
    context.selectedTables
      .map((t) => ["table", JSON.stringify(t)] as [string, string])
      .forEach((p) => pairs.push(p));
  }

  if (context.orderLines && context.orderLines.length > 0) {
    context.orderLines
      .map(
        (ol) =>
          ["cartItem", JSON.stringify([ol.productId, ol.quantity])] as [
            string,
            string
          ]
      )
      .forEach((p) => pairs.push(p));
  }

  if (el) {
    pairs.push(["scrollPosition", `${el.scrollTop}`]);
  }

  return new URLSearchParams(pairs);
}

function searchToRelativeUrl(
  search: URLSearchParams,
  history: ReturnType<typeof useHistory>
) {
  if (typeof history === "undefined" || typeof history.location === "undefined")
    return "";

  if (!search.has("intent")) {
    search.set("intent", "restore-snapshot");
  }

  return history.location.pathname + "?" + search.toString();
}

function saveToHistory(
  history: ReturnType<typeof useHistory>,
  search: URLSearchParams
) {
  history.location.search = search.toString();
  history.replace(history.location);
}

const throttledSaveSnapshotToUrl = throttle(saveSnapshotToUrl, 500, {
  leading: true,
});

export type MobilePayNoticeBarProps = {
  onClick?: () => void;
};
function MobilePayNoticeBar(props: MobilePayNoticeBarProps) {
  return (
    <div
      style={{
        backgroundColor: "#1e90ff",
        color: "white",
        padding: "0.25rem 0",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        gap: "1ch",
        fontSize: "1rem",
      }}
      onClick={props.onClick}
    >
      <span>Udfordringer med MobilePay...</span>
      <button
        style={{
          backgroundColor: "transparent",
          color: "white",
          textDecoration: "underline",
        }}
      >
        Læs mere
      </button>
    </div>
  );
}

function MobilePayNoticeContent() {
  return (
    <div
      style={{
        color: "var(--gm-color-alt-bg)",
        textAlign: "left",
        padding: "1rem",
      }}
    >
      <h2>Nogle brugere oplever problemer med MobilePay</h2>
      <p>
        Efter MobilePays nye opdatering oplever flere brugere problemer med 3DS
        secure (MitID popup når man skal betale).
      </p>
      <p>
        Hvis du oplever at den siger "BankID Cancelled", at den tager en evighed
        om at gennemføre betalingen eller andet mystisk kan du prøve en anden
        betalingsmulighed såsom Google Pay eller Apple Pay.
      </p>

      <p>I mellemtiden arbejder vi på at få den løst med MobilePay</p>
    </div>
  );
}

/**
 * Runs validation on the context and generates the arguments for creating the request
 * @throws {ArgumentNullOrUndefinedError} if any of the object args is undefined or null
 * @throws {ArrayEmptyError} if it find any of the array arguments to be empty
 * @throws {InvalidArrivalTimeError} is arrival time is before "now"
 */
export function createBookingRequestArgs(
  args: Pick<
    CreateBookingContext,
    | "orderLines"
    | "groupSize"
    | "arrivalTime"
    | "booking"
    | "selectedTables"
    | "venue"
    | "openingHours"
    | "comment"
  >
): CreateBookingRequestArgs {
  const now = new Date();
  if (!args.orderLines) throw new ArgumentNullOrUndefinedError("orderLines");
  if (args.orderLines.length === 0) throw new ArrayEmptyError("orderLines");
  if (!args.groupSize) throw new ArgumentNullOrUndefinedError("groupSize");
  if (!args.arrivalTime) throw new ArgumentNullOrUndefinedError("arrivalTime");
  if (!args.booking && args.selectedTables.length === 0)
    throw new ArrayEmptyError("selectedTables");
  if (!args.venue) throw new ArgumentNullOrUndefinedError("venue");
  // if (args.arrivalTime < now) throw new InvalidArrivalTimeError(args.arrivalTime, now);

  const period = createBookingPeriod2(args.arrivalTime, args.openingHours);

  const result: CreateBookingRequestArgs = {
    venueId: args.venue.id,
    groupSize: args.groupSize,
    period: period,
    selectedTables: args.selectedTables,
    orderLines: args.orderLines.map((ol) => ({
      productId: ol.productId!,
      quantity: ol.quantity,
    })),
    comment: args.comment,
  };

  return result;
}

type CreateBookingRequestArgs = {
  venueId: string;
  selectedTables: Array<TableId>;
  period: DatePeriod;
  groupSize: number;
  orderLines: Array<CreateOrderLineRequest>;
  comment: string;
};
export function createBookingRequest(args: CreateBookingRequestArgs) {
  if (!args.orderLines) throw new ArgumentNullOrUndefinedError("orderLines");
  if (args.orderLines.length === 0) throw new ArrayEmptyError("orderLines");
  if (!args.groupSize) throw new ArgumentNullOrUndefinedError("groupSize");
  if (args.selectedTables.length === 0)
    throw new ArrayEmptyError("selectedTables");
  if (!args.venueId) throw new ArgumentNullOrUndefinedError("venueId");

  const request: CreateBookingRequest = {
    venueId: args.venueId,
    groupSize: args.groupSize,
    period: args.period,
    tables: args.selectedTables,
    order: {
      orderLines: args.orderLines.map((ol) => ({
        productId: ol.productId,
        quantity: ol.quantity,
      })),
    },
    comment: args.comment,
  };

  return request;
}

type WarningBarProps = React.PropsWithChildren & {
  style?: React.CSSProperties;
  className?: string;
  onClick?: () => void;
  fixed?: boolean;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
};
const WarningBar: React.FC<WarningBarProps> = ({
  children,
  onClick = doNothing,
  fixed = false,
  title,
  subTitle,
}) => {
  return (
    <ClickableBar
      style={{
        backgroundColor: "var(--gm-color-warning)",
        color: "var(--gm-color-default-bg)",
        display: "grid",
        gridTemplateColumns: "auto 1fr",
        gridTemplateRows: "1fr 1fr",
        padding: "0 1rem",
        gap: "0.1rem",
        width: "100%",
        position: fixed ? "fixed" : "relative",
        animation: "fade-in-from-bottom 250ms",
      }}
      onClick={onClick}
    >
      <IonIcon
        icon={alertCircleOutline}
        style={{
          gridRow: "1 / 3",
          width: "2.5rem",
          height: "2.5rem",
          placeSelf: "center",
          ...{ "--ionic-stroke-width": "16px" },
        }}
      />

      <div style={{ alignSelf: "end", fontWeight: "bold" }}>{title}</div>
      <div style={{ alignSelf: "start" }} className="sm-text">
        {subTitle}
      </div>
    </ClickableBar>
  );
};

function isUneditableMessage(booking?: Booking, item?: string) {
  return `Hov, du har allerede oprettet en booking den dag.\nDu kan ikke opdatere ${item}.${
    booking && Booking.is(booking, "created")
      ? `\nHop ned i bunden og betal 👇`
      : ""
  }`;
}

type SelectedTable = {
  table: TableId;
  minPrice: number;
};

type RequiredProps = {
  isLoggedIn: boolean;
  isUserComplete: boolean;
  unavailableTables: Query<TableId[]>;
  /**
   * Used for caching the state of the booking
   */
  venueId: string;
  venue: Query<Venue>;
  floorPlan: Query<FloorPlan>;
  products: Query<Product[]>;
  specialPricePeriods: Query<Array<SpecialPricePeriod>>;

  bookingSettings: Query<BookingSettings>;
  specialOpeningHours: Query<SpecialOpeningHours[]>;
};

/**
 * DEVNOTE: These initial values will only be considered on FIRST RENDER.
 */
type OptionalProps = React.PropsWithChildren & {
  bookings?: Query<Booking[]>;
  greeterEvents?: Query<GreeterEvent[]>;
  groupSizes?: Query<number[]>;
  pickedProduct?: ProductId;

  customer?: Query<Customer>;

  paymentToken?: string;

  scrollPosition?: "top" | "bottom" | number;

  env?: Env;
  intent?: Intent;
  intentStatus?: "in progress" | "done" | "failed";
};

type InputProps = {
  arrivalDate?: Date;
  arrivalTime?: TimeOfDay;
  tables?: TableId[];
  cart?: CartItem[];
  groupSize?: number;
  comment?: string;
};

export type OnCreateBooking = (
  args: CreateBookingRequest
) => void | Promise<void>;

type ListenerProps = {
  onPayForBooking?: (bookingId: string) => Promise<void>;
  onProductChange?: (product: Product) => void;
  onSelectedDateChange?: (date: Date) => void;
  onCancelPayment?: (orderId: string) => void;
  onArrivalDateChange?: (date: Date) => void;
  onTableClick?: (area: string, table: Table) => void;
  onDone?: (bookingId: string) => void;

  onPaymentCancel?: () => void;
  onPaymentSuccess?: () => void;
  onPaymentError?: (error: DeclineError) => void;
};

type MutationProps = {
  createBooking: CreateBooking;
};

export type CreateBookingPageProps = RequiredProps &
  OptionalProps &
  ListenerProps &
  MutationProps &
  InputProps;

export function CreateBookingPage({
  isLoggedIn,
  isUserComplete,
  bookings,
  bookingSettings,
  pickedProduct,

  tables,
  groupSize,
  cart,
  arrivalDate,
  arrivalTime,
  comment,

  customer,

  intent,
  intentStatus,

  specialOpeningHours,

  scrollPosition,

  paymentToken,

  products = { type: "loading" },
  specialPricePeriods,

  greeterEvents,
  groupSizes = { type: "done", data: range(2, 201) },
  floorPlan,
  unavailableTables = { type: "loading" },
  venue = { type: "loading" },
  venueId,
  onPayForBooking = doNothingAsync,
  onSelectedDateChange = doNothing,
  onCancelPayment = doNothing,
  onDone = doNothing,
  onProductChange = doNothing,
  onTableClick = doNothing,

  onPaymentError = doNothing,
  onPaymentCancel = doNothing,
  onPaymentSuccess = doNothing,

  createBooking,
}: CreateBookingPageProps) {
  useHiddenTabBar();

  useResizeOnChange([greeterEvents, products]);
  const env = getEnv();

  const loginStatus: LoginStatus =
    isLoggedIn && isUserComplete
      ? "logged in"
      : isLoggedIn && !isUserComplete
      ? "incomplete user"
      : "not logged in";

  const _createBooking = useCallback(
    async function _createBooking(ctx: CreateBookingContext) {
      try {
        const request = createBookingRequest(createBookingRequestArgs(ctx));
        return await createBooking(request);
      } catch (error) {
        if (error instanceof InvalidArrivalTimeError) {
          toast(
            "Ankomsttiden er inkorrekt. Prøv at vælge en anden eller en anden dato.",
            2000
          );
        }

        throw error;
      }
    },
    [createBooking]
  );

  const pay = useCallback(
    async function _pay(booking: Booking) {
      if (!booking) throw new ArgumentNullOrUndefinedError("booking");

      if (onPayForBooking) await onPayForBooking(booking.id);
      if (onDone) onDone(booking.id);
    },
    [onPayForBooking, onDone]
  );

  const persistedSnapshot = usePersistedCache<Snapshot<unknown>>({
    id: `greeter.${venueId}.booking-snapshot`,
    schema: CreateBookingSnapshotSchema,
    policy: {
      staleAfter: minutesToMilliseconds(15),
    },
  });
  const [createBookingState, send, m] = useActor(createBookingMachine, {
    snapshot: intent ? persistedSnapshot.get() : undefined,
  });

  const [alert, dismiss] = useIonAlert();

  useEffect(() => {
    const sub = m.on("priceChangeDetected", ({ data }) => {
      alert(
        `Vær opmærksom på at bordpakkernes priser anderledes på den her dato. Specifikt, har ${data
          .map((p) => `'${p.title}'`)
          .join(", ")} ændret sig.`,
        [
          {
            text: "Scroll til toppen",
            handler() {
              contentRef.current?.scrollToTop();
            },
          },
          {
            text: "Ok",
          },
        ]
      );
    });

    return sub.unsubscribe;
  }, [m]);

  useEffect(
    function onCreating() {
      return m.on(
        "creatingBooking",
        async function _onCreating({ data: context }) {
          try {
            const booking = await _createBooking(context);
            send({ type: "setBooking", data: booking });
            send({ type: "pay" });
          } catch (error) {
            console.error(error);
            send({ type: "error" });
          }
        }
      ).unsubscribe;
    },
    [_createBooking, m, send]
  );

  useEffect(
    function onCreatingPayment() {
      return m.on(
        "creatingPayment",
        async function _onCreatingPayment({ data: booking }) {
          try {
            await pay(booking);
          } catch (error) {
            send({ type: "cancel" });
            console.error(error);
          }
        }
      ).unsubscribe;
    },
    [m, pay, send]
  );

  useEffect(
    function setSpecialPricePeriod() {
      if (specialPricePeriods.type === "done") {
        send({
          type: "setSpecialPricePeriods",
          data: specialPricePeriods.data,
        });
      }
    },
    [specialPricePeriods]
  );

  useIonViewWillEnter(() => {
    if (!intent) persistedSnapshot.clear();
  }, [intent]);

  useEffect(() => {
    if (createBookingState.matches("paying") && intent === "check-payment") {
      contentRef.current?.scrollToBottom();
      send({ type: "checkPayment" });
    }
  }, [createBookingState, intent, send]);

  useEffect(() => {
    if (customer?.type === "done") {
      send({ type: "setCustomer", data: customer.data });
    }
  }, [customer]);

  useEffect(
    () =>
      m.on("paying", () => persistedSnapshot.save(m.getPersistedSnapshot()))
        .unsubscribe,
    [m, persistedSnapshot]
  );

  // TODO: This should maybe be an internal thing on the state machine.
  useEffect(() => {
    if (createBookingState.matches("creatingPayment") && paymentToken) {
      send({ type: "next" });
    }
  }, [createBookingState]);

  useEffect(() => {
    if (
      createBookingState.matches("checkingPayment") &&
      intentStatus === "done"
    ) {
      send({ type: "cancel" });
    }
  }, []);

  const context = useMemo(
    () => createBookingState.context,
    [createBookingState]
  );

  useEffect(() => {
    if (
      createBookingState.matches("checkingPayment") &&
      intentStatus === "done"
    ) {
      send({ type: "next" });
    } else if (
      createBookingState.matches("checkingPayment") &&
      intentStatus === "failed"
    ) {
      send({ type: "cancel" });
    }
  });

  const edit = useCallback(
    function _edit(event: CreateBookingEvents) {
      send({ type: "edit" });
      send(event);
      send({ type: "validate" });
    },
    [send]
  );

  useEffect(() => {
    if (products.type !== "done") return;
    edit({ type: "setProducts", data: products.data });
  }, [products, edit]);

  useEffect(() => {
    if (specialOpeningHours.type !== "done") return;
    edit({ type: "setSpecialOpeningHours", data: specialOpeningHours.data });
  }, [edit, specialOpeningHours]);

  useInitEffect(
    function setupProduct() {
      if (products.type === "done" && pickedProduct) {
        const foundProduct = products.data.find((p) => p.id === pickedProduct);

        if (!foundProduct) return;

        edit({ type: "setProduct", data: foundProduct });
      }
    },
    [products, pickedProduct, edit],
    () => {
      return (
        products.type === "done" &&
        products.data.length > 0 &&
        !!pickedProduct &&
        !!edit
      );
    }
  );

  useInitEffect(
    function setupTables() {
      if (!tables) return;

      edit({ type: "setSelectedTables", data: tables });
    },
    [tables, createBookingState, edit],
    () =>
      !!tables && tables.length > 0 && !createBookingState.matches("uneditable")
  );

  useInitEffect(
    function setupGroupSize() {
      if (!groupSize) return;

      edit({ type: "setGroupSize", data: groupSize });
    },
    [edit, groupSize],
    () => !!edit && !!groupSize
  );

  useInitEffect(
    function setupArrivalDate() {
      if (!arrivalDate) return;
      edit({ type: "setArrivalDate", data: arrivalDate });
    },
    [arrivalDate, edit],
    () => !!arrivalDate
  );

  useInitEffect(
    function setupArrivalTime() {
      if (!arrivalTime || !arrivalDate) return;

      edit({
        type: "setArrivalTime",
        data: withTimeOfDay(arrivalDate, arrivalTime),
      });
    },
    [arrivalTime, edit],
    () => !!arrivalTime && !!arrivalDate
  );

  useInitEffect(
    function setupCart() {
      if (!cart || cart.length === 0) return;
      if (products.type !== "done") return;

      edit({ type: "setCart", data: cart });
    },
    [products, cart, edit],
    () => {
      return (
        !!cart &&
        cart.length > 0 &&
        !!products &&
        products.type === "done" &&
        !createBookingState.matches("uneditable")
      );
    }
  );

  useInitEffect(
    function setupComment() {
      if (!comment && comment !== "") return;

      edit({ type: "setComment", data: comment });
    },
    [comment],
    () => comment !== ""
  );

  const contentRef = useRef<HTMLIonContentElement>(null);

  const history = useHistory();

  const search = saveSnapshotToUrl(context, contentRef.current ?? undefined);

  useEffect(() => {
    const unwrappedBookingSettings = Query.unwrap(bookingSettings);
    if (
      unwrappedBookingSettings &&
      context.bookingBuffer !== unwrappedBookingSettings.buffer
    ) {
      edit({ type: "setBookingBuffer", data: unwrappedBookingSettings.buffer });
    }
  }, [bookingSettings, edit, context]);

  useInitEffect(
    function setupArrivalDate() {
      if (!arrivalDate) {
        warn(`Tried to set arrivalDate but value was undefined.`);
        return;
      }

      edit({ type: "setArrivalDate", data: arrivalDate });
    },
    [arrivalDate, greeterEvents, venue, edit],
    () =>
      !!arrivalDate &&
      !!edit &&
      greeterEvents?.type === "done" &&
      venue.type === "done"
  );

  useEffect(
    function _onProductChange() {
      if (context.product) onProductChange(context.product);
    },
    [context.product, onProductChange]
  );

  useInitEffect(
    function setupProduct() {
      if (products.type !== "done") {
        warn(
          `Tried to initialized initial product but products was not 'done'`
        );
        return;
      }

      let foundProduct = products.data.find((p) => p.id === pickedProduct);

      if (!foundProduct) {
        foundProduct = first(products.data);
      }

      if (foundProduct && !context.product) {
        edit({ type: "setProduct", data: foundProduct });
      }
    },
    [context.product, edit, products, pickedProduct],
    () => products && products.type === "done"
  );

  const wrappedArrivalTimes = useMemo(
    () =>
      context.arrivalTimes.map((d) => {
        const tod = TimeOfDay.fromDate(d);
        return { label: tod.toString(false), value: d } as SelectValue<Date>;
      }),
    [context.arrivalTimes]
  );

  const isSameVenue =
    !!context.venue &&
    context.booking &&
    context.booking.venueId === context.venue.id;

  const isUneditable =
    createBookingState.matches("uneditable") ||
    createBookingState.matches("bookingAlreadyPayedFor") ||
    createBookingState.matches("requiresPayment") ||
    createBookingState.matches("bookingExistsOtherVenue");

  const [toast] = useIonToast();

  const selectTable = useCallback(
    function selectTable(selectedTables: Array<TableId>) {
      if (!context.booking) {
        edit({ type: "setSelectedTables", data: selectedTables });
      }
    },
    [context.booking, edit]
  );

  const { ref, inView } = useInView({ rootMargin: "10px", threshold: 0 });

  useInitEffect(
    () => {
      if (!contentRef.current) return;

      if (scrollPosition === "bottom") contentRef.current.scrollToBottom(0);
    },
    [contentRef.current],
    () => !!contentRef.current
  );

  const handleQuantityChange = useCallback(
    function handleQuantityChange(quantity: number) {
      if (isUneditable) {
        toast(isUneditableMessage(context.booking, "bordpakke"), 3000);
        return;
      }

      edit({ type: "setQuantity", data: quantity });
    },
    [isUneditable, toast, edit]
  );

  const quantityForChosenProduct = useMemo(
    function _quantityForChosenProduct() {
      function findProductInOrderLines(id?: string) {
        return id === context.product?.id;
      }

      const existingOrderLine = context.booking?.order?.orderLines?.find(
        (ol: OrderLine) => findProductInOrderLines(ol.productId)
      );
      const newOrderLine = context.orderLines.find((ol) =>
        findProductInOrderLines(ol.productId)
      );
      return existingOrderLine?.quantity ?? newOrderLine?.quantity ?? 0;
    },
    [context.orderLines, context.product?.id, context.booking]
  );

  const [chatAttributes] = useState<ChatAttributes>();

  const tab = useDetectTab();

  useEffect(
    function onBookingStateDone() {
      if (createBookingState.matches("done") && context.booking) {
        const route = new BookingSummaryRoute(tab).route({
          bookingId: context.booking.id,
        });
        history.push(route);
      }
    },
    [history, createBookingState, m, tab, context.booking]
  );

  useEffect(
    function onReceiveBookings() {
      if (bookings?.type !== "done") return;

      edit({ type: "setBookings", data: bookings.data });
    },
    [bookings, edit]
  );

  useEffect(
    function onLoginStatus() {
      edit({ type: "setLoginStatus", data: loginStatus });
    },
    [edit, loginStatus]
  );

  useEffect(
    function onVenue() {
      if (venue.type === "done") edit({ type: "setVenue", data: venue.data });
    },
    [edit, venue]
  );

  useEffect(
    function onArrivalDateChange() {
      if (context.arrivalDate) onSelectedDateChange(context.arrivalDate);
    },
    [context.arrivalDate, onSelectedDateChange]
  );

  const handleDateChanged = useCallback(
    function _handleDateChanged(d) {
      edit({ type: "setArrivalDate", data: d });
    },
    [edit]
  );

  const handleArrivalTimeChanged = useCallback(
    function _handleArrivalTimeChanged(val, index) {
      if (isUneditable) {
        toast(isUneditableMessage(context.booking, "ankomst tidspunkt"), 3000);
        return;
      }

      edit({ type: "setArrivalTime", data: val.value });
    },
    [edit, isUneditable, toast]
  );

  const handleCommentChange = useCallback(
    function _handleCommentChange(e) {
      edit({ type: "setComment", data: e.currentTarget.value });
      if (e.currentTarget.value !== context.booking?.comment && isUneditable)
        toast(isUneditableMessage(context.booking, "kommentar"), 3000);
    },
    [context.booking?.comment, edit, isUneditable, toast]
  );

  const handleGroupSizeChange = useCallback(
    function _handleGroupSizeChange(val: number) {
      if (isUneditable) {
        toast(isUneditableMessage(context.booking, "gruppe størrelse"), 3000);
        return;
      }

      edit({ type: "setGroupSize", data: val });
    },
    [edit, isUneditable, toast]
  );

  const handleBookTableBoxClick = useCallback(
    function _handleBookTableBoxClick() {
      if (isUneditable) {
        toast(isUneditableMessage(context.booking, "borde"), 3000);
      }
    },
    [toast, isUneditable]
  );

  const handleMinPriceChange = useCallback(
    function _handleMinPriceChange(p) {
      edit({ type: "setMinTotal", data: p });
    },
    [edit]
  );

  const isPayable =
    createBookingState.matches("valid") ||
    createBookingState.matches("creatingBooking") ||
    createBookingState.matches("creatingPayment") ||
    createBookingState.matches("checkingPayment") ||
    createBookingState.matches("requiresPayment") ||
    createBookingState.matches("paying");

  useEffect(() => {
    function onFocus() {
      if (createBookingState.matches("paying")) {
        // To unstuck the paying state, return to validating.
        send({ type: "cancel" });
      }
    }
    window.addEventListener("focus", onFocus);

    return () => {
      window.removeEventListener("focus", onFocus);
    };
  }, [createBookingState, send]);

  const isPaying = createBookingState.matches("paying");

  const confirmerStage =
    isPaying || createBookingState.matches("checkingPayment")
      ? "confirming"
      : createBookingState.matches("done")
      ? "done"
      : "unconfirmed";

  const selectedTables =
    !!context.booking?.tables && isSameVenue
      ? Table.toTableIds(context.booking.tables)
      : context.selectedTables;

  const unwrappedVenue = Query.unwrap(venue);

  useEffect(
    function catchRedirectExternallyAndSaveSnapshot() {
      function saveSnapshot() {
        persistedSnapshot.save(m.getPersistedSnapshot());
      }

      const unregister = history.listen(() => {
        saveSnapshot();
      });

      return () => unregister();
    },
    [history]
  );

  const [isNoticeOpen, setIsNoticeOpen] = useState(false);

  return (
    <IonPage>
      <MobilePayNoticeBar onClick={() => setIsNoticeOpen(true)} />
      {context.venue && (
        <Helmet>
          <title>Create booking - {context.venue.name}</title>
          <meta name="og:title" content={context.venue.name} />
          <meta
            name="og:url"
            content={`https://app.greeter.dk/create-booking/${context.venue.id}`}
          />
          <meta name="og:description" content={context.venue.description} />
          <meta name="og:image" content={context.venue.coverUrl} />
        </Helmet>
      )}
      <Toolbar
        leftSlot={!isPaying && <BackButton />}
        rightSlot={<ChatButton attributes={chatAttributes} />}
      />
      <IonContent ref={contentRef}>
        <Container className={css.Container}>
          <PageCover>
            <LazyCoverImage
              fade
              blur
              src={
                unwrappedVenue
                  ? ImageAsset.findUriWithSizeOrDefault(
                      unwrappedVenue.coverAsset,
                      "16x9-w1024"
                    )
                  : placeholderCover
              }
              className={css.CoverImage}
            />
          </PageCover>
          <div className={css.VenueLogo}>
            <LazyCoverImage src={context.venue?.logoUrl ?? placeholderCover} />
          </div>
          <DescriptionCard className={css.DescriptionCard}>
            <TitledSection
              title="Vælg drikkevarer"
              className={css.ChooseProductSection}
              center
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "2rem",
                }}
              >
                {products.type === "done" ? (
                  <>
                    <ProductsCarousel
                      product={context.product}
                      products={products.data}
                      arrivalDate={context.arrivalDate}
                      arrivalTime={context.arrivalTime}
                      specialPricePeriods={
                        specialPricePeriods.type === "done"
                          ? specialPricePeriods.data
                          : []
                      }
                      onProductChanged={(p) => {
                        edit({ type: "setProduct", data: p });
                      }}
                    />
                    <div style={{ display: "grid", placeItems: "center" }}>
                      <QuantityControls
                        onChange={handleQuantityChange}
                        quantity={quantityForChosenProduct}
                      />
                    </div>
                  </>
                ) : (
                  products.type === "loading" && (
                    <Skeleton
                      pulse
                      style={{
                        width: "100%",
                        aspectRatio: "3 / 5",
                        boxSizing: "border-box",
                        margin: 0,
                      }}
                    />
                  )
                )}
              </div>
            </TitledSection>
            <TitledSection
              title="Kommentar"
              className={css.TitledSection}
              hint="Eks. valg af vodka eller juice"
            >
              <Area>
                <TextField
                  onChange={handleCommentChange}
                  value={context.booking?.comment ?? context.comment}
                  // placeholder="Indtast en kommentar hvis der er noget specifikt du ønsker."
                  style={{ backgroundColor: "var(--gm-color-default-bg)" }}
                />
              </Area>
            </TitledSection>

            <TitledSection
              title="Antal personer?"
              className={css.TitledSection}
              icon={peopleOutline}
            >
              <GroupSizePicker
                data-testid="groupsizes"
                value={context?.groupSize}
                onChange={handleGroupSizeChange}
              />
            </TitledSection>
            <div className={css.SectionHR} />
            <TitledSection
              title="Vælg en aften"
              className={css.TitledSection}
              icon={calendarOutline}
            >
              {venue.type === "done" && (
                <ChooseDateSelector
                  data-testid="dates"
                  date={context.arrivalDate}
                  onDateChanged={handleDateChanged}
                  className={css.SingleSelectButtonBar}
                  btnClassName={css.SelectButton}
                  dates={context.openingHours}
                  greeterEvents={
                    greeterEvents && greeterEvents.type === "done"
                      ? greeterEvents.data
                      : []
                  }
                />
              )}
            </TitledSection>
            <div className={css.SectionHR} />
            <TitledSection
              title="Ankomst tid?"
              className={css.TitledSection}
              icon={timeOutline}
            >
              <Loadable dependsOn={[wrappedArrivalTimes]}>
                <SelectHighlighter
                  className={css.SingleSelectButtonBar}
                  activeClassName={css.Active}
                  btnClassName={css.SelectButton}
                  selectableValues={wrappedArrivalTimes}
                  selectedValue={
                    isUneditable
                      ? context.booking?.period.from
                      : context.arrivalTime
                  }
                  onSelect={handleArrivalTimeChanged}
                />
              </Loadable>
            </TitledSection>
          </DescriptionCard>
        </Container>
        <Container>
          <TitledSection
            className={css.BookTableBox}
            title="Vælg pladser"
            icon={squareOutline}
          >
            {unavailableTables.type === "loading" ||
            floorPlan.type === "loading" ? (
              <Area>
                <Skeleton
                  pulse
                  style={{
                    aspectRatio: "1/1",
                    width: "100%",
                    boxSizing: "border-box",
                    margin: 0,
                  }}
                ></Skeleton>
              </Area>
            ) : (
              floorPlan.type === "done" &&
              unavailableTables.type === "done" && (
                <Area>
                  <BookTableBox
                    env={env}
                    now={context.arrivalTime}
                    display="capacity"
                    total={context.orderTotal}
                    bookedTables={unavailableTables.data}
                    floorPlan={floorPlan.data}
                    groupSize={context.groupSize ?? 2}
                    onTableClick={onTableClick}
                    onClick={handleBookTableBoxClick}
                    selectedTables={selectedTables}
                    onSelectedTablesChange={selectTable}
                    onMinimumPriceChange={handleMinPriceChange}
                    featureFlags={{ overlayOnOpeningHoursViolation: true }}
                    specialOpeningHours={context.specialOpeningHours}
                  />
                </Area>
              )
            )}
          </TitledSection>
        </Container>
        <Container {...classNames(css.FinishOrderContainer, css.OverlapFix)}>
          <LazyCoverImage
            fade
            blur
            className={css.Background}
            src={receiptBackground}
          />
          <div className={css.InnerContainer}>
            <Receipt
              lines={
                context.booking &&
                context.booking.status !== "rejected" &&
                context.booking.status !== "completed"
                  ? context.booking?.order?.orderLines.map(
                      ({ unitPrice, quantity, title }) => ({
                        unitPrice,
                        quantity,
                        title,
                      })
                    ) ?? []
                  : context.orderLines
              }
            />
          </div>
          {isPayable && isPlatform("desktop") && (
            <button
              ref={ref}
              data-testid="create-booking-btn"
              style={{
                background: "var(--gm-color-gradient-bg)",
                fontSize: "1.75rem",
                fontWeight: "bold",
                textAlign: "center",
                color: "white",
                borderRadius: "10px",
                width: "90%",
                padding: "1rem",
                margin: "auto",
              }}
              onClick={async () => {
                createBookingState.matches("requiresPayment")
                  ? send({ type: "pay" })
                  : send({ type: "createBooking" });
              }}
            >
              Tryk for at betale
            </button>
          )}
        </Container>
        {createBookingState.matches("invalidOrderLines") && (
          <WarningBar
            onClick={() => {
              contentRef?.current?.scrollToTop(200);
            }}
            title="Du skal vælge nogle drikkevarer."
            subTitle="Tryk for at scroll til toppen"
          />
        )}
        {createBookingState.matches("invalidTables") && (
          <WarningBar
            title="Du skal vælge et sted at sidde."
            subTitle="Scroll lidt op og vælg en plads 👆"
          />
        )}
        {isPayable && !isPlatform("desktop") && (
          <ConfirmSwiper
            data-testid="create-booking-btn"
            ref={ref}
            stage={confirmerStage}
            onConfirm={async function onConfirm() {
              createBookingState.matches("requiresPayment")
                ? send({ type: "pay" })
                : send({ type: "createBooking" });
            }}
          />
        )}
      </IonContent>
      {createBookingState.matches("invalidTotal") && (
        <WarningBar
          title={`Du mangler drikkevarer for ${
            context.minTotal - context.orderTotal
          } kr.`}
          subTitle={`Ift. til prisen for bordene (${context.minTotal} kr.). Tryk for at gå til toppen`}
          fixed
          onClick={() => {
            contentRef?.current?.scrollToTop(200);
          }}
        />
      )}
      {createBookingState.matches("bookingExistsOtherVenue") && (
        <ClickableBar
          // TODO: This should be something like a "pop up the list of bookings" view instead
          onClick={() => history.push(Routes.profile.route())}
          title="Tryk for at gå til dine bookinger"
          subtitle="Du har allerede en booking den dag, på et andet venue 🥳"
        />
      )}
      {createBookingState.matches("bookingAlreadyPayedFor") && (
        <ClickableBar
          // TODO: This should be something like a "pop up the list of bookings" view instead
          onClick={() => history.push(Routes.profile.route())}
          title="Tryk for at gå til dine bookinger"
          subtitle="Du har allerede en booking den dag 🥳"
        />
      )}
      {createBookingState.matches("requiresPayment") && (
        <ClickableBar
          onClick={() => {
            contentRef.current?.scrollToBottom(500);
          }}
          style={{
            ...displayWhen(!inView),
            background: "var(--gm-color-primary-highlight)",
          }}
          title="Tryk for at scroll til bunden og betal 🥳"
          subtitle="Din booking er klar til betaling"
        />
      )}

      <IonModal
        isOpen={createBookingState.matches("loggingIn")}
        onDidDismiss={function onDidDismiss() {
          if (m.getSnapshot().value === "loggingIn") {
            send({ type: "cancel" });
          }
        }}
      >
        {/* We should really abstract the implementation details to something like a Provider instead of ApiHandler */}
        <LoginPage
          onWillSignIn={function onWillSignIn() {
            // Ensure that we restore the snapshot after the redirection
            const search = new URLSearchParams(window.location.search);
            search.set("intent", "restore-snapshot");
            history.location.search = search.toString();
            history.replace(history.location);
          }}
          onRedirect={doNothing}
          onBack={function onBack() {
            send({ type: "cancel" });
          }}
          onDone={function onDone() {
            send({ type: "next" });
          }}
        />
      </IonModal>
      <IonModal
        isOpen={createBookingState.matches("updatingCustomerInfo")}
        onDidDismiss={function onDidDismiss() {
          if (m.getSnapshot().value === "updatingCustomerInfo") {
            send({ type: "cancel" });
          }
        }}
      >
        <UpdateUserPageApiHandler
          onBack={function onBack() {
            send({ type: "cancel" });
          }}
          onDone={function onDone() {
            send({ type: "next" });
          }}
        />
      </IonModal>
      <Spinner
        data-testid="spinner"
        reason={
          createBookingState.matches("creatingBooking")
            ? "Opretter booking..."
            : createBookingState.matches("creatingPayment")
            ? "Forbereder betaling..."
            : createBookingState.matches("choosingPaymentMethod")
            ? "Vælger betalingsmetode..."
            : createBookingState.matches("paying") && "Betaler..."
        }
      />

      <IonModal
        mode="ios"
        isOpen={
          !!paymentToken &&
          !Capacitor.isNativePlatform() &&
          createBookingState.matches("choosingPaymentMethod")
        }
        style={{ "--background": "white", "--height": "auto" }}
        breakpoints={[0, 1]}
        initialBreakpoint={1}
        onDidDismiss={function onDidDismiss() {
          send({ type: "cancel" });
          onPaymentCancel();
        }}
      >
        {!!paymentToken && !!context.booking?.id && (
          <div style={{ padding: "2rem 1rem" }}>
            <Checkout
              token={paymentToken}
              onPay={function onPay() {
                send({ type: "next" });
              }}
              redirectUrl={function generateRedirectUrl() {
                return new CreateBookingV2Route(tab).absoluteRoute({
                  bookingId: context.booking!.id,
                  arrivalDate: context.arrivalDate,
                  venueId: venueId,
                  intent: "check-payment",
                  paymentToken: paymentToken,
                });
              }}
              onSuccess={function onCheckoutSuccess() {
                send({ type: "next" });
                onPaymentSuccess();
              }}
              onError={function onCheckoutError(err: DeclineError) {
                send({ type: "cancel" });
                onPaymentError(err);
              }}
            />
          </div>
        )}
      </IonModal>

      <IonModal
        style={{ "--background": "white", "--height": "auto" }}
        breakpoints={[0, 1]}
        initialBreakpoint={1}
        isOpen={isNoticeOpen}
        onWillDismiss={() => {
          setIsNoticeOpen(false);
        }}
        mode="ios"
      >
        <MobilePayNoticeContent />
      </IonModal>
    </IonPage>
  );
}
