import css from "./BookingStatusIndicator.module.scss";

import React, { useEffect, useState } from "react";
import { classNames } from "@greeter/util";
import { ContextualBookingStatus } from "@greeter/core";
import { BaseProps } from "@greeter/matter";

export type BookingStatusIndicatorProps = BaseProps & {
  status: ContextualBookingStatus;
}

export const BookingStatusIndicator: React.FC<BookingStatusIndicatorProps> = ({ className, status }) => {
  const baseClassName = classNames(
    css.BookingStatusIndicator,
    className
  ).className;

  switch (status) {
    case "accepted":
      return <div {...classNames(baseClassName, css.Accepted)} />;
    case "missed": // Fallthrough
    case "rejected":
      return <div {...classNames(baseClassName, css.Rejected)} />;
    case "created":
      return <div {...classNames(baseClassName, css.Pending)} />;
    default:
      return <div {...classNames(baseClassName, css.Default)} />;
  }
};
