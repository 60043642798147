import React from "react";

import { BasePropsWithChildren } from "@greeter/matter";
import { Month } from "@greeter/date";
import { useTranslatedMonth } from "@greeter-guest/utility/TranslationHooks";

import css from "./MonthSection.module.scss";

export type MonthSectionProps = BasePropsWithChildren & {
  year: number;
  month: Month;
}

export const MonthSection: React.FC<MonthSectionProps> = ({ children, year, month }) => {
  const translatedMonth = useTranslatedMonth(month);
  return (
    <div className={css.MonthSection}>
      <h4 className={css.Title}>{translatedMonth} - {year} </h4>
      <div className={css.Orders}>
        {children}
      </div>
    </div>
  );
};
