export default {
  apiKey: "AIzaSyCotR2t6QDcydcL_pJJAqXt0Qbl7nVw3Xw",
  // This was the previous setup but in order to get redirect
  // working, we have to proxy through nginx.
  // https://firebase.google.com/docs/auth/web/redirect-best-practices#proxy-requests
  // authDomain: "greeter-prod.firebaseapp.com",
  authDomain: "app.greeter.dk",
  projectId: "greeter-prod",
  storageBucket: "greeter-prod.appspot.com",
  messagingSenderId: "441705227727",
  appId: "1:441705227727:web:2aa9061eeb5fa3f0e39eba",
  measurementId: "G-MDVJDM8149",
};
