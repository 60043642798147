import React from "react";

import css from "./Description.module.scss";

export type DescriptionProps = React.PropsWithChildren; 

export const Description: React.FC<DescriptionProps> = ({ children }) => {
  return <h3 className={css.Description}>{children}</h3>;
};

