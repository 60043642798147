// This files contains operations for entire arrays.
// This means a usual signature of 'f(array) returns somevalue';
export function any<T>(array?: T[] | Set<T>) {
  return (
    (!!array && Array.isArray(array) && array.length > 0) ||
    (!!array && !Array.isArray(array) && array.size > 0)
  );
}

type UnaryPredicate<T> = (a: T) => boolean;
export function exists<T>(
  array?: T[],
  predicate?: UnaryPredicate<T> | T
): boolean {
  if (!predicate || array!.length === 0 || !any(array)) return false;

  const isFunc = typeof predicate === "function";

  for (let i = 0; i < array!.length; i++) {
    const item = array![i];

    if (isFunc && (predicate as UnaryPredicate<T>)(item)) return true;
    if (!isFunc && predicate === item) return true;
  }

  return false;
}

export function empty<T>(array?: T[]) {
  return !any(array);
}
