import React from "react";

import { classNames } from "@greeter/util";
import { BasePropsWithChildren } from "@greeter/matter";

import css from "./RoundedButton.module.scss";

export type RoundedButtonProps = BasePropsWithChildren & {
  active: boolean;
  onClick?: () => void;
  style?: React.CSSProperties;
  activeClassName?: string;
  className?: string;
};

export const RoundedButton: React.FC<RoundedButtonProps> = ({
  activeClassName,
  className,
  active,
  onClick,
  children,
  style,
}) => (
  <button
    onClick={onClick}
    style={style}
    {...classNames(
      css.RoundedButton,
      active ? classNames(css.Active, activeClassName ?? "").className : "",
      className
    )}
  >
    {children}
  </button>
);
