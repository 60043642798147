import { AuthErrorCodes } from "firebase/auth";
import {
  AuthenticationError,
  EmailAlreadyExistsError,
  EmailAlreadyUsedWithOtherProviderError,
  EmailInvalidError,
  LoginCredentialsInvalidError,
  PasswordInvalidError,
  TimeoutError,
  TooManyAttemptsError,
  WeakPasswordError,
} from "./IAuthentication";

// https://firebase.google.com/docs/auth/admin/errors
export const errorCodeMap: Record<
  string,
  (...args: any[]) => AuthenticationError
> = {
  [AuthErrorCodes.INVALID_EMAIL]: (email: string) =>
    new EmailInvalidError(email),
  [AuthErrorCodes.INVALID_PASSWORD]: () => new PasswordInvalidError(),
  [AuthErrorCodes.INVALID_IDP_RESPONSE]: () =>
    new LoginCredentialsInvalidError(),
  [AuthErrorCodes.EMAIL_EXISTS]: (email: string) =>
    new EmailAlreadyExistsError(email),
  [AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER]: () =>
    new TooManyAttemptsError(),
  [AuthErrorCodes.NEED_CONFIRMATION]: (email: string) =>
    new EmailAlreadyUsedWithOtherProviderError(email),
  [AuthErrorCodes.TIMEOUT]: () => new TimeoutError(),
  [AuthErrorCodes.WEAK_PASSWORD]: () =>
    new WeakPasswordError(["Should be at least 6 characters long"]),
};
