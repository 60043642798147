import React, { useEffect, useState } from "react";
import { IonIcon } from "@ionic/react";
import { chevronUp } from "ionicons/icons";

import css from "./CloseButton.module.scss";
import expandCss from "../ExpandButton.module.scss";

export type CloseButtonProps = React.HTMLProps<HTMLDivElement> & {
}

export const CloseButton: React.FC<CloseButtonProps> = (props) => {
    return <div {...props} className={expandCss.ExpandButton}>
        <div className={expandCss.ChevronContainer} >
            <IonIcon icon={chevronUp} className={expandCss.Chevron} />
        </div>
        <div className={css.TextContainer}>
            <div className={expandCss.Text}>Luk</div>
        </div>
    </div>
}


