import { z } from "zod";
import { Product, ProductSchema } from "./Product";

export const OrderLineSchema = z.object({
  id: z.string(),
  quantity: z.number(),
  title: z.string(),
  description: z.string(),
  unitPrice: z.number(),
  total: z.number(),
  productId: z.optional(z.string()),
  product: z.optional(ProductSchema),
});
export type OrderLine = z.infer<typeof OrderLineSchema>;

export function createDefault(): OrderLine {
  return {
    id: "",
    quantity: 0,
    productId: "",
    product: Product.createDefault(),
    total: 0,
    unitPrice: 0,
    title: "Placeholder",
    description: "Placeholder",
  };
}
