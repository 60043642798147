import React from "react";

import { classNames } from "@greeter/util";
import { ButtonProps, FullWidthButton } from "@greeter/matter";

import css from "./ContinueButton.module.scss";

type ContinueButtonContinueButtonProps = ButtonProps;

export const ContinueButton: React.FC<ContinueButtonContinueButtonProps> = ({
  className,
  ...restOfProps
}) => {
  return (
    <FullWidthButton
      {...restOfProps}
      {...classNames(css.ContinueButton, className)}
    />
  );
};
