import React, { useEffect, useState } from "react";
import { GridArea } from "../../../components/GridArea/GridArea";
import {
  BasePropsWithChildren,
  CenteredContent,
  OutlinedButton,
  PulsingGreeter,
} from "@greeter/matter";
import { Description } from "../../../components/Description/Description";
import { Title } from "../../../components/Title/Title";
import { doNothing } from "@greeter/util";

import { Hint } from "@greeter/matter";
import { partial } from "lodash";

import css from "./ConfirmEmailView.module.scss";

const log = partial(console.log, "[ConfirmView]");

type ThrottleButtonProps = React.PropsWithChildren & {
  timeout?: number;
  onClick?: () => void;
  waitingSlot?: React.ReactNode;
  readySlot?: React.ReactNode;
};
const ThrottleButton: React.FC<ThrottleButtonProps> = ({
  onClick = doNothing,
  timeout = 5000,
  waitingSlot,
  readySlot,
}) => {
  const [recentlyClicked, setRecentlyClicked] = useState(false);

  useEffect(() => {
    if (recentlyClicked) {
      const id = setTimeout(() => setRecentlyClicked(false), timeout);
      return () => clearTimeout(id);
    }
  }, [recentlyClicked, timeout]);

  return (
    <OutlinedButton
      disabled={recentlyClicked}
      onClick={async () => {
        setRecentlyClicked(true);
        await onClick();
      }}
      className={css.OutlinedButton}
    >
      {recentlyClicked ? (
        !!waitingSlot ? (
          waitingSlot
        ) : (
          <span className={css.SentEmailSpan}>Sendt</span>
        )
      ) : !!readySlot ? (
        readySlot
      ) : (
        "Send igen"
      )}
    </OutlinedButton>
  );
};

export type ConfirmEmailViewProps = {
  onSendVerificationLink?: () => void | Promise<void>;
  isEmailVerified?: boolean;
};

export const ConfirmEmailView: React.FC<ConfirmEmailViewProps> = ({
  onSendVerificationLink = doNothing,
}) => {
  return (
    <div className={css.ConfirmEmailView}>
      <TitleArea>
        <CenteredContent>
          <div>
            <Title>Sådan!</Title>
            <Description>
              Inden det går løs, skal du <br />
              først bekræfte den mail, <br />
              vi netop har sendt dig
            </Description>
            <CenteredContent>
              <Hint className={css.DescriptionHint}>
                <span>
                  Vi venter her imens <span itemType="emoji">🥳</span>
                </span>
              </Hint>
            </CenteredContent>
          </div>
        </CenteredContent>
      </TitleArea>
      <LoadingArea>
        <CenteredContent>
          <PulsingGreeter />
        </CenteredContent>
      </LoadingArea>
      <BottomArea>
        <CenteredContent>
          <div>
            <Hint className={css.NotReceivedYetHint}>Ikke modtaget?</Hint>
            <ThrottleButton
              timeout={5000}
              onClick={async () => await onSendVerificationLink()}
            />
          </div>
        </CenteredContent>
      </BottomArea>
    </div>
  );
};

const TitleArea: React.FC<BasePropsWithChildren> = ({ ...props }) => (
  <GridArea {...props} area="title" />
);
const LoadingArea: React.FC<BasePropsWithChildren> = ({ ...props }) => (
  <GridArea {...props} area="loading" />
);
const BottomArea: React.FC<BasePropsWithChildren> = ({ ...props }) => (
  <GridArea {...props} area="bottom" />
);
