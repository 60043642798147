import React, { useEffect } from "react";

import { classNames } from "@greeter/util";
import { IonContent, IonIcon } from "@ionic/react";
import { chevronDown, chevronUp } from "ionicons/icons";
import { useToggle } from "../Hooks";

import css from "./ScrollableContent.module.scss";

export type ScrollableContentProps = React.PropsWithChildren & {
  className?: string;
  style?: React.CSSProperties;
};

export const ScrollableContent: React.FC<ScrollableContentProps> = ({
  className,
  children,
}) => {
  const [open, toggleOpen] = useToggle(false);

  return (
    <div className={className}>
      <div {...classNames(css.ScrollContainer, open ? css.Open : "")}>
        <div className={css.content}>{children}</div>
      </div>
      <div className={css.fadeBox} />
      <div className={css.Control} onClick={() => toggleOpen()}>
        {open ? (
          <IonIcon
            style={{ width: "1.5rem", height: "1.5rem" }}
            icon={chevronUp}
          />
        ) : (
          <IonIcon
            style={{
              width: "1.5rem",
              height: "1.5rem",
            }}
            icon={chevronDown}
          />
        )}
      </div>
    </div>
  );
};
