import React from "react";

import { classNames } from "@greeter/util";

import css from "./Container.module.scss";

export type ContainerProps = React.PropsWithChildren & {
  className?: string;
  style?: React.CSSProperties;
};

export const Container: React.FC<ContainerProps> = ({
  className,
  ...props
}) => {
  return (
    <div {...classNames(css.Container, className)} {...props} />
  );
};

export type PageContainerProps = ContainerProps;

export const PageContainer: React.FC<ContainerProps> = ({
  className,
  ...props
}) => {
  return <Container {...classNames(css.PageContainer, className)} {...props} />;
};
