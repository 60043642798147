import css from "./FullWidthButton.module.scss";

import React from "react";
import { classNames } from "@greeter/util";
import { Button, ButtonProps } from "../Button";

type FullWidthButtonProps = ButtonProps;

export const FullWidthButton: React.FC<FullWidthButtonProps & ButtonProps> = ({
  className,
  ...props
}) => {
  return (
    <Button {...props} {...classNames(css.FullWidthButton, className)} />
  );
};
