import { FloorPlan, Area, Table, Asset, AreaWeeklyOpeningHours, DailyOpeningHours, AreaDailyOpeningHours } from "@greeter/core";
import { IDeserializer } from "./IDeserializer";
import { Deserializer } from "./Deserializer";
import { AssetDeserializer } from ".";
import { TimeOfDay, convertToDay } from "@greeter/date";

export class FloorPlanDeserializer extends Deserializer<FloorPlan> {
  constructor(
    private areaDeserializer: IDeserializer<Area> = new AreaDeserializer()
  ) {
    super();
  }

  override deserialize(json: any): FloorPlan {
    return { areas: this.areaDeserializer.deserializeAll(json) };
  }
}

export class AreaDeserializer extends Deserializer<Area> {
  constructor(
    private tableDeserializer: IDeserializer<Table> = new TableDeserializer(),
    private assetDeserializer: IDeserializer<Asset> = new AssetDeserializer()
  ) {
    super();
  }

  private createTimePeriodFromJson(timePeriod: any): AreaDailyOpeningHours {
    // We dont care for timezones atm. It is assumed that opening hours are always local
    return {
      from: TimeOfDay.parse(timePeriod.from),
      to: TimeOfDay.parse(timePeriod.to),
      isOpen: timePeriod?.isOpen ?? false,
      isInherited: timePeriod?.isInherited ?? false,
    };
  }

  private createTimePeriodMapFromJson(timePeriods: any): AreaWeeklyOpeningHours {
    return timePeriods
      ? Object.entries(timePeriods).reduce(
        (acc, [day, timePeriod]: any) =>
          acc.set(
            convertToDay(day),
            this.createTimePeriodFromJson(timePeriod)
          ),
        new AreaWeeklyOpeningHours()
      )
      : new AreaWeeklyOpeningHours();
  }

  override deserialize(json: any): Area {
    return {
      name: json.name,
      floorPlanUrl: json.floorPlanUrl,
      openingHours: this.createTimePeriodMapFromJson(json.weeklyOpeningHours),
      tables: this.tableDeserializer.deserializeAll(json.tables),
    };
  }
}

class TableDeserializer extends Deserializer<Table> {}
