import { Config, Env } from "@greeter/config";
import config from "../../../config.json";
import env from "../../../environment.json";
import firebaseConfig from "../../../firebase-config";

export const getConfig = () => {
  return config as Config;
};

export const getFirebaseConfig = () => {
  return firebaseConfig;
};

export const getEnv = () => {
  return env.environment as Env;
};
