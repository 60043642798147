import { GreeterEvent, DaysliceConfig } from "@greeter/core";
import { Predicate } from "@greeter/util";
import { DateFactory, Day } from "@greeter/date";
import { addDays, isWithinInterval } from "date-fns";

export interface Filter extends Predicate<GreeterEvent> {
  name: string;
}

abstract class BaseFilter implements Filter {
  abstract name: string;
  abstract evaluate(element: GreeterEvent): boolean;

  // The fuck, you can do this :O?
  constructor(
    public daysliceConfig: DaysliceConfig = {
      dayOffset: 0,
      dayStart: 0,
      eveningStart: 18,
      nightStart: 22,
    }
  ) {}

  equals(other: Filter) {
    return this.name === other.name;
  }
}

export class AnyFilter extends BaseFilter {
  name: string;

  constructor() {
    super();

    this.name = "Any";
  }

  evaluate(element: GreeterEvent): boolean {
    return true;
  }
}

export class TodayFilter extends BaseFilter {
  name: string;

  constructor(daysliceConfig: DaysliceConfig) {
    super(daysliceConfig);

    this.name = "I dag";
  }

  evaluate(ge: GreeterEvent): boolean {
    // TODO: Is this the correct place to have this?
    const { dayOffset } = this.daysliceConfig;

    const result =
      (GreeterEvent.isToday(ge, dayOffset) && !GreeterEvent.hasEnded(ge)) ||
      (GreeterEvent.isInProgress(ge) && !GreeterEvent.hasEnded(ge));

    return result;
  }
}

export class TomorrowFilter extends BaseFilter {
  name: string;

  constructor(daysliceConfig: DaysliceConfig) {
    super(daysliceConfig);

    this.name = "I morgen";
  }

  evaluate(ge: GreeterEvent): boolean {
    const { dayOffset } = this.daysliceConfig;
    const isTomorrow = GreeterEvent.isTomorrow(ge, dayOffset);
    const isInProgress = GreeterEvent.isInProgress(ge);
    return isTomorrow && !isInProgress;
  }
}

/**
 * Shows events until next sunday, except on weekends
 * where it shows events till the sunday after.
 */
export class ThisWeekFilter extends BaseFilter {
  name: string;

  constructor(daysliceConfig: DaysliceConfig) {
    super(daysliceConfig);

    this.name = "Denne uge";
  }

  evaluate(ge: GreeterEvent): boolean {
    const now = DateFactory.create();
    const todayWeekDay = now.getDay();

    let daysToAdd = (7 - todayWeekDay) % 7; // Add enough weekdays to reach sunday.
    if (todayWeekDay === Day.Sunday || todayWeekDay >= Day.Friday)
      daysToAdd += 7; // Add another week on top (eg. 9 days total)

    const weekFromToday = addDays(now, daysToAdd);
    weekFromToday.setHours(24);

    const startTimeIsWithinWeek = isWithinInterval(ge.startsAt, {
      start: now,
      end: weekFromToday,
    });
    const endTimeIsWithin = isWithinInterval(ge.endsAt, {
      start: now,
      end: weekFromToday,
    });
    return startTimeIsWithinWeek || endTimeIsWithin;
  }
}
