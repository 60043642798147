import React from "react";
import { classNames, doNothing } from "@greeter/util";

import css from "./Card.module.scss";

export type CardProps = Omit<React.HTMLAttributes<HTMLDivElement>, "ref"> & {
  onHover?: () => void;
  href?: string;
};

export const Card = React.forwardRef<HTMLDivElement, CardProps>(
  ({ className = "", onHover = doNothing, href, ...props }, ref) => (
    <div ref={ref} {...props} {...classNames(className, css.Card)} />
  )
);
