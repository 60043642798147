import React from "react";
import { BasePropsWithChildren } from "../BaseProps";

import css from "./CenteredContent.module.scss";

type CenteredContentProps = BasePropsWithChildren;

const CenteredContent: React.FC<CenteredContentProps> = ({
  style,
  children,
}) => {
  return (
    <div className={css.CenteredContent} style={style}>
      {children}
    </div>
  );
};

export default CenteredContent;
