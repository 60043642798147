import React from "react";

import { BaseProps } from "../BaseProps";
import { classNames } from "@greeter/util";

import { GreeterIcon } from "../Icons";

import css from "./PulsingGreeter.module.scss";

type PulsingGreeterVariant = "gradient" | "white" | "black";

export type PulsingGreeterProps = BaseProps & {
  variant?: PulsingGreeterVariant;
};

export const PulsingGreeter: React.FC<PulsingGreeterProps> = ({
  className,
  variant = "white",
  ...props
}) => {
  return (
    <GreeterIcon
      {...props}
      {...classNames(
        className,
        css[`PulsingGreeter--${variant}`],
        css.PulsingGreeter
      )}
    />
  );
};
