import { ApiError, IGuestApi } from "@greeter/api";
import { Customer, TableServiceOrder, Venue } from "@greeter/core";
import { uniq } from "lodash";
import { useQuery, UseQueryOptions, QueryClient } from "@tanstack/react-query";

export function useVenueQuery(api: IGuestApi, venueId: string) {
  return useQuery<Venue, Error, Venue, string[]>({
    queryKey: ["venue", venueId],
    queryFn: async () => await api.fetchVenue(venueId),
  });
}
export function useVenuesQuery(api: IGuestApi, venueIds: string[] = []) {
  return useQuery({
    queryKey: ["venues", ...uniq(venueIds)],
    queryFn: async () => await api.fetchVenues({ ids: venueIds }),
    enabled: venueIds && venueIds.length > 0,
  });
}
export function useGreeterEventsQuery(api: IGuestApi, venueId?: string) {
  return useQuery({
    queryKey: ["greeterEvents", venueId],
    queryFn: async () =>
      venueId
        ? await api.fetchUpcomingGreeterEventsForVenue({ venueId })
        : await api.fetchUpcomingGreeterEvents({
            city: "Aalborg",
            country: "Denmark",
          }),
  });
}
export function useGreeterEventsByCityQuery(
  api: IGuestApi,
  country: string,
  city: string
) {
  return useQuery({
    queryKey: ["greeterEvents", country, city],
    queryFn: async () =>
      await api.fetchUpcomingGreeterEvents({
        city: city,
        country: country,
      }),
  });
}
export function useActivitiesQuery(api: IGuestApi, venueId: string) {
  return useQuery({
    queryKey: ["activities", venueId],
    queryFn: async () => await api.fetchVenueActivities(venueId),
  });
}
export function useThemesQuery(api: IGuestApi, venueId: string) {
  return useQuery({
    queryKey: ["themes", venueId],
    queryFn: async () => await api.fetchThemesByVenue(venueId),
  });
}
export function useProductsQuery(api: IGuestApi, venueId: string) {
  return useQuery({
    queryKey: ["products", venueId],
    queryFn: async () => await api.fetchProductsByVenue(venueId),
  });
}
export function useDaysliceConfigQuery(api: IGuestApi) {
  return useQuery({
    queryKey: ["daysliceConfig"],
    queryFn: async () => await api.fetchDaysliceConfig(),
    enabled: false, // Just use the placeholder data
    placeholderData: {
      dayOffset: 0,
      dayStart: 0,
      eveningStart: 18,
      nightStart: 22,
    },
  });
}
export function useCustomerQuery(
  api: IGuestApi,
  options?:
    | Partial<UseQueryOptions<Customer, ApiError, Customer, string[]>>
    | undefined
) {
  return useQuery({
    queryKey: ["customer"],
    queryFn: async () => await api.fetchCustomer(),
    retry: Infinity,
    ...options,
  });
}

export function useTableServiceOrderQuery(
  api: IGuestApi,
  id: string,
  options?:
    | Omit<
        UseQueryOptions<TableServiceOrder, Error, TableServiceOrder, string[]>,
        "queryKey" | "queryFn"
      >
    | undefined
) {
  return useQuery({
    queryKey: ["tableServiceOrder", id],
    queryFn: async () => await api.fetchTableServiceOrder(id),
    ...options,
  });
}

export function useCitiesQuery(
  api: IGuestApi,
  country: string,
  options?:
    | Omit<
        UseQueryOptions<string[], Error, string[], string[]>,
        "queryKey" | "queryFn"
      >
    | undefined
) {
  return useQuery({
    queryKey: ["cities"],
    queryFn: async () => await api.fetchCities(country),
    ...options,
  });
}
