import styles from "./CardContent.module.scss";
import React from "react";
import { classNames } from "@greeter/util";

export type CardContentProps = React.PropsWithChildren & {
  className?: string;
};

export const CardContent: React.FC<CardContentProps> = ({
  className = "",
  children,
}) => {
  return <div {...classNames(styles.CardContent, className)}>{children}</div>;
};
