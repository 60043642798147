import css from "./Button.module.scss";

import React, { HTMLProps, useEffect, useState } from "react";
import { classNames } from "@greeter/util";

type HTMLButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export type ButtonProps = HTMLButtonProps;

export const Button: React.FC<ButtonProps> = ({ className, ...props }) => {
  return <button {...classNames(css.Button, className)} {...props} />;
};
