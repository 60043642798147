import { PropsWithChildren } from "react";
import css from "./Alert.module.scss";
import { classNames } from "@greeter/util";
import { AlertContent } from "./AlertContent";
import { AlertIcon } from "./AlertIcon";
import { AlertTitle } from "./AlertTitle";
import { warning } from "ionicons/icons";
import { AlertDescription } from "./AlertDescription";

export type AlertProps = PropsWithChildren & {
  className?: string;
  style?: React.CSSProperties;
  title?: string;
  description?: string;
  icon?: string;
};

export function Alert({ className, children, ...props }: AlertProps) {
  return (
    <div
      {...classNames(
        className,
        css.Alert,
        "animate__animated",
        "animate__fadeIn"
      )}
      {...props}
    >
      {!children ? (
        <AlertContent>
          <AlertIcon icon={props.icon ?? warning} />
          <div style={{display: "flex", flexDirection: "column", gap: "0.5rem"}}>
            <AlertTitle>{props.title}</AlertTitle>
            <AlertDescription>{props.description}</AlertDescription>
          </div>
        </AlertContent>
      ) : children}
    </div>
  );
}
