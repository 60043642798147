export class UnwrapError<E = Error> extends Error {
  constructor(e: E) {
    super(`Failed to unwrap with error: ${JSON.stringify(e, null, 2)}`);
  }
}

export type Err = {
  code: string,
  data: unknown,
}

export type OkResult<T> = { ok: true; value: T };
export type ErrorResult<E extends Err> = { ok: false; error: E };
export type Result<T, E extends Err> = OkResult<T> | ErrorResult<E>;

export class Res {
  static unwrap<T, E extends Err>(r: Result<T, E>): T {
    if (r.ok) {
      return r.value;
    } else {
      throw new UnwrapError(r.error);
    }
  }

  static unwrapOr<T, E extends Err>(r: Result<T, E>, _default: T) {
    return r.ok ? r.value : _default;
  }

  static unwrapOrUndefined<T, E extends Err>(r: Result<T, E>, _default?: T) {
    return r.ok ? r.value : _default;
  }

  static ok<T>(value: T): OkResult<T> {
    return { ok: true, value: value };
  }

  static error<E extends Err>(error: E): ErrorResult<E> {
    return { ok: false, error: error };
  }
}
