import { Handle } from "@greeter/event";

export type UserInfo = {
  id: string;
  name: string;
  email: string;
  isAnon: boolean;
};

export type SignInMethod = "email" | "facebook" | "apple" | "google";

type AuthenticationErrorCode =
  | "too-many-attempts"
  | "login-credentials-invalid"
  | "email-invalid"
  | "email-already-exists"
  | "email-already-used-by-other-provider"
  | "password-invalid"
  | "weak-password"
  | "timeout";

type AuthenticationErrorConstructor = {
  code: AuthenticationErrorCode;
  message: string;
};

export class AuthenticationError extends Error {
  code: string;

  constructor(opts: AuthenticationErrorConstructor) {
    super(opts.message);
    this.code = opts.code;
    this.message = opts.message;
  }
}

export class EmailAlreadyUsedWithOtherProviderError extends AuthenticationError {
  constructor(email: string) {
    super({
      message: `Email "${email}" is already provided for by another provider. Use the correct provider to login or try another email.`,
      code: "email-already-used-by-other-provider",
    });
  }
}

export class EmailInvalidError extends AuthenticationError {
  constructor(email: string) {
    super({
      message: `Email "${email}" is invalid.`,
      code: "email-already-used-by-other-provider",
    });
  }
}

export class PasswordInvalidError extends AuthenticationError {
  constructor() {
    super({ message: "Invalid password.", code: "password-invalid" });
  }
}

export class LoginCredentialsInvalidError extends AuthenticationError {
  constructor() {
    super({
      message: "Invalid login credentials",
      code: "login-credentials-invalid",
    });
  }
}

export class WeakPasswordError extends AuthenticationError {
  constraints: string[];

  constructor(constraints: string[] = []) {
    super({
      message: `Password is too weak.${
        constraints.length > 0
          ? ` The password should fulfill the following: ${constraints.join(
              ", "
            )}`
          : ""
      }`,
      code: "weak-password",
    });
    this.constraints = constraints;
  }
}

export class EmailAlreadyExistsError extends AuthenticationError {
  constructor(email: string) {
    super({
      message: `Email "${email}" already exists. Try another.`,
      code: "email-already-exists",
    });
  }
}

export class TooManyAttemptsError extends AuthenticationError {
  constructor() {
    super({
      message: "Too many attempts too soon. Try again later.",
      code: "too-many-attempts",
    });
  }
}

export class TimeoutError extends AuthenticationError {
  constructor() {
    super({
      message: "Timeout exceeded. Failed to authenticate.",
      code: "timeout",
    });
  }
}

export interface IAuthentication {
  refreshToken(): Promise<string>;
  getAuthToken(): Promise<string>;
  getUser(): Promise<UserInfo | undefined>;
  fetchSignInMethods(email: string): Promise<SignInMethod[]>;
  createUserWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<void>;
  signInWithEmailAndPassword(email: string, password: string): Promise<void>;
  signInWithFacebook(): Promise<void>;
  signInWithGoogle(): Promise<void>;
  signInWithApple(): Promise<void>;
  signInAsAnon(): Promise<void>;
  updateEmail(email: string): Promise<UserInfo>;
  updateName(name: string): Promise<UserInfo>;
  onAuthChange(listener: (userInfo: UserInfo | undefined) => void): Handle;
  onAuthReady(listener: (d: Date) => void): Handle;
  onRedirect(listener: (userInfo: UserInfo | undefined) => void): Handle;
  logout(): Promise<void>;
}
