import React, { PropsWithChildren } from "react";
import { PulsingGreeter } from "./PulsingGreeter";

import css from "./LoadingOverlay.module.scss";

export type LoadingOverlayProps = PropsWithChildren & {
  message?: React.ReactNode;
  style?: React.CSSProperties;
};

export function LoadingOverlay(props: LoadingOverlayProps) {
  return (
    <div className={css.LoadingOverlay} style={props.style}>
      {props.children ? (
        props.children
      ) : (
        <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
          <PulsingGreeter /> {props.message ?? "Henter..."}
        </div>
      )}
    </div>
  );
}
