// import { IComparable } from "@greeter/util";
import { FailedToParseError } from "./FailedToParseError";
import { resetTimeOfDay, withTimeOfDay } from "./DateUtil";
import { addHours, addMinutes, addSeconds, secondsToMinutes } from "date-fns";
import { DateFactory } from "./DateFactory";
import { Minutes } from "./Time/Minutes";

// export type ParsableTimeOfDayString =
//   | `${number}:${number}`
//   | `${number}:${number}:${number}`


export type TimeZone =
  | "local"
  | "UTC"

export class TimeOfDay /* implements IComparable<TimeOfDay> */ {
  constructor(
    readonly hours: number = 0,
    readonly minutes: number = 0,
    readonly seconds: number = 0,
    public timezone: TimeZone = "local"
  ) {
    if (hours < 0 || hours > 23)
      throw new Error("Hours should be between 0 and 23");
    if (minutes < 0 || minutes > 59)
      throw new Error("Minutes should be between 0 and 59");
    if (seconds < 0 || seconds > 59)
      throw new Error("Seconds should be between 0 and 59");
  }

  static parse(value: string) {
    const pattern = new RegExp(
      /^([01][0-9]|2[0-3]):([0-5][0-9])(?::([0-5][0-9]))?$/
    );
    const match = value.match(pattern);

    if (!match) {
      throw new FailedToParseError(value, "HH:MM:SS");
    }

    const hours = match[1];
    const minutes = match[2];
    const seconds = match[3] ?? "0";

    return new TimeOfDay(parseInt(hours), parseInt(minutes), parseInt(seconds));
  }

  static max() { return new TimeOfDay(23, 59, 59); }
  static min() { return new TimeOfDay(0, 0, 0); }

  sameAs(other: TimeOfDay) {
    return this.compareTo(other) === 0;
  }

  compareTo(other: TimeOfDay) {
    return this.getTotalSeconds() - other.getTotalSeconds();
  }

  isEarlierThan(other: TimeOfDay) {
    return this.compareTo(other) < 0;
  }

  isLaterThan(other: TimeOfDay) {
    return this.compareTo(other) > 0;
  }

  isWithin(start: TimeOfDay, end: TimeOfDay) {
    return start.getTotalSeconds() > end.getTotalSeconds()
      ? this.getTotalSeconds() < end.getTotalSeconds() ||
          this.getTotalSeconds() > start.getTotalSeconds()
      : this.isLaterThan(start) && this.isEarlierThan(end);
  }

  isWithinOrEqual(start: TimeOfDay, end: TimeOfDay) {
    return start.getTotalSeconds() >= end.getTotalSeconds()
      ? this.getTotalSeconds() <= end.getTotalSeconds() ||
          this.getTotalSeconds() >= start.getTotalSeconds()
      : (this.isLaterThan(start) && this.isEarlierThan(end)) ||
          this.getTotalSeconds() === start.getTotalSeconds() ||
          this.getTotalSeconds() === end.getTotalSeconds();
  }

  /**
   * Contrary to the constructor which is STRICT, this method will account for overflow
   * such that 61 minutes turns into 1 hour and a minutes by exploiting the builtin date
   * @param hours
   * @param minutes
   * @param seconds
   */
  static create(hours = 0, minutes = 0, seconds = 0) {
    const date = resetTimeOfDay(DateFactory.create());
    const newDate = addHours(
      addMinutes(addSeconds(date, seconds), minutes),
      hours
    );

    return this.fromDate(newDate);
  }

  static copy(toCopy: TimeOfDay) {
    return new TimeOfDay(toCopy.hours, toCopy.minutes, toCopy.seconds);
  }

  static fromDate(date: Date) {
    return new TimeOfDay(date.getHours(), date.getMinutes(), date.getSeconds());
  }

  static fromSeconds(seconds: number) {
    return TimeOfDay.fromDate(
      addSeconds(resetTimeOfDay(DateFactory.create()), seconds)
    );
  }

  static range(
    start: TimeOfDay,
    end: TimeOfDay,
    stepInMinutes: 5 | 10 | 15 | 30 | 60 = 30
  ): TimeOfDay[] {
    const diff = start.difference(end);
    const diffAsMinutes = secondsToMinutes(diff.getTotalSeconds());
    const steps = Math.floor(diffAsMinutes / stepInMinutes);

    const startDate = withTimeOfDay(DateFactory.create(), start);
    const dates: Date[] = [];

    for (let i = 0; i <= steps; i++) {
      dates.push(addMinutes(startDate, i * stepInMinutes));
    }

    return dates.map(TimeOfDay.fromDate);
  }

  addMinutes(amount: number) {
    // Use builtin Date logic to calculate the next step.
    const date = new Date(1970, 1, 1, this.hours, this.minutes, this.seconds);
    return TimeOfDay.fromDate(addMinutes(date, amount));
  }

  static difference(first: TimeOfDay, second: TimeOfDay) {
    const firstAsUtc = first.toUTC();
    const secondAsUtc = second.toUTC();

    const dayInSeconds = 60 * 60 * 24;
    const totalSeconds = firstAsUtc.getTotalSeconds();
    const otherTotalSeconds =
      secondAsUtc.getTotalSeconds() < totalSeconds
        ? secondAsUtc.getTotalSeconds() + dayInSeconds
        : secondAsUtc.getTotalSeconds();

    return TimeOfDay.fromSeconds(otherTotalSeconds - totalSeconds);
  }

  difference(other: TimeOfDay) {
    return TimeOfDay.difference(this, other);
  }

  toString(withSeconds: boolean = false) {
    const paddedHours = `${this.hours}`.padStart(2, "0");
    const paddedMinutes = `${this.minutes}`.padStart(2, "0");
    const paddedSeconds = `${this.seconds}`.padStart(2, "0");

    return `${paddedHours}:${paddedMinutes}${
      withSeconds ? `:${paddedSeconds}` : ""
    }`;
  }

  getTotalSeconds() {
    const secondsInAMinute = 60;
    const secondsInAnHour = secondsInAMinute * 60;
    return (
      this.seconds +
      this.minutes * secondsInAMinute +
      this.hours * secondsInAnHour
    );
  }

  toJSON() {
    const pad = (val: number) => `${val}`.padStart(2, "0");
    return `${pad(this.hours)}:${pad(this.minutes)}:${pad(this.seconds)}`;
  }

  toUTC() {
    if (this.timezone === "local") {
      const date = withTimeOfDay(new Date(), this);
      return new TimeOfDay(
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds(),
        "UTC"
      );
    }

    return this;
  }
}
