import {
  DateFactory,
  DatePeriod,
  Day,
  toDateOnlyString,
} from "@greeter/date";
import { addDays, formatRelative } from "date-fns";
import { da } from "date-fns/locale";
import {
  AreaWeeklyOpeningHours,
  SpecialOpeningHours,
  Table,
  TableId,
  WeeklyOpeningHours,
} from "@greeter/core";
import { any } from "@greeter/array";

export type AreaOpeningHoursStatusOptions = {
  now?: Date;
  timeZone?: string;
};
export type AreaOpeningHoursStatusResult = {
  /**
   * Human readable danish string that describes the distance to the next opening hours.
   * Intended to be used to be displayed in the UI
   */
  opensAt: string | "never";
  opensAtDate?: Date;
  isOpenNow: boolean;
};
export function areaOpeningHourStatus(
  anchorDate: Date,
  openingHours: AreaWeeklyOpeningHours,
  specialOpeningHours: SpecialOpeningHours[],
  opts?: AreaOpeningHoursStatusOptions
): AreaOpeningHoursStatusResult {
  opts ??= {};
  opts.timeZone ??= "Europe/Copenhagen";
  opts.now ??= new Date();

  const periods: DatePeriod[] = [];

  const normalizedOpeningHours = WeeklyOpeningHours.normalize(openingHours);

  // Prepare a simple map for easy O(N)-ish lookups in the next loop
  const sohMap = new Map<string, SpecialOpeningHours>();
  for (let i = 0, len = specialOpeningHours.length; i < len; i++) {
    const specialOpeningHour = specialOpeningHours[i];
    sohMap.set(
      toDateOnlyString(specialOpeningHour.period.from),
      specialOpeningHour
    );
  }

  // NOTE: Javascript is stupid, simply do our own optimized loops.
  for (let i = 0, weekLength = 7; i < weekLength; i++) {
    const d = addDays(DateFactory.zone(anchorDate, opts.timeZone), i);

    const oh = normalizedOpeningHours.get(d.getDay() as Day);
    const soh = sohMap.get(toDateOnlyString(d));

    // No opening hours for day, skip to next day.
    if ((!oh || !any(oh.filter((oh) => oh.isOpen))) && !soh) continue;

    const _periods = soh
      ? // Prioritize special opening hours first
        [soh.period]
      : oh
      ? oh.map((o) => DatePeriod.fromTimePeriod(d, o.from, o.to))
      : [];

    for (let j = 0, l = _periods.length; j < l; j++) {
      periods.push(_periods[j]);
    }
  }

  for (let i = 0, l = periods.length; i < l; i++) {
    const period = periods[i];
    if (period.isWithin(anchorDate)) {
      return {
        opensAt: formatRelative(period.from, anchorDate, { locale: da }),
        opensAtDate: period.from,
        isOpenNow: true,
      };
    }
    if (period.from >= anchorDate) {
      return {
        opensAt: formatRelative(period.from, anchorDate, { locale: da }),
        opensAtDate: period.from,
        isOpenNow: false,
      };
    }
  }
  return { opensAt: "never", isOpenNow: false };
}

export function difference(a: TableId[], b: TableId[]) {
  return a.filter((bt) => !b.find((sbt) => Table.eqIds(sbt, bt)));
}
