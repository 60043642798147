import React, { useMemo } from "react";
import greeterIcon from "@greeter-guest/assets/greeter-logo-white.svg";

import { RoundedButton } from "@greeter-guest/components/RoundedButton";
import { VenueIcon } from "@greeter-guest/components/VenueIcon";
import { Address, Booking, DaysliceConfig, Venue } from "@greeter/core";
import { classNames } from "@greeter/util";
import { useHistory } from "react-router-dom";
import { UpcomingGreeterEventsCarousel } from "@greeter-guest/components/UpcomingGreeterEventsCarousel";
import { TitledSection } from "@greeter-guest/components/Section/TitledSection";
import { GreeterEvent } from "@greeter/core";
import Routes, { HomeRoute, Tab } from "@greeter-guest/utility/Routes";
import { IonPage, IonContent, useIonRouter } from "@ionic/react";
import {
  Container,
  GreeterIcon,
  HorizontalLine,
  Skeleton,
} from "@greeter/matter";

import css from "./BookingSummaryPage.module.scss";

const dateFormatter = new Intl.DateTimeFormat("da-DK", {
  weekday: "short",
  month: "short",
  day: "numeric",
});

export type BookingSummaryPageProps = {
  venue?: Venue;
  booking?: Booking;
  upcomingEvents?: GreeterEvent[];
  venues?: Venue[];
  daysliceConfig: DaysliceConfig;
};

export const BookingSummaryPage: React.FC<BookingSummaryPageProps> = ({
  venue,
  booking,
  upcomingEvents = [],
  venues = [],
  daysliceConfig,
}) => {
  const history = useHistory();
  const upcomingEventsSection = useMemo(() => {
    if (upcomingEvents && upcomingEvents.length > 0)
      return (
        <TitledSection title={`Andre events på ...`}>
          <UpcomingGreeterEventsCarousel
            greeterEvents={upcomingEvents}
            venues={venues}
            daysliceConfig={daysliceConfig}
          />
        </TitledSection>
      );
  }, [daysliceConfig, upcomingEvents, venues]);

  const tablesText = useMemo(() => {
    if (booking) {
      return booking
        ? [...booking.tables.keys()].map((k) => {
            return `${k} - Bord${
              (booking.tables.get(k)?.length ?? 0) > 1 ? "e" : ""
            } ${
              booking.tables
                .get(k)
                ?.map((v) => v.number)
                .join(", ") ?? ""
            }`;
          })
        : "";
    }
  }, [booking]);

  const router = useIonRouter();

  return (
    <IonPage>
      <IonContent>
        <Container className={css.OuterContainer}>
          <div>
            <div
              style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
              {...classNames(css.InnerContainer, css.BodyContainer)}
            >
              <div {...classNames(css.ContentContainer)}>
                <Title>
                  Din bestilling
                  <br />
                  er afsendt
                </Title>
                <div className="hr" />
                <HorizontalLine />
                <div className={css.Grid}>
                  {!venue ? (
                    <Skeleton
                      style={{
                        width: "5rem",
                        height: "5rem",
                        borderRadius: "100vw",
                      }}
                      pulse
                    />
                  ) : (
                    <VenueIcon className={css.VenueIcon} venue={venue} />
                  )}
                  <div className={css.OrderSummary}>
                    <div className={css.OrderSummaryTitle}>
                      <b>{booking?.order?.orderLines[0].title}</b>
                    </div>
                    <div className={css.OrderSummaryContent}>
                      <div>{`${booking?.groupSize} pers.`}</div>
                      <div>{tablesText}</div>
                      <div>
                        {booking
                          ? dateFormatter.format(booking.period.from)
                          : ""}
                      </div>
                      <div>{`Ank. kl. ${(
                        "" + booking?.period.from.getHours()
                      ).padStart(2, "0")}:${(
                        "" + booking?.period.from.getMinutes()
                      ).padStart(2, "0")}`}</div>
                    </div>
                    <br />
                    <br />
                    <div className={css.Address}>
                      {venue?.address && Address.toString(venue.address)}
                    </div>
                  </div>
                </div>
                <HorizontalLine />
                <div className={css.Information}>
                  <p>
                    <b>Vi sender en bekræftelse, når bookingen er godkendt</b>
                  </p>
                  <p>
                    <span style={{ color: "var(--gm-color-warning)" }}>
                      <b>Stedet svarer typisk inden 24 timer</b>
                    </span>
                  </p>
                  <br />
                  <br />
                  <div className={css.Grid}>
                    <div className={css.GreeterIcon}>
                      <GreeterIcon />
                    </div>
                    <div className={css.ObsContainer}>
                      <div>
                        <b>OBS:</b>
                      </div>
                      <div>
                        Møder i op efter ovenstående
                        ankomsttidspunkt, kan i risikere at miste jeres bord.
                        <br />
                        <br />I vil naturligvis modtage forudbetalte
                        drikkevarer
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ borderBottom: "dashed white 1px" }} />
            <div {...classNames(css.InnerContainer, css.EndContainer)}>
              <RoundedButton
                onClick={() => history.push(Routes.profile.route())}
                active={true}
                activeClassName={css.GoToOrdersButton}
              >
                Gå til bookingoversigt
              </RoundedButton>
              <div
                onClick={() => history.push(new HomeRoute(router.routeInfo.tab as Tab).route())}
                className={css.FinishButton}
                style={{ padding: "1rem 0 0" }}
              >
                Afslut
              </div>
            </div>
          </div>
          {upcomingEventsSection}
        </Container>
      </IonContent>
    </IonPage>
  );
};

const Title: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <div className={css.Title}>{children}</div>;
};
