import React, { useState } from "react";
import { useDefaultGuestApi } from "@greeter-guest/api/api";
import { DaysliceConfig } from "@greeter/core";
import {
  useCitiesQuery,
  useGreeterEventsByCityQuery,
  useGreeterEventsQuery,
  useVenuesQuery,
} from "@greeter/guest-api-hooks";
import { useHistory } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { GreeterEventOverviewPage } from "./GreeterEventOverviewPage";
import { getString, getStringOr, useSearchParams } from "@greeter/url";
import { usePersistentStore } from "@greeter/store";
import { useIonViewWillEnter } from "@ionic/react";

export function GreeterEventOverviewPageApiHandler() {
  const history = useHistory();
  const searchParams = useSearchParams();

  const store = usePersistentStore<string>();

  const [city, setCity] = useState<string>("Aalborg");
  const [country, setCountry] = useState<string>("Denmark");

  useIonViewWillEnter(() => {
    const city = store.getOrDefault("greeter.city", "Aalborg");
    const country = store.getOrDefault("greeter.country", "Denmark");

    setCity(city);
    setCountry(country);
  }, [store]);

  const guestApi = useDefaultGuestApi();
  const citiesQuery = useCitiesQuery(guestApi, country);
  const greeterEventsQuery = useGreeterEventsByCityQuery(
    guestApi,
    country,
    city
  );
  const venuesQuery = useVenuesQuery(
    guestApi,
    greeterEventsQuery.data?.map((ge) => ge.venueId)
  );

  const daysliceConfig = useMemo(() => DaysliceConfig.createDefault(), []);

  return (
    <GreeterEventOverviewPage
      greeterEvents={greeterEventsQuery.data}
      venues={venuesQuery.data}
      daysliceConfig={daysliceConfig}
      cities={citiesQuery.data ?? []}
      city={city}
      onCityChange={(c) => {
        store.save("greeter.city", c);
        setCity(c);
      }}
    />
  );
};
