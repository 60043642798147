import Routes from "@greeter-guest/utility/Routes";
import React, { useEffect } from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import { BookingSummaryApiHandler } from "./BookingSummaryApiHandler";

type Params = {
  bookingId: string;
}

export function BookingSummaryRouteHandler({ match }) {
  return <BookingSummaryApiHandler bookingId={match?.params.bookingId} />;
};
