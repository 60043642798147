export type DaysliceConfig = {
  dayOffset: number;
  dayStart: number;
  eveningStart: number;
  nightStart: number;
};

export module DaysliceConfig {
  export function createDefault(): DaysliceConfig {
    return {
      dayOffset: 0,
      dayStart: 6,
      eveningStart: 18,
      nightStart: 23,
    };
  }
}
