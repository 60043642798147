import { TimeOfDay, TimeOfDayPeriod } from "@greeter/date";
import { z } from "zod";

export const PriceChangeSchema = z.object({
  id: z.string(),
  period: z.object({
    from: z.string().transform(TimeOfDay.parse),
    to: z.string().transform(TimeOfDay.parse),
  }).transform(p => p as TimeOfDayPeriod),
  price: z.number(),
})

export type PriceChange = z.infer<typeof PriceChangeSchema>;
