import css from "./Hint.module.scss";

import React from "react";
import { classNames } from "@greeter/util";

export type HintProps = React.HTMLProps<HTMLDivElement> & {
  text?: string;
  placement?: "top" | "bottom";
};

export const Hint: React.FC<HintProps> = ({
  text = "",
  className,
  children,
  placement = "bottom",
  ...props
}) => {
  return (
    <div {...props} {...classNames(css.Hint, placement === "top" && css.Top, className)}>
      {text ? text : children}
    </div>
  );
};
