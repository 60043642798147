import { Month, TimeOfDay } from "@greeter/date";

export const monthFormatter =
  (format: "long" | "short" | "numeric" = "long") => new Intl.DateTimeFormat("da-DK", { month: format });

export function convertMonthToString(month: Month) {
  switch (month) {
    case Month.January:
      return "january";
    case Month.February:
      return "february";
    case Month.March:
      return "march";
    case Month.April:
      return "april";
    case Month.May:
      return "may";
    case Month.June:
      return "june";
    case Month.July:
      return "july";
    case Month.August:
      return "august";
    case Month.September:
      return "september";
    case Month.October:
      return "october";
    case Month.November:
      return "november";
    case Month.December:
      return "december";
  }
}

export const dateFormatter = new Intl.DateTimeFormat("da-DK", { month: "short", day: "numeric", year: "numeric" })

export function toPrettyDateString(date: Date) {
  return dateFormatter.format(date);
}

export function toPrettyTimeString(date: Date) {
  return TimeOfDay.fromDate(date).toString();
}
