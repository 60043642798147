import React from "react";

import { useDefaultGuestApi } from "@greeter-guest/api/api";
import { UserPage } from "./UserPage";
import { useAuth } from "@greeter/matter";
import { useBookingsQuery } from "@greeter-guest/api/QueryHooks";
import { DatePeriod } from "@greeter/date";
import { useCustomerQuery, useVenuesQuery } from "@greeter/guest-api-hooks";
import { useHistory } from "react-router-dom";
import { logger } from "@greeter/log";

import Routes from "@greeter-guest/utility/Routes";
import { PhoneNumber } from "@greeter/core";

const log = logger("[UserPageApiHandler]");

export const UserPageApiHandler: React.FC = () => {
  const api = useDefaultGuestApi();
  const { isLoggedIn, auth } = useAuth();
  const customerQuery = useCustomerQuery(api, { enabled: isLoggedIn });

  const bookings = useBookingsQuery(api, {
    period: new DatePeriod({
      from: new Date(Number.MIN_VALUE),
      to: new Date(Number.MAX_VALUE),
    }),
  });
  const venuesQuery = useVenuesQuery(
    api,
    bookings.data?.map((b) => b.venueId) ?? []
  );

  const history = useHistory();

  return (
    <UserPage
      user={{
        email: customerQuery.data?.email ?? "",
        name: customerQuery.data?.name ?? "",
        phoneNumber: customerQuery.data?.phoneNumber ? PhoneNumber.toString(customerQuery.data.phoneNumber) : "",
      }}
      isLoggedIn={isLoggedIn}
      bookings={bookings.data ?? []}
      venues={venuesQuery.data ?? []}
      onCreateProfile={() => history.push(Routes.login.route())}
      onEditProfile={() => {
        history.push(Routes.updateUser.route());
      }}
      onSignOut={() => {
        auth?.logout();
      }}
    />
  );
};
