import React from "react";

import { doNothing, Lazy } from "@greeter/util";
import { IonIcon, isPlatform, useIonAlert, useIonToast } from "@ionic/react";
import { chatboxOutline, chatbubbleEllipsesOutline } from "ionicons/icons";
import { useEffect, useState, useMemo, useCallback } from "react";
import { Capacitor } from "@capacitor/core";
import { TawkTo } from "../TawkTo";
import { Signal } from "@greeter/event";
import { atom } from "jotai";
import { useAtom } from "jotai/react";

import css from "./ChatButton.module.scss";
import { useLoading } from "@greeter/loading";

export type ChatAttributes = {
  name?: string;
  venue?: string;
  type?: "guest" | "venue";
};

export type ChatButtonProps = React.PropsWithChildren & {
  onClick?: (e: React.SyntheticEvent) => void;
  attributes?: ChatAttributes;
};

export type SupportChatProps = {
  attributes?: ChatAttributes;
};

const chatAtom = atom(() => {
  const loadSignal = new Signal();
  const lazyTawk = new Lazy<TawkTo>(() => {
    const tawk = new TawkTo("629d11137b967b11799303cf", "1g4qoldj4");

    loadSignal.subscribe(() => {
      tawk.hideWidget();
    });

    tawk.hideWidget();
    tawk.onChatMinimized(tawk.hideWidget.bind(tawk));
    tawk.onLoad(() => loadSignal.emit());

    return tawk;
  });

  return {
    lazy: lazyTawk,
    promise: new Promise<TawkTo>((resolve) => {
      loadSignal.subscribe(() => {
        resolve(lazyTawk.value);
      });
    }),
  };
});

export const useSupportChat = (props?: SupportChatProps) => {
  const [{ lazy, promise }] = useAtom(chatAtom);

  useEffect(() => {
    if (!props?.attributes) return;
    // lazy.value.setAttributes(props.attributes);
  }, [props?.attributes, lazy.value]);

  const toggle = useCallback(() => {
    if (Capacitor.isNativePlatform() && isPlatform("ios")) {
      // iOS is causing issues when it reaches the native compiled version.
      // The simplest solution is therefore to use the chat link directly.
      // Sadly this is the best we can do at the moment
      window.location.href =
        "https://tawk.to/chat/629d11137b967b11799303cf/1g4qoldj4";
    } else {
      lazy.value.toggle();
    }
  }, [lazy.value]);

  const result = useMemo(() => {
    return {
      toggle: toggle,
      promise: promise,
    };
  }, [promise, toggle]);

  return result;
};

export const useSupportToast = () => {
  const [showToast] = useIonToast();
  const { toggle, promise } = useSupportChat();
  const { waitFor } = useLoading();

  return useCallback(
    (msg: string) => {
      showToast({
        message: msg,
        buttons: [
          {
            text: "💬 Hjælp",
            handler: async () => {
              toggle();
              await waitFor(promise, "Åbner chatten...");
            },
          },
          { text: "Ok" },
        ],
        duration: 5000,
      });
    },
    [showToast, waitFor, toggle, promise]
  );
};

export const useSupportAlert = () => {
  const [showAlert] = useIonAlert();
  const { waitFor } = useLoading();
  const { toggle, promise } = useSupportChat();

  const supportAlert = useCallback(
    (msg: string) => {
      return showAlert({
        header: "🦉 Der er ugler i mosen.",
        message: msg,
        buttons: [
          { text: "Ok" },
          {
            text: "💬 Hjælp",
            handler: async () => {
              toggle();
              await waitFor(promise, "Åbner chatten...");
            },
          },
        ],
      });
    },
    [toggle, waitFor, showAlert, promise]
  );

  return supportAlert;
};

export const ChatButton: React.FC<ChatButtonProps> = ({
  attributes,
  onClick = doNothing,
}) => {
  const { toggle } = useSupportChat({ attributes });

  return (
    <div
      className={css.ChatButton}
      onClick={(e: React.SyntheticEvent) => {
        toggle();
        onClick(e);
      }}
    >
      <IonIcon icon={chatboxOutline} />
    </div>
  );
};
