import React from "react";
import { classNames } from "@greeter/util";

import css from "./FloatingActionBar.module.scss";

export type FloatingActionBarProps = React.ComponentPropsWithoutRef<"div"> & {
  style?: React.CSSProperties;
  className?: string;
  onClick?: () => void;
  type: "gradient" | "warning" | "danger" | "grey";
};

export const FloatingActionBar = React.forwardRef<
  HTMLDivElement,
  FloatingActionBarProps
>(({ className, type, ...props }, ref) => (
  <div
    ref={ref}
    {...props}
    {...classNames(css.FloatingActionBar, css[type], className)}
  />
));

type TextProps = React.PropsWithChildren &{
  className?: string;
  style?: React.CSSProperties;
};

export const FloatingActionTitle: React.FC<TextProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div {...classNames(css.FloatingActionTitle, className)} {...props}>
      {children}
    </div>
  );
};

export const FloatingActionSubTitle: React.FC<TextProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div {...classNames(css.FloatingActionSubTitle, className)} {...props}>
      {children}
    </div>
  );
};
