/**
 * Mutates the map with key and array as value.
 *
 * Finds the key and the value then adds to the array.
 * If it doesnt exist, create a new array and add to the array.
 */
export function upsert<TKey, TValue>(
  map: Map<TKey, Array<TValue>>,
  k: TKey,
  v: TValue
) {
  const existing = map.get(k);

  if (existing) {
    existing.push(v);
  } else {
    map.set(k, [v]);
  }

  return map;
}
