import { partial, reduce } from "lodash";

const fizzle = (...args: any) => {};

// 'var' used on purpose, we want it globalized
var enabled = false;

export type LogLevel = "log" | "warn" | "error";

export function enableLogs() {
  enabled = true;
}

export function disableLogs() {
  enabled = false;
}

export function timestamp () { return `[${new Date().toISOString()}]` };

export type CanaryOptions = {
  args: any[];
  logLevel: LogLevel;
};

/**
 * The canary in the coal mine. Simply a logging framework for easy to read context for
 * debugging with print statements. It allows for filtering based on tags.
 * @param opts The options for the canary
 * @returns
 */
export const canary = (opts: CanaryOptions) => {
  const log =
    opts.logLevel === "error"
      ? console.error
      : opts.logLevel === "warn"
      ? console.warn
      : console.log;

  const baseLog = reduce(opts.args, (f, arg) => partial(f, arg), log);

  return (...args: any) =>
    enabled
      ? reduce([timestamp(), ...args], (f, arg) => partial(f, arg), baseLog)()
      : fizzle(args);
};

export const logger = (...args: any) => canary({ logLevel: "log", args });
export const warner = (...args: any) => canary({ logLevel: "warn", args });
export const sirene = (...args: any) => canary({ logLevel: "error", args });
