import { Deserializer } from "./Deserializer";
import { GreeterEvent } from "@greeter/core";
import { DateFactory } from "@greeter/date";

export class GreeterEventDeserializer extends Deserializer<GreeterEvent> {
  override deserialize(json: any): GreeterEvent {
    const startDate = DateFactory.create(Date.parse(json.startsAt));
    const endDate = DateFactory.create(Date.parse(json.endsAt));

    const greeterEvent: GreeterEvent = {
      ...json,
      startsAt: startDate,
      endsAt: endDate,
    };
    return greeterEvent;
  }
}
