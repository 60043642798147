import { useMemo } from "react";
import { createEmitter, Event, Signal, Transport } from "./EventEmitter";

export function useEmitter<T extends Event>() {
  return useMemo(() => createEmitter<T>(), []);
}

export function useSignal() {
  return useMemo(() => new Signal(), []);
}

export function useTransport<T>() {
  return useMemo(() => new Transport<T>(), []);
}
