import React from "react";
import { BookingStatusText } from "./BookingStatusText";
import { DateText } from "./DateText";
import { Title } from "./Title";
import { Booking, Venue } from "@greeter/core";
import { IonIcon } from "@ionic/react";
import {
  beerOutline,
  calendarOutline,
  locationOutline,
  peopleOutline,
  pricetagOutline,
  square,
  squareOutline,
} from "ionicons/icons";
import { BookingId } from "@greeter/matter";

import css from "./DescriptionColumn.module.scss";

export type DescriptionColumnProps = {
  booking: Booking;
  venue?: Venue;
};

export const DescriptionColumn: React.FC<DescriptionColumnProps> = ({
  booking,
  venue,
}) => (
  <div className={css.DescriptionColumn}>
    {/* Order title will break of no orderlines */}
    <Title>{venue?.name}</Title>
    <div>
      <IonIcon icon={pricetagOutline} /> <BookingId id={booking.id} />
      {[...booking.tables.entries()].map(([area, tables]) => {
        return (
          <div>
            <IonIcon icon={squareOutline} /> {area}:{" "}
            {tables.map((t) => t.number).join(", ")}
          </div>
        );
      })}
    </div>
    {booking ? (
      <div style={{ display: "flex", alignContent: "center", gap: "0.2rem" }}>
        <IonIcon icon={calendarOutline} />
        <DateText date={booking.period.from} />
      </div>
    ) : (
      <DateText />
    )}
    {venue && (
      <div>
        <IonIcon icon={locationOutline} /> {venue.address.city},{" "}
        {venue.address.street}
      </div>
    )}
    <div>
      <IonIcon icon={peopleOutline} /> {booking.groupSize} personer
    </div>
    {booking.order && booking.order.orderLines[0] && (
      <div>
        <IonIcon icon={beerOutline} /> {booking.order.orderLines[0].quantity} x{" "}
        {booking.order.orderLines[0].title}
      </div>
    )}
    <BookingStatusText status={booking.status} />
  </div>
);
