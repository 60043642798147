import React, { useEffect, useMemo, useState } from "react";
import { ImageColumn } from "./ImageColumn/ImageColumn";
import { DescriptionColumn } from "./DescriptionColumn";
import { PriceColumn } from "./PriceColumn";
import {
  Venue,
  Booking,
  RejectedBooking,
} from "@greeter/core";
import { Card, CardContent } from "@greeter/matter";

import { RejectionMessage } from "./RejectionMessage";
import { ReadMoreButton } from "./ExpandButton/ReadMoreButton";
import { CloseButton } from "./ExpandButton/CloseButton";

import css from "./BookingHistoryItem.module.scss";
import { classNames } from "@greeter/util";

export type BookingHistoryItemProps = {
  venue: Venue;
  booking: Booking;
  active?: boolean;
}

const useToggle = (initialValue: boolean): [boolean, () => void] => {
  const [toggle, setToggle] = useState(initialValue);
  return [toggle, () => setToggle(!toggle)];
};

const useBoolean = (
  initialValue: boolean
): [boolean, () => void, () => void] => {
  const [value, setValue] = useState(initialValue);
  return [value, () => setValue(true), () => setValue(false)];
};

export const BookingHistoryItem: React.FC<BookingHistoryItemProps> = ({
  booking,
  venue,
  active = false,
}) => {
  const [showDescriptionField, toggleDescription] = useToggle(false);
  const isRejectedOrder = useMemo(
    () => Object.prototype.hasOwnProperty.call(booking, "rejectionMessage"),
    [booking]
  );

  return (
    <Card >
      <CardContent {...classNames(css.BookingHistoryItem, active ? css.InProgress : "")}>
        <ImageColumn booking={booking} venue={venue} />
        <DescriptionColumn venue={venue} booking={booking} />
        {isRejectedOrder && !showDescriptionField ? (
          <ReadMoreButton onClick={toggleDescription}>
            Læs stedets forklaring
          </ReadMoreButton>
        ) : (
          !isRejectedOrder &&
          booking.order && <PriceColumn order={booking.order} />
        )}
        {isRejectedOrder && showDescriptionField && (
          <>
            <RejectionMessageColumn>
              <RejectionMessage>
                {(booking as RejectedBooking).rejectionMessage}
              </RejectionMessage>
            </RejectionMessageColumn>
            <CloseButton onClick={toggleDescription} />
          </>
        )}
      </CardContent>
    </Card>
  );
};

const RejectionMessageColumn = (props: any) => (
  <div {...props} className={css.RejectionMessageColumn} />
);
