import React, { useMemo } from "react";

import "./ThemeFilterBar.scss";
import { Theme } from "@greeter/core";
import { RoundedButton } from "@greeter-guest/components/RoundedButton";
import { Carousel } from "@greeter/matter";
import { doNothing } from "@greeter/util";

export type ThemeFilterBarProps = {
  selectedThemeId: string;
  onClick?: (themeId: string) => void;
  themes: Theme[];
};

export function ThemeFilterBar({
  selectedThemeId,
  themes,
  onClick = doNothing,
}: ThemeFilterBarProps) {
  const themeBarButtons = useMemo(
    () =>
      themes.map((theme, index) => {
        return (
          <RoundedButton
            onClick={() => {
              onClick(theme.id);
            }}
            key={index}
            active={theme.id === selectedThemeId}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                justifyItems: "center",
                alignItems: "center",
                gap: "5px",
                padding: "0 0.5rem 0 0",
              }}
            >
              <img
                src={theme.iconUrl}
                style={{ height: "1.5rem", width: "1.5rem" }}
                alt={theme.name}
              />
              {theme.name}
            </div>
          </RoundedButton>
        );
      }),
    [selectedThemeId, themes]
  );

  return (
    <Carousel fades spacing="sm">
      {themeBarButtons}
    </Carousel>
  );
}
