import React, { useEffect, useMemo, useState } from "react";
import {
  BookingSettings,
  GreeterEvent,
  SpecialOpeningHours,
  Venue,
} from "@greeter/core";
import { classNames, doNothing, isEmpty } from "@greeter/util";

import { isSameDay } from "date-fns";
import {
  SelectDate,
  generateBookableDates,
  mergeGreeterEvents,
} from "./Utility";

import { MiniGreeterEventButtonCard } from "../MiniGreeterEventButtonCard";

import { Carousel } from "../Carousel";
import { DatePeriod, TimeOfDay, toDateOnlyString } from "@greeter/date";

export type ChooseDateSelectorProps = React.PropsWithChildren & {
  className?: string;
  dates?: DatePeriod[];
  greeterEvents: GreeterEvent[];
  btnClassName?: string;
  onDateChanged?: (dateTime: Date) => void;
  date?: Date;
  now?: Date;
};

const defaultBookingSettings: BookingSettings = {
  buffer: TimeOfDay.parse("01:00:00"),
};

export function ChooseDateSelector({
  className,
  btnClassName,
  greeterEvents,
  onDateChanged = doNothing,
  date,
  dates,
  now = new Date(),
  ...props
}: ChooseDateSelectorProps) {
  const [bookableDates, setBookableDates] = useState<SelectDate[]>([]);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (!dates) return;

    setBookableDates(mergeGreeterEvents(greeterEvents, dates));
  }, [dates]);

  useEffect(() => {
    if (!isEmpty(bookableDates) && !date) onDateChanged(bookableDates[0].date);
  }, [bookableDates, date, onDateChanged]);

  useEffect(() => {
    if (!date) return;

    setActiveIndex(bookableDates.findIndex((d) => isSameDay(date, d.date)));
  }, [bookableDates, date]);

  return (
    <Carousel index={activeIndex} className={className} {...props}>
      {bookableDates.map((d, i) => {
        return (
          <MiniGreeterEventButtonCard
            key={i}
            {...classNames(btnClassName, "date")}
            onClick={() => {
              onDateChanged(bookableDates[i].date);
            }}
            coverUrl={d.coverUrl}
            date={d.date}
            isActive={activeIndex === i}
          />
        );
      })}
    </Carousel>
  );
}
