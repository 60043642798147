import styles from "./Section.module.scss";
import React from "react";
import { classNames } from "@greeter/util";
import { BasePropsWithChildren } from "@greeter/matter";

export type SectionProps = BasePropsWithChildren & {
  padding?: boolean;
};

export const Section: React.FC<SectionProps> = ({
  children,
  padding,
  className = "",
}) => (
  <div
    {...classNames(
      styles.Section,
      styles[`Section___padding___${padding}`],
      className
    )}
  >
    {children}
  </div>
);
