import { CancelEvent, NextEvent, ValidateEvent } from "@greeter/machine";
import { isEmail } from "@greeter/matter";
import { createMachine, raise, assign, setup, assertEvent, emit } from "xstate";
import { IdleEvent, idleMachine } from "./IdleMachine";

type CommandEvent<T> = { type: T };
type ValueEvent<T, V> = { type: T; data: V };

export type SetEmailEvent = ValueEvent<"setEmail", string>;
export type SetPasswordEvent = ValueEvent<"setPassword", string>;
export type SetRepeatedPasswordEvent = ValueEvent<
  "setRepeatedPassword",
  string
>;
export type ValidateEmailExistsEvent = CommandEvent<"validateEmailExists">;
export type ClearEvent = CommandEvent<"clear">;
export type SetNameEvent = ValueEvent<"setName", string>;

export type SuccessEvent = { type: "success" };
export type ErrorEvent = { type: "error" };

export type EmailFlowEvent =
  | SetEmailExistsEvent
  | SuccessEvent
  | ErrorEvent
  | NextEvent
  | ValidateEvent
  | CancelEvent
  | ValidateEmailExistsEvent
  | ClearEvent
  | IdleEvent
  | UpdateInitialEvent
  | UpdateNewUserEvent
  | UpdateExistingUserEvent;

export type EmailFlowContext = {
  email: string;
  password: string;
  repeatedPassword: string;
  emailExists: boolean;
};

export type UpdateNewUserEvent = {
  type: "updateNewUser";
  data: Partial<
    Pick<EmailFlowContext, "email" | "password" | "repeatedPassword">
  >;
};

export type UpdateExistingUserEvent = {
  type: "updateExistingUser";
  data: Partial<Pick<EmailFlowContext, "email" | "password">>;
};

export type UpdateInitialEvent = {
  type: "updateInitial";
  data: Partial<Pick<EmailFlowContext, "email">>;
};

type SetEmailExistsEvent = {
  type: "setEmailExists";
  data: boolean;
};

type StateTemplate<C, T> = { context: C; value: T };
type EmailFlowStateTemplate<T> = StateTemplate<EmailFlowContext, T>;
type SigningInState = EmailFlowStateTemplate<"signingIn">;
type InitialState = EmailFlowStateTemplate<"initial">;
type NewUserState = EmailFlowStateTemplate<"newUser">;
type ExistingUserState = EmailFlowStateTemplate<"existingUser">;
type DoneState = EmailFlowStateTemplate<"done">;
type CreatingUserState = EmailFlowStateTemplate<"creatingUser">;

export type EmailFlowState =
  | SigningInState
  | InitialState
  | NewUserState
  | ExistingUserState
  | DoneState
  | CreatingUserState;

export type Email = string;

export const emailFlowMachine = setup({
  types: {} as {
    context: EmailFlowContext;
    events: EmailFlowEvent;
    emitted:
      | {
          type: "creatingUser";
          data: { email: string; password: string; repeatedPassword: string };
        }
      | { type: "signingIn"; data: { email: string; password: string } }
      | { type: "signedIn" }
      | { type: "done" };
  },
  actions: {
    updateInitial: assign(({ context: _ctx, event: ev }) => {
      assertEvent(ev, "updateInitial");
      return { ...ev.data };
    }),
    updateNewUser: assign(({ context: _ctx, event: ev }) => {
      assertEvent(ev, "updateNewUser");
      return { ...ev.data };
    }),
    updateExistingUser: assign(({ context: _ctx, event: ev }) => {
      assertEvent(ev, "updateExistingUser");
      return { ...ev.data };
    }),
    setEmailExists: assign(({ context: _ctx, event: ev }) => {
      assertEvent(ev, "setEmailExists");
      return { emailExists: ev.data };
    }),
    resetPasswords: assign(({ context: _ctx }) => {
      return { password: "", repeatedPasswords: "" };
    }),
  },
  guards: {
    initial: ({ context: { email } }) => !isEmail(email),
    existingUser: ({ context: { email, emailExists } }) =>
      isEmail(email) && emailExists,
    newUser: ({ context: { email, emailExists } }) =>
      isEmail(email) && !emailExists,
  },
}).createMachine({
  initial: "initial",
  context: {
    password: "",
    repeatedPassword: "",
    email: "",
    emailExists: false,
  },
  on: {
    cancel: ".initial",
    setEmailExists: {
      actions: [
        assign({ emailExists: ({ event }) => event.data }),
        raise({ type: "validate" }),
      ],
    },
    validate: [
      {
        target: ".newUser",
        guard: "newUser",
      },
      {
        target: ".existingUser",
        guard: "existingUser",
      },
      {
        target: ".initial",
        guard: "initial",
      },
      { target: ".initial" },
    ],
  },
  states: {
    initial: {
      on: {
        next: { actions: raise({ type: "validate" }) },
        updateInitial: { actions: "updateInitial" },
      },
    },
    newUser: {
      on: {
        next: "creatingUser",
        updateNewUser: { actions: ["updateNewUser"] },
      },
    },
    existingUser: {
      on: {
        next: "signingIn",
        updateExistingUser: { actions: "updateExistingUser" },
      },
    },
    creatingUser: {
      entry: emit(({ context }) => ({
        type: "creatingUser",
        data: {
          email: context.email,
          password: context.password,
          repeatedPassword: context.repeatedPassword,
        },
      })),
      on: {
        success: { target: "done" },
        error: { actions: [raise({ type: "validate" })] },
      },
    },
    signingIn: {
      entry: emit(({ context: { email, password } }) => ({
        type: "signingIn",
        data: { email, password },
      })),
      on: {
        success: { target: "done" },
        error: { actions: [raise({ type: "validate" })] },
      },
    },
    done: {
      after: { 1000: { actions: raise({ type: "validate" }) } },
      entry: [raise({ type: "clear" }), emit({ type: "signedIn" })],
      on: {
        clear: {
          actions: "resetPasswords",
        },
      },
    },
  },
});
