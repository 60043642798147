import { first } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";

export type Func<TArgs, TReturn> = (args: TArgs) => TReturn;

export type UseClampArgs = {
  initial?: number;
  min: number;
  max: number;
};
export const useClamp: Func<UseClampArgs, [number, (val: number) => void]> = ({
  initial,
  min,
  max,
}: UseClampArgs) => {
  const [val, setVal] = useState(initial ?? 0);

  const setValue = useCallback(
    (newVal: number) => void setVal(Math.max(Math.min(newVal, max), min)),
    [max, min]
  );

  return [val, setValue];
};

export interface UseInputPropsBind {
  value: string;
  onChange: (
    e: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>
  ) => void;
}

export type UseInputProps = {
  initialValue?: string;
}

export type UseInputResult = {
  value: string;
  bind: UseInputPropsBind;
  setValue: (value: string) => void;
  hasChanged: boolean;
} & [
  value: string,
  bind: UseInputPropsBind,
  setValue: (value: string) => void,
  hasChanged: boolean
];

export const useInput = ({
  initialValue = "",
}: UseInputProps): UseInputResult => {
  const [value, setValue] = useState(initialValue);

  // Support both object destructuring and array destructuring
  const bind = {
    value,
    onChange: (
      e:
        | React.FormEvent<HTMLInputElement>
        | React.FormEvent<HTMLTextAreaElement>
    ) => setValue(e.currentTarget.value),
  } as UseInputPropsBind;
  const result = [
    value,
    bind,
    (value: string) => setValue(value),
    initialValue !== value,
  ] as UseInputResult;

  result.value = value;
  result.bind = bind;
  result.setValue = (value: string) => setValue(value);
  result.hasChanged = initialValue !== value;

  return result;
};

type Replacer = {
  from: RegExp;
  to: RegExp;
};
export const useRegex = ({
  replacers = [],
  regex,
  defaultValue,
  allowEmpty,
}: {
  replacers?: Replacer[];
  regex: RegExp;
  defaultValue: string;
  allowEmpty?: boolean;
}): [value: string, setValue: (value: string) => void] => {
  const [val, setVal] = useState(defaultValue);

  const setValue = useCallback(
    (newValue: string) => {
      replacers.forEach(({ from, to }) => {
        if (!from || !to || !newValue) return;

        const match = first(newValue.match(from));
        const withMatch = newValue.match(to);
        if (match && withMatch && withMatch.length > 0) {
          newValue = withMatch[1];
        }
      });

      // NOTE: we apparently cannot return early on this one. It seems
      //       That the code is either stripped or something else is wrong.
      //       In any case. It doesn't work. So we "?" and default the shit or of the 
      //       statement.
      const match = first(newValue?.match(regex) ?? []);
      setVal(match ? match : allowEmpty ? "" : val);
    },
    [allowEmpty, regex, val, replacers]
  );

  return [val, setValue];
};

type PriceInputProps = {
  value: string;
  onChange: (value: React.FormEvent<HTMLInputElement>) => void;
};
type PriceInputResult = {
  price: number;
  bind: {
    value: string;
    onChange: (value: React.FormEvent<HTMLInputElement>) => void;
  };
  setPriceInput: (val: string) => void;
} & [
  price: number,
  bind: {
    value: string;
    onChange: (value: React.FormEvent<HTMLInputElement>) => void;
  },
  setPriceInput: (val: string) => void
];

type UsePriceInputProps = {
  initialValue: number;
};

export const usePriceInput = ({
  initialValue = 0.0,
}: UsePriceInputProps): PriceInputResult => {
  const [inputValue, setInputValue] = useRegex({
    replacers: [
      {
        from: /^0*$/,
        to: /0/,
      },
      {
        from: /([0-9]{2,}(,[0-9]*)?)/,
        to: /([1-9]{1}[0-9]+(,[0-9]*)?)/,
      },
    ],
    regex: /([0-9]*(,[0-9]*)?)?/,
    defaultValue: `${initialValue ?? 0}`,
    allowEmpty: true,
  });
  const [price, setPrice] = useClamp({
    initial: initialValue,
    min: 0.0,
    max: Number.MAX_VALUE,
  });

  useEffect(() => {
    if (inputValue === "") {
      setPrice(0);
    } else {
      setPrice(parseFloat(inputValue.replace(",", ".")));
    }
  }, [inputValue, setPrice]);

  const setPriceInput = useCallback(
    (val: string) => {
      setInputValue(val);
    },
    [setInputValue]
  );

  const result = useMemo(() => {
    const bind = {
      value: inputValue,
      onChange: (e: React.FormEvent<HTMLInputElement>) =>
        setInputValue(e.currentTarget.value),
    } as PriceInputProps;

    // Support array and object destructuring
    const result = [price, bind, setPriceInput] as PriceInputResult;
    result.price = price;
    result.bind = bind;
    result.setPriceInput = setPriceInput;

    return result;
  }, [inputValue, price, setInputValue, setPriceInput]);

  return result;
};
