import { IComparable, range } from "@greeter/util";
import { isEqual, isToday } from "date-fns";
import { Dispatch, useCallback, useEffect, useMemo, useState } from "react";

// Therefore we wrap the date in a "Comparer"
export class DateComparer implements IComparable<DateComparer> {
  date: Date;

  constructor(date: Date) {
    this.date = date;
  }

  sameAs(other: DateComparer): boolean {
    return isEqual(this.date, other.date);
  }
}

export type SetStateAction<T> = Dispatch<React.SetStateAction<T>>;

export type OnBookingCreated = (bookingId: string) => void;

export type LoginStatus = "logged in" | "incomplete user" | "not logged in";

export const scrollToTop = () =>
  window.scrollTo({ top: 0, behavior: "smooth" });

export const useResizeOnChange = (deps: any[]) =>
  useEffect(() => void document.dispatchEvent(new Event("resize")), deps);
export const useEffectOnLoad = (callback: React.EffectCallback) =>
  useEffect(callback, [callback]);

