import { DependencyList, useEffect, useState } from "react";

export const useInterval = (
  fn: () => void,
  timeoutMs: number,
  deps?: DependencyList | undefined
) => {
  useEffect(() => {
    const id = setInterval(fn, timeoutMs);

    return () => clearInterval(id);
  }, [fn, timeoutMs, ...(deps ?? [])]);
};

/**
 * Used to initialize things once and not more.
 */
export function useInitEffect(
  fn: () => void,
  deps: DependencyList,
  predicate?: (deps: DependencyList | undefined) => boolean
) {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (initialized) return;
    if (predicate && !predicate(deps)) return;

    fn();
    setInitialized(true);
  }, deps);
}

export function useWindowEventListener(
  ev: keyof WindowEventMap,
  fn: () => void,
  deps?: DependencyList
) {
  return useEffect(() => {
    window.addEventListener(ev, fn);
    return () => {
      window.removeEventListener(ev, fn);
    };
  }, deps);
}

export * from "./useIdle";
