import React from "react";
import Icon from "../Icon/Icon";
import css from "./LoginButton.module.scss";

const LoginButton: React.FC<{
  onClick?: () => void;
  alt?: string;
  src?: string;
  providerName: string;
  providerIcon?: React.ReactNode;
  content?: string;
}> = ({
  onClick = () => {},
  providerName,
  alt,
  src,
  content,
  providerIcon = (
    <Icon className={css.ProviderIcon} src={src ?? ""} alt={alt ?? "Login"} />
  ),
  ...props
}) => (
  <button onClick={onClick} className={css.LoginButton} {...props}>
    {providerIcon}
    <span className={css.ProviderName}>{content ? content : `Fortsæt med ${providerName}`}</span>
  </button>
);

export default LoginButton;
