import css from "./TitledSection.module.scss";
import React from "react";
import { Section } from "./Section";
import { classNames } from "@greeter/util";
import { IonIcon } from "@ionic/react";
import { Props } from "@greeter/matter";

export type TitledSectionProps = Props & {
  title: string;
  padding?: boolean;
  hint?: string;
  icon?: string;
  center?: boolean;
  required?: boolean;
};

export function TitledSection({
  id,
  children,
  title,
  className,
  padding,
  hint,
  icon,
  center,
  required,
}: TitledSectionProps) {
  return (
    <Section
      id={id}
      padding={padding}
      {...classNames(css.TitledSection, className)}
    >
      <div
        className={css.Title}
        style={{
          display: "flex",
          justifyContent: center ? "center" : "space-between",
        }}
      >
        <div>
          <h2 style={{ margin: 0 }}>
            {required && (
              <span
                style={{
                  color: "var(--gm-color-primary-highlight)",
                  fontWeight: "bold",
                }}
              >
                *
              </span>
            )}
            {title}
          </h2>
          {hint && <div style={{ fontSize: "1rem", opacity: 0.6 }}>{hint}</div>}
        </div>
        {icon && (
          <IonIcon icon={icon} style={{ width: "1.5rem", height: "1.5rem" }} />
        )}
      </div>
      {children}
    </Section>
  );
}
