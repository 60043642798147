import { ArgumentNullOrUndefinedError } from "@greeter/error";
import { UseQueryResult } from "@tanstack/react-query";


export type DoneQuery<T> = { type: "done"; data: T };
export type ErrorQuery = { type: "error"; errors: Error[] };
export type LoadingQuery = { type: "loading" };

export type Query<T> = LoadingQuery | ErrorQuery | DoneQuery<T>;

export function mapQuery<T>(q: UseQueryResult<T, Error>): Query<T> {
  return q.isError
    ? { type: "error", errors: [q.error] }
    : q.isLoading
    ? { type: "loading" }
    : q.data
    ? { type: "done", data: q.data }
    : { type: "error", errors: [new ArgumentNullOrUndefinedError("data")] };
}

export function unwrap<T>(q: Query<T>, or?: T | undefined): T | undefined {
  return q?.type === "done" ? q.data : or;
}

export function unwrapOr<T>(q: Query<T>, or: T): T {
  return q?.type === "done" ? q.data : or;
}

export function isLoading<T>(q: Query<T>) {
  return q?.type === "loading";
}

export function isError<T>(q: Query<T>) {
  return q?.type === "error";
}

export function isDone<T>(q: Query<T>) {
  return q?.type === "done";
}

export module Query {
  export function empty(): Query<void>  {
    return { type: "done", data: undefined };
  }

  export function loading(): Query<void> {
    return { type: "loading" };
  }

  export function done<T>(t: T): Query<T> {
    return { type: "done", data: t };
  }
}
