import styles from "./DescriptionCard.module.scss";
import React from "react";
import { classNames } from "@greeter/util";

export type DescriptionCardProps = React.PropsWithChildren &{
  className?: string;
  style?: React.CSSProperties;
};

export const DescriptionCard: React.FC<DescriptionCardProps> = ({ children, className = "" }) => (
  <div {...classNames(styles.DescriptionCard, className)}>{children}</div>
);
