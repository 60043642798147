import React, { useEffect, useMemo, useState } from "react";
import { doNothing } from "@greeter/util";
import { BottomButtonBar } from "@greeter-guest/pages/LoginPage/BottomButtonBar/BottomButtonBar";
import { Title } from "../../../components/Title/Title";
import { Description } from "../../../components/Description/Description";
import { GridArea } from "../../../components/GridArea/GridArea";
import { ContinueButton, NotNowButton } from "@greeter-guest/components/Button";
import {
  PulsingGreeter,
  FadeOutFadeIn,
  BasePropsWithChildren,
} from "@greeter/matter";

import {
  EmailInput,
  PhoneNumberInput,
  Hint,
  Input,
  useAuth,
} from "@greeter/matter";

import { IonCheckbox, IonIcon, IonItem } from "@ionic/react";
import { personOutline, trashOutline } from "ionicons/icons";

import { PhoneNumber } from "@greeter/core";
import { partial } from "lodash";
import { deleteUser } from "firebase/auth";

import css from "./UpdateUserInfoView.module.scss";

export type UpdateUserInfoViewProps = React.PropsWithChildren & {
  name?: string;
  email?: string;
  phoneNumber?: PhoneNumber;
  acceptedTerms?: boolean;
  acceptedPrivacyPolicy?: boolean;
  onContinue?: () => void;
  onNotNow?: () => void;
  onEmailChange?: (value: string) => void;
  onPhoneNumberChange?: (value: PhoneNumber) => void;
  onNameChange?: (value: string) => void;
  onAcceptedTermsChange?: (value: boolean) => void;
  onAcceptedPrivacyPolicyChange?: (value: boolean) => void;
};

const log = partial(console.log, "[UpdateUserInfoView]");

const ctaContinue = "Ja, let's go";

export function UpdateUserInfoView({
  name = "",
  email = "",
  phoneNumber,
  acceptedTerms = false,
  acceptedPrivacyPolicy = false,
  onContinue = doNothing,
  onNotNow = doNothing,
  onEmailChange = doNothing,
  onPhoneNumberChange = doNothing,
  onNameChange = doNothing,
  onAcceptedPrivacyPolicyChange = doNothing,
  onAcceptedTermsChange = doNothing,
}: UpdateUserInfoViewProps) {
  const [emailValid, setEmailValid] = useState(false);
  const [phoneNumberValid, setPhoneNumberValid] = useState(false);
  const [isPostingUserUpdate, setIsPostingUserUpdate] = useState(false);

  return (
    <div className={css.UpdateUserInfoView}>
      <TitleArea>
        <Title>Klar til at feste?</Title>
        <Description>
          Vi mangler informationer,
          <br />
          så venueet kan få fat på dig
          <br />
          skulle der ske ændringer
        </Description>
      </TitleArea>
      <InputArea className={css.InputFieldSection}>
        <div>
          <Hint placement="top" text="Så du kan få din kvittering" />
          <EmailInput
            data-testid="email-input"
            email={email}
            onValidChange={setEmailValid}
            onChange={onEmailChange}
          />
        </div>
        <div>
          <Hint placement="top" text="Så vi kan genkende dig når du ankommer" />
          <Input
            className={css.InputField}
            icon={
              <IonIcon
                icon={personOutline}
                style={{
                  marginLeft: "0.5rem",
                  width: "2.5rem",
                  height: "1.5rem",
                }}
              />
            }
            placeholder="Dit fornavn og efternavn..."
            type="text"
            value={name}
            onChange={(v) => onNameChange(v.currentTarget.value)}
          />
        </div>
        <div>
          <Hint
            placement="top"
            text="Så vi kan ringe i tilfælde af ændringer"
          />
          <PhoneNumberInput
            onValidChange={setPhoneNumberValid}
            onChange={onPhoneNumberChange}
            phoneNumber={phoneNumber}
          />
        </div>
        {/*<IonItem
          style={{ "--background": "transparent", "--border-style": "none" }}
        >
          <IonCheckbox
            slot="start"
            mode="md"
            checked={acceptedTerms}
            onIonChange={(ev) => {
              onAcceptedTermsChange(Boolean(ev.detail.value));
            }}
          />
          <div style={{ fontSize: "0.9rem" }}>
            Du har læst, forstået og accepterer{" "}
            <a
              href="https://www.greeter.dk/greeter-salgs-og-leveringsbetingelser/"
              target="_blank"
              style={{
                color: "var(--gm-color-alt-bg)",
                textDecoration: "underline",
              }}
            >
              salgs- og leveringsbetingelserne
            </a>{" "}
            og at du er over 18 år gammel
          </div>
        </IonItem>
        <IonItem
          style={{ "--background": "transparent", "--border-style": "none" }}
        >
          <IonCheckbox
            slot="start"
            mode="md"
            checked={acceptedPrivacyPolicy}
            onIonChange={(ev) => {
              onAcceptedPrivacyPolicyChange(Boolean(ev.detail.value));
            }}
          />
          <div style={{ fontSize: "0.9rem" }}>
            Du har læst, forstået og accepterer{" "}
            <a
              href="https://www.greeter.dk/privatlivspoltik"
              target="_blank"
              style={{
                color: "var(--gm-color-alt-bg)",
                textDecoration: "underline",
              }}
            >
              privatlivspolitikken
            </a>
          </div>
        </IonItem>*/}
      </InputArea>
      <BottomArea>
        <div style={{ textAlign: "left", fontSize: "0.9rem", padding: "1rem 0" }}>
          Ved at trykke <q>{ctaContinue}</q> accepterer du{" "}
          <a
            href="https://www.greeter.dk/privatlivspoltik"
            target="_blank"
            style={{
              color: "var(--gm-color-alt-bg)",
              textDecoration: "underline",
            }}
          >
            privatlivspolitikken
          </a>{" "}
          og{" "}
          <a
            href="https://www.greeter.dk/greeter-salgs-og-leveringsbetingelser/"
            target="_blank"
            style={{
              color: "var(--gm-color-alt-bg)",
              textDecoration: "underline",
            }}
          >{" "}
            salgs- og leveringsbetingelserne
          </a>
        </div>

        <BottomButtonBar>
          <NotNowButton onClick={onNotNow} />
          <FadeOutFadeIn transitionKey={isPostingUserUpdate}>
            {isPostingUserUpdate ? (
              <PulsingGreeter className={css.UpdatingUserLoader} />
            ) : (
              <ContinueButton
                disabled={!email || !name || !phoneNumber}
                onClick={async (e) => {
                  setIsPostingUserUpdate(true);
                  onAcceptedTermsChange(true);
                  onAcceptedPrivacyPolicyChange(true);
                  await onContinue();
                  setIsPostingUserUpdate(false);
                }}
              >
                {ctaContinue}
              </ContinueButton>
            )}
          </FadeOutFadeIn>
          <div style={{ padding: "1rem" }} />
        </BottomButtonBar>
      </BottomArea>
    </div>
  );
};

const TitleArea: React.FC<BasePropsWithChildren> = ({ ...props }) => (
  <GridArea {...props} area="title" />
);
const InputArea: React.FC<BasePropsWithChildren> = ({ ...props }) => (
  <GridArea {...props} area="input" />
);
const BottomArea: React.FC<BasePropsWithChildren> = ({ ...props }) => (
  <GridArea {...props} area="bottom" />
);
