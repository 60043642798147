import css from "./Title.module.scss";

import React, { useEffect, useState } from "react";

export type TitleProps = React.PropsWithChildren;

export const Title: React.FC<TitleProps> = ({ children }) => {
    return <h1 className={css.Title}>{children}</h1>;
};

