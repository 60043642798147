import { z } from "zod";

export const CategoryCategorySchema = z.object({
  id: z.string(),
  name: z.string(),
  priority: z.number(),
});
export type CategoryCategory = z.infer<typeof CategoryCategorySchema>;

export const CategorySchema = z.object({
  id: z.string(),
  name: z.string(),
  priority: z.number(),
  categoryCategoryId: z.optional(z.string()),
});

export type Category = z.infer<typeof CategorySchema>;

export function emptyCategory(): Category {
  return {
    id: crypto.randomUUID(),
    priority: 0,
    categoryCategoryId: undefined,
    name: "",
  };
}
