import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./GreeterEventOverviewPage.scss";
import { DaysliceConfig, GreeterEvent, Venue } from "@greeter/core";

import {
  Filter,
  AnyFilter,
  TodayFilter,
  TomorrowFilter,
  ThisWeekFilter,
} from "@greeter/filters";
import { BigGreeterEventCard } from "@greeter-guest/components/BigGreeterEventCard";
import { UnorderedListComponent } from "@greeter-guest/components/UnorderedListComponent";
import { RoundedButton } from "@greeter-guest/components/RoundedButton";
import {
  IonPage,
  IonContent,
  IonPopover,
  IonItem,
  IonList,
  useIonViewDidEnter,
} from "@ionic/react";
import { IconChevronDown, IconCheck } from "@tabler/icons-react";

import css from "./GreeterEventOverviewPage.module.scss";
import { isEmpty, sortBy } from "lodash";
import { Area, Carousel, Container, Skeleton } from "@greeter/matter";
import Routes from "@greeter-guest/utility/Routes";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { doNothing } from "@greeter/util";

type FilterName = "any" | "today" | "tomorrow" | "this week";

export type GreeterEventOverPageProps = React.PropsWithChildren & {
  venues?: Venue[];
  greeterEvents?: GreeterEvent[];
  daysliceConfig?: DaysliceConfig;
  onCityChange?: (city: string) => void;
  city: string;
  cities: string[];
};

export function GreeterEventOverviewPage({
  venues,
  greeterEvents,
  daysliceConfig,
  cities,
  city,
  onCityChange = doNothing,
}: GreeterEventOverPageProps) {
  const [visibleGreeterEvents, setVisibleGreeterEvents] = useState<
    GreeterEvent[]
  >([]);
  const [selectedFilter, setSelectedFilter] = useState<FilterName>("any");

  const filters = useMemo(() => {
    if (daysliceConfig) {
      return new Map<FilterName, Filter>()
        .set("any", new AnyFilter())
        .set("today", new TodayFilter(daysliceConfig))
        .set("tomorrow", new TomorrowFilter(daysliceConfig))
        .set("this week", new ThisWeekFilter(daysliceConfig));
    }

    return new Map<FilterName, Filter>().set("any", new AnyFilter());
  }, [daysliceConfig]);

  const wrappedGreeterEvents = useMemo(
    () =>
      sortBy(visibleGreeterEvents, (a) => a.startsAt.getTime()).map(
        (greeterEvent, index) => {
          return (
            <BigGreeterEventCard
              daysliceConfig={daysliceConfig ?? DaysliceConfig.createDefault()}
              key={index}
              greeterEvent={greeterEvent}
              venue={(venues ?? []).find((v) => v.id === greeterEvent.venueId)}
            />
          );
        }
      ),
    [daysliceConfig, venues, visibleGreeterEvents]
  );

  const handleFilterButtonClick = useCallback(
    (filter: FilterName) => () => {
      setSelectedFilter(filter === selectedFilter ? "any" : filter);
    },
    [selectedFilter]
  );

  useEffect(() => {
    if (selectedFilter && greeterEvents) {
      setVisibleGreeterEvents([
        ...greeterEvents.filter((ge) =>
          filters.get(selectedFilter)?.evaluate(ge)
        ),
      ]);
    }
  }, [selectedFilter, greeterEvents, filters]);

  const history = useHistory();

  return (
    <IonPage style={{ paddingTop: "env(safe-area-inset-top)" }}>
      <Helmet>
        <title>Events overview</title>
      </Helmet>
      <IonContent>
        <Container>
          <div
            className={css.GreeterEventOverviewPage}
            style={{ paddingTop: "env(safe-area-inset-top)" }}
          >
            <Area>
              <h2 className={css.title} id="city">
                Events i{" "}
                <span
                  className={css.city}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {city} <IconChevronDown />
                </span>
              </h2>
              <IonPopover reference="trigger" trigger="city">
                <IonContent>
                  <IonList>
                    {cities?.map((c) => {
                      return (
                        <IonItem
                          style={{
                            "font-weight": "bold",
                          }}
                          key={c}
                          button
                          detail={false}
                          onClick={() => onCityChange(c)}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              width: "100%",
                              justifyContent: "space-between",
                            }}
                          >
                            {c} {city === c && <IconCheck />}
                          </div>
                        </IonItem>
                      );
                    })}
                  </IonList>
                </IonContent>
              </IonPopover>
            </Area>
            <Carousel className="FilterButtonBar">
              <RoundedButton
                active={selectedFilter === "any"}
                onClick={handleFilterButtonClick("any")}
              >
                Alle
              </RoundedButton>
              <RoundedButton
                active={selectedFilter === "today"}
                onClick={handleFilterButtonClick("today")}
              >
                I dag
              </RoundedButton>
              <RoundedButton
                active={selectedFilter === "tomorrow"}
                onClick={handleFilterButtonClick("tomorrow")}
              >
                I morgen
              </RoundedButton>
              <RoundedButton
                active={selectedFilter === "this week"}
                onClick={handleFilterButtonClick("this week")}
              >
                Denne uge
              </RoundedButton>
            </Carousel>
            <Area>
              <UnorderedListComponent>
                {isEmpty(greeterEvents) ? (
                  <Skeleton style={{ height: "12rem", margin: "unset" }}>
                    <div>
                      <p>Det ser ud til at der ikke er kommende events</p>
                    </div>
                  </Skeleton>
                ) : (
                  wrappedGreeterEvents
                )}
              </UnorderedListComponent>
            </Area>
          </div>
        </Container>
      </IonContent>
    </IonPage>
  );
}
