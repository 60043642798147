import { Order, OrderLine } from "@greeter/core";
import { DateFactory } from "@greeter/date";
import { IDeserializer, OrderLineDeserializer } from ".";
import { Deserializer } from "./Deserializer";

export class OrderDeserializer extends Deserializer<Order> {
  constructor(
    private orderLineDeserializer: IDeserializer<OrderLine> = new OrderLineDeserializer()
  ) {
    super();
  }

  override deserialize(json: any): Order {
    const orderLines = this.orderLineDeserializer.deserializeAll(
      json.orderLines
    );

    return {
      ...json,
      createdAt: DateFactory.create(json.created),
      orderLines,
    };
  }
}
