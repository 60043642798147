import React, { useMemo } from "react";

import { IonIcon } from "@ionic/react";
import { addCircleOutline, removeCircleOutline } from "ionicons/icons";
import { clamp } from "lodash";
import { Button } from "../Button";

import css from "./QuantityControls.module.scss";
import { ReceiptLine } from "../Receipt";
import { Product } from "@greeter/core";
import { classNames } from "@greeter/util";

export function useQuantity(orderLines: ReceiptLine[], product: Product) {
  return useMemo(() => {
    return orderLines.find((ol) => ol.productId === product.id)?.quantity ?? 0;
  }, [orderLines, product]);
}

export function upsertQuantity(
  orderLines: ReceiptLine[],
  productId: string,
  productTitle: string,
  unitPrice: number,
  quantity: number
) {
  const receiptLinesIndexed = new Map<string, ReceiptLine>();

  for (const line of orderLines) {
    if (!line.productId) continue;

    receiptLinesIndexed.set(line.productId, line);
  }

  const existingOrderLine = receiptLinesIndexed.get(productId);

  if (existingOrderLine) {
    existingOrderLine.quantity = quantity;
  } else {
    receiptLinesIndexed.set(productId, {
      productId: productId,
      title: productTitle,
      unitPrice: unitPrice,
      quantity: quantity,
    });
  }

  return Array.from(receiptLinesIndexed.values());
}

type QuantityButtonProps = {
  type: "sub" | "add";
  size: "lg" | "sm";
  disabled?: boolean;
  onClick?: () => void;
};

const QuantityButton: React.FC<QuantityButtonProps> = ({
  type,
  size = "lg",
  disabled,
  onClick,
  ...props
}) => {
  return (
    <Button
      {...classNames(css.QuantityButton, size === "lg" ? css.Large : css.Small)}
      onClick={onClick}
      style={{ backgroundColor: "rgba(0,0,0,0)", padding: "0" }}
      {...props}
    >
      {type === "add" ? (
        <>
          <div className={css.Add} />
          <div className={css.Add} />
        </>
      ) : (
        <div className={css.Sub} />
      )}
    </Button>
  );
};

export type QuantityControlsProps = {
  quantity: number;
  onChange: (quantity: number) => void;
  tight?: boolean;
  size?: "sm" | "lg";
  style?: React.CSSProperties;
};

export const QuantityControls: React.FC<QuantityControlsProps> = ({
  quantity,
  onChange,
  tight = false,
  size = "lg",
  style,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: tight ? "0.5rem" : "1rem",
        alignItems: "center",
        color: "white",
        fontSize: size === "lg" ? "1.25rem" : "1rem",
        ...style,
      }}
    >
      <QuantityButton
        data-testid="quantity-sub"
        type="sub"
        size={size}
        onClick={() => onChange(clamp(quantity - 1, 0, Number.MAX_VALUE))}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          color: quantity > 0 ? "var(--gm-color-primary-highlight)" : "white",
        }}
      >
        <div style={{ fontWeight: "bold" }}>{quantity}</div>
        <div>stk.</div>
      </div>
      <QuantityButton
        data-testid="quantity-add"
        type="add"
        size={size}
        onClick={() => onChange(clamp(quantity + 1, 0, Number.MAX_VALUE))}
      />
    </div>
  );
};
