import css from "./FloorPlanViewer.module.scss";

import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { BaseProps } from "../BaseProps";
import { PulsingGreeter } from "../Loading/PulsingGreeter";

import { Table } from "@greeter/core";
import { classNames, doNothing } from "@greeter/util";
import { logger } from "@greeter/log";
import { useDrag, usePinch, useScroll } from "@use-gesture/react";
import { IonIcon } from "@ionic/react";
import { addOutline, removeOutline } from "ionicons/icons";
import {
  IconRefresh,
  IconZoomInFilled,
  IconZoomOutFilled,
} from "@tabler/icons-react";
import { useSpring, a } from "@react-spring/web";

const log = logger("[FloorPlanViewer]");

export function getTable(el: Element) {
  return {
    type: el.getAttribute("greeter:type") ?? "table",
    number: parseInt(el.getAttribute("greeter:table-number") ?? "-1"),
    minCapacity: parseInt(el.getAttribute("greeter:min-capacity") ?? "-1"),
    maxCapacity: parseInt(el.getAttribute("greeter:max-capacity") ?? "-1"),
  } as Table;
}

const query = [
  "table",
  "pool-table",
  "football-table",
  "pingpong-table",
  "beerpong-table",
]
  .map((id) => `[greeter\\:type="${id}"]`)
  .join(", ");

function queryForTables(container: HTMLDivElement | null): SVGElement[] {
  const tables = container?.querySelectorAll(query) ?? [];

  return Array.from(tables) as SVGElement[];
}

export type RenderInfoBoxArgs = {
  bounds: DOMRect;
  el: Element;
  table: Table;
};

export type OnStyleTable = { el: SVGElement; table: Table; i: number };

export type FloorPlanViewerProps = BaseProps & {
  src?: string;
  onTablesFound?: (tables: Table[]) => void;
  onTableClick?: (table: Table) => void;
  onStyleTable?: (args: OnStyleTable) => void;
  renderInfoBox?: (args: RenderInfoBoxArgs) => React.ReactNode;
  refresher?: (refreshFunc: () => void) => void;
  svgHeader?: React.ReactNode[] | React.ReactNode;
  debug?: boolean;
  disabled?: boolean;
  slots?: {
    overlay?: {
      after?: React.ReactNode;
    };
  };
};

export const ZoomButton: React.FC<React.HTMLAttributes<HTMLButtonElement>> = (
  props
) => {
  return <button {...props} className={css.ZoomButton} />;
};

export type ZoomControlsProps = {
  onZoomIn?: () => void;
  onZoomOut?: () => void;
  onZoomReset?: () => void;
};

export const ZoomControls: React.FC<ZoomControlsProps> = ({
  onZoomIn = doNothing,
  onZoomOut = doNothing,
  onZoomReset = doNothing,
}) => {
  return (
    <div className={css.ZoomControls}>
      <ZoomButton key="zoom-out" onClick={onZoomOut}>
        <IconZoomOutFilled style={{ color: "white" }} />
      </ZoomButton>
      <ZoomButton key="zoom-in" onClick={onZoomIn}>
        <IconZoomInFilled style={{ color: "white" }} />
      </ZoomButton>
      <ZoomButton key="zoom-reset" onClick={onZoomReset}>
        <IconRefresh style={{ color: "white" }} />
      </ZoomButton>
    </div>
  );
};

export const FloorPlanViewer: React.FC<FloorPlanViewerProps> = ({
  className,
  src,
  onTableClick = doNothing,
  onTablesFound = doNothing,
  onStyleTable = doNothing,
  renderInfoBox,
  refresher = doNothing,
  svgHeader = undefined,
  debug = false,
  style,
  ...props
}) => {
  const [svg, setSvg] = useState("");
  const floorPlanContainerRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState<boolean>();
  const [shouldRefresh, setShouldRefresh] = useState<boolean>(false);

  // FOR DEBUGGING
  const [fileUrl, setFileUrl] = useState<string>();

  useEffect(() => {
    refresher(() => void setShouldRefresh(true));
  }, [refresher]);

  useEffect(() => {
    // Style the rects
    if (floorPlanContainerRef.current) {
      const tables = queryForTables(floorPlanContainerRef.current);
      const mappedTables = tables.map(getTable);
      onTablesFound(mappedTables);
    }
  }, [svg, loading, onTablesFound]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        if (debug && !!fileUrl) {
          const result = await fetch(fileUrl);
          const text = await result.text();
          setSvg(text);
        } else if (src) {
          const result = await fetch(src);
          const text = await result.text();
          setSvg(text);
        }
        setLoading(false);
      } catch (error) {
        log(error);
      }
    })();
  }, [src, fileUrl, debug]);

  useEffect(() => {
    setShouldRefresh(false);
  }, [shouldRefresh]);

  const handleTableClick = useCallback(
    (ev: MouseEvent | TouchEvent) => {
      const target = ev.target as SVGElement;
      const table = getTable(target);
      onTableClick(table);
    },
    [onTableClick]
  );

  const [infoBoxes, setInfoBoxes] = useState<React.ReactNode[]>([]);

  const floorPlanRef = useRef<HTMLDivElement>(null);
  const [zoomStyle, api] = useSpring(() => ({
    x: 0,
    y: 0,
    scale: 1,
    rotate: 0,
  }));
  const dragBind = useDrag(
    ({ offset: [ox, oy], pinching, cancel }) => {
      if (pinching) cancel();
      api.start({ x: ox, y: oy, immediate: true });
    },
    { from: () => [zoomStyle.x.get(), zoomStyle.y.get()] }
  );

  // const [zoom, setZoom] = useState({ scale: 1, rotate: 0 });
  const pinchBind = usePinch(
    ({ offset: [scale], event }) => {
      api.start({ scale: scale });
    },
    { pinchOnWheel: true }
  );

  // Disables accessibility conflict issues with safari
  useEffect(() => {
    const handler = (e) => e.preventDefault();
    document.addEventListener("gesturestart", handler);
    document.addEventListener("gesturechange", handler);
    document.addEventListener("gestureend", handler);
    return () => {
      document.removeEventListener("gesturestart", handler);
      document.removeEventListener("gesturechange", handler);
      document.removeEventListener("gestureend", handler);
    };
  }, []);

  useEffect(() => {
    if (
      !svg ||
      !floorPlanContainerRef.current ||
      floorPlanContainerRef.current.getBoundingClientRect().width <= 10
    ) {
      return;
    }

    const tableElements = queryForTables(floorPlanContainerRef.current);

    tableElements.forEach((el, i) => {
      onStyleTable({ el, table: getTable(el), i });
      el.addEventListener("click", handleTableClick);
    });

    // Generated rect info boxes
    const parentBox = floorPlanContainerRef.current.getBoundingClientRect();
    setInfoBoxes(
      tableElements.map((t) => {
        const box = t.getBoundingClientRect();

        const table = getTable(t);

        // Get the relative x and y for the view
        const [x, y] = [
          box.x - (parentBox ? parentBox.x : 0),
          box.y - (parentBox ? parentBox.y : 0),
        ];

        const rect = {
          width: box.width / zoomStyle.scale.get(),
          height: box.height / zoomStyle.scale.get(),
          x: x / zoomStyle.scale.get(),
          y: y / zoomStyle.scale.get(),
        } as DOMRect;

        // TODO: A listener that returns something is bad practice
        //       Instead rename to InfoBox or something
        return renderInfoBox ? (
          renderInfoBox({ bounds: rect, el: t, table })
        ) : (
          null
        );
      })
    );

    return () =>
      tableElements.forEach((n) =>
        n.removeEventListener("click", handleTableClick)
      );
  }, [
    svg,
    loading,
    handleTableClick,
    onStyleTable,
    renderInfoBox,
    shouldRefresh,
    zoomStyle.scale,
  ]);

  const zoom = (zoomStep: number) => () => {
    api.start({ scale: zoomStyle.scale.get() + zoomStep });
  };

  return (
    <>
      {debug && (
        <input
          type="file"
          onChange={(e) => {
            if (e.target && e.target.files && e.target.files?.length >= 1) {
              setFileUrl(URL.createObjectURL(e.target.files[0]));
            }
          }}
        />
      )}
      <div
        style={{
          aspectRatio: "1 / 1",
          width: "100%",
          height: "100%",
          boxSizing: "border-box",
        }}
      >
        <svg height="0">{svgHeader}</svg>
        <div
          {...pinchBind()}
          {...classNames(css.FloorPlanViewer, className)}
          style={{ ...style, padding: "unset", position: "relative" }}
        >
          <div>
            {!loading && svg ? (
              <a.div
                {...dragBind()}
                ref={floorPlanRef}
                style={{
                  position: "absolute",
                  transition: "50ms",
                  touchAction: "none",
                  ...zoomStyle,
                }}
              >
                <div
                  ref={floorPlanContainerRef}
                  {...classNames(css.Padded, css.FloorPlanContainer)}
                  dangerouslySetInnerHTML={{ __html: svg }}
                  style={{ userSelect: "none" }}
                />
                <div
                  style={{ position: "absolute", top: "0", left: "0" }}
                  className={css.FloorPlanInformation}
                >
                  {infoBoxes}
                </div>
              </a.div>
            ) : (
              <div className={css.Center}>
                <PulsingGreeter />
              </div>
            )}
            <div {...classNames(css.Padded, css.ZoomControlContainer)}>
              <ZoomControls
                onZoomIn={zoom(0.2)}
                onZoomOut={zoom(0.2 * -1)}
                onZoomReset={() => {
                  api.start({ scale: 1, x: 0, y: 0 });
                }}
              />
            </div>
          </div>
          {props.disabled && <div className={css.DisabledOverlay} />}
          {props.slots?.overlay?.after}
        </div>
      </div>
    </>
  );
};
