export const coverStyles = (url: string) => {
  return {
    style: {
      backgroundImage: `url(${url})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
    } as React.CSSProperties,
  };
};

export function displayWhen(condition: boolean) {
  return { display: condition ? "inline-block" : "none" };
}
