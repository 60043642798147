import css from "./GreeterEventCard.module.scss";
import React, { useEffect, useState } from "react";
import { GreeterEvent, When, DaysliceConfig, Venue, ImageAsset } from "@greeter/core";
import Routes from "@greeter-guest/utility/Routes";
import { useHistory } from "react-router-dom";
import { classNames } from "@greeter/util";
import {
  GreenLabel,
  YellowLabel,
  BaseProps,
  Card,
  CardHeader,
  LazyCoverImage,
  CardContent,
  CardTitle,
  CardDescription,
} from "@greeter/matter";

export type GreeterEventCardProps = BaseProps & {
  width?: string | number;
  greeterEvent: GreeterEvent;
  venue?: Venue;
  daysliceConfig: DaysliceConfig;
};

function pluralize(val: number, suffix: string) {
  return val > 1 ? suffix : "";
}

export function GreeterEventCard({
  width,
  greeterEvent,
  daysliceConfig,
  venue,
}: GreeterEventCardProps) {
  const [when, setWhen] = useState<When>(When.InTheFuture);

  useEffect(() => {
    setWhen(GreeterEvent.figureOutWhen(greeterEvent, daysliceConfig.dayOffset));
  }, [greeterEvent, daysliceConfig?.dayOffset]);

  const style: React.CSSProperties = {};
  if (width) style.width = width;

  const history = useHistory();

  return (
    <Card
      {...classNames(css.Card, css.GreeterEventCard)}
      onClick={() =>
        history.push(
          Routes.greeterEvent.route({ greeterEventId: greeterEvent.id })
        )
      }
      style={style}
    >
      <CardHeader style={{ aspectRatio: "16/9" }}>
        <LazyCoverImage src={ImageAsset.findUriWithSizeOrDefault(greeterEvent.coverAsset, "16x9-w512")} />
      </CardHeader>
      <CardContent>
        <CardTitle size="sm">{greeterEvent.name}</CardTitle>
        <CardDescription>{venue?.name ?? ""}</CardDescription>
      </CardContent>
      <CardTag when={when} />
    </Card>
  );
}

type CardTagProps = BaseProps & {
  when: When;
};

const cardTags = {
  [When.InProgress]: <GreenLabel text="Igang" className={css.whenLabel} />,
  [When.Today]: <GreenLabel text="Idag" className={css.whenLabel} />,
  [When.Tomorrow]: <YellowLabel text="Imorgen" className={css.whenLabel} />,
};

function CardTag({ when }: CardTagProps) {
  return cardTags[when] ?? <div className={css.whenLabel} />;
}
