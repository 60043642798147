import React from "react";

export function EmailIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={52}
      height={52}
      viewBox="0 0 13.8 13.8"
      {...props}
    >
      <path
        d="M2.2 2.9c-.3 0-.6.2-.6.5v6.9c0 .4.3.5.6.5h9.4c.3 0 .6-.2.6-.5V3.4c0-.3-.3-.5-.6-.5zM3.5 4h6.7L7.1 6.6zm-.9.5l3.9 2.9c.6.5.6.5 1.3 0l3.3-2.9v5.3H2.6z"
        style={{
          fontFeatureSettings: "normal",
          fontVariantAlternates: "normal",
          fontVariantCaps: "normal",
          fontVariantEastAsian: "normal",
          fontVariantLigatures: "normal",
          fontVariantNumeric: "normal",
          fontVariantPosition: "normal",
          fontVariationSettings: "normal",
          inlineSize: 0,
          isolation: "auto",
          lineHeight: "normal",
          mixBlendMode: "normal",
          shapeMargin: 0,
          textAlign: "start",
          textDecorationColor: "#000",
          textDecorationLine: "none",
          textDecorationStyle: "solid",
          textIndent: 0,
          textOrientation: "mixed",
          textTransform: "none",
          whiteSpace: "normal",
        }}
        fill="#fff"
        fontFamily="sans-serif"
        paintOrder="fill markers stroke"
      />
    </svg>
  );
}
const greeterIcon = `
<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<svg
   viewBox="0 0 61.0911 78.8004"
   version="1.1"
   id="svg6"
   sodipodi:docname="greeter-logo-logo-mark-inverted-rgb.svg"
   inkscape:version="1.1.2 (0a00cf5339, 2022-02-04, custom)"
   inkscape:export-filename="C:\Users\workb\OneDrive\GreeterPersonal\Design\UIDesign\Logos\Logo Mark\Hvid\Digital\greeter-logo-logo-mark-inverted-rgb.png"
   inkscape:export-xdpi="144"
   inkscape:export-ydpi="144"
   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:svg="http://www.w3.org/2000/svg">
  <defs
     id="defs10" />
  <sodipodi:namedview
     id="namedview8"
     pagecolor="#505050"
     bordercolor="#eeeeee"
     borderopacity="1"
     inkscape:pageshadow="0"
     inkscape:pageopacity="0"
     inkscape:pagecheckerboard="0"
     showgrid="false"
     inkscape:snap-bbox="true"
     inkscape:object-paths="true"
     inkscape:zoom="16"
     inkscape:cx="37.8125"
     inkscape:cy="43.8125"
     inkscape:window-width="1920"
     inkscape:window-height="1027"
     inkscape:window-x="-8"
     inkscape:window-y="-8"
     inkscape:window-maximized="1"
     inkscape:current-layer="logoMark" />
  <g
     id="invert-rgb">
    <g
       id="logoMark">
      <g
         id="g1997">
        <path
           id="logoMark_PathItem_2"
           data-name="logoMark &lt;PathItem&gt;"
           d="m 55.5382,17.08 a 38.0775,38.0775 0 0 0 -16.2425,-2.725 25.0677,25.0677 0 0 0 -13.36,3.8247 22.9,22.9 0 0 0 -8.2264,9.2227 27.7634,27.7634 0 0 0 0.1052,24.2059 23.1229,23.1229 0 0 0 8.33,9.2739 23.861,23.861 0 0 0 13.2564,3.5618 17.8,17.8 0 0 0 5.2389,-0.6808 2.9933,2.9933 0 0 0 2.2,-3.1965 V 39.4 a 3.1868,3.1868 0 0 0 -1.0475,-2.5669 3.9945,3.9945 0 0 0 -5.03,0 A 3.1825,3.1825 0 0 0 39.715,39.4 v 17.92 a 18.2424,18.2424 0 0 1 -9.6932,-2.463 15.865,15.865 0 0 1 -6.0774,-6.5493 19.3992,19.3992 0 0 1 -2.0438,-8.8021 19.6374,19.6374 0 0 1 2.0964,-8.9072 16.4653,16.4653 0 0 1 6.2863,-6.7582 19.1054,19.1054 0 0 1 10.1651,-2.5692 30.2677,30.2677 0 0 1 12.26,2.3053 3.681,3.681 0 0 0 1.4668,0.3155 3.129,3.129 0 0 0 2.4631,-1.1526 3.776,3.776 0 0 0 0.9949,-2.5157 3.3592,3.3592 0 0 0 -2.095,-3.1435 z"
           style="fill:#ffffff" />
        <path
           id="logoMark_PathItem_"
           data-name="logoMark &lt;PathItem&gt;"
           d="M 61.0911,70.4176 V 39.4 a 3.1871,3.1871 0 0 0 -1.0474,-2.5669 3.6219,3.6219 0 0 0 -2.41,-0.8911 3.7557,3.7557 0 0 0 -2.5143,0.8911 3.1825,3.1825 0 0 0 -1.048,2.5669 V 68.3212 A 33.042,33.042 0 0 1 39.0854,71.7792 33.1287,33.1287 0 0 1 23.3674,67.9019 30.2529,30.2529 0 0 1 11.5266,56.6383 32.4412,32.4412 0 0 1 7.0212,39.4 32.5178,32.5178 0 0 1 11.474,22.2673 30.1142,30.1142 0 0 1 23.5777,10.8973 36.2084,36.2084 0 0 1 40.5536,6.916 43.8529,43.8529 0 0 1 54.4907,9.2213 4.2752,4.2752 0 0 0 55.9575,9.5354 3.2583,3.2583 0 0 0 58.4191,8.4879 3.3976,3.3976 0 0 0 59.4154,6.0779 3.3461,3.3461 0 0 0 58.6821,3.9815 4.1037,4.1037 0 0 0 56.5857,2.6199 51.3786,51.3786 0 0 0 40.4484,0 42.68,42.68 0 0 0 20.0146,4.9248 37.3761,37.3761 0 0 0 5.3966,18.7567 38.8393,38.8393 0 0 0 0,39.2957 39.6181,39.6181 0 0 0 5.344,59.9911 37.4958,37.4958 0 0 0 19.5953,73.8756 38.7951,38.7951 0 0 0 38.6675,78.8 a 41.7107,41.7107 0 0 0 20.5376,-5.3441 4.448,4.448 0 0 0 1.363,-1.31 3.0561,3.0561 0 0 0 0.523,-1.7283 z"
           style="fill:#ffffff" />
        <path
           style="color:#000000;fill:#ffffff;-inkscape-stroke:none"
           d="m 57.663583,35.941871 -14.51542,0.0022 3.691637,6.208284 7.2316,-3.35e-4 z"
           id="path931"
           sodipodi:nodetypes="ccccc" />
      </g>
    </g>
  </g>
</svg>
`;

export function GreeterIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    // <img src={greeterIconDataUrl} />
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={52}
      height={52}
      viewBox="0 0 13.758 13.758"
      dangerouslySetInnerHTML={{ __html: greeterIcon }}
      {...props}
    />
  );
}

export function KeyIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={52}
      height={52}
      viewBox="0 0 13.758 13.758"
      {...props}
    >
      <path
        d="M12.664 10.892h-3.44V8.599H7.688a4.01 4.01 0 01-3.623 2.293A4.017 4.017 0 01.052 6.879c0-2.213 1.8-4.013 4.013-4.013 1.56 0 2.964.906 3.623 2.293h6.122V8.6h-1.146zM10.37 9.745h1.146V7.452h1.147V6.306H6.897l-.132-.384a2.87 2.87 0 00-2.7-1.91 2.867 2.867 0 000 5.733 2.87 2.87 0 002.7-1.908l.132-.385h3.474zM4.065 8.6c-.946 0-1.72-.774-1.72-1.72s.774-1.72 1.72-1.72 1.72.774 1.72 1.72S5.01 8.6 4.065 8.6zm0-2.293a.575.575 0 00-.573.573c0 .315.258.573.573.573a.575.575 0 00.573-.573.575.575 0 00-.573-.573z"
        opacity={1}
        fill="#fff"
      />
    </svg>
  );
}

export function PhoneIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={52}
      height={52}
      viewBox="0 0 52 52"
      {...props}
    >
      <path
        d="M13.8 5c-1.2 0-2.4.5-3.3 1.4l-4.4 3.9c-.4.3-1.1 1.5-1.4 2.8-3.2 20.5 14.7 36.3 34.6 33.6 1.6-.4 2.8-1.2 3.3-1.8.6-.7 3.7-4.1 3.7-4.1 1.8-1.9 1.8-4.9 0-6.7l-5.9-5.9c-.9-.9-2.1-1.4-3.4-1.4-1.2 0-2.4.4-3.3 1.4l-4 4.1c-.6.6-.8.9-1.9.4-4.1-2.6-6.5-5-8.2-8-.8-1.7.1-2.3.7-2.9l2.8-2.9c1.9-1.8 1.8-4.9 0-6.7l-6-5.8C16.2 5.4 15 5 13.8 5zm0 3c.4 0 .9.2 1.2.5l5.9 5.9c.7.7.7 1.7 0 2.4l-4 4.2c-1.4 1.4-.8 3.3-.8 3.3 1.2 3.2 6.7 9.8 11.8 11.5.8.2 2.1.4 3.1-.5l4.8-5c.7-.7 1.7-.7 2.4 0l5.9 5.9c.7.7.7 1.7 0 2.4l-3.9 4.2C39 44 38.1 44 38.1 44 21 45.7 5.3 30.7 8 13.6c.2-1.1.5-1.4 1-1.9l3.6-3.2c.4-.3.8-.5 1.2-.5z"
        style={{
          fontFeatureSettings: "normal",
          fontVariantAlternates: "normal",
          fontVariantCaps: "normal",
          fontVariantEastAsian: "normal",
          fontVariantLigatures: "normal",
          fontVariantNumeric: "normal",
          fontVariantPosition: "normal",
          fontVariationSettings: "normal",
          inlineSize: 0,
          isolation: "auto",
          lineHeight: "normal",
          mixBlendMode: "normal",
          textAlign: "start",
          textDecorationColor: "#000",
          textDecorationLine: "none",
          textDecorationStyle: "solid",
          textIndent: 0,
          textOrientation: "mixed",
          textTransform: "none",
          whiteSpace: "normal",
        }}
        fill="#fff"
        fontFamily="sans-serif"
        paintOrder="fill markers stroke"
      />
    </svg>
  );
}
