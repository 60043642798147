import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AreaWeeklyOpeningHours, SpecialOpeningHours, Table, TableId } from "@greeter/core";
import { Area, FloorPlan } from "@greeter/core";
import { FloorPlanViewer } from "../FloorPlanViewer";
import {
  IonIcon,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  useIonToast,
} from "@ionic/react";
import { doNothing, classNames } from "@greeter/util";
import { logger } from "@greeter/log";
import { peopleOutline } from "ionicons/icons";
import { first } from "lodash";
import { SwiperMultiCarousel } from "../SwiperCarousel";
import {
  RenderInfoBoxArgs,
  OnStyleTable,
} from "../FloorPlanViewer/FloorPlanViewer";

import { Env } from "@greeter/config";
import { nanoid } from "nanoid";
import css from "./BookTableBox.module.scss";

import devFloorPlan from "./HavanaSkive_Stue_Floorplan.svg";
import { areaOpeningHourStatus, difference } from "./utils";

const log = logger("[BookTableBox]");

type DisplayTable = "capacity" | "number";

type StyleTableProps = {
  bookedTables: TableId[];
  selectedTables: TableId[];
  gradientId: string;
};

const styleTable =
  ({ bookedTables, selectedTables, gradientId }: StyleTableProps) =>
  ({ el, table }: OnStyleTable) => {
    el.classList.add("table");
    el.classList.add(css.DefaultTable);

    el.setAttribute("rx", "1");
    el.setAttribute("ry", "1");

    if (bookedTables.find((t) => t.tableNumber === table.number)) {
      el.classList.add(css.BookedTable);
      el.classList.add("booked");
    } else {
      el.classList.remove(css.BookedTable);
      el.classList.remove("booked");
    }

    if (
      selectedTables &&
      selectedTables.find((t) => t.tableNumber === table.number)
    ) {
      el.style.setProperty("fill", `url(#${gradientId})`);
      el.style.setProperty("stroke", `url(#${gradientId})`);
      el.classList.add("selected");
    } else {
      el.style.removeProperty("fill");
      el.style.removeProperty("stroke");
      el.classList.remove("selected");
    }
  };

type RenderInfoBoxProps = {
  mode: "capacity" | "number";
  renderInfoBoxFooter?: (args: RenderInfoBoxArgs) => void;
  area: string;
};

function renderInfoBox({ mode, renderInfoBoxFooter, area }: RenderInfoBoxProps) {
  return ({ bounds, el, table }: RenderInfoBoxArgs) => {
    if (table.type !== "table") return <div />;

    const { x, y, width, height } = bounds;
    const posStyle: React.CSSProperties = {
      transform: `translate(${x}px, ${y}px)`,
      width: `${width}px`,
      height: `${height}px`,
    };

    switch (mode) {
      case "capacity":
        return (
          <>
            <div
              key={area + table.number}
              style={posStyle}
              className={css.FloorPlanOverlay}
            >
              <IonIcon icon={peopleOutline} className={css.Icon} />
              <div className={css.CapacityText}>
                {`${table.minCapacity}-${table.maxCapacity}`}
              </div>
            </div>
            {renderInfoBoxFooter && renderInfoBoxFooter({ bounds, el, table })}
          </>
        );
      default:
        return (
          <>
            <div
              key={area + table.number}
              style={{
                ...posStyle,
                display: "grid",
                placeItems: "center",
                gridTemplateRows: "unset",
              }}
              className={css.FloorPlanOverlay}
            >
              <div style={{ fontWeight: "bold", color: "white" }}>
                {table.number}
              </div>
            </div>
            {renderInfoBoxFooter && renderInfoBoxFooter({ bounds, el, table })}
          </>
        );
    }
  };
}

type OverlayBoxProps = PropsWithChildren & {
  onClick?: () => void;
};
const OverlayBox: React.FC<OverlayBoxProps> = (props) => {
  return (
    <div
      style={{
        padding: "1rem",
        backgroundColor: "var(--gm-color-alt-bg)",
        border: "2px solid var(--gm-color-default-bg)",
        fontWeight: "bold",
        color: "white",
        borderRadius: "10px",
        textAlign: "center",
      }}
      {...props}
    />
  );
};

const Overlay: React.FC<PropsWithChildren> = (props) => {
  return (
    <div
      key="overlay"
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "grid",
        placeItems: "center",
      }}
      {...props}
    />
  );
};

export type BookTableBoxFeatureFlags = {
  /**
   * This ensures that an overlay is displayed in case of the start time violating the opening hours specified
   *
   * We want to be able to disable this since venues do not desire to have this shown.
   */
  overlayOnOpeningHoursViolation?: boolean;
};

export type BookTableBoxProps = React.PropsWithChildren & {
  bookedTables: TableId[];
  floorPlan: FloorPlan;
  groupSize: number;
  selectableBookedTables?: TableId[];
  selectedTables?: TableId[];
  display?: DisplayTable;
  total?: number;
  // TODO: This is the start of a plugin architecture for this component
  featureFlags?: BookTableBoxFeatureFlags;
  specialOpeningHours?: SpecialOpeningHours[];

  /**
   * Strict does two things:
   * It makes sure that you cannot exceed the capacity overall
   * and it makes sure that you do not exceed the capacity on a single table.
   *
   * This is in relation to groupsize and accumulated capacity across all selected
   * tables.
   */
  strict?: boolean;
  onClick?: () => void;
  onTableClick?: (area: string, table: Table) => void;
  env?: Env;
  onSelectedTablesChange?: (tables: TableId[]) => void;
  onMinimumPriceChange?: (minPrice: number) => void;
  onAreaChange?: (area: string) => void;
  onClosedOverlayClick?: (opensAt?: Date) => void;

  /**
   * Define from which point in time this BookTableBox should be
   * anchoring from.
   *
   * Mainly used for testing.
   */
  now?: Date;

  /**
   * We are using a function since we need to pass
   * some contextual data to the components
   */
  renderInfoBoxFooter?: (args: RenderInfoBoxArgs) => void;

  debug?: boolean;
};

export function BookTableBox ({
  bookedTables,
  floorPlan,
  groupSize,
  selectableBookedTables = [],
  selectedTables = [],
  total = 0,
  strict = false,
  featureFlags,
  onClick = doNothing,
  onTableClick = doNothing,
  onSelectedTablesChange = doNothing,
  onMinimumPriceChange = doNothing,
  onAreaChange = doNothing,
  env,
  display,
  renderInfoBoxFooter,
  now,
  onClosedOverlayClick,
  specialOpeningHours = []
}: BookTableBoxProps) {
  const [capacity, setCapacity] = useState(0);
  const [selectedArea, setSelectedArea] = useState<string>(
    floorPlan.areas.length > 0 ? floorPlan.areas[0].name : ""
  );
  const [area, setArea] = useState<Area>();

  const [present] = useIonToast();


  const findTable = useCallback(
    (tableId: TableId) =>
      floorPlan.areas
        .find((a) => a.name === tableId.area)
        ?.tables.find((t) => t.number === tableId.tableNumber),
    [floorPlan]
  );

  useEffect(() => {
    if (!selectedTables) return;

    onSelectedTablesChange(selectedTables);
  }, [onSelectedTablesChange, selectedTables]);

  useEffect(() => {
    if (selectedArea) {
      setArea(floorPlan.areas.find((a) => a.name === selectedArea));
    } else {
      setArea(first(floorPlan.areas));
    }
  }, [selectedArea, floorPlan, floorPlan.areas]);

  useEffect(() => {
    if (!selectedTables) return;

    setCapacity(
      selectedTables.reduce((acc, curr) => {
        return (acc += findTable(curr)?.maxCapacity ?? 0);
      }, 0)
    );
  }, [selectedTables, findTable]);

  useEffect(() => {
    if (!selectedTables) return;

    const selectedTablesCopy = [...selectedTables].reverse();
    const reducedSelectedTables = strict
      ? selectedTablesCopy.reduce((acc, curr) => {
          const [capacityReached] = acc.reduce(
            (acc2, curr2) => {
              const table = findTable(curr2);

              if (!table) return acc2;

              const [, accCapacity] = acc2;
              const newCapacity = accCapacity + table.maxCapacity;
              return [newCapacity > groupSize, newCapacity];
            },
            [false, 0] // [hasMaxBeenReached, currentCapacity]
          );

          if (!capacityReached) acc.push(curr);
          return acc;
        }, [] as TableId[])
      : selectedTablesCopy;
    const reversedReduction = [...reducedSelectedTables].reverse();

    if (reversedReduction.length !== selectedTables.length) {
      onSelectedTablesChange(reversedReduction);
    }
  }, [
    findTable,
    capacity,
    selectedTables,
    groupSize,
    onSelectedTablesChange,
    strict,
  ]);

  useEffect(() => {
    if (!selectedTables || bookedTables.length <= 0) return;

    const selectedTablesWithoutBookedTables = selectedTables.reduce(
      (acc, curr) => {
        const bookedTable = difference(bookedTables, selectableBookedTables).find((bt) => Table.eqIds(bt, curr));

        if (!bookedTable) {
          acc.push(curr);
        }

        return acc;
      },
      [] as TableId[]
    );

    onSelectedTablesChange(selectedTablesWithoutBookedTables);

    // NOTE: Do not add selectedTables to this, as it will end in an infinite loop
    // eslint-disable-next-line
  }, [bookedTables, onSelectedTablesChange, strict]);

  const [minPrice, setMinPrice] = useState(0);

  /**
   * We use a gradientId since creating an SVG defs header with the same id
   * will result in the browser not being able to figure out which element to
   * refer to.
   *
   * This resulted in unintended see through tables which logically didnt make sense
   * since the tables each were refering to the same #gradient.
   *
   * In reality they were to separate elements and it seemed like the browser was
   * trying to refer to a linearGradient that didn't exist.
   *
   * To solve it we create a unique id for each BookTableBox generated and
   * refer to that instead which finally results in a BookTableBox with
   * gradient when traversing pages.
   */
  const gradientId = useMemo(() => nanoid(), []);

  const defs = useMemo(() => {
    return (
      <linearGradient gradientTransform="rotate(335)" id={gradientId}>
        <stop offset="0%" stopColor="var(--gm-color-secondary-highlight)" />
        <stop offset="100%" stopColor="var(--gm-color-primary-highlight)" />
      </linearGradient>
    );
  }, [gradientId]);

  const handleTableClick = useCallback(
    (table: Table) => {
      if (!area) return;

      if (onTableClick) onTableClick(area.name, table);

      if (table.type !== "table") return;

      const unavailableTable = difference(bookedTables, selectableBookedTables).find(
        (ti) => ti.tableNumber === table.number && ti.area === area.name
      );

      if (!!unavailableTable) {
        present("Hov, her kommer der andre og holder fest!", 3000);
        return;
      }

      if (strict && table && table?.minCapacity > groupSize) {
        present("Hov, det bord er for stort til jeres gruppe", 3000);
        return;
      }

      const tables = selectedTables ?? [];

      const index = tables.findIndex(
        (ti) => ti.area === area.name && ti.tableNumber === table.number
      );

      if (index === -1) {
        const withAddedTable = [
          ...tables,
          { tableNumber: table.number, area: area.name },
        ];
        onSelectedTablesChange(withAddedTable);
      } else {
        onSelectedTablesChange(
          tables.filter(
            (t) => !(t.area === area.name && t.tableNumber === table.number)
          )
        );
      }
    },
    [
      area,
      groupSize,
      selectedTables,
      bookedTables,
      onTableClick,
      selectableBookedTables,
      onSelectedTablesChange,
      present,
      strict,
    ]
  );

  useEffect(() => {
    if (!selectedTables) return;

    // We timeout the min. price calc. to avoid price flicker as the selectedtables are not updated right away on click.
    const id = setTimeout(() => {
      const _minPrice = selectedTables.reduce((acc, curr) => {
        const t = findTable(curr);
        return acc + (t?.minPrice ?? 0);
      }, 0);
      if (_minPrice === minPrice) return;
      onMinimumPriceChange(_minPrice);
      setMinPrice(_minPrice);
    }, 100);

    return () => clearTimeout(id);
  }, [selectedTables, findTable, onMinimumPriceChange, minPrice]);

  const [mode, setMode] = useState<DisplayTable>(display ?? "capacity");

  useEffect(() => {
    if (!area) return;

    onAreaChange(area.name);
  }, [area, onAreaChange]);

  const areasButtons = useMemo(() => {
    if (area)
      return floorPlan.areas.map((a, _) => (
        <button
          key={a.name}
          onClick={() => setSelectedArea(a.name)}
          {...classNames(
            css.AreaButton,
            a.name === area.name ? css.Active : ""
          )}
        >
          {a.name}
        </button>
      ));
  }, [floorPlan.areas, area]);

  const { opensAt, opensAtDate, isOpenNow } = areaOpeningHourStatus(
    now ?? new Date(),
    area?.openingHours ?? new AreaWeeklyOpeningHours(),
    specialOpeningHours
  );

  return (
    <div style={{ position: "relative" }} onClick={onClick}>
      {area && (
        <div>
          <div className={css.AreaButtonBar}>
            <SwiperMultiCarousel>{areasButtons}</SwiperMultiCarousel>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingBottom: "0.2rem",
              }}
            >
              <div className={css.DescriptiveText}>
                {capacity >= groupSize ? (
                  <span className={css.Green}>Sådan! Du er klar til fest!</span>
                ) : capacity < groupSize &&
                  selectedTables &&
                  selectedTables.length !== 0 ? (
                  <span className={css.Yellow}>
                    {selectedTables.length} bord
                    {selectedTables.length > 1 ? "e" : ""} valgt - Vælg evt.
                    endnu et bord.
                  </span>
                ) : (
                  <span className={css.Subtle}>
                    Tryk på det bord, du ønsker at sidde ved.
                  </span>
                )}
              </div>
              {minPrice > 0 && (
                <div
                  className={css.MinPriceText}
                  style={{
                    color:
                      total > minPrice
                        ? "var(--gm-color-primary-highlight)"
                        : "var(--gm-color-warning)",
                  }}
                >
                  Min. pris {minPrice} kr.
                </div>
              )}
            </div>
            {display && (
              <IonSegment
                mode="ios"
                value={mode}
                onIonChange={(e) => setMode(e.detail.value as DisplayTable)}
                style={{
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                  "--background": "var(--gm-color-alt-bg)",
                }}
              >
                <IonSegmentButton value="number" layout="icon-start">
                  <div style={{ fontWeight: "bold", fontSize: "1rem" }}>#</div>
                  <IonLabel>Numre</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="capacity" layout="icon-start">
                  <IonIcon icon={peopleOutline} />
                  <IonLabel>Kapacitet</IonLabel>
                </IonSegmentButton>
              </IonSegment>
            )}
            <FloorPlanViewer
              debug={env === "dev"}
              style={{
                ...(display
                  ? { borderTopLeftRadius: 0, borderTopRightRadius: 0 }
                  : {}),
                backgroundColor: "var(--gm-color-default-bg)",
                boxSizing: "border-box",
                touchAction: "none",
              }}
              src={env === "dev" ? devFloorPlan : area.floorPlanUrl}
              onTableClick={handleTableClick}
              onStyleTable={styleTable({
                bookedTables: difference(bookedTables, selectableBookedTables),
                selectedTables: selectedTables ?? [],
                gradientId,
              })}
              renderInfoBox={renderInfoBox({
                area: area.name,
                mode: mode,
                renderInfoBoxFooter: renderInfoBoxFooter,
              })}
              svgHeader={defs}
              disabled={
                !!featureFlags?.overlayOnOpeningHoursViolation && !isOpenNow
              }
              slots={{
                overlay: !!featureFlags?.overlayOnOpeningHoursViolation
                  ? {
                      after: !isOpenNow && (
                        <Overlay>
                          <OverlayBox
                            onClick={function handleOverlayOnOpeninghoursClick() {
                              if (opensAtDate && onClosedOverlayClick)
                                onClosedOverlayClick(opensAtDate);
                            }}
                          >
                            {opensAt === "never" ? (
                              <h3>Lukket alle dage</h3>
                            ) : (
                              <div>
                                <h3>Åbner først {opensAt}</h3>
                                {opensAtDate && onClosedOverlayClick && (
                                  <p>Tryk for at opdatere ankomst tidspunkt</p>
                                )}
                              </div>
                            )}
                          </OverlayBox>
                        </Overlay>
                      ),
                    }
                  : undefined,
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};
