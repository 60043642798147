import css from "./Label.module.scss";

import React from "react";
import { BaseProps } from "../BaseProps";
import { classNames } from "@greeter/util";

type LabelProps = BaseProps & {
  text: string;
  position?: "top-left" | "none";
};

type FC = React.FC<LabelProps>;

export const Label: FC = ({ className, text, position = "top-left" }) => (
  <div
    {...classNames(
      css.Label,
      className,
      position === "top-left" && css.TopLeft
    )}
  >
    {text}
  </div>
);

export const YellowLabel: FC = ({ className, text }) => (
  <Label {...classNames(className, css.YellowLabel)} text={text} />
);
export const GreenLabel: FC = ({ className, text }) => (
  <Label {...classNames(className, css.GreenLabel)} text={text} />
);
export const GreyLabel: FC = ({ className, text }) => (
  <Label {...classNames(className, css.GreyLabel)} text={text} />
);
