import { useIonViewWillEnter, useIonViewWillLeave } from "@ionic/react";
import { useEffect, useMemo, useState } from "react";
import { useInView, IntersectionOptions } from "react-intersection-observer";

export const useToggle = (initialValue: boolean): [boolean, () => void] => {
  const [toggle, setToggle] = useState(initialValue);
  return [toggle, () => setToggle(!toggle)];
};

export const useLog = (log: (...args: any[]) => void, ...deps: any[]) => {
  return useEffect(() => log(deps), deps);
};

export const useImage = (src: string, defaultSrc: string) => {
  const [, render] = useState<any>({});
  return useMemo(() => {
    const image = new Image();
    image.src = src;
    image.addEventListener("error", () => {
      if (image.src !== defaultSrc) {
        image.src = defaultSrc;
      }
    });
    image.addEventListener("load", () => {
      render({});
    });
    return image;
  }, [src, defaultSrc]);
};

export const useHiddenTabBar = () => {
  useIonViewWillEnter(() => {
    const tabBar = document.body.querySelector("ion-tab-bar");
    if (tabBar) {
      tabBar.style.display = "none";
    }
  });
  useIonViewWillLeave(() => {
    const tabBar = document.body.querySelector("ion-tab-bar");
    if (tabBar) {
      tabBar.style.removeProperty("display");
    }
  });
};

export const useNow = () => {
  return useMemo(() => new Date(), []);
};

function load(src: string) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(src));
    image.addEventListener("error", reject);
    image.src = src;
  });
}

export function useLazyImage(
  src?: string,
  options?: IntersectionOptions | undefined
): [
  ref: (node: Element | undefined | null) => void,
  isLoading: boolean,
  src?: string
] {
  const [isLoading, setIsLoading] = useState(false);
  const [ref, inView] = useInView({ threshold: 0, ...options });

  useEffect(() => {
    if (!src) return;

    if (inView) {
      load(src)
        .catch(console.log)
        .finally(() => setIsLoading(false));
    }
  }, [inView, src]);

  useEffect(() => {
    setIsLoading(true);
  }, [src]);

  return [ref, isLoading, src];
}
