import {
  convertDayToString,
  DateFactory,
  DatePeriod,
  Day,
  TimeOfDay,
  TimeOfDayPeriod,
} from "@greeter/date";
import { addDays, differenceInDays, subDays } from "date-fns";
import { upsert } from "@greeter/map";
import { DailyOpeningHours } from "./DailyOpeningHours";

export type OpenStatus =
  | { when: "now" }
  | { when: "tomorrow"; at: TimeOfDay }
  | { when: "today"; at: TimeOfDay }
  | { when: "future"; at: Date };

// export type WeeklyOpeningHours = Map<Day, DailyOpeningHours | undefined>;
export class WeeklyOpeningHours extends Map<
  Day,
  DailyOpeningHours | undefined
> {
  toJSON() {
    return [...this.entries()].map(([k, v]) => [convertDayToString(k), v]);
  }
}

function defaultOpeningHours(): DailyOpeningHours {
  return {
    isOpen: true,
    from: TimeOfDay.parse("00:00"),
    to: TimeOfDay.parse("23:59"),
  };
}

export module WeeklyOpeningHours {
  export function createPeriod(
    openingHours: WeeklyOpeningHours,
    date: Date
  ): undefined | DatePeriod {
    const day = date.getDay() as Day;
    const openingHoursForDay = openingHours.get(day);

    if (!openingHoursForDay) return undefined;

    const period = TimeOfDayPeriod.toDatePeriod(openingHoursForDay, date);
    return period;
  }

  export function alwaysOpen(): WeeklyOpeningHours {
    const oh = new WeeklyOpeningHours();

    oh.set(Day.Monday, defaultOpeningHours());
    oh.set(Day.Tuesday, defaultOpeningHours());
    oh.set(Day.Wednesday, defaultOpeningHours());
    oh.set(Day.Thursday, defaultOpeningHours());
    oh.set(Day.Friday, defaultOpeningHours());
    oh.set(Day.Saturday, defaultOpeningHours());
    oh.set(Day.Sunday, defaultOpeningHours());

    return oh;
  }

  /**
   * Uses the opening hours as a template to create dateperiods
   * representing the opening hours in real time for any span of time
   * @param openingHours Opening hours to operate from
   * @param period The period to generate the opening hour dates in
   * @returns Dates from any point in time where opening hours is
   *          open using the opening hours as a template
   */
  export function createDates(
    openingHours: WeeklyOpeningHours,
    period: DatePeriod
  ): DatePeriod[] {
    const daysDiff = differenceInDays(period.to, period.from);
    if (daysDiff <= 0) return [];

    let dates = Array(daysDiff);

    for (let i = 0; i < daysDiff; i++) {
      const anchor = addDays(period.from, i);

      const day = anchor.getDay() as Day;
      const openingHoursForDay = openingHours.get(day);

      if (openingHoursForDay && openingHoursForDay.isOpen) {
        const datePeriod = TimeOfDayPeriod.toDatePeriod(
          openingHoursForDay,
          anchor
        );
        dates[i] = datePeriod;
      }
    }

    const result = dates.filter((d) => !!d);

    return result;
  }

  export function normalize(openingHours: WeeklyOpeningHours) {
    return [...openingHours.entries()].reduce((acc, [day, oh]) => {
      if (
        oh?.from &&
        oh.to &&
        oh?.to.getTotalSeconds() < oh?.from.getTotalSeconds()
      ) {
        const d1 = oh.from;
        const d2: TimeOfDay = TimeOfDay.parse("23:59:59");

        const d3: TimeOfDay = TimeOfDay.parse("00:00:00");
        const d4 = oh.to;

        upsert(acc, day, { from: d1, to: d2, isOpen: oh.isOpen });
        upsert(acc, Day.tomorrow(day), { from: d3, to: d4, isOpen: oh.isOpen });
      } else if (oh) {
        upsert(acc, day, oh);
      }

      return acc;
    }, new Map<Day, DailyOpeningHours[]>());
  }

  // TODO: These fail
  export function isOpen(openingHours: WeeklyOpeningHours, day: Day) {
    // const normalizedOpeningHours = normalize(openingHours);
    // const normalizedOpeningHour = normalizedOpeningHours.get(day);
    // const open = normalizedOpeningHour?.some(oh => oh.isOpen);
    return openingHours.get(day)?.isOpen;
  }

  export function isOpenTomorrow(
    openingHours: WeeklyOpeningHours,
    now: Date = DateFactory.create()
  ) {
    return isOpen(openingHours, addDays(now, 1).getDay());
  }

  export function isOpenToday(
    openingHours: WeeklyOpeningHours,
    now: Date = DateFactory.create()
  ) {
    return isOpen(openingHours, now.getDay());
  }

  export function isOpenNow(
    openingHours: WeeklyOpeningHours,
    now: Date = DateFactory.create()
  ) {
    const dates = createDates(
      openingHours,
      new DatePeriod({ from: subDays(now, 1), to: addDays(now, 1) })
    );

    return dates.some((dp) => dp.isWithin(now));
  }

  export function getOpenStatus(
    openingHours: WeeklyOpeningHours,
    now: Date = DateFactory.create()
  ): OpenStatus | undefined {
    const openNow = isOpenNow(openingHours, now);
    if (openNow) {
      return { when: "now" };
    }

    const datePeriods = createDates(
      openingHours,
      new DatePeriod({ from: now, to: addDays(now, 10) })
    );

    const nextOpeningHour = datePeriods.find((dp) => dp.from >= now);
    if (!nextOpeningHour) return undefined;

    const distance = differenceInDays(nextOpeningHour.from, now);
    const isToday = distance === 0;
    const isTomorrow = distance === 1;

    if (isToday) {
      return { when: "today", at: TimeOfDay.fromDate(nextOpeningHour.from) };
    } else if (isTomorrow) {
      return {
        when: "tomorrow",
        at: TimeOfDay.fromDate(nextOpeningHour.from),
      };
    }

    return { when: "future", at: nextOpeningHour.from };
  }
}
