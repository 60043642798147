import React, { useEffect, useRef, useState } from "react";
import {
  IonContent,
  IonModal,
  IonPage,
  useIonViewDidEnter,
} from "@ionic/react";
import {
  assign,
  createMachine,
  sendTo,
  raise,
  setup,
  emit,
  fromCallback,
} from "xstate";
import { useMachine } from "@xstate/react";
import {
  CategoryWithProducts,
  MenuContainer,
  MenuContent,
  Price,
} from "../MenuPage/MenuPage";

import {
  cartMachine,
  CartMachineRef,
  getTotal,
  IncrementQuantityEvent,
  ReloadCartEvent,
  SetQuantityEvent,
  SetIdEvent,
} from "@greeter/commerce";
import { ImageAsset, Menu, TableServiceOrder, Venue } from "@greeter/core";
import { Query, doNothing } from "@greeter/util";
import { logger, warner } from "@greeter/log";
import {
  OutlinedGradientButton,
  Card,
  CardContent,
  FloatingActionBar,
  FloatingActionTitle,
  useHiddenTabBar,
  LazyCoverImage,
  PageCover,
  Spinner,
  LoadingOverlay,
} from "@greeter/matter";

import { CreateTableServiceOrderRequest } from "@greeter/api";

import Routes from "@greeter-guest/utility/Routes";
import { useHistory } from "react-router-dom";
import { Checkout } from "@greeter-guest/components/Payments";
import css from "./CartPage.module.scss";
import { useLoading } from "@greeter/loading";

const name = "[CartPage]";
const log = logger(name);
const warn = warner(name);

type PaymentMethod = "apple" | "google" | "card";

type CartPageMachineContext = {
  categoryWithProducts: CategoryWithProducts[];
  cartRef?: CartMachineRef;
  paymentMethod?: PaymentMethod;
  tableServiceOrder?: TableServiceOrder;
};
type SetCategoryWithProductsEvent = {
  type: "setCategoryWithProducts";
  data: CategoryWithProducts[];
};
type PayEvent = {
  type: "pay";
  data: "apple" | "google" | "card";
};
type CreateEvent = {
  type: "create";
};
type ShowPaymentMethodPickerEvent = {
  type: "showPaymentMethods";
};
type PickPaymentMethodEvent = {
  type: "pickPaymentMethod";
  data: PaymentMethod;
};
type SetTableServiceOrderEvent = {
  type: "setTableServiceOrder";
  data: TableServiceOrder;
};

type PrevEvent = {
  type: "prev";
};

type NextEvent = {
  type: "next";
};

type RetryEvent = {
  type: "retry";
};

type ResetEvent = {
  type: "reset";
};

type ValidateEvent = {
  type: "validate";
};

type ErrorEvent = {
  type: "error";
};

type CancelEvent = {
  type: "cancel";
};

export type CartPageMachineEvents =
  | PayEvent
  | CreateEvent
  | IncrementQuantityEvent
  | SetIdEvent
  | SetQuantityEvent
  | SetCategoryWithProductsEvent
  | ShowPaymentMethodPickerEvent
  | PickPaymentMethodEvent
  | PrevEvent
  | NextEvent
  | RetryEvent
  | ResetEvent
  | ErrorEvent
  | CancelEvent
  | ValidateEvent
  | ReloadCartEvent
  | SetTableServiceOrderEvent;

const selectCart = ({ context: ctx }: { context: CartPageMachineContext }) =>
  ctx.cartRef!;

export const cartPageMachine = setup({
  types: {} as {
    context: CartPageMachineContext;
    events: CartPageMachineEvents;
    emitted:
      | { type: "creatingOrder" }
      | { type: "creatingPayment" }
      | { type: "success" }
      | { type: "failure" };
  },
  actors: {
    ticker: fromCallback(function ticker({ sendBack, system }) {
      setInterval(() => sendBack({ type: "validate" }), 1_000);
    }),
  },
}).createMachine({
  initial: "validating",
  context: {
    categoryWithProducts: [],
  },
  invoke: {
    src: "ticker",
  },
  entry: assign({
    cartRef: ({ spawn }) => spawn(cartMachine),
  }),
  on: {
    incrementQuantity: {
      actions: [sendTo(selectCart, ({ event: ev }) => ev)],
    },
    setQuantity: {
      actions: [sendTo(selectCart, ({ event: ev }) => ev)],
    },
    setId: {
      actions: [
        sendTo(selectCart, ({ event: ev }) => {
          return ev;
        }),
      ],
    },
    setCategoryWithProducts: {
      actions: [assign({ categoryWithProducts: ({ event: ev }) => ev.data })],
    },
    setTableServiceOrder: {
      actions: [assign({ tableServiceOrder: ({ event: ev }) => ev.data })],
    },
    reloadCart: {
      actions: [sendTo(selectCart, () => ({ type: "reloadCart" }))],
    },
  },
  states: {
    invalid: {
      on: {
        validate: { target: "validating" },
      },
    },
    validating: {
      entry: raise(() => ({ type: "validate" })),
      on: {
        validate: [
          {
            target: "ready",
            guard: ({ context: ctx }) => {
              if (!ctx.cartRef) return false;

              console.log("READY", ctx);

              const snap = ctx.cartRef.getSnapshot();
              if (!snap) return false;

              console.log("CARTSTATE", snap.value);

              return snap.matches("notEmpty");
            },
          },
          { target: "invalid" },
        ],
      },
    },
    ready: {
      on: {
        create: [
          {
            target: "creatingOrder",
            guard: function noOrder({ context }) {
              return !context.tableServiceOrder;
            },
          },
          {
            target: "creatingPayment",
            guard: function hasOrder({ context }) {
              return !!context.tableServiceOrder;
            },
          },
        ],
      },
    },
    failed: {
      on: { retry: { target: "ready" } },
    },
    creatingOrder: {
      entry: emit({ type: "creatingOrder" }),
      on: {
        next: {
          target: "creatingPayment",
          guard: function hasOrder({ context }) {
            return !!context.tableServiceOrder;
          },
        },
        error: { target: "failed" },
        cancel: { target: "ready" },
        validate: {
          target: "creatingPayment",
          guard: function hasOrder({ context }) {
            return !!context.tableServiceOrder;
          },
        },
      },
    },
    creatingPayment: {
      entry: emit({ type: "creatingPayment" }),
      on: {
        next: { target: "choosingPaymentMethod" },
        cancel: { target: "ready" },
        error: { target: "failure" },
      },
    },
    choosingPaymentMethod: {
      on: {
        next: { target: "paying" },
        cancel: { target: "validating" },
      },
    },
    paying: {
      on: {
        next: { target: "success" },
        cancel: { target: "ready" },
      },
    },
    success: {
      entry: emit({ type: "success" }),
      on: {
        reset: { target: "ready" },
      },
    },
    failure: {
      entry: emit({ type: "failure" }),
      on: {
        retry: { target: "ready" },
      },
    },
    empty: {},
  },
});

export type OnPayArgs = {};

export type OnCreateArgs = CreateTableServiceOrderRequest;

export type CartPageProps = React.PropsWithChildren & {
  menu: Query<Menu>;
  venue: Query<Venue>;
  tableServiceOrder?: Query<TableServiceOrder>;
  onCreate?: (args: OnCreateArgs) => Promise<void>;
  onCreatePayment?: () => Promise<void>;
  id?: string;
  area: string;
  tableNumber: number;
  paymentToken?: string;
};

export const CartPage: React.FC<CartPageProps> = ({
  menu,
  venue,
  onCreate = doNothing,
  onCreatePayment = doNothing,
  area,
  tableNumber,
  tableServiceOrder,
  paymentToken,
  id,
}) => {
  const history = useHistory();

  useHiddenTabBar();

  const [
    state,
    send,
    machine,
  ] = useMachine(cartPageMachine);

  const { waitFor } = useLoading();

  useEffect(() => {
    const sub = machine.on("creatingPayment", async function createPayment() {
      try {
        onCreatePayment();
      } catch {
        send({ type: "cancel" });
      }
    });

    return sub.unsubscribe;
  }, [machine, onCreatePayment]);

  useEffect(() => {
    if (state.matches("creatingPayment") && paymentToken) {
      send({ type: "next" });
    }
  }, [paymentToken]);

  useEffect(() => {
    const sub = machine.on("success", async function success() {
      if (!state.context.tableServiceOrder) {
        warn(
          "Unable to route to the receipt page since tableServiceOrder is undefined"
        );
        return;
      }

      history.push(
        Routes.receipt.route({
          tableServiceOrderId: state.context.tableServiceOrder.id,
          tableNumber,
          area,
          venueId: venue.type === "done" ? venue.data.id : "",
        })
      );
    });

    return sub.unsubscribe;
  }, [
    machine,
    history,
    state.context.tableServiceOrder,
    venue,
    area,
    tableNumber,
  ]);

  useEffect(() => {
    const sub = machine.on("creatingOrder", async function createOrder() {
      if (venue.type !== "done") {
        send({ type: "cancel" });
        return;
      }

      const cartSnap = state.context.cartRef?.getSnapshot();
      if (!cartSnap) {
        send({ type: "cancel" });
        return;
      }

      // TODO: This could optionally return the order and the assignment could happen
      //       in the end.
      try {
        onCreate({
          tableId: { area: area, tableNumber: tableNumber },
          venueId: venue.data.id,
          orderLines: cartSnap.context.cart
            .entries()
            .map(([productId, quantity]) => {
              return { productId: productId, quantity: quantity };
            }),
        });
        send({ type: "next" });
      } catch {
        send({ type: "cancel" });
      }
    });

    return sub.unsubscribe;
  }, [machine, venue]);

  const venueId = venue.type === "done" ? venue.data.id : "";

  useEffect(() => {
    send({
      type: "setId",
      data: venueId,
    });
  }, [venueId, send]);

  const cart = state.context.cartRef?.getSnapshot()?.context.cart;

  useEffect(() => {
    if (!tableServiceOrder || tableServiceOrder.type !== "done") {
      return;
    }

    if (tableServiceOrder.data.id === state.context.tableServiceOrder?.id) {
      return;
    }

    send({ type: "setTableServiceOrder", data: tableServiceOrder.data });
    send({ type: "validate" });
  }, [state.context.tableServiceOrder, send, tableServiceOrder]);

  useEffect(() => {
    if (menu.type === "done" && cart) {
      const productIds = cart.entries().map(([productId]) => {
        return productId;
      });
      const products = menu.data.products.filter((p) =>
        productIds.some((productId) => productId === p.id)
      );
      const merged: CategoryWithProducts[] = menu.data.categories
        .filter((c) =>
          products.some((p) => p.categories.some((pc) => pc === c.id))
        )
        .map((c) => ({
          ...c,
          products: products.filter((p) =>
            p.categories.some((pc) => pc === c.id)
          ),
        }));

      send({
        type: "setCategoryWithProducts",
        data: merged,
      });
    }
  }, [menu, cart, send]);

  useEffect(() => {
    send({ type: "validate" });
  }, [send]);

  useIonViewDidEnter(() => {
    send({ type: "reset" });
    send({ type: "reloadCart" });
  }, [venue, send]);

  useEffect(() => {
    send({ type: "reloadCart" });
  }, [venueId, send]);

  return (
    <IonPage>
      <IonContent>
        <div
          style={{
            maxWidth: "75ch",
            minHeight: "100%",
            height: "fit-content",
            margin: "auto",
          }}
        >
          <div style={{ width: "100%", padding: "3rem 0", margin: "auto" }}>
            {venue.type === "done" && (
              <PageCover>
                <LazyCoverImage
                  src={ImageAsset.findUriWithSizeOrDefault(
                    venue.data.coverAsset,
                    "16x9-w1024"
                  )}
                />
              </PageCover>
            )}
            <h1 style={{ color: "white" }}>Kurv</h1>
          </div>
          {cart && cart.empty() ? (
            <Card style={{ margin: "0 1rem" }}>
              <CardContent>
                <div className={css.EmptyCartContent}>
                  <div className={css.Title}>Ingen drikkevarer i kurven.</div>

                  <div className={css.Body}>
                    Det ser ud til at den er tom, gå tilbage og fyld den op med
                    drinks, så du kan få det leveret til bordet!
                  </div>
                  <OutlinedGradientButton
                    className={css.GradientButton}
                    onClick={() => {
                      if (venue.type !== "done") return;

                      history.push(
                        Routes.menu.route({
                          venueId: venue.data.id,
                          area,
                          tableNumber,
                        })
                      );
                    }}
                  >
                    Gå til menuen
                  </OutlinedGradientButton>
                </div>
              </CardContent>
            </Card>
          ) : (
            cart &&
            !cart.empty() && (
              <MenuContainer>
                <MenuContent
                  onSet={(productId, quantity) => {
                    send({
                      type: "setQuantity",
                      data: { productId, quantity },
                    });
                  }}
                  onAdd={(productId) => {
                    send({ type: "incrementQuantity", data: { productId } });
                  }}
                  cart={cart}
                  menuWithCategories={state.context.categoryWithProducts}
                />

                <div
                  style={{
                    borderTop: "1px dashed white",
                    padding: "0",
                    paddingTop: "1rem",
                    margin: "0",
                    position: "sticky",
                    paddingBottom: "5rem",
                    background: "var(--gm-color-alt-bg)",
                    bottom: "0",
                    left: 0,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <h3 style={{ margin: "0" }}>Total</h3>
                    <h3 style={{ margin: "0" }}>
                      {menu.type === "done" ? (
                        <Price
                          value={getTotal(menu.data.products, cart).total}
                        />
                      ) : (
                        <Price value={0} />
                      )}
                    </h3>
                  </div>
                </div>
              </MenuContainer>
            )
          )}
        </div>
      </IonContent>
      {cart && !cart.empty() && state.matches("ready") && (
        <FloatingActionBar
          type="gradient"
          style={{ maxWidth: "75ch", margin: "auto" }}
          onClick={() => {
            if (state.matches("failed")) {
              send({ type: "retry" });
            } else {
              send({ type: "validate" });
              send({ type: "create" });
            }
          }}
        >
          <FloatingActionTitle style={{ fontSize: "1.1rem" }}>
            Tryk for at betale
          </FloatingActionTitle>
        </FloatingActionBar>
      )}
      <IonModal
        isOpen={!!paymentToken && state.matches("choosingPaymentMethod")}
        style={{ "--background": "white" }}
        onDidDismiss={() => send({ type: "cancel" })}
        breakpoints={[0, 0.75]}
        initialBreakpoint={0.75}
      >
        {!!paymentToken && !!id && (
          <div style={{ padding: "2rem 1rem" }}>
            <Checkout
              token={paymentToken}
              onPay={() => send({ type: "next" })}
              onSuccess={() => send({ type: "next" })}
              onError={() => send({ type: "prev" })}
              redirectUrl={function generateRedirectUrl() {
                return Routes.cart.absoluteRoute({
                  id: id,
                  area: area,
                  tableNumber: tableNumber,
                  venueId: venueId,
                  intent: "check-payment",
                });
              }}
            />
          </div>
        )}
        {state.matches("paying") && <LoadingOverlay message="Betaler..." />}
      </IonModal>
      <Spinner
        reason={
          state.matches("creatingPayment")
            ? "Forbereder betaling..."
            : state.matches("creatingOrder")
            ? "Opretter ordrer..."
            : state.matches("choosingPaymentMethod")
            ? "Vælger betalingsmetode..."
            : state.matches("paying")
            && "Betaler..."
        }
      />
    </IonPage>
  );
};
