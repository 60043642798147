export class ArgumentError extends Error {
  constructor(
    public readonly arg: string,
    public readonly reason: string = ""
  ) {
    super(`${arg} ${reason !== "" ? reason : "is invalid"}`);
    this.name = "ArgumentError";
  }
}

export class ArrayEmptyError extends ArgumentError {
  constructor(arg: string) {
    super(arg, `'${arg}' is empty`);
    this.name = "ArrayEmptyError";
  }
}

export class ArgumentNullOrUndefinedError extends ArgumentError {
  constructor(arg: string) {
    super(arg, `is undefined or null`);
    this.name = "ArgumentNullOrUndefinedError";
  }
}

export class AggregateError extends Error {
  constructor(public readonly errors: (Error | false)[] = []) {
    super("Aggregated errors");
    this.errors = this.errors.filter((e) => !!e);
    this.name = "AggregateError";
  }

  get hasError() {
    return this.errors.length > 0;
  }
}

export class NotImplementedError extends Error {
  constructor() {
    super("Not implemented yet");
    this.name = "NotImplementedError";
  }
}
