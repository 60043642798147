export type Theme = {
    id: string,
    name: string, 
    iconUrl: string
}

export module Theme {
    export function createDefault(): Theme {
        return {
            id: "Placeholder",
            name: "Placeholder",
            iconUrl: "Placeholder"
        }
    }
}