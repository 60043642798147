import { IAuthentication } from "@greeter/auth";
import { useMemo } from "react";
import { GuestApi } from "./GuestApi";
import { Config } from "@greeter/config";

export function useGreeterGuestApi(
  config: Config,
  authentication: IAuthentication
) {
  return useMemo(
    () => new GuestApi(config, authentication),
    [authentication, config]
  );
}
