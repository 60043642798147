import React from "react";

import { ProfileHeader } from "./ProfileHeader";
import { Card, CardContent, CenteredContent } from "@greeter/matter";
import { ContinueButton } from "@greeter-guest/components/Button";
import { RequiredUserInfo } from "../UserPage";
import { doNothing, classNames } from "@greeter/util";

import css from "./ProfileSection.module.scss";

export type ProfileSectionProps = {
  isLoggedIn: boolean;
  user?: RequiredUserInfo;
  onCreateProfile?: () => void;
  onEditProfile?: () => void;
  onSignOut?: () => void;
};

export const ProfileSection: React.FC<ProfileSectionProps> = ({
  user,
  isLoggedIn,
  onCreateProfile = doNothing,
  onEditProfile = doNothing,
  onSignOut = doNothing,
}) => {
  return (
    <div className={css.ProfileSection}>
      <ProfileHeader name={user?.name ?? "Hemmeligt navn"} />
      {isLoggedIn ? (
        <Card>
          <CardContent {...classNames(css.CardSpacingUser, css.ProfileInfo)}>
            <div>
              Email: {user?.email ?? "..."}
              <br />
              Tlf: {user?.phoneNumber ?? "Mangler"}
            </div>
          </CardContent>
        </Card>
      ) : (
        <Card>
          <CardContent className={css.CardSpacingNoUser}>
            <div className={css.GreeterInfoWhite}>
              Du får den fulde Greeter <br /> oplevelse med en profil!
            </div>
            <div className={css.GreeterInfoHighlight}>
              Reservér og bestil bordpladser og drikkevarer på få sekunder. Det
              er satme nemt.
            </div>
          </CardContent>
        </Card>
      )}
      {isLoggedIn ? (
        <div className={css.ProfileActions}>
          <button
            {...classNames("EditProfileButton", css.EditProfileButton)}
            onClick={() => onEditProfile()}
          >
            Rediger profil
          </button>
          <button
            {...classNames("EditProfileButton", css.EditProfileButton)}
            onClick={onSignOut}
          >
            Log ud
          </button>
        </div>
      ) : (
        <CenteredContent>
          <ContinueButton
            className={css.CreateProfileButton}
            onClick={() => onCreateProfile()}
          >
            Login eller opret
          </ContinueButton>
        </CenteredContent>
      )}
    </div>
  );
};
