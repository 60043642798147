import React, { useEffect, useMemo, useState } from "react";

import { CupertinoPane } from "cupertino-pane";
import { BackdropModule, FitHeightModule } from "cupertino-pane/dist/modules";
import ReactDOM from "react-dom";
import { doNothing } from "@greeter/util";

let id = 0;

export const Cupertino = ({ children, open = false, onClose = doNothing }) => {
  const [pane, setPane] = useState<CupertinoPane>();
  const localId = useMemo(() => id, []);
  const el = useMemo(() => {
    const existingPane = document.querySelector("#cupertino-pane");

    if (existingPane) return existingPane;

    const el = document.createElement("div");
    el.id = `cupertino-pane`;
    el.style.padding = "2rem 1rem";
    el.style.setProperty("--cupertino-background", "black");
    return el;
  }, []);

  useEffect(() => {
    const root = document.querySelector("#root");
    root?.appendChild(el);
  }, [el]);

  useEffect(() => {
    const refitter = () =>
      setInterval(() => {
        pane.calcFitHeight(true);
      }, 1000);

    let refitterId = refitter();

    const dragStart = () => clearInterval(refitterId);
    const dragEnd = () => {
      refitterId = refitter();
    };

    const pane = new CupertinoPane(`#cupertino-pane`, {
      modules: [BackdropModule, FitHeightModule],
      freeMode: true,
      fitHeight: true,
      events: {
        onDragStart: dragStart,
        onDragEnd: dragEnd,
        onDidDismiss: onClose
      }
    });

    setPane(pane);
    
    return () => {
      clearInterval(refitterId);
    }
  }, [el, onClose]);

  useEffect(() => {
    if (!pane) return;

    if (open)  pane.present();
    if (!open) pane.hide();
  }, [open, pane]);

  return ReactDOM.createPortal(children, el);
};
