/* eslint-disable @typescript-eslint/prefer-namespace-keyword */
import { addSeconds, hoursToSeconds } from "date-fns";
import { DateFactory } from "./DateFactory";
import { DatePeriod } from "./DatePeriod";
import { withTimeOfDay } from "./DateUtil";
import { TimeOfDay } from "./TimeOfDay";

export type TimeOfDayPeriod = {
  from: TimeOfDay;
  to: TimeOfDay;
};

export module TimeOfDayPeriod {
  export function fromString(from: string, to: string): TimeOfDayPeriod {
    return {
      from: TimeOfDay.parse(from),
      to: TimeOfDay.parse(to),
    };
  }

  export function toDatePeriod(
    period: TimeOfDayPeriod,
    date: Date = DateFactory.create()
  ): DatePeriod {
    const start = withTimeOfDay(date, period.from);
    const end = period.to.isEarlierThan(period.from)
      ? addSeconds(withTimeOfDay(date, period.to), hoursToSeconds(24))
      : withTimeOfDay(date, period.to);

    return new DatePeriod({ from: start, to: end });
  }
}
