import { useDefaultGuestApi } from "@greeter-guest/api/api";
import { TableId } from "@greeter/core";
import {
  useTableServiceOrderQuery,
  useVenueQuery,
} from "@greeter/guest-api-hooks";
import { logger } from "@greeter/log";
import { UrlBuilder, getNumber, getString, getStringOr } from "@greeter/url";
import { Query, mapQuery } from "@greeter/util";
import { useSearchParams } from "@greeter/url";
import { useEffect, useMemo, useState } from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import { ReceiptPage } from "./ReceiptPage";
import Routes from "@greeter-guest/utility/Routes";
import { getConfig } from "@greeter-guest/utility/ConfigHooks";

const log = logger("[ReceiptPageApiHandler]");

const getTableId = (search: URLSearchParams): TableId | undefined => {
  const tableNumber = getNumber(search, "tableNumber");
  const area = getString(search, "area");

  if (!tableNumber || !area) return undefined;

  return { area: area, tableNumber: tableNumber };
};

type Params = {
  tableServiceOrderId: string;
};

export const ReceiptPageApiHandler = () => {
  const api = useDefaultGuestApi();
  const routeMatch = useRouteMatch<Params>(Routes.receipt.template());

  const searchParams = useSearchParams();
  const tableId = getTableId(searchParams);
  const venueId = getStringOr(searchParams, "venueId", "NONE");

  const config = getConfig();

  const tableServiceOrder = useTableServiceOrderQuery(
    api,
    routeMatch?.params.tableServiceOrderId ?? "",
    { refetchInterval: 2500 }
  );
  const venue = useVenueQuery(api, venueId);

  const simpleVenueQuery = useMemo(() => mapQuery(venue), [venue]);
  const simpleTableServiceOrderQuery = useMemo(
    () => mapQuery(tableServiceOrder),
    [tableServiceOrder]
  );

  const pdfQuery = useMemo<Query<URL>>(() => {
    if (simpleTableServiceOrderQuery.type !== "done")
      return { type: "loading" };

    return {
      type: "done",
      data: new URL(
        new UrlBuilder(new URL(config.apiUrl)).addPath("api").addPath("tableServiceOrders").addPath(simpleTableServiceOrderQuery.data.id).addPath("pdf").build()
      ),
    };
  }, [simpleTableServiceOrderQuery]);

  return tableId ? (
    <ReceiptPage
      tableServiceOrderPdf={pdfQuery}
      tableServiceOrder={simpleTableServiceOrderQuery}
      venue={simpleVenueQuery}
      tableId={tableId}
    />
  ) : (
    <div />
  );
};
