import { useLogin } from "@greeter-guest/contexts/LoginProvider";
import { useCustomerQuery } from "@greeter/guest-api-hooks";
import { useIonToast, useIonViewDidEnter, useIonViewWillEnter } from "@ionic/react";
import { useState } from "react";
import { UpdateUserPage, UpdateUserStep } from "./UpdateUserPage";
import { sendEmailVerification } from "firebase/auth";
import { partial } from "lodash";
import { useHistory } from "react-router-dom";
import { useDefaultFirebaseAuth } from "@greeter-guest/api/FirebaseHooks";
import { useDefaultGuestApi } from "@greeter-guest/api/api";
import { LoadingOverlay, Spinner, useAuth } from "@greeter/matter";
import { enableLogs } from "@greeter/log";

const log = partial(console.log, "[CompleteUserPageApiHandler]");

export type UpdateUserPageApiHandlerProps = {
  onBack?: () => void;
  onDone?: () => void;
};

export function UpdateUserPageApiHandler(props: UpdateUserPageApiHandlerProps) {
  const api = useDefaultGuestApi();
  const { isLoggedIn, isAnon, auth } = useAuth();

  const customerQuery = useCustomerQuery(api, {
    staleTime: Infinity,
    enabled: isLoggedIn,
  });
  const { refreshUser } = useLogin();

  const [step, setStep] = useState<UpdateUserStep>("update user");
  const [loading, setLoading] = useState(false);

  const [toast] = useIonToast();

  const history = useHistory();

  useIonViewWillEnter(() => {
    console.log(new Date().toISOString(), "ENTERED UPDATE USER PAGE");
    customerQuery.refetch();
  }, [customerQuery]);

  return (
    <>
      <UpdateUserPage
        customer={customerQuery.data}
        onUpdateUser={async (args) => {
          try {
            setLoading(true);

            // This should be renamed updateCustomer.
            await api.updateCustomer(args);

            // Update user properties so they match the customer properties.
            try {
              if (!isAnon) {
                if (args.email) {
                  await auth?.updateEmail(args.email);
                }

                if (args.name) {
                  await auth?.updateName(args.name);
                }
              }
            } catch(error) {
              console.warn("Failed to update firebase user with error.", error);
            }

            await refreshUser();
            await customerQuery.refetch();
            if (props.onDone) {
              props.onDone();
            } else {
              history.goBack();
            }
            log("Updated customer", customerQuery.data);
          } catch(error) {
            console.error("Failed to update with error", error);
            toast("Kunne ikke opdatere brugeren", 2000);
          } finally {
            setLoading(false);
          }
        }}
        step={step}
        onClose={() => {
          if (props.onBack) {
            props.onBack();
          } else {
            history.goBack();
          }
          setStep("update user");
        }}
      />
      <Spinner
        reason={
          loading
            ? "Opdaterer..."
            : customerQuery.isLoading && "Henter informationer..."
        }
      />
    </>
  );
}
