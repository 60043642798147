import React, { useMemo } from "react";
import { Card, CardContent } from "../Card";
import { BaseProps } from "../BaseProps";
import { Button } from "../Button";
import { useImage } from "../Hooks";
import { dateFormatter, TimeOfDay } from "@greeter/date";
import { Address, ImageAsset, Theme, Venue, WeeklyOpeningHours } from "@greeter/core";
import { IonIcon } from "@ionic/react";
import {
  locationOutline,
  musicalNotesOutline,
  shirtOutline,
  sunnyOutline,
} from "ionicons/icons";
import { DateFactory } from "@greeter/date";
import { doNothing, classNames } from "@greeter/util";
import { match } from "ts-pattern";
import { isToday, isTomorrow } from "date-fns";
import { ScrollableContent } from "../ScrollableContent";
import { CoverImage } from "../CoverImage";
import { LazyCoverImage } from "@greeter/matter";

import defaultUser from "../../assets/defaultUser.png";
import css from "./VenueDescriptionCard.module.css";

const TagText: React.FC<React.PropsWithChildren> = ({ ...props }) => (
  <div {...props} className={css.tagText} />
);
const HighlightText: React.FC<React.PropsWithChildren> = ({ ...props }) => (
  <span {...props} className={css.HighlighText} />
);
const WarningText: React.FC<React.PropsWithChildren> = ({ ...props }) => (
  <span {...props} className={css.WarningText} />
);
const FadeBox: React.FC<React.PropsWithChildren> = ({ ...props }) => (
  <div {...props} className={css.FadeBox} />
);
const TagContainer: React.FC<React.PropsWithChildren> = ({ ...props }) => (
  <div {...props} className={css.TagContainer} />
);
const DescriptionContainer: React.FC<React.PropsWithChildren> = ({ ...props }) => (
  <div {...props} className={css.DescriptionContainer} />
);
const Description: React.FC<React.PropsWithChildren> = ({ ...props }) => (
  <pre {...props} className={css.Description} />
);
const OpenStatus: React.FC<React.PropsWithChildren & {
  now?: Date;
  launchDate: Date;
  openingHours: WeeklyOpeningHours;
}> = ({ now = DateFactory.create(), launchDate, openingHours }) => {
  const openStatus = WeeklyOpeningHours.getOpenStatus(openingHours, now);

  return (
    <div {...classNames(css.OpenContainer, !openingHours ? css.Invisible : "")}>
      {launchDate > now ? (
        isToday(launchDate) ? (
          <WarningText>
            Idag kl. {TimeOfDay.fromDate(launchDate).toString(false)}
          </WarningText>
        ) : isTomorrow(launchDate) ? (
          <WarningText>
            I morgen kl. {TimeOfDay.fromDate(launchDate).toString(false)}
          </WarningText>
        ) : (
          <WarningText>
            Launch d. {dateFormatter.format(launchDate)}
          </WarningText>
        )
      ) : (
        match(openStatus)
          .with({ when: "now" }, () => <HighlightText>Åbent</HighlightText>)
          .with({ when: "today" }, ({ at }) => (
            <WarningText>åbner kl. {at.toString(false)}</WarningText>
          ))
          .with({ when: "tomorrow" }, ({ at }) => (
            <WarningText>åbner imorgen kl. {at.toString(false)}</WarningText>
          ))
          .with({ when: "future" }, ({ at }) => (
            <WarningText>åbner d. {dateFormatter.format(at)}</WarningText>
          ))
          .otherwise(() => <WarningText>Lukket</WarningText>)
      )}
    </div>
  );
};
const AddressText: React.FC<{ address: Address }> = ({ address }) => (
  <span className={css.Address}>{`${address.street}, ${address.city}`}</span>
);

type VenueDescriptionCardStyle = {
  content?: React.CSSProperties;
};

export type VenueDescriptionCardProps = BaseProps & React.PropsWithChildren & {
  now?: Date;
  venue: Venue;
  themes: Theme[];
  editButton?: boolean;
  styles?: VenueDescriptionCardStyle;
};

export const VenueDescriptionCard: React.FC<VenueDescriptionCardProps> = ({
  now = DateFactory.create(),
  venue,
  themes = [],
  editButton = false,
  className,
  style,
  styles,
}) => {
  const tags = useMemo(() => {
    const musicGenres = venue.musicGenres.map((m) => m.name);
    const themeTag = (
      <React.Fragment key={themes.map((theme) => theme.name).join(", ")}>
        <IonIcon icon={sunnyOutline} className={css.icon} />
        <TagText>{themes.map((theme) => theme.name).join(", ")}</TagText>
      </React.Fragment>
    );
    const dressCodeTag = (
      <React.Fragment key={venue.dressCode}>
        <IonIcon icon={shirtOutline} className={css.icon} />
        <TagText>
          {!venue.dressCode || (venue.dressCode && venue.dressCode === "none")
            ? "Ingen dressCode"
            : venue.dressCode}
        </TagText>
      </React.Fragment>
    );
    const musictag = (
      <React.Fragment key={musicGenres.join(", ")}>
        <IonIcon icon={musicalNotesOutline} className={css.icon} />
        <TagText>{musicGenres.join(", ")}</TagText>
      </React.Fragment>
    );

    return [
      themes.length > 0 && themeTag,
      dressCodeTag,
      musicGenres.length > 0 && musictag,
    ];
  }, [venue.dressCode, themes, venue.musicGenres]);

  const venueImage = useImage(ImageAsset.findUriWithSizeOrDefault(venue.logoAsset, "1x1-w256"), defaultUser);

  return (
    <div {...classNames(css.VenueDescriptionCard, className)} style={style}>
      <div className={css.Header}>
        <div className={css.Title}>
          <div className={css.VenueName}>{venue.name}</div>
          <div className={css.Location}>
            <IonIcon icon={locationOutline} className={css.LocationIcon} />
            <AddressText address={venue.address} />
          </div>
        </div>
        <div className={css.Logo}>
          <LazyCoverImage src={venueImage.src} />
        </div>
      </div>
      <Card className={editButton ? css.EditCard : ""} style={styles?.content}>
        <CardContent {...classNames(css.Content)}>
          <OpenStatus
            now={now}
            launchDate={venue.launchDate}
            openingHours={venue.openingHours}
          />
          <TagContainer>{tags}</TagContainer>
          <DescriptionContainer>
            <ScrollableContent>
              <Description>{venue.description}</Description>
            </ScrollableContent>
          </DescriptionContainer>
        </CardContent>
      </Card>
    </div>
  );
};
