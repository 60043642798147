import React, { CSSProperties, MutableRefObject, Ref } from "react";
import { classNames } from "@greeter/util";

import css from "./SwipableContainer.module.scss";

export type SwipableContainerProps = React.PropsWithChildren &{
  ref?: MutableRefObject<HTMLDivElement>;
  className?: string;
  style?: React.CSSProperties;
}

export const SwipableContainer: React.FC<SwipableContainerProps> = ({ ref, style, className, children }) => {
  return (
    <div
      ref={ref}
      style={style}
      {...classNames("SwipableContainer", css.SwipableContainer, className)}
    >
      {children}
    </div>
  );
};
