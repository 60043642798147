import { ZodTypeAny, z } from "zod";
import { reviveMap, reviveSet } from "./json";

export function JsonMapSchema<
  TKey extends ZodTypeAny,
  TValue extends ZodTypeAny
>(k: TKey, v: TValue) {
  return z
    .object({
      __type: z.literal("Map"),
      value: z.array(z.tuple([k, v])),
    })
    .transform((o) => reviveMap<z.infer<typeof k>, z.infer<typeof v>>(o));
}

export function JsonSetSchema<TValue extends ZodTypeAny>(v: TValue) {
  return z
    .object({
      __type: z.literal("Set"),
      value: z.array(v),
    })
    .transform((o) => reviveSet<z.infer<typeof v>>(o));
}
