import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Input } from "../Input";
import { HintedInput } from "../HintedInput";
import { doNothing } from "@greeter/util";

import { IonIcon } from "@ionic/react";
import { mailOutline } from "ionicons/icons";

import css from "./EmailInput.module.scss";

export type EmailInputProps = {
  id?: string;
  email?: string;
  onChange?: (value: string) => void;
  onValidChange?: (valid: boolean) => void;
  useHint?: boolean;
  hint?: string;
  inputIconClassName?: string;
};

// https://tools.ietf.org/html/rfc5322
// https://emailregex.com/
// const emailRegex =
//   /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;

const emailRegex =
  /([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)+/g;

export const isEmail = (email: string): boolean => {
  const match = email.match(emailRegex);
  return !!match;
};

export const useEmailInput = () => {
  const [email, setEmail] = useState("");

  const result = useMemo(() => {
    return { email, setEmail, onChange: setEmail };
  }, [email]);

  return result;
};

export const EmailInput: React.FC<EmailInputProps> = ({
  id,
  email = "",
  onChange = doNothing,
  onValidChange = doNothing,
  useHint = false,
  hint = "Indtast din email",
  inputIconClassName = "",
  ...props
}) => {
  const [valid, setValid] = useState(false);
  const placeholder = "Din email...";
  useEffect(() => {
    onChange(email);
    setValid(isEmail(email));
  }, [email, onChange]);
  useEffect(() => {
    onValidChange(valid);
  }, [onValidChange, valid]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value),
    [onChange]
  );

  return useHint ? (
    <HintedInput
      icon={<IonIcon icon={mailOutline} />}
      value={email}
      error={!valid}
      onChange={handleChange}
      type="email"
      placeholder={placeholder}
      className={css.EmailInputField}
      hint={hint}
    />
  ) : (
    <Input
      id={id}
      icon={<IonIcon icon={mailOutline} />}
      value={email}
      error={!valid}
      onChange={handleChange}
      type="email"
      placeholder={placeholder}
      className={css.EmailInputField}
      inputIconClassName={inputIconClassName}
      {...props}
    />
  );
};
