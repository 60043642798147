import React, { useEffect, useState } from "react";
import { FadeOutFadeIn } from "@greeter/matter";
import { classNames } from "@greeter/util";

import css from "./ConditionalRender.module.scss";

export type ConditionalRenderProps = React.PropsWithChildren & {
  shouldRender: boolean;
}

export const ConditionalRender: React.FC<ConditionalRenderProps> = ({
  shouldRender,
  children,
}) => {
  return (
    <FadeOutFadeIn
      {...classNames(!shouldRender ? css.Hide : "")}
      transitionKey={shouldRender}
    >
      {shouldRender && children}
    </FadeOutFadeIn>
  );
};
