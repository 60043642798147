import { dateStringToDate } from "@greeter/date";
import { OrderLine, OrderLineSchema } from "./OrderLine";
import { z } from "zod";

const OrderStatusArray = [
  "created",
  "accepted",
  "rejected",
  "pending",
  "cancelled",
  "refunded",
  "completed",
] as const;

export type OrderStatus = (typeof OrderStatusArray)[number];

export const OrderSchema = z.object({
  id: z.string(),
  createdAt: dateStringToDate,
  status: z.enum(OrderStatusArray),
  total: z.number(),
  venueId: z.string(),
  orderLines: z.array(OrderLineSchema),
  customerId: z.string(),
});
export type Order = z.infer<typeof OrderSchema>;

export const RejectedOrderShema = OrderSchema.extend({
  rejestionMessage: z.string(),
});
export type RejectedOrder = z.infer<typeof RejectedOrderShema>;

export module Order {
  export function is(self: Order, status: OrderStatus) {
    return self.status === status;
  }
}
