import React, { ChangeEvent } from "react";

import { IonIcon } from "@ionic/react";
import { keyOutline } from "ionicons/icons";
import { useMemo, useState } from "react";
import { Input } from "../Input";

import css from "./PasswordInput.module.scss";

export const usePasswordInput = () => {
  const [password, setPassword] = useState("");

  const result = useMemo(() => {
    return { password, setPassword, onChange: setPassword };
  }, [password]);

  return result;
};

export const PasswordInput: React.FC<
  {
    password: string;
    onChange: (password: string) => void;
    placeholder?: string;
    error?: boolean;
  } & Omit<React.HTMLProps<HTMLInputElement>, "value" | "onChange">
> = ({ onChange, password, placeholder = "Adgangskode...", ...props }) => {
  return (
    <Input
      className={css.InputField}
      icon={<IonIcon icon={keyOutline} />}
      placeholder={placeholder}
      type="password"
      value={password}
      onChange={(v: ChangeEvent<HTMLInputElement>) => onChange(v.target.value)}
      {...props}
    />
  );
};
