import css from "./EmptyOrderList.module.scss";

import React from "react";
import { Card, CardContent, OutlinedButton } from "@greeter/matter";
import { Link, useHistory } from "react-router-dom";
import Routes from "@greeter-guest/utility/Routes";

export const EmptyOrderList: React.FC = () => {
  const history = useHistory();
  return (
    <div className={css.EmptyOrderList}>
      <Card>
        <CardContent className={css.CardContent}>
          <div className={css.ContentHeader}>
            Dine kvitteringer
            <br />
            vil blive vist her!
          </div>
          <div className={css.ContentBody}>
            En god måde at få kvitteringer,
            <br />
            er at komme en tur i byen!
          </div>
        </CardContent>
      </Card>
      <div className={css.ButtonContainer}>
        <OutlinedButton
          onClick={() => history.push(Routes.greeterEvents.route())}
          className={css.GradientButton}
        >
          Se event
        </OutlinedButton>
        <OutlinedButton
          onClick={() => history.push(Routes.venues.route({}))}
          className={css.GradientButton}
        >
          Se venues
        </OutlinedButton>
      </div>
    </div>
  );
};
