import css from "./DateText.module.scss";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useTranslatedDay,
  useTranslatedMonth,
} from "@greeter-guest/utility/TranslationHooks";
import { capitalize } from "@greeter/util";

export type DateTextProps = {
  date?: Date;
};

export const DateText: React.FC<DateTextProps> = ({ date }) => {
  const readableDate = useReadableDate(date ?? new Date());
  // TODO: Internatilization
  return (
    <div className={css.DateText}>
      {!!date ? readableDate : "Venter på dato..."}
    </div>
  );
};

function useReadableDate(dateTime: Date) {
  const [t, i18] = useTranslation();
  const translatedMonth = useTranslatedMonth(dateTime.getMonth());
  const translatedDay = useTranslatedDay(dateTime.getDay());

  // TODO: Why doesn't DateTime.getTimeAsString() not work?
  const day = capitalize(translatedDay.slice(0, 3));
  const date = dateTime.getDate();
  const month = translatedMonth.slice(0, 3);
  const hours = `${dateTime.getHours()}`.padStart(2, "0");
  const minutes = `${dateTime.getMinutes()}`.padStart(2, "0");
  const timeOfDay = `${hours}:${minutes}`;

  return `${day}. d. ${date}. ${month}. kl. ${timeOfDay}`;
}
