import { Container } from "@greeter/matter";
import React from "react";

const PrivacyPage: React.FC = () => {
  return (
    <Container>
      <div style={{ textAlign: "left" }}>
        <h1>COOKIE- OG PRIVATLIVSPOLITIK HOS GREETER APS</h1>
        <h3>Introduktion</h3>
        <p>
          Når du besøger vores website bliver der indsamlet oplysninger om dig,
          som bliver brugt til at tilpasse og forbedre vores indhold og til at
          skabe mere værdi hos de annoncer, der vises på siden. Hvis du ikke
          ønsker, at der bliver indsamlet oplysninger om dig, bør du slette dine
          cookies og undlade videre brug af hjemmesiden. I de nedenstående
          afsnit har vi uddybet, hvad det er for informationer, der indsamles,
          deres formål og hvilke tredjeparter, der har adgang til dem.
        </p>
        <h3>Cookies</h3>
        <p>
          Hjemmesiden anvender "cookies", som er en tekstfil, der gemmes på din
          computer, mobil el. tilsvarende med formålet at genkende den, huske
          indstillinger, udføre statistik og målrette annoncer. Cookies kan dog
          ikke indeholde skadelig kode som fx. virus.
        </p>
        <p>
          Det er muligt for dig at slette eller blokere for cookies. Det kan du
          gøre ved at følge denne vejledning:{" "}
          <a href="http://minecookies.org/cookiehandtering">
            cookiehaandtering
          </a>
        </p>
        <p>
          Hvis du følger vejledningen og sletter eller blokerer cookies, så vil
          de annoncer du ser kun blive mindre relevante for dig, mens de vil
          optræde hyppigere. Du kan samtidig også risikere, at hjemmesiden ikke
          fungerer optimalt, samt at der er indhold, som du ikke kan få adgang
          til.
        </p>
        <p>
          Websitet indeholder cookies fra tredjeparter, der i varierende omfang
          kan omfatte:
        </p>
        <ul>
          <li>Google</li>
          <li>Facebook</li>
        </ul>
        <h1>PERSONOPLYSNINGER</h1>
        <h3>Generelt</h3>
        <p>
          Personoplysninger er alle former for informationer, som i et eller
          andet omfang kan henføres til dig. Når du benytter vores website, så
          indsamler og behandler vi en række af sådanne informationer. Det sker
          f.eks. ved helt almindelige interaktioner på hjemmesiden som fx at du
          tilmelder dig vores nyhedsbrev, deltager i en
          konkurrence/undersøgelse, registrere dig som ny bruger/abonnent eller
          ved øvrig brug af services eller køb via vores hjemmeside.
        </p>
        <p>
          Vi indsamler og behandler typisk følgende slags oplysninger: Et unikt
          ID og tekniske oplysninger om din computer, tablet eller mobiltelefon,
          dit IP-nummer, geografisk placering, samt hvilke sider du klikker på.
          I det omfang du selv giver eksplicit samtykke og selv indtaster
          informationerne, behandler vi desuden: Navn, telefonnummer, e-mail,
          adresse og betalingsoplysninger. Det sker typisk i forbindelse med, at
          du opretter et login eller ved køb på siden.
        </p>
        <h3>Sikkerhed</h3>
        <p>
          Vi behandler dine personoplysninger sikkert og fortroligt i
          overensstemmelse med gældende lovgivning, herunder
          persondataforordningen og databeskyttelsesloven.
        </p>
        <p>
          Dine oplysninger vil alene blive anvendt til det formål, de er
          indsamlet til og vil blive slettet, når dette formål er opfyldt eller
          ikke længere er relevant.
        </p>
        <p>
          Vi har truffet tekniske og organisatoriske foranstaltninger mod, at
          dine oplysninger hændeligt eller ulovligt bliver slettet,
          offentliggjort, fortabt, forringet eller kommer til uvedkommendes
          kendskab, misbruges eller i øvrigt behandles i strid med lovgivningen.
        </p>
        <h3>Formål</h3>
        Oplysningerne bruges til at identificere dig som bruger og vise dig de
        annoncer, som vil have mest relevans for dig, at registrere dine køb og
        betalinger, samt at kunne levere dig de services, du har efterspurgt,
        som fx at modtage nyhedsbreve. Derudover anvender vi oplysningerne til
        at optimere og forbedre vores services og indhold. Websitet indeholder
        cookies fra tredjeparter, der i varierende omfang kan omfatte:
        <h3>Periode for opbevaring</h3>
        <p>
          Vi opbevarer oplysninger i det tidsrum, som er tilladt i henhold til
          lovgivningen, og vi sletter dem, når de ikke længere er nødvendige
          eller relevante. Perioden afhænger af, hvilken karakter oplysningen er
          og baggrunden for opbevaring. Det er derfor ikke muligt at give en
          generel tidsramme for, hvornår informationer slettes.
        </p>
        <h3>Videregivelse af oplysninger</h3>
        <p>
          Data om din brug af websitet, hvilke annoncer du modtager og evt.
          klikker på, geografisk placering, køn og alderssegment m.v.
          videregives til tredjeparter i det omfang oplysningerne er kendt, og
          såfremt vi benytter os af tredjeparter. Du kan se hvilke tredjeparter,
          der er tale om, i afsnittet om Cookies ovenfor. Oplysningerne bliver
          anvendt til målrettet annoncering.
        </p>
        <p>
          Vi benytter derudover en række tredjeparter til opbevaring og
          behandling af data. Disse behandler udelukkende oplysninger på vegne
          af os og må ikke anvende dem til egne formål.
        </p>
        <p>
          Videregivelse af personoplysninger som navn og e-mail m.m. vil kun
          ske, hvis du selv giver samtykke til det. Vi anvender kun
          databehandlere i EU eller i lande, der kan give dine oplysninger en
          tilstrækkelig beskyttelse.
        </p>
        <h3>Indsigt og klager</h3>
        <p>
          Du har ret til at få oplyst, hvilke personoplysninger, vi behandler om
          dig i et almindeligt format (dataportabilitet). Du kan desuden til
          enhver tid gøre indsigelse mod, at oplysningerne anvendes. Du kan også
          tilbagekalde dit samtykke til, at der bliver behandlet oplysninger om
          dig. Hvis de oplysninger, der behandles om dig, er forkerte har du ret
          til, at de bliver rettet eller slettet. Henvendelse herom kan ske til:
          Christoffer@greeter.dk. Hvis du vil klage over vores behandling af
          dine personoplysninger, har du også mulighed for at tage kontakt til
          Datatilsynet.
        </p>
        <p>
          Ønsker du ikke længere, at vi skal behandle dine personoplysninger,
          eller at vi skal begrænse behandlingen af dine personoplysninger, kan
          du også sende os en anmodning herom til ovennævnte e-mailadresse.
        </p>
        <h3>Udgiver</h3>
        <p>Websitet ejes og publiceres af:</p>
        <p>Greeter ApS</p>
        <p>Jyllandsgade 10, 2. 1.</p>
        <p>Aalborg 9000</p>
        <p>Telefon: 29252826</p>
        <p>E-mail: Christoffer@greeter.dk</p>
      </div>
    </Container>
  );
};

export default PrivacyPage;
