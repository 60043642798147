import { z, ZodAny, ZodTypeAny } from "zod";
import { DatePeriod, DayOfWeek, TimeOfDay, convertToDay } from "@greeter/date";
import {
  Booking,
  BookingSchema,
  CustomerSchema,
  OrderLineSchema,
  ProductSchema,
  SpecialPricePeriodSchema,
  TableIdSchema,
  WeeklyOpeningHours,
} from "@greeter/core";
import { createBookingMachine } from "./CreateBookingMachine";
import { SnapshotFrom } from "xstate";
import { ReceiptLineSchema } from "@greeter/matter";
import { JsonMapSchema, JsonSetSchema } from "@greeter/json";

const dateStringToDate = z
  .string()
  .datetime()
  .transform((s) => new Date(s));
export const DatePeriodSchema = z
  .object({
    from: dateStringToDate,
    to: dateStringToDate,
  })
  .transform((dp) => new DatePeriod(dp));

export const SpecialOpeningHoursSchema = z.object({
  id: z.string(),
  period: DatePeriodSchema,
});

export const OpeningHoursSchema = z.object({
  from: z.string().transform((s) => TimeOfDay.parse(s)),
  to: z.string().transform((s) => TimeOfDay.parse(s)),
  isOpen: z.boolean(),
});
export type OpeningHoursSchema = z.infer<typeof OpeningHoursSchema>;

function toWeeklyOpeningHours(obj: [string, any][]) {
  const map = new WeeklyOpeningHours();
  for (const [k, v] of obj) {
    map.set(convertToDay(k as DayOfWeek), v);
  }
  return map;
}

function toBookingsByFromDate(o: any) {
  const m = new Map<string, Booking>();

  if (!o) {
    return m;
  }

  for (const [key, v] of Object.entries(o)) {
    if (key && v) {
      m.set(key, BookingSchema.parse(v));
    } else {
      console.error("Why is key and v null|undefined?", key, v);
    }
  }
  return m;
}

export const VenueSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string(),
  address: z.any(),
  logoUrl: z.string(),
  logoAsset: z.any(),
  coverUrl: z.string(),
  coverAsset: z.any(),
  cvr: z.string(),
  verified: z.boolean(),
  dressCode: z.string(),
  launchDate: z
    .string()
    .datetime()
    .transform((s) => new Date(s)),
  openingHours: z
    .array(z.tuple([z.string(), OpeningHoursSchema]))
    .transform(toWeeklyOpeningHours),
  arrivalOpeningHours: z
    .array(z.tuple([z.string(), OpeningHoursSchema]))
    .transform(toWeeklyOpeningHours),
  bookableOpeningHours: z
    .array(z.tuple([z.string(), OpeningHoursSchema]))
    .transform(toWeeklyOpeningHours),
  musicGenres: z.array(z.any()),
  themes: z.array(z.any()),
});

/**
 * Note: This is not suited to serialize over the web as the format is different.
 */
export const CreateBookingContextSchema = z.object({
  loginStatus: z.string(),
  comment: z.string(),
  customer: z.optional(CustomerSchema),

  booking: z.optional(BookingSchema),
  bookings: z.array(BookingSchema).default([]),
  bookingsByOpeningHoursFromDate: JsonMapSchema(z.string(), BookingSchema),

  product: z.optional(ProductSchema),
  productsById: z.optional(JsonMapSchema(z.string(), ProductSchema)),
  products: z.optional(z.array(ProductSchema)),

  specialPricePeriods: z.array(SpecialPricePeriodSchema),
  orderLines: z.array(ReceiptLineSchema),
  orderTotal: z.number(),
  minTotal: z.number(),

  /**
   * Holds the product ids we've recently notified about.
   *
   * This is to prevent spamming notifications when we've already notified the user
   * about a price change.
   */
  lastProductPriceChangesDetected: JsonSetSchema(z.string()),

  openingHour: z.optional(DatePeriodSchema),
  openingHours: z.array(DatePeriodSchema).default([]),

  arrivalDate: z.optional(dateStringToDate),
  arrivalTime: z.optional(dateStringToDate),
  arrivalTimes: z.array(dateStringToDate),
  specialOpeningHours: z.array(SpecialOpeningHoursSchema),
  selectedTables: z.array(TableIdSchema),
  groupSize: z.optional(z.number()),
  bookingBuffer: z.optional(z.string().transform((s) => TimeOfDay.parse(s))),
  now: dateStringToDate,
  ticks: z.number(),
  venue: z.optional(VenueSchema),
});
export type CreateBookingContext = z.infer<typeof CreateBookingContextSchema>;

export const CreateBookingSnapshotSchema = z.object({
  children: z.any(),
  context: CreateBookingContextSchema,
  historyValue: z.any(),
  status: z.string(),
  value: z.string(),
});
export type CreateBookingSnapshotSchema = z.infer<
  typeof CreateBookingSnapshotSchema
>;

type AssertEqual<T, U extends T> = U;
type Test = AssertEqual<
  CreateBookingSnapshotSchema,
  SnapshotFrom<typeof createBookingMachine>
>;
