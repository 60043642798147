import { OrderLine, Product } from "@greeter/core";
import { Deserializer } from "./Deserializer";
import { IDeserializer } from "./IDeserializer";
import { ProductDeserializer } from "./ProductDeserializer";

export class OrderLineDeserializer extends Deserializer<OrderLine> {
  constructor(
    private productDeserializer: IDeserializer<Product> = new ProductDeserializer()
  ) {
    super();
  }

  override deserialize(json: any): OrderLine {
    return {
      ...(json as OrderLine),
      product: this.productDeserializer.deserialize(json.product),
    } as OrderLine;
  }
}
