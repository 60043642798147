import React from "react";

import defaultUserImage from "@greeter-guest/assets/defaultUser.png";
import { ReactComponent as Plus } from "@greeter-guest/assets/plus.svg";
import DefaultUser from "@greeter-guest/assets/defaultUser.png";
import { classNames } from "@greeter/util";
import { LazyImage } from "@greeter/matter";

import css from "./ProfileHeader.module.scss";

export type ProfileHeaderProps = {
  name?: string;
  profilePicture?: string;
};

export const ProfileHeader: React.FC<ProfileHeaderProps> = ({
  name,
  profilePicture = DefaultUser,
}) => {
  return (
    <div className={css.ProfileHeader}>
      {name ? (
        <div className={css.Name}>
          {name.split(" ").length > 0 ? name.split(" ")[0] : name}
        </div>
      ) : (
        <div {...classNames(css.DefaultName, css.Name)}>
          Nååh, du er den hemmelige type!
        </div>
      )}
      <div className={css.ProfilePictureContainer}>
        <LazyImage
          className={css.PictureStyle}
          src={profilePicture}
          defaultImage={defaultUserImage}
          alt="Profilbillede"
        />
        {/* TODO: Readd when we can add profile pictures */}
        {/* <button className={css.AddPictureIcon}>
          <Plus
            className={css.PlusIcon}
            onClick={() => {
              console.log("Skift profilbillede");
            }}
          ></Plus>
        </button> */}
      </div>
    </div>
  );
};
