import React, { useCallback, useEffect, useMemo, useState } from "react";
import Content from "@greeter-guest/components/Content";
import { PartyPage } from "@greeter-guest/components/PartyPage";
import { doNothing, doNothingAsync } from "@greeter/util";
import { UpdateUserInfoView } from "./UpdateUserInfoView/UpdateUserInfoView";
import { ConfirmEmailView } from "./ConfirmEmailView/ConfirmEmailView";
import { FadeOutFadeIn } from "@greeter/matter";
import { DoneView } from "./DoneView/DoneView";
import {
  Message,
  Notify,
  useNotifications,
} from "@greeter-guest/contexts/NotificationProvider";
import { SendVerificationLink, UpdateUser } from "@greeter/api";
import { partial } from "lodash";

import { match } from "ts-pattern";
import { Container } from "@greeter/matter";
import { Customer, PhoneNumber } from "@greeter/core";
import { IonIcon, useIonViewDidEnter } from "@ionic/react";
import { trashOutline } from "ionicons/icons";

import { useHistory } from "react-router-dom";
import Routes from "@greeter-guest/utility/Routes";
import { useDefaultFirebaseAuth } from "@greeter-guest/api/FirebaseHooks";

import css from "./UpdateUserPage.module.scss";

export type UpdateUserPageProps = ListenerProps & {
  step?: UpdateUserStep;
  customer?: Customer;
  notify?: Notify;
};

type ListenerProps = React.PropsWithChildren & {
  onUpdateUser?: UpdateUser;
  onSendVerificationLink?: SendVerificationLink;
  onClose?: () => void;
};

export type UpdateUserStep = "update user" | "confirm email" | "done";

const log = partial(console.log, "[CompleteUserPage]");

const emptyCustomer: Customer = {
  id: "",
  name: "",
  email: "",
  phoneNumber: PhoneNumber.create()
};

export function UpdateUserPage({
  step = "update user",
  onUpdateUser = doNothingAsync,
  onSendVerificationLink = doNothingAsync,
  onClose = doNothing,
  customer,
  ...props
}: UpdateUserPageProps) {
  const { notify } = useNotifications();

  const [_customer, setCustomer] = useState<Customer>(
    customer ?? emptyCustomer
  );

  const [acceptedPrivacy, setAcceptedPrivacy] = useState(
    !!customer?.acceptedPrivacyPolicyAt
  );
  const [acceptedTerms, setAcceptedTerms] = useState(
    !!customer?.acceptedTermsOfServiceAt
  );

  useEffect(() => {
    setCustomer(customer ?? emptyCustomer);
  }, [customer]);

  const setEmail = useCallback(
    function setEmail(email: string) {
      return setCustomer((_customer) => ({ ..._customer, email: email }));
    },
    []
  );
  const setName = useCallback(
    function setName(name: string) {
      return setCustomer((_customer) => ({ ..._customer, name: name }));
    },
    []
  );
  const setPhoneNumber = useCallback(
    function setPhoneNumber(phoneNumber: PhoneNumber) {
      return setCustomer((_customer) => ({
        ..._customer,
        phoneNumber: phoneNumber,
      }));
    },
    []
  );

  const allSteps: UpdateUserStep[] = useMemo(
    () => ["update user", "confirm email", "done"],
    []
  );
  const stepIndex = useMemo(
    () => allSteps.findIndex((s) => s === step) + 1,
    [step, allSteps]
  );

  const history = useHistory();

  return (
    <PartyPage>
      <Content>
        <Container className={css.Container}>
          {step === "update user" ? (
            <div
              style={{
                display: "grid",
                placeItems: "end",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  gap: "0.5rem",
                  // color: "red",
                }}
                onClick={() => {
                  history.push(
                    Routes.deleteUser.route({
                      redirectUrl: Routes.home.route(),
                    })
                  );
                }}
              >
                Slet din bruger?
                <IonIcon icon={trashOutline} />
              </div>
            </div>
          ) : (
            <div />
          )}
          <FadeOutFadeIn transitionKey={stepIndex}>
            {step === "update user" ? (
              <UpdateUserInfoView
                onContinue={async () => {
                  try {
                    await onUpdateUser({
                      email: _customer.email,
                      phoneNumber: PhoneNumber.toString(_customer.phoneNumber),
                      name: _customer.name,
                      acceptedPrivacyPolicy: true,
                      acceptedTermsOfService: true,
                    });
                  } catch (e) {
                    notify(
                      new Message(
                        "Et eller andet gik galt",
                        "Tjek om de indtastede oplysninger er korrekte"
                      )
                    );
                  }
                }}
                onNotNow={onClose}
                onEmailChange={setEmail}
                onNameChange={setName}
                email={_customer.email}
                name={_customer.name}
                onAcceptedTermsChange={setAcceptedTerms}
                acceptedTerms={acceptedTerms}
                onAcceptedPrivacyPolicyChange={setAcceptedPrivacy}
                acceptedPrivacyPolicy={acceptedPrivacy}
                onPhoneNumberChange={setPhoneNumber}
                phoneNumber={_customer.phoneNumber}
              />
            ) : step === "confirm email" ? (
              <ConfirmEmailView
                onSendVerificationLink={onSendVerificationLink}
              />
            ) : step === "done" ? (
              <DoneView />
            ) : (
              <h1>That step doesn't exist</h1>
            )}
          </FadeOutFadeIn>
        </Container>
      </Content>
    </PartyPage>
  );
};
