import React from "react";
import { classNames } from "@greeter/util";

import css from "./Skeleton.module.scss";

export type SkeletonProps = React.HTMLAttributes<HTMLDivElement> & {
  pulse?: boolean;
};

export const Skeleton: React.FC<SkeletonProps> = React.forwardRef<
  HTMLDivElement,
  SkeletonProps
>(({ children, style, className, pulse = false, ...props }, ref) => {
  return (
    <div
      ref={ref}
      style={{
        ...(pulse && {
          background:
            "linear-gradient(-90deg, #b4b4b4 0%, #fcfcfc 50%, #b4b4b4 100%)",
          backgroundSize: "400% 400%",
        }),
        ...style,
      }}
      {...classNames(className, pulse && css.Pulse, css.Skeleton)}
      {...props}
    >
      {children}
    </div>
  );
});
