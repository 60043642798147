type SerializedMap<TKey, TValue> = {
  __type: "Map";
  value: Array<[TKey, TValue]>;
};

type SerializedSet<TValue> = { __type: "Set"; value: Array<TValue> };

/**
 * Adds support for maps, introducing a metadata prop called __type
 * which described that it's a Map
 */
export function replacer(_key: any, value: any) {
  if (value instanceof Map) {
    return {
      __type: "Map",
      value: Array.from(value.entries()), // or with spread: value: [...value]
    };
  } else if (value instanceof Set) {
    return {
      __type: "Set",
      value: Array.from(value.values()),
    };
  } else {
    return value;
  }
}

/**
 * Deserializes the same thing with support for Set and Map.
 */
export function reviver(_key: any, value: any) {
  if (typeof value === "object" && value !== null && value.__type === "Map") {
    if (value.__type === "Map") {
      new Map(value.value);
    } else if (value.__type === "Set") {
      return new Set(value);
    }
  }
  return value;
}

export function reviveMap<K, T>(o: SerializedMap<K, T>): Map<K, T> {
  if (o.__type === "Map" && Array.isArray(o.value)) {
    return new Map(o.value);
  }

  throw new Error("Could not parse Map");
}

export function reviveSet<T>(o: SerializedSet<T>): Set<T> {
  if (o.__type === "Set" && Array.isArray(o.value)) {
    return new Set(o.value);
  }

  throw new Error("Could not parse Set");
}

export function parsify(s: string): any {
  return JSON.parse(s, reviver);
}

/**
 * Adds support for Maps and Set using a __type to signify hard to serialize stuff.
 */
export function jsonify(obj: any, settings?: { indent?: number }): string {
  return JSON.stringify(obj, replacer, settings?.indent);
}
