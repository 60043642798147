export interface ISubscribable {
  subscribe(func: () => (Promise<void> | void)): () => void;
}

export interface ITransportable<T> {
  subscribe(func: (t: T) => void): () => void;
}

type Subscriber = () => (Promise<void> | void);

export class Signal implements ISubscribable {
  private subscribers: Set<Subscriber> = new Set<Subscriber>();

  subscribe(func: Subscriber): () => void {
    this.subscribers.add(func);

    return () => this.subscribers.delete(func);
  }

  async emit(): Promise<void> {
    const subs = [...this.subscribers.values()];
    for (let i = 0; i < subs.length; i++) {
      const sub = subs[i];
      await sub();
    }
  }
}

export class Transport<T> implements ITransportable<T> {
  private subscribers: Set<(t: T) => void>;

  constructor() {
    this.subscribers = new Set();
  }

  subscribe(func: (t: T) => void): () => void {
    this.subscribers.add(func);

    return () => this.subscribers.delete(func);
  }

  emit(t: T) {
    this.subscribers.forEach((s) => s(t));
  }
}

export type Event = {
  event: string;
  data: unknown;
};

type EventKey<T extends Event> = string & T["event"];

export type Handle = () => void;

// NOTE: Kept for notes and remembering why we use the Type conditional
// export type EventHandler<T extends Event> = (ev: T) => void;
// as you can see a conditional type is being used by the EventHandler
// this results in a great developer ergonomic and experience since
// it allows us to implement a handler with the following signature
// (ev: ChangedEvent) => void instead of (ev: EventUnion) => void
// Which would result in a function body something like this
// function (ev: EventUnion) {
//   if (ev.type === "changed") {
//     // Do something
//   }
// }
// instead we get
// function (ev: SpecificEvent) {
//   // Do something.
// }
// since Typescript can infer and validate which type of event it is
export type EventHandler<T> = T extends Event ? (ev: T) => void : unknown;

export interface IEventEmitter<T extends Event> {
  on<K extends EventKey<T>>(e: K, listener: EventHandler<T>): Handle;
  off<K extends EventKey<T>>(e: K, listener: EventHandler<T>): void;
  emit(ev: T): void;
}

export class EventEmitter<T extends Event> implements IEventEmitter<T> {
  private listeners = new Map<T["event"], EventHandler<T>[]>();

  on(e: T["event"], listener: EventHandler<T>): Handle {
    const existingListeners = this.listeners.get(e);

    if (existingListeners) {
      existingListeners.push(listener);
    } else {
      this.listeners.set(e, [listener]);
    }

    return () => this.off(e, listener);
  }

  off(e: T["event"], listener: EventHandler<T>): void {
    const listeners = this.listeners.get(e);
    if (!listeners) return;

    this.listeners.set(
      e,
      listeners.filter((l) => l !== listener)
    );
  }

  emit(ev: T) {
    const listeners = this.listeners.get(ev.event);
    if (!listeners) return;

    for (const l of listeners) {
      l(ev);
    }
  }
}

export function createEmitter<T extends Event>() {
  return new EventEmitter<T>();
}

