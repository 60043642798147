import { classNames } from "@greeter/util";
import React, { useEffect, useState } from "react";
import css from "./GroupSizePicker.module.scss";

export interface GroupSizePickerProps {
  onChange: (groupSize: number) => void;
  // size?: number;
  value?: number;
}

export const GroupSizePicker: React.FC<GroupSizePickerProps> = ({
  onChange,
  value,
  ...props
}) => {
  const [otherSize, setOtherSize] = useState<string>();
  const [placeholderText, setPlaceholderText] = useState<string>("Flere...");
  const [otherFocus, setOtherFocus] = useState(false);

  useEffect(() => {
    if (!value) onChange(2); // Initialize the thing
  }, []);

  const handleClick = (groupSize: number) => () => {
    onChange(groupSize);
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (otherSize) onChange(Number(otherSize));

    setOtherFocus(true);
    setPlaceholderText("");
  };

  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setOtherFocus(false);
    if (placeholderText === "") {
      setPlaceholderText("Flere...");
    }
  };

  const handleOtherSizeSelect = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const otherSize = event.currentTarget.value;
    setOtherSize(otherSize);

    if (otherSize !== undefined) {
      onChange(Number(otherSize));
    }
  };

  const arraySize = 14;

  return (
    <div className={css.GroupSizePickerContainer} {...props}>
      <div className={css.GroupSizePicker}>
        {Array.from(Array(arraySize), (_, i) => i + 2).map((groupSize) => {
          return (
            <button
              key={groupSize}
              {...classNames(
                css.GroupSizePickerButton,
                !!value && value === groupSize && !otherFocus && css.Active,
                "groupsize",
              )}
              onClick={handleClick(groupSize)}
            >
              {groupSize}
            </button>
          );
        })}
        <input
          {...classNames(
            css.OtherSizePickerButton,
            otherFocus && css.Active,
            value === Number(otherSize) && css.Active
          )}
          onFocus={handleFocus}
          onBlur={handleOnBlur}
          placeholder={placeholderText}
          onInput={handleOtherSizeSelect}
          value={otherSize}
          inputMode="numeric"
          type="number"
          max="999"
        />
      </div>
    </div>
  );
};
