import { FirebaseApp, getApp, initializeApp } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  getAuth,
  browserLocalPersistence,
  initializeAuth,
  signInAnonymously,
  Auth,
  signInWithCredential,
  FacebookAuthProvider,
  OAuthProvider,
  onAuthStateChanged,
  signOut,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  getIdToken,
} from "firebase/auth";
import { AuthChangedEvent, FirebaseAuthEvents, RedirectEvent } from "./events";
import { EventEmitter, Handle } from "@greeter/event";
import { IAuthentication, SignInMethod, UserInfo } from "./IAuthentication";
import { logger, warner } from "@greeter/log";
import { Capacitor } from "@capacitor/core";
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import { handleFirebase, mapSignInMethods } from "./utils";

const logId = "[Auth]" + "[NativeFirebaseAuth]";
const log = logger(logId);
const warn = warner(logId);

function mapUser(user: any | null | undefined): UserInfo | undefined {
  if (!user) return undefined;
  return {
    id: user.id ?? "",
    isAnon: user.isAnonymous ?? false,
    name: user.displayName ?? "",
    email: user.email ?? "",
  };
}

export class NativeFirebaseAuth implements IAuthentication {
  private emitter = new EventEmitter<FirebaseAuthEvents>();
  private auth: Auth;

  constructor(private firebaseConfig: any) {
    // TODO: Remove. Kept for now in case things break.
    // NOTE: This is temporary and is used to for current
    //       available login methods for email
    //       Otherwise we have to build backend to check
    //       what login methods are available for the email
    let app: FirebaseApp;
    try {
      log("Getting app");
      app = getApp();
    } catch {
      log("App not initialized. Initializing the app");
      app = initializeApp(firebaseConfig);
    }

    this.auth = Capacitor.isNativePlatform()
      ? initializeAuth(app, {
          persistence: browserLocalPersistence,
        })
      : getAuth(app);

    onAuthStateChanged(this.auth, (user) => {
      log(
        "Auth changed for web! Emitting auth state change to external listeners"
      );
      this.emitter.emit({
        event: "authStateChanged",
        data: mapUser(user),
      });
    });

    FirebaseAuthentication.addListener("authStateChange", ({ user }) => {
      log(
        "Auth changed for native! Emitting auth state change to external listeners"
      );
      this.emitter.emit({
        event: "authStateChanged",
        data: mapUser(user),
      });
    });
  }

  async updateEmail(email: string): Promise<UserInfo> {
    await FirebaseAuthentication.updateEmail({ newEmail: email });
    return mapUser(this.auth.currentUser)!;
  }

  async updateName(name: string): Promise<UserInfo> {
    await FirebaseAuthentication.updateProfile({ displayName: name });
    return mapUser(this.auth.currentUser)!;
  }

  async refreshToken(): Promise<string> {
    return (await FirebaseAuthentication.getIdToken({ forceRefresh: true })).token;
  }

  onAuthReady(listener: (d: Date) => void): Handle {
    // We don't have a way to check on the native side. Simply trigger right away.
    listener(new Date());
    return () => {};
  }

  onRedirect(listener: (userInfo: UserInfo | undefined) => void): Handle {
    warn("Not available for native apps...");
    return this.emitter.on("redirect", (ev: RedirectEvent) => {
      listener(ev.data);
    });
  }

  async signInAsAnon(): Promise<void> {
    return await handleFirebase(async () => {
      warn("not signing in natively. Using web.");
      await signInAnonymously(getAuth());
    });
  }

  async fetchSignInMethods(email: string): Promise<SignInMethod[]> {
    return await handleFirebase(async () => {
      const methods = await fetchSignInMethodsForEmail(getAuth(), email);

      const signMethods: (SignInMethod | undefined)[] =
        methods.map(mapSignInMethods);

      return signMethods.filter((m) => !!m) as SignInMethod[];
    });
  }

  async createUserWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<void> {
    return await handleFirebase(async () => {
      await createUserWithEmailAndPassword(this.auth, email, password);
      await FirebaseAuthentication.signInWithEmailAndPassword({
        email,
        password,
      });
    });
  }

  async getUser(): Promise<UserInfo | undefined> {
    return await handleFirebase(async () => mapUser(this.auth.currentUser));
  }

  async signInWithGoogle(): Promise<void> {
    return await handleFirebase(async () => {
      const result = await FirebaseAuthentication.signInWithGoogle({
        scopes: ["profile", "email"],
      });

      if (!result.credential?.accessToken) return;

      const creds = GoogleAuthProvider.credential(
        result.credential.accessToken
      );
      await signInWithCredential(this.auth, creds);
    });
  }

  async signInWithApple(): Promise<void> {
    return await handleFirebase(async () => {
      const result = await FirebaseAuthentication.signInWithApple({
        scopes: ["name", "email"],
      });

      if (!result.credential?.accessToken || !result.credential.nonce) return;
      const creds = new OAuthProvider("apple.com").credential({
        accessToken: result.credential.accessToken,
        rawNonce: result.credential.nonce,
      });
      await signInWithCredential(this.auth, creds);
    });
  }

  onAuthChange(listener: (user: UserInfo | undefined) => void): Handle {
    const l = (ev: AuthChangedEvent) => {
      listener(ev.data);
    };

    return this.emitter.on("authStateChanged", l);
  }

  async signInWithFacebook(): Promise<void> {
    return await handleFirebase(async () => {
      const result = await FirebaseAuthentication.signInWithFacebook({
        scopes: ["public_profile", "email"],
      });

      if (!result.credential?.accessToken) return;
      const creds = FacebookAuthProvider.credential(
        result.credential?.accessToken
      );
      await signInWithCredential(this.auth, creds);
    });
  }

  async logout(): Promise<void> {
    return await handleFirebase(async () => {
      await FirebaseAuthentication.signOut();
      await signOut(this.auth);
    });
  }

  async getAuthToken(): Promise<string> {
    return await handleFirebase(async () => {
      if (!this.auth.currentUser) return "";

      return await getIdToken(this.auth.currentUser);
    });
  }

  async signInWithEmailAndPassword(email: string, password: string) {
    return await handleFirebase(async () => {
      await FirebaseAuthentication.signInWithEmailAndPassword({
        email,
        password,
      });

      await signInWithEmailAndPassword(this.auth, email, password);
    });
  }
}
