export type Price = {
    name: string,
    value: number,
    symbol: string
}

export module Price {
    export function createDefault(): Price {
        return {
            name: "Danske Kroner",
            value: 0,
            symbol: "DKK"
        }
    }
}