import React from "react";

import css from "./Toolbar.module.scss";

type NavbarProps = {
  leftSlot?: React.ReactNode;
  rightSlot?: React.ReactNode;
}

export const Toolbar: React.FC<NavbarProps> = ({leftSlot, rightSlot}) => {
  return (
    <>
      <div className={css.LeftSlot}>{leftSlot}</div>
      <div className={css.RightSlot}>{rightSlot}</div>
    </>
  );
}
