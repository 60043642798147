import css from "./ClickableBar.module.scss";

import React from "react";

export type ClickableBarProps = React.PropsWithChildren & {
  title?: string;
  subtitle?: string;
  onClick: () => void;
  style?: React.CSSProperties;
};

export const ClickableBar: React.FC<ClickableBarProps> = ({
  title = "",
  subtitle = "",
  onClick,
  style,
  children,
  ...props
}) => {
  return (
    <div
      onMouseDown={onClick}
      className={css.ClickableBar}
      style={style}
      {...props}
    >
      {children ? (
        children
      ) : (
        <div className={css.ClickableBarText}>
          <div><b>{title}</b></div>
          <div className="sm-text">{subtitle}</div>
        </div>
      )}
    </div>
  );
};
