import { DatePeriod } from "@greeter/date";

export type SpecialOpeningHours = {
  id: string,
  period: DatePeriod;
}

export module SpecialOpeningHours {
  export function findOpen(specialOpeningHours: SpecialOpeningHours[], date: Date): SpecialOpeningHours | undefined {
    for (let i = 0, length = specialOpeningHours.length; i < length; i++) {
      const soh = specialOpeningHours[i];
      if (date >= soh.period.from && date <= soh.period.to) {
        return soh;
      }
    }
    return undefined;
  }

  export function isOpen(specialOpeningHours: SpecialOpeningHours[], date: Date): boolean {
    for (let i = 0, length = specialOpeningHours.length; i < length; i++) {
      const soh = specialOpeningHours[i];
      if (date >= soh.period.from && date <= soh.period.to) {
        return true;
      }
    }

    return false;
  }
}
