import { z } from "zod";
import { PhoneNumber } from "./PhoneNumber";

export const CustomerSchema = z.object({
  id: z.string(),
  name: z.string(),
  email: z.string(),
  phoneNumber: z.union([
    z.string().transform((s) => PhoneNumber.parse(s)),
    z
      .object({ prefix: z.string(), number: z.string() })
      .transform((s) => s as PhoneNumber),
  ]),
  acceptedTermsOfServiceAt: z.nullable(
    z.optional(z.string().transform((s) => (s ? new Date(s) : null)))
  ),
  acceptedPrivacyPolicyAt: z.nullable(
    z.optional(z.string().transform((s) => (s ? new Date(s) : null)))
  ),
});
export type Customer = z.infer<typeof CustomerSchema>;

export module CustomerUtil {
  export function isComplete(customer?: Customer): boolean {
    return (
      !!customer &&
      !!customer.email &&
      !!customer.name &&
      !!customer.phoneNumber &&
      !!customer.acceptedPrivacyPolicyAt &&
      !!customer.acceptedTermsOfServiceAt
    );
  }
}
