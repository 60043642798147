import { toZonedTime } from "date-fns-tz";

type DateConstructor = string | number | undefined | Date;

/**
 * A factory for dates.
 * It simply mimics the constructor for dates.
 * Should be used instead of the mockable new Date(Date.now()) trick.
 */
class DateFactory {
  static create(date?: DateConstructor) {
    return date ? new Date(date) : new Date(Date.now());
  }
  static createUtc(date?: DateConstructor) {
    const localNow = date ? new Date(date) : new Date();
    const utcNow = Date.UTC(
      localNow.getUTCFullYear(),
      localNow.getUTCMonth(),
      localNow.getUTCDate(),
      localNow.getUTCHours(),
      localNow.getUTCMinutes(),
      localNow.getUTCSeconds(),
      localNow.getUTCMilliseconds()
    );

    return utcNow;
  }

  static zone(date: DateConstructor, timeZone: string) {
    return toZonedTime(DateFactory.createUtc(date), timeZone);
  }

  static createZonedNow(timeZone: string) {
    return DateFactory.zone(DateFactory.createUtc(), timeZone);
  }
}

export { DateFactory };
