import React from "react";

// import CoverImage from "@greeter-guest/assets/profile_cover.jpg";
import coverImage from "../../assets/profile_cover.jpg";

import { Booking, RejectedBooking, Venue } from "@greeter/core";
import { classNames, doNothing, isEmpty } from "@greeter/util";
import { EmptyOrderList } from "./EmptyOrderlist/EmptyOrderList";
import { ProfileSection } from "./ProfileSection";

import { IonPage, IonContent } from "@ionic/react";
import { BookingHistory } from "./BookingHistory";
import { Container, LazyCoverImage, PageCover } from "@greeter/matter";

import css from "./UserPage.module.scss";

export type VenueBooking = {
  venue?: Venue;
  booking: Booking | RejectedBooking;
};

export type RequiredUserInfo = {
  email: string;
  phoneNumber: string;
  name: string;
};

export type OptionalUserInfo = {
  birthday?: string;
  profilePicture?: string;
  username?: string;
  age?: string;
  city?: string;
};

type UserInfo = RequiredUserInfo & OptionalUserInfo;

export type UserPageProps = {
  isLoggedIn: boolean;
  user: RequiredUserInfo;
  bookings: Booking[];
  venues: Venue[];
  onCreateProfile?: () => void;
  onEditProfile?: () => void;
  onSignOut?: () => void;
};

export const UserPage: React.FC<UserPageProps> = ({
  isLoggedIn,
  user,
  bookings,
  venues,
  onCreateProfile = doNothing,
  onEditProfile = doNothing,
  onSignOut = doNothing,
}) => {
  return (
    <IonPage>
      <IonContent>
        <PageCover>
          <LazyCoverImage fade blur src={coverImage} />
        </PageCover>
        <Container {...classNames(css.Container, css.UserPage)}>
          <ProfileSection
            isLoggedIn={isLoggedIn}
            user={user}
            onCreateProfile={onCreateProfile}
            onEditProfile={onEditProfile}
            onSignOut={onSignOut}
          />
          {!isLoggedIn || isEmpty(bookings) ? (
            <EmptyOrderList />
          ) : (
            <BookingHistory venues={venues} bookings={bookings} />
          )}
        </Container>
      </IonContent>
    </IonPage>
  );
};
