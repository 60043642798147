import { entries } from "@greeter/util";

export function getNumber(params: URLSearchParams, key: string) {
  const val = params.get(key);
  if (val === null) return undefined;

  return parseInt(val);
}

export function getNumberOr(
  params: URLSearchParams,
  key: string,
  or: number
) {
  return getNumber(params, key) ?? or;
}

export function getDate(search: URLSearchParams, key: string): Date | undefined {
  const item = search.get(key);

  if (!item) return undefined;

  return new Date(Date.parse(item));
}

export function getBoolean(params: URLSearchParams, key: string, or = false) {
  const val = params.get(key);

  if (val === null) return or;

  switch(val) {
    case "true":
      return true;
    default:
      return false;
  }
}

export function getString(params: URLSearchParams, key: string) {
  const val = params.get(key);

  if (val === null) {
    return undefined;
  }

  return val;
}

export function getUrl(params: URLSearchParams, key: string) {
  const val = params.get(key);

  if (val === null)
    return undefined;

  return decodeURIComponent(decodeURIComponent(val));
}

export function getStringOr(params: URLSearchParams, key: string, or: string) {
  return getString(params, key) ?? or;
}

export function toSearchParams<T>(queryObj: T): URLSearchParams {
  const params = new URLSearchParams();

  if (!queryObj) return params;

  for (const [key, val] of entries(queryObj)) {
    if (!val || typeof val === "function" || typeof val === "symbol") continue;

    if (val instanceof Date) {
      params.append(key, val.toISOString());
    } else if (typeof val === "object" && !Array.isArray(val)) {
      params.append(key, JSON.stringify(val));
    } else if (Array.isArray(val)) {
      val.forEach((v) => {
        params.append(
          `${key}`,
          `${typeof v === "object" ? JSON.stringify(v) : v}`
        );
      });
    } else {
      params.append(key, `${val}`);
    }
  }

  return params;
}

export function queries<T>(queryObj?: T) {
  const result = toSearchParams(queryObj).toString();
  return result === "" ? result : `?${result}`;
}

export function paths(...paths: any[]) {
  const path: string[] = [];

  for (let i = 0, l = paths.length; i < l; i++) {
    const part = paths[i];
    if (!part) continue;

    const curr = `${paths[i]}`.trim();

    if (curr.startsWith("/") && curr.endsWith("/")) {
      path.push(curr.slice(1, -1));
    } else if (curr.endsWith("/")) {
      path.push(curr.slice(0, -1));
    } else if (curr.startsWith("/")) {
      path.push(curr.slice(1));
    } else {
      path.push(curr);
    }
  }

  return path.join("/");
}
