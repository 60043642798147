export enum Day {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}


export module Day {
  export function addDays(day: Day, addition: number): Day {
    return ((day + addition) % 7) as Day;
  }

  export function yesterday(day: Day) {
    return addDays(day, -1) as Day;
  }

  export function tomorrow(day: Day) {
    return addDays(day, 1) as Day;
  }
}
