import { chunk } from "lodash";

export type PhoneNumber = {
  prefix: string;
  number: string;
};

// Follows the format https://en.wikipedia.org/wiki/E.164
export module PhoneNumber {
  export function toString(phoneNumber: PhoneNumber) {
    return `${phoneNumber?.prefix ?? ""} ${phoneNumber?.number ?? ""}`;
  }

  const defaultPrefix = "+45";
  export function parse(phoneNumber: string) {
    try {
      if (!phoneNumber) return PhoneNumber.create();

      // const re =
      //   /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/g;
      const re = /([+0-9]{3})[ ]?([0-9 ]+)/;
      const match = phoneNumber.match(re);

      if (match) {
        const prefix = match[1] ?? defaultPrefix;
        const number = match[2] ?? "";
        return { prefix, number };
      }

      return { prefix: defaultPrefix, number: "" };
    } catch(error) {
      console.warn(`Could not parse ${phoneNumber} with error ${error}, defaulting to empty phonenumber.`);
      return PhoneNumber.create();
    }
  }

  export function create() {
    return {
      prefix: defaultPrefix,
      number: "",
    };
  }

  const notPhoneNumberRegex = /[^0-9()+-]+/g;

  const defaultFormatOptions: FormatOptions = {
    space: true,
  };

  const formatters = (prefix: string) => {
    switch (prefix) {
      case "+47":
      case "+46":
      case "+45":
      default:
        return (
          number: string,
          opts: FormatOptions = defaultFormatOptions
        ): string => {
          let _number = number.replace(notPhoneNumberRegex, "");

          if (opts?.space) {
            const chunks = chunk(_number, 2);
            _number = chunks.map((c) => c.join("")).join(" ");
          }

          return _number;
        };
    }
  };

  export type FormatResult = {
    prefix: string;
    value: string;
  };

  export type FormatOptions = {
    strict?: boolean;
    space?: boolean;
  };

  /**
   * We ditched the regex, since it is almost always confusing to read
   * and replaced it with a simple for loop.
   */
  export function format(
    prefix: string,
    number: string,
    opts?: FormatOptions
  ): PhoneNumber {
    if (prefix === "+00") {
      prefix = defaultPrefix;
    }

    const format = formatters(prefix);

    return { prefix, number: format(number, opts) };
  }

  export function valid(prefix: string, number: string): boolean {
    try {
      const isDanishPrefix = prefix === "+45";
      const danishNumberSize = 8;
      const normalizedNumber = number.replace(/ /g, "");
      const matchesDanishNumber = normalizedNumber.match(/^\d{8}$/g);

      return isDanishPrefix && !!matchesDanishNumber;
    } catch {
      return false;
    }
  }
}
