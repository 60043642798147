import { IonContent, IonLoading, useIonViewDidEnter } from "@ionic/react";
import React, { useCallback, useMemo, useState } from "react";

import { PartyPage } from "@greeter-guest/components/PartyPage";

import { GridArea } from "@greeter-guest/components/GridArea";
import { Title } from "@greeter-guest/components/Title";
import { Description } from "@greeter-guest/components/Description";
import { OutlinedButton, useAuth } from "@greeter/matter";
import { useSearchParams } from "@greeter/url";
import { BottomButtonBar } from "../LoginPage/BottomButtonBar";
import { ContinueButton } from "@greeter-guest/components/Button";
import { useHistory } from "react-router-dom";
import { useEmailInput } from "libs/matter/src/lib/EmailInput/EmailInput";
import { useDefaultGuestApi } from "@greeter-guest/api/api";
import { logger } from "@greeter/log";
import Routes from "@greeter-guest/utility/Routes";

import css from "./DeleteUserPage.module.scss";

const log = logger("[DeleteUserPage]");

export const DeleteUserPage: React.FC = () => {
  const searchQueries = useSearchParams();

  const history = useHistory();

  const redirectUrl = useMemo(
    () => searchQueries.get("redirectUrl"),
    [searchQueries]
  );

  const emailInput = useEmailInput();

  const [updating, setUpdating] = useState(false);

  // const auth = useDefaultFirebaseAuth();
  const { auth } = useAuth();

  const { refresh } = useAuth();

  const api = useDefaultGuestApi();

  const redirect = useCallback(() => {
    if (redirectUrl) history.push(redirectUrl);
    else if (history.length > 0) history.goBack();
    else history.push(Routes.home.route());
  }, [history, redirectUrl]);

  const onCancel = useCallback(() => {
    redirect();
  }, [redirect]);

  const onConfirm = useCallback(async () => {
    if (!auth) return;

    setUpdating(true);

    try {
      const user = await auth.getUser();

      if (user?.email) {
        await api.deleteUser();
        auth.logout();
        log("Logged out and deleted", JSON.stringify(user));
        log("Refreshing...");
        await refresh();
        redirect();
      }
    } finally {
      setUpdating(false);
    }
  }, [api, redirect, refresh, auth]);

  useIonViewDidEnter(() => {
    (async () => {
      const user = await auth?.getUser();
      if (user && user.email) {
        emailInput.setEmail(user.email);
      }
    })();
  }, [auth]);

  return (
    <PartyPage>
      <IonContent>
        <div className={css.DeleteUserPage}>
          <IonLoading isOpen={updating} />
          <GridArea area="title">
            <Title>Slet din bruger.</Title>
            <Description>Bekræft at du gerne vil slette din konto</Description>
          </GridArea>

          <div
            style={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}
          >
            <BottomButtonBar>
              <ContinueButton
                onClick={onConfirm}
                style={{ background: "var(--gm-color-danger)" }}
              >
                Ja, slet den
              </ContinueButton>
              <OutlinedButton onClick={onCancel}>Annuller</OutlinedButton>
            </BottomButtonBar>
          </div>
        </div>
      </IonContent>
    </PartyPage>
  );
};
