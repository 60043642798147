import React from "react";

import {
  CardHeader,
  Card,
  CardContent,
  useLazyImage,
  Skeleton,
  LazyCoverImage,
  CardTitle,
} from "@greeter/matter";
import { ImageAsset, Product, Venue } from "@greeter/core";
import { useHistory } from "react-router-dom";
import Routes, { CreateBookingV2Route, Tab } from "@greeter-guest/utility/Routes";
import { classNames } from "@greeter/util";

import css from "./TablePackageCard.module.scss";
import { useIonRouter } from "@ionic/react";

export type TablePackageCardProps = {
  className?: string;
  product: Product;
  venue: Venue;
};

export const TablePackageCard: React.FC<TablePackageCardProps> = ({
  className,
  product,
  venue,
}) => {
  const history = useHistory();
  const router = useIonRouter();

  return (
    <Card
      {...classNames(css.Card, className)}
      onClick={() =>
        history.push(
          new CreateBookingV2Route(router.routeInfo.tab as Tab).route({
            venueId: venue.id,
            productId: product.id,
          })
        )
      }
    >
      <CardHeader style={{ aspectRatio: "4/3" }}>
        <LazyCoverImage src={product.coverAsset ? ImageAsset.findUriWithSizeOrDefault(product.coverAsset, "4x3-w512") : product.coverUrl} />
      </CardHeader>
      <CardContent className={css.CardContent}>
        <CardTitle size="sm" className={css.CardTitle}>
          {product.title}
        </CardTitle>
        {/*<div className={css.CardTitle}>{product.title}</div>*/}
        {/*<div className={css.CardSubtitle}>{product.subTitle}</div>*/}
        <hr />
        <div className={css.Footer}>
          <div className={css.Price}>{product.price} kr.</div>
        </div>
      </CardContent>
    </Card>
  );
};
