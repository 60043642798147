import { addDays, addHours, isAfter, isBefore, isSameDay } from "date-fns";
import { DateFactory } from "@greeter/date";
import { ImageAsset } from "./ImageAsset";

export type GreeterEvent = {
  id: string;
  name: string;
  description: string;
  startsAt: Date;
  endsAt: Date;
  entryPrice: number;
  dressCode: string;
  coverUrl: string;
  coverAsset: ImageAsset;
  venueId: string;
};

export enum When {
  Ended,
  InProgress,
  Today,
  Tomorrow,
  InTheFuture,
}

export module GreeterEvent {
  // NOTE:
  // We split it like this to keep the data and the functionality separated for purity
  // A classic problem with Typescript is the instantiation of a class that doesn't have the right
  // prototype which leads to hard to track down bugs where you're hunting why the method
  // "isToday" is not defined. By splitting it like this we never have this problem
  //
  // We can now safely transform data and simply attach the functionality when we need it.
  export function createDefault(): GreeterEvent {
    return {
      id: "Placeholder",
      name: "Placeholder",
      description: "Placeholder",
      startsAt: DateFactory.create(0),
      endsAt: DateFactory.create(0),
      entryPrice: 100,
      dressCode: "Placeholder",
      coverUrl: "Placeholder",
      coverAsset: { name: "", id: "", uri: "", path: "", root: "", sizes: [] },
      venueId: "",
    };
  }

  export function isToday(self: GreeterEvent, hourOffset: number = 0): boolean {
    // Logically we need to hourly offset which can be thought of in +hours. We need an offset of 5 hours or can be thought of as we need the day to start at 5.
    // The calculation itself requires the value to be negative.
    // This is because the offset simply subtracts the hours from the startDatetime
    // which then keeps the date as today even though the real date is tomorrow.
    // We're essentially setting the timezone to -05:00.
    // It can be though of as ISODATETIME+0200-0500 where 0200 is our current timezone in Copenhagen and -0500 is our offset.
    hourOffset = -hourOffset;
    const now = addHours(DateFactory.create(), hourOffset);
    const startDatetime = addHours(
      DateFactory.create(self.startsAt),
      hourOffset
    );
    return isSameDay(now, startDatetime);
  }

  export function isTomorrow(
    self: GreeterEvent,
    hourOffset: number = 0
  ): boolean {
    // See comment in this.isToday(...)
    hourOffset = -hourOffset;

    const tomorrow = addDays(addHours(DateFactory.create(), hourOffset), 1);
    const startDatetime = addHours(self.startsAt, hourOffset);
    return isSameDay(tomorrow, startDatetime);
  }

  export function isInProgress(self: GreeterEvent): boolean {
    const now = DateFactory.create();
    const isInProgress =
      isAfter(now, self.startsAt) && isBefore(now, self.endsAt);
    return isInProgress;
  }

  export function hasEnded(self: GreeterEvent): boolean {
    const now = DateFactory.create();
    const hasEnded = isAfter(now, self.endsAt);
    return hasEnded;
  }

  export function isInFuture(self: GreeterEvent): boolean {
    const now = DateFactory.create();
    return self.startsAt.getTime() > now.getTime();
  }

  export function figureOutWhen(self: GreeterEvent, offset: number = 0): When {
    let when = When.InTheFuture;

    if (isToday(self, offset) && !hasEnded(self)) {
      when = isInProgress(self) ? When.InProgress : When.Today;
    } else if (isInProgress(self)) {
      when = When.InProgress;
    } else if (isTomorrow(self, offset)) {
      when = When.Tomorrow;
    } else if (hasEnded(self)) {
      when = When.Ended;
    } else {
      when = When.InTheFuture;
    }
    return when;
  }
}
