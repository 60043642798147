import { IDeserializer } from "./IDeserializer";
import {
  Address,
  DailyOpeningHours,
  MusicGenre,
  Theme,
  Venue,
  WeeklyOpeningHours,
} from "@greeter/core";
import {
  convertToDay,
  DateFactory,
  TimeOfDay,
} from "@greeter/date";
import { Deserializer } from "./Deserializer";
import { ThemeDeserializer } from "./ThemeDeserializer";

export class VenueDeserializer extends Deserializer<Venue> {
  constructor(
    private themeDeserializer: IDeserializer<Theme> = new ThemeDeserializer()
  ) {
    super();
  }

  private createTimePeriodFromJson(timePeriod: any): DailyOpeningHours {
    // We dont care for timezones atm. It is assumed that opening hours are always local
    return {
      from: TimeOfDay.parse(timePeriod.from),
      to: TimeOfDay.parse(timePeriod.to),
      isOpen: timePeriod?.isOpen ?? false,
    };
  }

  private createTimePeriodMapFromJson(timePeriods: any): WeeklyOpeningHours {
    return timePeriods
      ? Object.entries(timePeriods).reduce(
        (acc, [day, timePeriod]: any) =>
          acc.set(
            convertToDay(day),
            this.createTimePeriodFromJson(timePeriod)
          ),
        new WeeklyOpeningHours()
      )
      : new WeeklyOpeningHours();
  }

  override deserialize(json: any) {
    const weeklyOpeningHours = this.createTimePeriodMapFromJson(
      json.weeklyOpeningHours
    );
    const weeklyArrivalOpeningHours = this.createTimePeriodMapFromJson(
      json.weeklyArrivalOpeningHours
    );

    const bookableOpeningHours = this.createTimePeriodMapFromJson(json.bookableOpeningHours);
    const venue: Venue = {
      ...json,
      address: json.address as Address,
      launchDate: DateFactory.create(json.launchDate),
      openingHours: weeklyOpeningHours,
      arrivalOpeningHours: weeklyArrivalOpeningHours,
      bookableOpeningHours: bookableOpeningHours,
      musicGenres: json.musicGenres as MusicGenre[],
      themes: this.themeDeserializer.deserializeAll(json.themes),
    };
    return venue;
  }
}
