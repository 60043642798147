import React, { useEffect, useMemo, useState } from "react";
import { GreeterEvent, DaysliceConfig, Venue, When, ImageAsset } from "@greeter/core";
import Routes, { GreeterEventV2Route, Tab } from "@greeter-guest/utility/Routes";
import { GreenLabel, YellowLabel } from "@greeter/matter";
import { BigContentCard } from "@greeter-guest/components/BigContentCard";
import { useHistory } from "react-router-dom";
import {
  toPrettyDateString,
  toPrettyTimeString,
} from "@greeter-guest/utility/DateUtil";
import { classNames } from "@greeter/util";

import css from "./BigGreeterEventCard.module.scss";
import { useIonRouter } from "@ionic/react";

export type BigGreeterEventCardProps = React.PropsWithChildren &  {
  greeterEvent: GreeterEvent;
  venue?: Venue;
  daysliceConfig: DaysliceConfig;
};

export const BigGreeterEventCard: React.FC<BigGreeterEventCardProps> = ({
  greeterEvent,
  venue,
  daysliceConfig,
}) => {
  const history = useHistory();

  const { startsAt, endsAt } = greeterEvent;
  const when = useMemo(
    () => GreeterEvent.figureOutWhen(greeterEvent, daysliceConfig.dayOffset),
    [greeterEvent, daysliceConfig.dayOffset]
  );

  const label = useMemo(() => {
    const labels = {
      [When.InProgress]: (
        <GreenLabel
          className={css.Label}
          text={`Igang, slutter kl. ${toPrettyTimeString(endsAt)}`}
        />
      ),
      [When.Today]: (
        <GreenLabel
          className={css.Label}
          text={`Idag, kl. ${toPrettyTimeString(startsAt)}`}
        />
      ),
      [When.Tomorrow]: (
        <YellowLabel
          className={css.Label}
          text={`I morgen, Kl. ${toPrettyTimeString(startsAt)}`}
        />
      ),
    };

    return (
      labels[when] ?? (
        <YellowLabel
          className={css.Label}
          text={`${toPrettyDateString(startsAt)}, kl. ${toPrettyTimeString(
            startsAt
          )}`}
        />
      )
    );
  }, [when, startsAt, endsAt]);

  const uri = ImageAsset.findUriWithSizeOrDefault(greeterEvent.coverAsset, "16x9-w1024");
  const router = useIonRouter();

  return (
    <BigContentCard
      onClick={() =>
        history.push(
          new GreeterEventV2Route(router.routeInfo.tab as Tab).route({ greeterEventId: greeterEvent.id })
        )
      }
      className={css.BigGreeterEventCard}
      title={greeterEvent.name}
      description={venue?.name ?? ""}
      cover={uri}
      venue={venue}
      label={label}
    />
  );
};
