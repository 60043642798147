import { IonAlert } from "@ionic/react";
import React, { useCallback, useContext, useState } from "react";

type Notify = (msg: Message) => void;

class Message {
  constructor(
    public header: string = "Fejl",
    public message: string = "Et eller andet gik galt. Prøv igen senere"
  ) {}
}

interface Params {
  messages: Message[];
  notify: (msg: Message) => void;
}

const initialValues: Params = {
  messages: [],
  notify: () => {
    return;
  },
};

const NotificationContext = React.createContext(initialValues);

const NotificationProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const notify = useCallback<Notify>(
    (msg: Message) => {
      setMessages([...messages, msg]);
    },
    [messages]
  );

  const wrappedMessages = messages.map((m, i) => (
    <IonAlert
      key={i}
      isOpen
      backdropDismiss
      animated
      {...m}
      buttons={["OK"]}
      onDidDismiss={() => setMessages(messages.filter((fm) => m != fm))}
    />
  ));

  return (
    <NotificationContext.Provider value={{ messages, notify }}>
      {children}
      {wrappedMessages}
    </NotificationContext.Provider>
  );
};

const useNotifications = () => useContext(NotificationContext);

export default NotificationProvider;
export { Message, useNotifications };
export type { Notify };
