import css from "./OutlinedButton.module.scss";

import React from "react";
import { classNames } from "@greeter/util";
import { Button, ButtonProps } from "../Button";

export type OutlinedButtonProps = ButtonProps;

export const OutlinedButton: React.FC<OutlinedButtonProps> = ({
  className,
  ...props
}) => {
  return <Button {...props} {...classNames(className, css.OutlinedButton)} />;
};
