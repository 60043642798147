import React, { useEffect, useMemo } from "react";

import wineOutline from "../../assets/icons/wine-outline.svg";

import { Venue } from "@greeter/core";
import { UnorderedListComponent } from "@greeter-guest/components/UnorderedListComponent";
import { VenueCard } from "@greeter-guest/components/VenueCard";
import { ThemeFilterBar } from "@greeter-guest/components/ThemeFilterBar";
import { Activity } from "@greeter/core";
import { Theme } from "@greeter/core";

import { classNames, doNothing } from "@greeter/util";
import {
  IonPage,
  IonContent,
  IonPopover,
  IonList,
  IonItem,
} from "@ionic/react";
import { first, partial } from "lodash";
import { Area, Container } from "@greeter/matter";

import { Helmet } from "react-helmet";
import { IconChevronDown, IconCheck } from "@tabler/icons-react";

import css from "./VenueOverviewPage.module.scss";

const log = partial(console.log, "[VenueOverviewPage]");

export type OnThemeChange = (themeId?: string) => void;
export type OnCityChange = (city: string) => void;

export type VenueOverviewPageProps = {
  venues?: Venue[];
  activity?: Activity;
  selectedThemeId?: string;
  themes?: Theme[];
  onThemeChange?: OnThemeChange;
  cities: string[];
  city: string;
  onCityChange?: OnCityChange;
};

export const VenueOverviewPage: React.FC<VenueOverviewPageProps> = ({
  venues = [],
  activity,
  themes = [],
  selectedThemeId = "",
  onThemeChange = doNothing,
  onCityChange = doNothing,
  city,
  cities,
}) => {
  const wrappedVenues = useMemo(
    () =>
      venues.map((venue: Venue, i) => {
        return (
          <VenueCard
            key={venue.id}
            venue={venue}
            style={{ animationDelay: `${i * 50}ms` }}
            {...classNames(
              "animate__animated",
              "animate__fadeIn",
              "animate__faster"
            )}
          />
        );
      }),
    [venues]
  );

  const titleText = useMemo(
    () =>
      activity ? (
        <>
          Venues i <span className="highlight">{city}</span> med aktivitet{" "}
          <span className="highlight">{activity?.name}</span>
        </>
      ) : (
        <>
          Venues i{" "}
          <span className="highlight" id="venue-city" style={{ display: "flex", alignItems: "center" }}>
            {city} <IconChevronDown />
          </span>
          <IonPopover reference="trigger" trigger="venue-city">
            <IonContent>
              <IonList>
                {cities?.map((c) => {
                  return (
                    <IonItem
                      style={{
                        "--ion-background-color": "var(--gm-color-alt-bg)",
                        "font-weight": "bold",
                      }}
                      key={c}
                      button
                      detail={false}
                      onClick={() => onCityChange(c)}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        {c} {city === c && <IconCheck />}
                      </div>
                    </IonItem>
                  );
                })}
              </IonList>
            </IonContent>
          </IonPopover>
        </>
      ),
    [activity, city, cities]
  );

  useEffect(() => {
    const fst = first(themes);
    // Select the first theme if none are selected
    if (!selectedThemeId && fst) {
      onThemeChange(fst.id);
    }
  }, [onThemeChange, selectedThemeId, themes]);

  const themebarItems = useMemo(() => {
    return [
      { iconUrl: wineOutline, id: "all", name: "Alle" },
      ...(themes ?? []),
    ];
  }, [themes]);

  return (
    <IonPage>
      <Helmet>
        <title>Venue overview</title>
      </Helmet>
      <IonContent>
        <Container>
          <div
            className={css.VenueOverviewPage}
            style={{ paddingTop: "env(safe-area-inset-top)" }}
          >
            <Area>
              <h2 className={css.title}>{titleText}</h2>
            </Area>
            <ThemeFilterBar
              themes={themebarItems}
              selectedThemeId={selectedThemeId ?? ""}
              onClick={onThemeChange}
            />
            <Area>
              <UnorderedListComponent>{wrappedVenues}</UnorderedListComponent>
            </Area>
          </div>
        </Container>
      </IonContent>
    </IonPage>
  );
};
