import { useGreeterGuestApi } from "@greeter/api";
import { useEffect, useMemo } from "react";
import Routes from "@greeter-guest/utility/Routes";
import {
  getConfig,
  getEnv,
  getFirebaseConfig,
} from "@greeter-guest/utility/ConfigHooks";
import { createAuth } from "@greeter/auth";

export const useDefaultGuestApi = () => {
  const config = getConfig();
  const env = useMemo(() => getEnv(), []);
  const firebaseConfig = useMemo(() => getFirebaseConfig(), []);
  const auth = useMemo(() => createAuth({ env, firebaseConfig }), []);

  return useGreeterGuestApi(config, auth);
};

// TODO: What to do with this function?
export function resolveNewsLink(linkType: string, linkData: string): string {
  switch (linkType) {
    case "event":
      return Routes.greeterEventsTab.greeterEvent.route({ greeterEventId: linkData });
    case "venue":
      return Routes.venuesTab.venue.route({ venueId: linkData });
    case "external":
    default:
      // TODO: Treating unknown stuff as outbound might not be the smartest move.
      return linkData;
  }
}
