import css from "./BottomButtonBar.module.scss";

import React from "react";
import { doNothing, classNames } from "@greeter/util";
import { BasePropsWithChildren } from "@greeter/matter";

export type BottomButtonBarProps = BasePropsWithChildren & {
  onContinue?: () => void;
  onNotNow?: () => void;
}

export const BottomButtonBar: React.FC<BottomButtonBarProps> = ({
  className,
  onContinue = doNothing,
  onNotNow = doNothing,
  ...props
}) => {
  return <div {...props} {...classNames(className, css.BottomButtonBar)}></div>;
};
