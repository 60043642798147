import "./UpcomingGreeterEventsCarousel.scss";
import styles from "./UpcomingGreeterEventsCarousel.module.scss";
import React, { useMemo } from "react";
import { GreeterEvent, Venue } from "@greeter/core";

import { GreeterEventCard } from "@greeter-guest/components/GreeterEventCard";
import { DaysliceConfig } from "@greeter/core";
import { classNames } from "@greeter/util";
import { Carousel } from "@greeter/matter";
import { first } from "lodash";

export type UpcomingGreeterEventsCarouselProps = {
  cardWidth?: string | number;
  greeterEvents: GreeterEvent[];
  venues?: Venue[];
  daysliceConfig: DaysliceConfig;
};

const empty = [];

export const UpcomingGreeterEventsCarousel: React.FC<
  UpcomingGreeterEventsCarouselProps
> = ({ cardWidth, greeterEvents, venues = empty, daysliceConfig }) => {
  const cardsUpcomingEvents = useMemo(
    () =>
      greeterEvents
        .sort((a, b) => a.startsAt.getTime() - b.startsAt.getTime())
        .map((greeterEvent: GreeterEvent, index: number) => {
          return (
            <GreeterEventCard
              width={cardWidth}
              daysliceConfig={daysliceConfig}
              {...classNames(
                GreeterEvent.hasEnded(greeterEvent) ? styles.grayedOut : ""
              )}
              key={index}
              greeterEvent={greeterEvent}
              venue={first(venues.filter((v) => v.id === greeterEvent.venueId))}
            />
          );
        }),
    [greeterEvents, venues, cardWidth]
  );

  return (
    <div className={UpcomingGreeterEventsCarousel.name}>
      <Carousel spacing="md">{cardsUpcomingEvents}</Carousel>
    </div>
  );
};
