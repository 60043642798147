import React, { useCallback, useEffect, useMemo, useState } from "react";
import { isEmpty, classNames, doNothing } from "@greeter/util";
import { isEqual } from "lodash";
import { RoundedButton } from "../Button";
import { Carousel } from "../Carousel";

export type SelectValue<T> = {
  label: string;
  value: T;
};

export type SelectHighlighterProps<T> = {
  className?: string;
  activeClassName?: string;
  btnClassName?: string;
  selectedValue?: T;
  selectableValues: SelectValue<T>[];
  compare?: (a: T, b: T) => boolean;
  onSelect: (t: SelectValue<T>, index: number) => void;
};

export function SelectHighlighter<T>({
  className = "",
  activeClassName,
  btnClassName,
  selectedValue,
  selectableValues,
  onSelect = doNothing,
  // compare = (a, b) => a === b,
  compare = isEqual,
}: SelectHighlighterProps<T>) {
  useEffect(() => {
    dispatchEvent(new Event("resize"));
  });

  const handleSelect = useCallback(
    (i: number) => {
      if (!isEmpty(selectableValues)) onSelect(selectableValues[i], i);
    },
    [selectableValues, onSelect]
  );

  const selectedIndex = useMemo(
    () => findIndexOfValue(compare, selectableValues, selectedValue),
    [compare, selectableValues, selectedValue]
  );
  const wrappedButtons = useMemo(
    () =>
      selectableValues?.map((v, i) => {
        const active =
          i ===
          selectableValues.findIndex((sv) => {
            if (selectedValue) return compare(selectedValue, sv.value);
          });
        return (
          <RoundedButton
            activeClassName={activeClassName}
            className={btnClassName}
            key={i}
            active={active}
            onClick={() => {
              handleSelect(i);
            }}
          >
            {v.label}
          </RoundedButton>
        );
      }),
    [
      selectableValues,
      activeClassName,
      btnClassName,
      selectedValue,
      compare,
      handleSelect,
    ]
  );
  return (
    <Carousel
      index={selectableValues.findIndex((sv) => {
        if (selectedValue) return compare(selectedValue, sv.value);
      })}
      {...classNames(className)}
    >
      {wrappedButtons}
    </Carousel>
  );
}

function findIndexOfValue<T>(
  compare: (a: T, b: T) => boolean,
  selectableValues?: SelectValue<T>[],
  selectedValue?: T
) {
  if (selectableValues && selectedValue) {
    return selectableValues.findIndex((val) => {
      return compare(selectedValue, val.value);
    });
  }

  return -1;
}
