import React, { useEffect, useState } from "react";
import { classNames } from "@greeter/util";

import css from "./TextField.module.scss";
import { useInput } from "../Input";

export type TextFieldProps = React.HTMLProps<HTMLTextAreaElement> & {
  detectChanges?: boolean;
}

export const TextField: React.FC<TextFieldProps> = ({ className, detectChanges = false, ...props }) => {
  const [initialValue,] = useState(`${props.value}`);
  const {
    bind,
    hasChanged
  } = useInput({ initialValue });
  return (
    <textarea
      {...bind}
      {...props}
      {...classNames(
        css.TextField,
        className,
        detectChanges && hasChanged ? css.Changed : ""
      )}
    />
  );
}
