import { Booking } from "@greeter/core";
import { DatePeriod } from "@greeter/date";

export function deserializeBooking(json: any) {
  const booking = json as Booking;
  booking.tables = new Map(Object.entries(json.tables));
  booking.period = new DatePeriod({
    from: new Date(Date.parse(json.period.from)),
    to: new Date(Date.parse(json.period.from))
  });
  booking.createdAt = new Date(booking.createdAt);
  return booking;
}
