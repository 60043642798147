import React from "react";

import defaultImage from "../../assets/thegap_party1.jpg";

import { classNames } from "@greeter/util";
import { LazyImage, LazyImageProps } from "../LazyImage";

import css from "./LazyCoverImage.module.scss";

export type LazyCoverImageProps = {
  src?: string | false;
  alt?: string;
  blur?: boolean;
  fade?: boolean;
  className?: string;
  style?: React.CSSProperties;
  styles?: LazyImageProps["styles"]
};

export const LazyCoverImage: React.FC<LazyCoverImageProps> = ({
  className = "",
  src,
  blur = false,
  fade = false,
  alt = "",
  style,
  styles
}) => {
  return (
    <LazyImage
      classNames={{ container: fade ? css.Fade : "" }}
      {...classNames(
        "cover-centered",
        css.LazyImage,
        className,
        fade && css.Fade,
        blur && css.Blur
      )}
      style={style}
      styles={styles}
      src={src}
      defaultImage={defaultImage}
      alt={alt}
    />
  );
};
