import css from "./Title.module.scss";
import React from "react";

function Title() {
    return (
        <div className={css.Title}>
            Velkommen
            <br />
            til Greeter
        </div>
    );
}

export default Title;
