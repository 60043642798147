import css from "./DoneView.module.scss";

import React from "react";
import { IonIcon } from "@ionic/react";
import { checkmark } from "ionicons/icons";
import { CenteredContent } from "@greeter/matter";

export const DoneView: React.FC = () => {
  return (
    <div className={css.DoneView}>
      <CenteredContent>
        <IonIcon className={css.Checkmark} icon={checkmark} />
      </CenteredContent>
    </div>
  );
};
