import { useDefaultGuestApi } from "@greeter-guest/api/api";
import { useVenueQuery } from "@greeter/guest-api-hooks";
import { useMenuQuery } from "@greeter-guest/api/QueryHooks";
import { Menu, TableServiceOrder } from "@greeter/core";
import { mapQuery } from "@greeter/util";
import { logger, warner } from "@greeter/log";
import { Query } from "@greeter/query";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CartPage, OnCreateArgs, OnPayArgs } from "./CartPage";
import {
  getNumber,
  getString,
  getStringOr,
  useSearchParams,
} from "@greeter/url";
import { useHistory } from "react-router-dom";
import Routes, { Intent } from "@greeter-guest/utility/Routes";
import { Spinner, useAuth } from "@greeter/matter";
import { useLoading } from "@greeter/loading";
import { usePostHog } from "posthog-js/react";
import { useIonRouter } from "@ionic/react";

const name = "[CartPageApiHandler]";
const warn = warner(name);
const log = logger(name);

const mockMenu: Menu = {
  products: [],
  categories: [],
  featuredCategoryId: "",
};

export const CartPageApiHandler: React.FC = () => {
  const api = useDefaultGuestApi();
  const queries = useSearchParams();
  const menuQuery = useMenuQuery(api, getString(queries, "venueId"));
  const venueQuery = useVenueQuery(api, getStringOr(queries, "venueId", ""));

  const menu = useMemo(() => mapQuery(menuQuery), [menuQuery]);
  const venue = useMemo(() => mapQuery(venueQuery), [venueQuery]);

  const params = useSearchParams();
  const area = useMemo(() => getString(params, "area"), []);
  const tableNumber = useMemo(() => getNumber(params, "tableNumber"), []);
  const id = useMemo(() => getString(params, "id"), []);
  const intent = useMemo(() => getString(params, "intent") as Intent, []);
  const redirectStatus = useMemo(
    () => getString(params, "redirect_status"),
    []
  );

  const [loading, setLoading] = useState<undefined | string>();

  useEffect(() => {
    if (
      (!intent && intent === "check-payment") ||
      !id ||
      !redirectStatus ||
      !tableNumber ||
      venue.type !== "done" ||
      !area
    )
      return;

    if (redirectStatus === "failed") return;

    async function checkTableServiceOrderStatus() {
      const order = await api.fetchTableServiceOrder(id!);

      if (order.status === "pending") {
        history.push(
          Routes.receipt.route({
            venueId: Query.unwrap(venue)!.id,
            tableNumber: tableNumber!,
            tableServiceOrderId: id!,
            area: area!,
          })
        );
      }
    }

    setLoading("Tjekker om betalingen er gået igennem...");
    checkTableServiceOrderStatus().then(() => setLoading(undefined));
  }, [intent, id, redirectStatus, tableNumber, venue, area]);

  const { auth } = useAuth();

  const [tableServiceOrder, setTableServiceOrder] =
    useState<TableServiceOrder>();

  const history = useHistory();

  useEffect(() => {
    if (!auth) return;

    (async () => {
      try {
        log("Signing in as anon...");
        await auth?.signInAsAnon();
      } catch (e) {
        warn("Failed to sign in anonymously with error", e);
      }
    })();
  }, [auth]);

  const [paymentToken, setPaymentToken] = useState<string>();

  return (
    <>
      <CartPage
        id={tableServiceOrder?.id}
        menu={menu}
        venue={venue}
        area={area!}
        tableNumber={tableNumber!}
        paymentToken={paymentToken}
        tableServiceOrder={
          tableServiceOrder
            ? { type: "done", data: tableServiceOrder }
            : undefined
        }
        onCreate={async (args: OnCreateArgs) => {
          const tableServiceOrder = await api.createTableServiceOrder(args);
          log("ON CREATE");

          setTableServiceOrder(tableServiceOrder);
        }}
        onCreatePayment={async () => {
          if (!tableServiceOrder || !tableServiceOrder.order) {
            throw new Error(
              "Could not pay for order. Missing order for tableserviceorder"
            );
          }

          log("Creating payment for", tableServiceOrder);
          const payment = await api.createPayment({
            orderId: tableServiceOrder.order.id,
            redirectUrl:
              window.location.origin +
              Routes.receipt.route({
                tableServiceOrderId: tableServiceOrder.id,
                area: tableServiceOrder.tableId.area,
                tableNumber: tableServiceOrder.tableId.tableNumber,
                venueId: venue.type === "done" ? venue.data.id : "",
              }),
          });
          setPaymentToken(payment.token);
          log("Created payment", payment);
        }}
      />
      <Spinner reason={loading} />
    </>
  );
};
