import React from "react";

import { useLoading } from "@greeter/loading";
import { PulsingGreeter } from "./PulsingGreeter";
import { logger } from "@greeter/log";
import { classNames } from "@greeter/util";

import css from "./LoadingListener.module.scss";
import { Alert } from "../Alert";

const log = logger("[LoadingListener]");

export type SpinnerProps = {
  open?: boolean;
  reason?: string | false;
};
export function Spinner({ open, reason }: SpinnerProps) {
  return (
    <div
      className={css.SpinnerContainer}
      style={{ display: open ?? !!reason ? "initial" : "none" }}
    >
      <div
        onClick={(ev) => {
          ev.stopPropagation();
          ev.preventDefault();
        }}
        {...classNames(css.Backdrop, "animate__animated", "animate__fadeIn")}
      />
      <div className={css.LoadingWindow}>
        <Alert>
          <div className={css.LogoContainer}>
            <PulsingGreeter />
          </div>
          {!!reason && (
            <div
              key={reason.trim().length > 0 ? reason : "Bip bup bip..."}
              className={css.Reason}
            >
              {reason.trim().length > 0 ? reason : "Bip bup bip..."}
            </div>
          )}
        </Alert>
      </div>
    </div>
  );
}

export const LoadingListener: React.FC = () => {
  const loading = useLoading();
  return <Spinner open={loading.isLoading} reason={loading.reason} />;
};
