import React from "react";
import { InputProps, Input } from "../Input";
import { Hint } from "../Hint";

export type HintedInputProps = InputProps & {
  hint: string;
}

export const HintedInput: React.FC<HintedInputProps> = ({
  hint,
  className,
  ...props
}) => {
  return (
    <div className={className}>
      <Input {...props} />
      <Hint text={hint} />
    </div>
  );
};
