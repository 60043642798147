import React, { useContext, useMemo } from "react";

import { useDefaultGuestApi } from "@greeter-guest/api/api";
import { doNothing } from "@greeter/util";
import { partial } from "lodash";
import { useAuth } from "@greeter/matter";
import { useCustomerQuery } from "@greeter/guest-api-hooks";
import { CustomerUtil } from "@greeter/core";

const log = partial(console.log, "[LoginProvider]");

interface ContextParams {
  isCustomerComplete: boolean;
  refreshUser: () => Promise<void> | void;
}

const initialValue: ContextParams = {
  isCustomerComplete: false,
  refreshUser: doNothing,
};
const LoginContext = React.createContext(initialValue);

export type LoginProviderProps = React.PropsWithChildren & {};

export const LoginProvider: React.FC<LoginProviderProps> = ({ children }) => {
  const api = useDefaultGuestApi();
  const { isLoggedIn, refresh } = useAuth();
  const customerQuery = useCustomerQuery(api, { enabled: isLoggedIn });

  const isCustomerComplete = useMemo(() => {
    const customer = customerQuery.data;

    return customer && CustomerUtil.isComplete(customer);
  }, [customerQuery.data]);

  return (
    <LoginContext.Provider
      value={{
        isCustomerComplete: isCustomerComplete ?? false,
        refreshUser: async () =>
          void (await Promise.all([customerQuery.refetch(), refresh()])),
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export const useLogin = () => useContext(LoginContext);
