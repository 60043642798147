import React from "react";
import { Card, CardContent, CardHeader } from "../Card";
import { classNames, doNothing } from "@greeter/util";
import { LazyCoverImage } from "@greeter/matter";

import css from "./MiniGreeterEventButtonCard.module.scss";

const dateFormatter = new Intl.DateTimeFormat("da-DK", {
  day: "numeric",
  month: "short",
  weekday: "short",
});

export type MiniGreeterEventButtonCardProps = React.PropsWithChildren & {
  coverUrl: string;
  date: Date;
  isActive: boolean;
  id?: string;
  onClick?: () => void;
  className?: string;
};

export const MiniGreeterEventButtonCard: React.FC<
  MiniGreeterEventButtonCardProps
> = ({ id, coverUrl, date, isActive, onClick = doNothing, className, ...props }) => {
  return (
    <div id={id} onClick={onClick} {...props}>
      <Card
        {...classNames(
          css.MiniGreeterEventButtonCard,
          isActive ? css.Active : "",
          className
        )}
      >
        <CardHeader className={css.CardHeader}>
          <LazyCoverImage src={coverUrl} />
        </CardHeader>
        <CardContent className={css.CardContent}>
          <div>{dateFormatter.format(date)}</div>
        </CardContent>
      </Card>
    </div>
  );
};
