import { useMemo } from "react";
import { getApps, initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import { Env } from "@greeter/config";
import {
  Auth,
  connectAuthEmulator,
  browserLocalPersistence,
  browserPopupRedirectResolver,
  indexedDBLocalPersistence,
  initializeAuth,
  inMemoryPersistence,
  getAuth,
} from "firebase/auth";
import { logger, warner } from "@greeter/log";

const tagId = "[FirebaseHooks]";
const log = logger(tagId);
const warn = warner(tagId);

export const getOrInitFirebaseApp = (env: Env, config: any) => {
  const existingApp = getApps().find((a) => a.name === env);
  return existingApp ? existingApp : initializeApp(config, env);
};
export const useFirebaseApp = (env: Env, config: any) => {
  return useMemo(() => getOrInitFirebaseApp(env, config), [config, env]);
};

export function getOrInitFirebaseAuth(env: Env = "demo", config: any, testConfig?: { emulatorPort?: number }) {
  const app = getOrInitFirebaseApp(env, config);

  // TODO: Remove. Kept for now in case things break.
  // NOTE: We no longer need to init. on native platform
  //       as we are actually using the native Firebase
  //       to do the authentication
  // const auth = Capacitor.isNativePlatform()
  //   ? initializeAuth(app, {
  //       persistence: indexedDBLocalPersistence,
  //     })
  //   : getAuth(app);
  //

  const auth: Auth = initializeAuth(app, {
    persistence: [indexedDBLocalPersistence, browserLocalPersistence],
    popupRedirectResolver: navigator.userAgent.includes("jsdom") ? undefined : browserPopupRedirectResolver,
  });
  // IndexedDb seems to be an issue. Therefore we default to localStorage.
  // log("Setting persistence to", browserLocalPersistence);
  // setPersistence(auth, browserLocalPersistence);

  // const connected = Boolean(window.sessionStorage.getItem("emulatorConnected"));
  // if (
  //   window.location.href.match(/localhost|127.0.0.1/) &&
  //   !connected
  // ) {
  //   const emulatorHost = `http://${window.location.hostname}:${testConfig?.emulatorPort ?? "9099"}`;
  //   fetch(emulatorHost).then((response) => {
  //     if (response.ok) {
  //       warn("Detected auth emulator. Connecting...");
  //       warn(
  //         "When doing Google auth remember to use the matching url!\nFor example, use 127.0.0.1 if emulator is hosted on 127.0.0.1:9099. DOMAINS MUST MATCH!"
  //       );
  //       window.sessionStorage.setItem("emulatorConnected", "true");
  //       connectAuthEmulator(auth, emulatorHost, { disableWarnings: true });
  //     }
  //   }).catch(() => {
  //     console.log("Emulator is not live. Ignoring...");
  //   });
  // }

  return auth;
};

export const useFirebaseAuth = (env: Env = "demo", config: any) => {
  const auth = useMemo(() => getOrInitFirebaseAuth(env, config), [config, env]);

  return auth;
};

export const getOrInitFirebaseAnalytics = (env: Env = "demo", config: any) => {
  const app = getOrInitFirebaseApp(env, config);
  const analytics = getAnalytics(app);

  return analytics;
};

export const useFirebaseAnalytics = (env: Env = "demo", config: any) => {
  return useMemo(() => getOrInitFirebaseAnalytics(env, config), [config, env]);
};
