import React, { useEffect } from "react";
import { useParams, useHistory, RouteComponentProps } from "react-router-dom";
import { VenuePageApiHandler } from "./VenuePageApiHandler";
import { useIonRouter } from "@ionic/react";

type Params = {
  venueId: string;
};

function VenuePageRouteHandler({ match }: RouteComponentProps) {
  const { venueId } = useParams<Params>();
  const router = useIonRouter();

  console.log("[VenuePageRouteHandler][match]", match);
  console.log("[VenuePageRouteHandler][params]", venueId);
  console.log("[VenuePageRouteHandler][router]", router);

  return <VenuePageApiHandler venueId={venueId ?? ""} />;
};

export default VenuePageRouteHandler;
