import React from "react";
import { SwitchTransition, Transition } from "react-transition-group";
import { CenteredContent } from "../../CenteredContent";
import { SwipableContainer } from "../SwipableContainer";

type Stage = {
  name: string;
  component: React.ReactNode;
};

export type MultiStageConfirmerProps = React.PropsWithChildren &{
  style?: React.CSSProperties;
  className?: string;
  defaultComponent: React.ReactNode;
  stage: string;
  stages: Stage[];
}

export const MultiStageConfirmer: React.FC<MultiStageConfirmerProps> = ({
  style,
  className,
  defaultComponent,
  stage,
  stages,
}) => {
  const foundStage = stages.find((s) => s.name === stage);
  return (
    <SwipableContainer style={style} className={className}>
      <SwitchTransition mode={"out-in"}>
        <Transition key={stage} timeout={100} classNames="fade">
          {(state) => (
            <CenteredContent
              style={{
                display: state === "exited" ? "none" : "block",
                opacity: state === "entered" ? 1 : 0,
                transition: "150ms",
              }}
            >
              {foundStage ? foundStage.component : defaultComponent}
            </CenteredContent>
          )}
        </Transition>
      </SwitchTransition>
    </SwipableContainer>
  );
};
