import { useState, useEffect } from "react";

import { useIonViewDidEnter } from "@ionic/react";
import { useLocation } from "react-router-dom";

export function useSearchParams() {
  const location = useLocation();
  const [searchParams, setSearchParams] = useState(
    new URLSearchParams(location.search)
  );

  useEffect(() => {
    setSearchParams(new URLSearchParams(location.search));
  }, [location]);

  useIonViewDidEnter(
    () => setSearchParams(new URLSearchParams(location.search)),
    [location]
  );

  return searchParams;
}
